const mentors = [
  {
    id: 1,
    name: 'Vera Wijaya',
    title: 'Online Brand Management Trainee at L’Oréal',
    photoUrl: '/static/images/mentors1/vera.jpg',
    videoUrl: '/static/videos/mentors/vera-wijaya.mp4',
  },
  {
    id: 2,
    name: 'Samuel Davidsen Widjaja',
    title: 'Co-founder and CEO at Aksel',
    photoUrl: '/static/images/mentors1/samuel.jpg',
    videoUrl: '/static/videos/mentors/samuel-davidsen-widjaja.mp4',
  },
  {
    id: 3,
    name: 'Nyoman Meta Rosanti',
    title: 'Talent manager at Glints Singapore',
    photoUrl: '/static/images/mentors1/nyoman.jpeg',
    videoUrl: '/static/videos/mentors/nyoman-meta-rosanti.mp4',
  },
];

export default mentors;
