import { Box, IconButton, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { format } from 'date-fns';
import idLocale from 'date-fns/locale/id';

const WorkExperienceCard = ({ item, handleDelete, handleEdit }) => {
  item?.jobs?.forEach((job) => {
    if (!job.startDate) {
      job.startDate = new Date().toISOString().split('T')[0];
    }

    if (!job.endDate) {
      job.endDate = new Date().toISOString().split('T')[0];
    }
  });

  return (
    <Box style={{ display: 'flex', alignItems: 'center', padding: '0.6rem 0' }}>
      <DragIndicatorIcon
        style={{
          color: '#BDBDBD',
          marginRight: '0.5rem',
        }}
      />
      <Box style={{ flex: 1 }}>
        <Typography variant='pBold'>{item.name}</Typography>
        {item?.jobs?.map((job) => (
          <Box
            key={job.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0.1rem 0',
            }}
          >
            <Box style={{ flex: 1 }}>
              <Typography variant='p' style={{ color: '#BDBDBD' }}>{`${format(
                new Date(job.startDate),
                'dd MMMM yyyy',
                {
                  locale: idLocale,
                },
              )} - ${format(new Date(job.endDate), 'dd MMMM yyyy', {
                locale: idLocale,
              })}`}</Typography>
            </Box>
            <Box>
              <IconButton
                size='small'
                edge='end'
                style={{ color: '#BDBDBD' }}
                onClick={() => handleEdit(job)}
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                size='small'
                style={{ color: '#BDBDBD' }}
                onClick={() => handleDelete(job.id)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WorkExperienceCard;
