import { useState } from 'react';

import { Checkbox, FormControlLabel, Grid, MenuItem } from '@material-ui/core';

import CustomButton from 'src/components/button';
import CustomInput from 'src/components/cvMaker/CustomInput';
import CustomDatePicker from 'src/components/cvMaker/CustomDatePicker';
import { format } from 'date-fns';

const WorkExperienceForm = ({
  experiences,
  dataDetail,
  handleAdd,
  setExperiences,
  handleClose,
}) => {
  const [values, setValues] = useState(dataDetail);

  const [isWorkingHere, setIsWorkingHere] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsWorkingHere(!isWorkingHere);

    if (event.target.checked) {
      setValues({
        ...values,
        endDate: format(new Date(), 'yyyy-MM-dd'),
      });
    }
  };

  const handleHeadingChange = (event) => {
    setExperiences({
      ...experiences,
      heading: event.target.value,
    });
  };

  const handleDateChange = (date, name) => {
    setValues({
      ...values,
      [name]: format(new Date(date), 'yyyy-MM-dd'),
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container spacing={1} marginTop={2}>
      <Grid item xs={12}>
        <CustomInput
          label='Heading'
          name='heading'
          onChange={handleHeadingChange}
          value={experiences.heading}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomInput
          label='Job Title'
          name='position'
          onChange={handleChange}
          value={values.position}
          // defaultValue={dataDetail.position}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Company'
          name='company'
          onChange={handleChange}
          value={values.company}
          // defaultValue={dataDetail.company}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Employment Type'
          name='employment'
          select
          onChange={handleChange}
          value={values.employment}
          // defaultValue={dataDetail.employment}
        >
          <MenuItem value='Full Time'>Full Time</MenuItem>
          <MenuItem value='Part Time'>Part Time</MenuItem>
          <MenuItem value='Freelance'>Freelance</MenuItem>
          <MenuItem value='Contract'>Contract</MenuItem>
          <MenuItem value='Internship'>Internship</MenuItem>
          <MenuItem value='Volunteer'>Volunteer</MenuItem>
        </CustomInput>
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          label='Start Date'
          name='startDate'
          onChange={(date) => handleDateChange(date, 'startDate')}
          value={values.startDate}
          // defaultValue={dataDetail.startDate}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          label='End Date'
          name='endDate'
          onChange={(date) => handleDateChange(date, 'endDate')}
          value={values.endDate}
          // defaultValue={dataDetail.endDate}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isWorkingHere}
              onChange={handleCheckboxChange}
              name='isWorkingHere'
              color='primary'
              size='small'
            />
          }
          label='I currently work here'
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Summary'
          name='summary'
          onChange={handleChange}
          value={values.summary}
          // defaultValue={dataDetail.summary}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={() => {
            handleAdd(values, dataDetail.id ? 'edit' : 'add');
          }}
          fullWidth
        >
          Save Experience
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          fullWidth
        >
          Cancel
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default WorkExperienceForm;
