import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Divider, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import DragNDropList from 'src/components/cvMaker/DragNDropList';
import WorkExperienceCard from 'src/components/cvMaker/workExperience/WorkExperienceCard';
import WorkExperienceForm from 'src/components/cvMaker/workExperience/WorkExperienceForm';
import { updateCvDetail } from 'src/redux/actions';
import CustomButton from '../button';

const WorkExperience = ({ cvDetail }) => {
  const { work } = cvDetail;
  const [experiences, setExperiences] = useState(work);
  const [dataDetail, setDataDetail] = useState({});
  const [formOpen, setFormOpen] = useState(false);

  const [orderedExperience, setOrderedExperience] = useState([]);

  useEffect(() => {
    const groupedExperience =
      experiences.items &&
      experiences.items.reduce((result, experience) => {
        const isFound = result.findIndex(
          (el) => el.name === experience.company,
        );

        if (isFound !== -1) {
          return result.map((el) => {
            if (el.name === experience.company) {
              return {
                ...el,
                jobs: [...el.jobs, experience],
              };
            }

            return el;
          });
        }

        return [
          ...result,
          {
            name: experience.company,
            id: experience.company,
            jobs: [experience],
          },
        ];
      }, []);

    setOrderedExperience(groupedExperience);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiences || formOpen]);

  const handleAdd = (values, type) => {
    let newExperience = {};
    if (type === 'edit') {
      const customExp = { ...experiences };
      customExp.items.forEach((exp) => {
        if (exp.id === values.id) {
          exp.company = values.company || '';
          exp.employment = values.employment || '';
          exp.endDate =
            values.endDate || new Date().toISOString().split('T')[0];
          exp.id = values.id;
          exp.position = values.position || '';
          exp.startDate =
            values.startDate || new Date().toISOString().split('T')[0];
          exp.summary = values.summary || '';
          exp.website = values.website || '';
        }
      });
      newExperience = customExp;
    } else {
      if (experiences.items) {
        values.company = values.company || '';
        values.employment = values.employment || '';
        values.endDate =
          values.endDate || new Date().toISOString().split('T')[0];
        values.position = values.position || '';
        values.startDate =
          values.startDate || new Date().toISOString().split('T')[0];
        values.summary = values.summary || '';
        values.website = values.website || '';

        newExperience = {
          ...experiences,
          items: [...experiences.items, { ...values, id: uuid() }].sort(
            (a, b) => {
              return new Date(b.endDate) - new Date(a.endDate);
            },
          ),
        };
      } else {
        const newItems = [];
        newItems.push({ ...values, id: uuid() });
        newExperience = { ...experiences, items: newItems };
      }
    }

    cvDetail['work'] = newExperience;
    updateCvDetail(cvDetail);
    setExperiences(newExperience);
    setFormOpen(false);
  };

  const handleEdit = (d) => {
    setDataDetail(d);
    setFormOpen(true);
  };

  const handleDelete = (id) => {
    const newExperience = {
      ...experiences,
      items: experiences.items.filter((experience) => experience.id !== id),
    };
    cvDetail['work'] = newExperience;
    updateCvDetail(cvDetail);
    setExperiences(newExperience);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(orderedExperience);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrderedExperience(items);
    const newExperience = {
      ...experiences,
      items: items.reduce((result, item) => {
        return [...result, ...item.jobs];
      }, []),
    };
    cvDetail['work'] = newExperience;
    updateCvDetail(cvDetail);
    setExperiences(newExperience);
  };

  return (
    <>
      {!formOpen && (
        <>
          <Typography
            variant='sectionHeader'
            style={{ margin: '1rem 0 0.5rem 0' }}
          >
            {experiences.heading}
          </Typography>
          <Divider style={{ color: '#E8E8E8' }} />

          <DragNDropList
            handleOnDragEnd={handleOnDragEnd}
            items={orderedExperience}
            setItems={setOrderedExperience}
          >
            <WorkExperienceCard
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </DragNDropList>

          <br />
          <CustomButton
            onClick={() => {
              setFormOpen(true);
              setDataDetail({});
            }}
            color='grey'
            startIcon={<Add />}
            fullWidth
          >
            Add another experience
          </CustomButton>
        </>
      )}
      {formOpen && (
        <WorkExperienceForm
          experiences={experiences}
          dataDetail={dataDetail}
          handleAdd={handleAdd}
          setExperiences={setExperiences}
          handleClose={() => setFormOpen(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cvDetail: state.cv.cvDetail,
});

export default connect(mapStateToProps)(WorkExperience);
