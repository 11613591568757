import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
  MenuItem,
  Drawer,
  Stack,
  Divider,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import CardPackage from './CardPackage';
import { cvReviewPackage } from 'src/templates/cvReviewPackage';
import CustomInput from '../cvMaker/CustomInput';
import CustomButton from '../button';
import voucherCode from 'src/templates/vouchers';
import { numberWithCommas } from 'src/utils/utilities';
import firebase from 'firebase/app';
import reactGA from 'react-ga';
import { bundlingAtsc } from 'src/templates/bundling';
import { DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
    backgroundColor: '#fff !important',
    color: '#404040 !important',
    boxShadow: 'none !important',
  },
  bookingButtonContainer: {
    padding: '16px 70px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
    bottom: 0,
    position: 'sticky',
    width: '100%',
    backgroundColor: '#fff',
    '@media (max-width:767px)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: '16px',
    },
  },
  bookingButtonText: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:767px)': {
      display: 'block',
    },
  },
  bookingButton: {
    width: '100%',
    '@media (max-width:767px)': {
      width: 'auto',
    },
  },
  '@global': {
    '.css-zw3mfo-MuiModal-root-MuiDialog-root': {
      zIndex: '10001 !important',
    },
    '.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root': {
      zIndex: '10002 !important',
    },
    '.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall > div':
      {
        display: 'none !important',
      },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Header = () => (
  <Box
    display={{ xs: 'none', lg: 'block' }}
    style={{ width: '100%', backgroundColor: '#1D2D5E', padding: '48px 70px' }}
  >
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='20' cy='20' r='20' fill='#5BC0EB' fill-opacity='0.1' />
        <path
          d='M25.5925 30.7159C24.7253 29.973 24.8281 30.0241 24.5212 29.6445C24.2142 29.2649 25.5925 29.2874 25.5925 29.2874L26.9869 30.0026C27.1807 30.4577 27.2425 30.6899 26.2728 30.7169C25.303 30.7439 25.5925 30.7159 25.5925 30.7159Z'
          fill='#788A96'
        />
        <path
          d='M30.3709 28.7338C30.3709 30.224 29.1627 31.4322 27.6725 31.4322H12.8312C11.341 31.4322 10.1328 30.224 10.1328 28.7338V9.8449C10.1328 8.3547 11.341 7.14648 12.8312 7.14648H27.6725C29.1627 7.14648 30.3709 8.3547 30.3709 9.8449V28.7338Z'
          fill='#1894C8'
        />
        <path
          d='M27.6733 23.3354C27.6733 23.7085 27.3718 24.01 26.9987 24.01H13.5066C13.1343 24.01 12.832 23.7085 12.832 23.3354C12.832 22.9624 13.1343 22.6608 13.5066 22.6608H26.9987C27.3718 22.6608 27.6733 22.9624 27.6733 23.3354ZM16.8797 26.0339C16.8797 26.4069 16.5774 26.7085 16.205 26.7085H13.5066C13.1343 26.7085 12.832 26.4069 12.832 26.0339C12.832 25.6608 13.1343 25.3593 13.5066 25.3593H16.205C16.5774 25.3593 16.8797 25.6608 16.8797 26.0339ZM27.6733 12.5418C27.6733 12.9142 27.3718 13.2164 26.9987 13.2164H13.5066C13.1343 13.2164 12.832 12.9142 12.832 12.5418C12.832 12.1694 13.1343 11.8672 13.5066 11.8672H26.9987C27.3718 11.8672 27.6733 12.1694 27.6733 12.5418ZM27.6733 15.2402C27.6733 15.6133 27.3718 15.9148 26.9987 15.9148H13.5066C13.1343 15.9148 12.832 15.6133 12.832 15.2402C12.832 14.8671 13.1343 14.5656 13.5066 14.5656H26.9987C27.3718 14.5656 27.6733 14.8671 27.6733 15.2402ZM27.6733 17.9386C27.6733 18.3117 27.3718 18.6132 26.9987 18.6132H13.5066C13.1343 18.6132 12.832 18.3117 12.832 17.9386C12.832 17.5656 13.1343 17.264 13.5066 17.264H26.9987C27.3718 17.264 27.6733 17.5656 27.6733 17.9386ZM27.6733 20.637C27.6733 21.0101 27.3718 21.3116 26.9987 21.3116H13.5066C13.1343 21.3116 12.832 21.0101 12.832 20.637C12.832 20.264 13.1343 19.9624 13.5066 19.9624H26.9987C27.3718 19.9624 27.6733 20.264 27.6733 20.637Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.0295 26.0191C18.8704 25.8932 16.4838 23.3724 16.6989 20.3887C16.7207 20.0863 16.7684 19.7907 16.8397 19.5039C16.2365 20.3088 15.8523 21.2717 15.777 22.3164C15.5619 25.3001 17.9486 27.821 21.1076 27.9469C22.4845 28.0017 23.7808 27.5926 24.8231 26.8665L25.6877 27.7798C25.3343 28.0792 25.2978 28.5858 25.6062 28.9115L27.9276 31.3635C28.5443 32.0154 29.618 32.0582 30.3249 31.4591C30.8395 31.0232 31.0178 30.3669 30.8384 29.7907C30.1715 30.0958 29.3562 29.9714 28.8494 29.4358L26.528 26.9838C26.2952 26.7379 26.259 26.3889 26.4073 26.1039L26.047 25.7234C26.1146 25.6388 26.1798 25.5524 26.2423 25.4641L25.745 24.9388C24.7027 25.6649 23.4064 26.074 22.0295 26.0191ZM27.0233 23.8532L26.9689 23.7957C27.0054 23.75 27.0412 23.7038 27.0762 23.6571C27.0598 23.7229 27.0422 23.7883 27.0233 23.8532Z'
          fill='#66757F'
        />
        <path
          d='M26.3622 23.8477L25.026 25.0434L27.1189 27.3823L28.4552 26.1862L26.3622 23.8477Z'
          fill='#293F85'
        />
        <path
          d='M31.6119 28.3688L29.1258 25.5909C28.7956 25.2219 28.2288 25.1904 27.8598 25.5207L26.5235 26.7164C26.1545 27.0466 26.1231 27.6133 26.4533 27.9823L28.939 30.7602C29.5994 31.4987 30.7338 31.5617 31.4718 30.9008C32.2094 30.2408 32.2719 29.1068 31.6119 28.3688Z'
          fill='#FACD11'
        />
        <path
          d='M21.9094 25.9165C19.1168 25.7615 16.9785 23.3719 17.1335 20.5793C17.2885 17.7866 19.6781 15.6483 22.4708 15.8033C25.2634 15.9583 27.4017 18.3479 27.2467 21.1406C27.0917 23.9333 24.7021 26.0715 21.9094 25.9165Z'
          fill='white'
          fill-opacity='0.75'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.031 20.5075C15.8457 23.8451 18.4012 26.701 21.7389 26.8863C25.0766 27.0715 27.9325 24.516 28.1177 21.1783C28.303 17.8407 25.7474 14.9848 22.4098 14.7995C19.0721 14.6143 16.2162 17.1698 16.031 20.5075ZM17.8171 20.6051C17.6867 22.9538 19.4851 24.9635 21.8338 25.0939C24.1825 25.2242 26.1922 23.4259 26.3226 21.0771C26.4529 18.7284 24.6546 16.7187 22.3059 16.5883C19.9571 16.458 17.9474 18.2563 17.8171 20.6051Z'
          fill='#1D2D5E'
        />
      </svg>
      <Typography color='#ffffff' variant='header32'>
        CV Review
      </Typography>
    </Box>

    <Typography color='#ffffff' variant='subHeader16' sx={{ mt: 1 }}>
      Layanan CV Preview terpercaya oleh senior praktisi HR di Indonesia.
    </Typography>
  </Box>
);

export default function DialogCvReview({ open, handleClose, data }) {
  const classes = useStyles();
  const [dataSelected, setDataSelected] = useState({
    id: '7-days',
    title: '7 Days',
    time: 7,
    price: 60000,
  });
  const [voucherValue, setVoucherValue] = useState('');
  const [isVoucher, setIsVoucher] = useState(false);
  const [endPriceValue, setEndPriceValue] = useState(dataSelected.price);
  const [finalPrice, setFinalPrice] = useState(dataSelected.price);
  const [onLoadingPayment, setOnLoadingPayment] = useState(false);
  const [formValue, setFormValue] = useState({
    role: 'marketing',
    notes: '',
    bundleAtsc: '|',
  });
  const user = JSON.parse(localStorage.getItem('user'));
  const [emailTo, setEmailTo] = useState(user.email);
  const [priceOpen, setPriceOpen] = useState(false);
  const [cvrDiscount, setCvrDiscount] = useState(null);
  const [selectedBundle, setSelectedBundle] = useState({});
  const [paymentUrl, setPaymentUrl] = useState(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setPriceOpen(open);
  };

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'bundleAtsc') {
      const findBundle = bundlingAtsc.filter(
        (bundle) => bundle.id === event.target.value,
      );
      if (findBundle[0]) {
        setSelectedBundle(findBundle[0]);
        setFinalPrice(
          endPriceValue + findBundle[0].price - findBundle[0].discountPrice,
        );
      } else {
        setSelectedBundle({});
        setFinalPrice(endPriceValue);
      }
    }
  };

  const handleSelect = (pkg) => {
    setDataSelected(pkg);
    setEndPriceValue(pkg.price);
    setFinalPrice(pkg.price);
    setCvrDiscount(null);
    setSelectedBundle({});
    setFormValue({
      ...formValue,
      bundleAtsc: '|',
    });
    setIsVoucher(false);
    document.getElementById('voucher').value = '';
    setVoucherValue('');
  };

  const handleSubmitVoucher = async () => {
    setEndPriceValue(dataSelected.price);

    const ref = firebase.database().ref();
    const resVoucher = await ref.child('vouchers').child(voucherValue).get();

    if (!resVoucher.val()) return alert('Voucher tidak ditemukan');

    // check voucher code in date range
    const epochNow = Math.round(new Date().getTime() / 1000);
    const {
      startAt,
      closeAt,
      id,
      users,
      types,
      appliesTo,
      discount,
      counter,
      max,
    } = resVoucher.val();

    if (epochNow < startAt || epochNow > closeAt) {
      alert('Voucher expired');
      return;
    }

    if (JSON.parse(users).includes(user.uid)) {
      alert('Voucher sudah Anda digunakan');
      return;
    }

    if (counter >= max) {
      alert('Voucher sudah mencapai batas penggunaan');
      return;
    }

    if (id) {
      // cek jika voucher kodenya bener
      const userExist = JSON.parse(users).findIndex(
        (user) => user === user.uid,
      );
      if (userExist === -1) {
        if (types.indexOf('cv-review') !== -1) {
          if (appliesTo.indexOf(dataSelected.id) !== -1) {
            if (discount > 1) {
              // kalau diatas 1.0 itu dianggap potongan langsung
              const tempPrice = dataSelected.price - discount;
              setEndPriceValue(tempPrice);
              setCvrDiscount(discount);
              if (selectedBundle.price) {
                setFinalPrice(
                  tempPrice +
                    selectedBundle.price -
                    selectedBundle.discountPrice,
                );
              } else {
                setFinalPrice(tempPrice);
              }
            } else {
              const tempPrice = dataSelected.price * (1 - discount);
              setEndPriceValue(dataSelected.price * (1 - discount));
              setCvrDiscount(dataSelected.price * discount);
              if (selectedBundle.price) {
                setFinalPrice(
                  tempPrice +
                    selectedBundle.price -
                    selectedBundle.discountPrice,
                );
              } else {
                setFinalPrice(tempPrice);
              }
            }
            setIsVoucher(true);
          } else {
            alert('Voucher tidak berlaku untuk paket ini');
            return;
          }
        } else {
          alert('Voucher tidak ditemukan');
          return;
        }
      } else {
        alert('Anda sudah menggunakan kode ini');
      }
    } else {
      alert('voucher code gagal diterapkan');
    }

    reactGA.event({
      category: 'CV Review',
      action: 'User Set Voucher',
      label: user.uid,
    });

    // let code = voucherCode.filter((c) => {
    //   if (!c.used) {
    //     if (c.code === voucherValue && c.type === 'cv-review') {
    //       if (new Date() < new Date(c.until)) {
    //         if (c.appliesTo.indexOf(dataSelected.id) !== -1) {
    //           c.used = true;
    //           return true;
    //         } else {
    //           alert('Voucher ini tidak berlaku untuk paket ini');
    //         }
    //       } else {
    //         alert('Voucher ini sudah kadaluarsa');
    //       }
    //     }
    //   }

    //   return false;
    // })[0];

    // if (code) {
    //   if (code.discount > 1) {
    //     setEndPriceValue(dataSelected.price - code.discount);
    //   } else {
    //     setEndPriceValue(dataSelected.price * (1 - code.discount));
    //   }
    //   setIsVoucher(true);
    // } else {
    //   alert(`Voucher ${voucherValue} gagal diterapkan`);
    // }
  };

  const cancelVoucher = () => {
    setEndPriceValue(dataSelected.price);

    let code = voucherCode.filter((c) => {
      if (c.used) {
        if (c.code === voucherValue.toUpperCase() && c.type === 'cv-review') {
          c.used = false;
          return true;
        }
      }

      return false;
    })[0];

    if (code) {
      setVoucherValue('');
      setIsVoucher(false);
    }
    setVoucherValue('');
    setIsVoucher(false);
  };

  const handlePayment = () => {
    setOnLoadingPayment(true);
    let firstName;
    let lastName;

    if (formValue.notes.length < 10) {
      setOnLoadingPayment(false);
      alert('Tuliskan catatan di booking, catatan harus diatas 10 karakter.');
      return;
    }

    if (user != null) {
      [firstName, lastName] = user.displayName.split(' ');
      const voucherFinal = isVoucher ? voucherValue : '';

      let content = {
        customerFirstName: firstName,
        customerLastName: lastName,
        customerEmail: emailTo,
        customerId: user.uid,
        productId: data.id,
        productName: data.name,
        productPrice: endPriceValue,
        productEmail: data.driveUrl || `https://aksel.co.id/rd/${data.id}`,
        bookTime: dataSelected.time,
        transactionType:
          formValue.bundleAtsc === '|' ? 'cvReview' : 'bundleAtsc',
        voucherCode: voucherFinal,
        packageId: dataSelected.id,
        bookNotes: formValue.notes,
        reviewPurpose: formValue.role,
      };

      if (selectedBundle.id) {
        content.atsPackageId = selectedBundle.id;
        content.atsPackageName = selectedBundle.name;
        content.atsPackageDuration = selectedBundle.duration;
        content.atsPackagePrice = selectedBundle.price;
        content.atsPackageDiscount = selectedBundle.discountPrice;
      }

      if (voucherFinal) {
        const voucherRef = firebase.database().ref(`vouchers/${voucherFinal}`);
        // check voucher belum sampai max
        voucherRef.transaction((postVoucher) => {
          if (!postVoucher) {
            return {};
          }

          // nambahin ke voucer counter & daftarin usernya
          postVoucher.counter++;
          const currUsers = JSON.parse(postVoucher.users);
          postVoucher.users = JSON.stringify([...currUsers, user.uid]);
          return postVoucher;
        });
      }

      let paymentServer =
        // 'http://node-express-env.eba-3gz67gz9.ap-southeast-1.elasticbeanstalk.com/token';
        process.env.REACT_APP_DEPLOYED === 'dev'
          ? 'https://aksel-payment-staging.herokuapp.com/create/invoice'
          : 'https://aksel-payment.herokuapp.com/create/invoice';
      // let paymentServer =
      // // 'http://node-express-env.eba-3gz67gz9.ap-southeast-1.elasticbeanstalk.com/token';
      // process.env.REACT_APP_DEPLOYED === 'dev'
      //   ? 'https://aksel-payment-staging.herokuapp.com/token'
      //   : 'https://aksel-payment.herokuapp.com/token';

      let resumeServer =
        process.env.REACT_APP_DEPLOYED === 'dev'
          ? 'https://aksel-resume-staging.herokuapp.com'
          : 'https://aksel-resume.herokuapp.com';

      axios
        .post(paymentServer, content, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          const resp = response.data;
          setOnLoadingPayment(false);
          // window.snap.pay(resp.transactionToken);
          setPaymentUrl(resp.resp.invoice_url);
          content['bookId'] = resp.orderId;
          axios
            .post(
              // 'http://resume-backend-stagging.eba-hsbygz2h.ap-southeast-1.elasticbeanstalk.com/writeCvMaker',
              `${resumeServer}/writeCvMaker`,
              content,
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((e) => {
          alert(
            'Cannot connecting to payment. Please contact customer support or wait a minutes.',
          );
          setOnLoadingPayment(false);
        });
    } else {
      alert('User does not exist. Please contact customer support');
      setOnLoadingPayment(false);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar
            sx={{
              height: 48,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='sectionHeader'>CV Review</Typography>
            <div style={{ width: '24px' }}></div>
          </Toolbar>
        </AppBar>

        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '4.5rem',
          }}
        >
          <Header />
          <Grid
            container
            spacing={2}
            padding={{ xs: '16px', lg: '32px 70px ' }}
          >
            <Grid
              item
              xs={0}
              lg={7}
              display={{ xs: 'none', lg: 'block', paddingRight: '16px' }}
            >
              <Typography color='#252525' variant='sectionHeader'>
                Hal-hal yang akan kamu dapatkan adalah:
              </Typography>
              <Typography variant='pD' sx={{ marginTop: '16px' }}>
                1. Panduan Umum cara membuat CV profesional berdasarkan praktik
                di Indonesia <br />
                2. Review personal CV kamu oleh reviewer profesional dengan
                format Voice Note <br />
                3. Contoh CV profesional yang tepat <br />
                4. Template CV profesional
              </Typography>
              <Typography
                color='#BDBDBD'
                variant='pD'
                sx={{ marginTop: '40px' }}
              >
                <span style={{ color: '#E86258' }}>*</span> Maks. 1 hari setelah
                mendaftar kamu akan mendapatkan email dari Aksel untuk informasi
                lebih lanjut.
              </Typography>
              <Typography
                color='#BDBDBD'
                variant='pD'
                sx={{ marginTop: '24px' }}
              >
                <span style={{ color: '#E86258' }}>**</span> Hasil yang
                dikirimkan merupakan jabaran ulasan mengenai CV kamu dalam
                bentuk poin-poin di file terpisah berdasarkan standar
                profesional. Kami TIDAK menyediakan jasa joki atau pembuatan CV.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={5}
              gap={{ xs: '16px', lg: '32px' }}
            >
              <Grid item xs={12}>
                <Typography variant='subHeader16' style={{ color: '#252525' }}>
                  Pilih Durasi CV Review
                </Typography>

                <Grid container spacing={2}>
                  {cvReviewPackage.map((pkg) => (
                    <Grid item xs={6}>
                      <CardPackage
                        data={pkg}
                        selected={dataSelected.id}
                        handleSelect={() => handleSelect(pkg)}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Typography variant='p' style={{ color: '#D0D0D0' }}>
                  <span style={{ color: '#E86258' }}>*</span>
                  Review akan dikirim sekitar jam 8-10 malam!
                  <br />
                  <b>Jaminan uang kembali 50%</b> jika hasil review Anda
                  tertunda lebih dari 20 menit.
                </Typography>
              </Grid>

              {/* card email */}
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: '#EFF9FD',
                  boxShadow: '0px 4px 8px rgba(37, 37, 37, 0.08)',
                  borderRadius: '8px',
                  padding: '32px 14px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant='subHeader16'
                  gutterBottom
                  style={{ color: '#252525' }}
                >
                  Kami akan mengirimkan Hasil CV Review ke kamu melalui <br />
                  Email ini!
                </Typography>
                <Typography
                  variant='p'
                  style={{ color: '#BDBDBD', marginBottom: '24px' }}
                >
                  Pastikan alamat email kamu benar
                </Typography>

                <CustomInput
                  name='email'
                  placeholder='your@mail.com'
                  defaultValue={user.email}
                  onChange={(e) => setEmailTo(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='subHeader16'
                  style={{ color: '#252525' }}
                  gutterBottom
                >
                  Role yang Akan Kamu Tuju Setelah Melakukan CV Review
                </Typography>

                <CustomInput
                  name='role'
                  onChange={handleChange}
                  value={formValue.role}
                  select
                >
                  <MenuItem value='marketing'>Marketing</MenuItem>
                  <MenuItem value='business-development'>
                    Business Development
                  </MenuItem>
                  <MenuItem value='finance'>Finance</MenuItem>
                  <MenuItem value='human-resources'>Human Resources</MenuItem>
                  <MenuItem value='project-management'>
                    Project Management
                  </MenuItem>
                  <MenuItem value='other'>Role Lainnya</MenuItem>
                </CustomInput>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='subHeader16'
                  style={{ color: '#252525' }}
                  gutterBottom
                >
                  Catatan untuk CV Review
                </Typography>

                <CustomInput
                  id='notes'
                  name='notes'
                  onChange={handleChange}
                  value={formValue.notes}
                  placeholder={
                    formValue.role === 'other'
                      ? 'Role lain apalagi yang ingin kamu tuju selain role yang sudah ada di atas ? dan apa yang kamu butuhkan dari CV Review ini ?'
                      : 'Apa yang kamu butuhkan dari CV Review ini ?'
                  }
                  multiline
                  error={formValue.notes.length < 10}
                  helperText={
                    formValue.notes.length < 10
                      ? 'Catatan harus diatas 10 karakter'
                      : ''
                  }
                  rows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='subHeader16'
                  style={{ color: '#252525' }}
                  gutterBottom
                >
                  Beli Sama ATS Checker Lebih Untung!
                </Typography>

                <Typography variant='p' style={{ color: '#D0D0D0' }}>
                  Lebih dari 500 perusahaan besar di dunia termasuk di Indonesia
                  memakai software ATS, sebuah sistem penyeleksi CV kandidat.
                  Pastikan CV-mu ATS-Friendly dan dapetin panggilan interview
                  pake ATS Checker Aksel!
                </Typography>
                <CustomInput
                  name='bundleAtsc'
                  onChange={handleChange}
                  value={formValue.bundleAtsc}
                  select
                  placeholder='Pilih Paket'
                >
                  <MenuItem value={'|'}>Pilih Paket</MenuItem>
                  {bundlingAtsc.map((bundle) => (
                    <MenuItem
                      value={bundle.id}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <Typography variant='pD'>
                        {bundle.name}
                        <span
                          style={{ fontSize: '14px', color: '#BDBDBD' }}
                        >{` (${bundle.desc})`}</span>
                      </Typography>
                      <div>
                        <Typography variant='pD'>
                          Rp.{' '}
                          {numberWithCommas(
                            bundle.price - bundle.discountPrice,
                          )}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
                </CustomInput>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='subHeader16'
                  style={{ color: '#252525' }}
                  gutterBottom
                >
                  Gunakan Kode Promo
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <CustomInput
                      id='voucher'
                      name='voucher'
                      placeholder='Masukkan kode promo'
                      value={voucherValue}
                      onChange={(e) => setVoucherValue(e.target.value)}
                      disabled={isVoucher}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomButton
                      sx={{ marginTop: '0.4rem', padding: '5px 8px' }}
                      onClick={isVoucher ? cancelVoucher : handleSubmitVoucher}
                      fullWidth
                    >
                      {isVoucher && 'Batalkan Voucher'}
                      {!isVoucher && 'Terapkan'}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.bookingButtonContainer}>
            <div style={{ maxWidth: '720px', width: '100%', margin: 'auto' }}>
              <Box
                className={classes.bookingButtonText}
                mb={1}
                onClick={() => setPriceOpen(true)}
              >
                <Typography
                  variant='p20'
                  sx={{ fontWeight: 700, color: '#252525' }}
                >
                  Total Biaya
                </Typography>
                <Typography
                  variant='subHeader16'
                  sx={{
                    color: '#252525',
                    '@media (min-width:768px)': {
                      fontSize: '18px!important',
                    },
                  }}
                >
                  Rp. {numberWithCommas(finalPrice)}
                </Typography>
              </Box>
              <CustomButton
                onClick={() => handlePayment()}
                className={classes.bookingButton}
              >
                {onLoadingPayment ? (
                  <CircularProgress sx={{ color: 'white' }} size={20} />
                ) : (
                  'Bayar'
                )}
              </CustomButton>
            </div>
          </Box>
        </div>
      </Dialog>

      <Drawer
        anchor={'bottom'}
        open={priceOpen}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 9999999, borderRadius: '16px 16px 0 0' }}
      >
        <Stack
          sx={{
            p: '24px',
            width: '100%',
            color: '#252525',
            maxWidth: '720px',
            m: 'auto',
          }}
        >
          <Typography sx={{ textAlign: 'center' }} variant='subHeader20'>
            Rincian Biaya
          </Typography>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{ mb: '24px' }}
          >
            <Typography variant='p20'>CV Review</Typography>
            <Typography variant='p20'>
              Rp. {numberWithCommas(dataSelected.price)}
            </Typography>
          </Stack>
          {selectedBundle.id && (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ mb: '24px' }}
            >
              <Typography variant='p20'>
                Bundling ATS Checker - {selectedBundle.name}
              </Typography>
              <Typography variant='p20'>
                Rp. {numberWithCommas(selectedBundle.price)}
              </Typography>
            </Stack>
          )}
          {selectedBundle.id && (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ mb: '24px' }}
            >
              <Typography variant='p20'>Diskon Bundling ATS Checker</Typography>
              <Typography variant='p20'>
                - Rp. {numberWithCommas(selectedBundle.discountPrice)}
              </Typography>
            </Stack>
          )}
          {isVoucher && (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ mb: '24px' }}
            >
              <Typography variant='p20'>Diskon</Typography>
              <Typography variant='p20'>
                - Rp. {numberWithCommas(cvrDiscount)}
              </Typography>
            </Stack>
          )}

          <Divider sx={{ m: '16px 0' }} />

          <Box
            className={classes.bookingButtonText}
            mb={1}
            onClick={() => setPriceOpen(true)}
          >
            <Typography variant='p20' sx={{ fontWeight: 700 }}>
              Total Biaya
            </Typography>
            <Typography
              variant='subHeader16'
              sx={{
                color: '#252525',
                '@media (min-width:768px)': {
                  fontSize: '18px!important',
                },
              }}
            >
              Rp. {numberWithCommas(finalPrice)}
            </Typography>
          </Box>
          <CustomButton
            onClick={() => handlePayment()}
            className={classes.bookingButton}
          >
            {onLoadingPayment ? (
              <CircularProgress sx={{ color: 'white' }} size={20} />
            ) : (
              'Bayar'
            )}
          </CustomButton>
        </Stack>
      </Drawer>

      <Dialog
        open={paymentUrl}
        keepMounted
        fullWidth
        maxWidth='xs'
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>
          <Typography variant='h4'>Pembayaran</Typography>
          <IconButton
            aria-label='close'
            onClick={() => setPaymentUrl(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: '500px',
            paddingTop: '56.25%',
          }}
        >
          <iframe
            src={paymentUrl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
            }}
            title='payment'
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
