import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import {
  useLocation,
  // useNavigate
} from 'react-router';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  CircularProgress,
  TextField,
  InputAdornment,
  Pagination,
  PaginationItem,
  useMediaQuery,
} from '@material-ui/core';
import CardImage from 'src/components/mentoring/CardImage';
import mentors from 'src/templates/products';
import filterMentor from 'src/templates/filterMentor';
import refSort from 'src/templates/sortMentor';
import filterMentorEmpty from 'src/assets/img/filterMentorEmpty.png';
import MentorFilter from 'src/components/mentoring/MentorFilter';
import DialogFilter from 'src/components/mentoring/DialogFilter';
import DialogSort from 'src/components/mentoring/DialogSort';
import reactGA from 'react-ga';
import Fuse from 'fuse.js';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import useDebounce from 'src/components/useDebounce';
import { setNavbarTitle, setFilterMentor } from 'src/redux/actions';
import { Link } from 'react-router-dom';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed

const useStyles = makeStyles({
  pagination: {
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent!important',
      border: '1px solid #1D2D5E',
    },
    '& .MuiPaginationItem-root': {
      fontWeight: 700,
      fontSize: '16px',
    },
  },
  paginationItem: {
    '& .MuiPaginationItem-root': {
      padding: '8px',
      border: '1px solid #BDBDBD',
      fontWeight: 700,
    },
  },
});

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

let products = [];

if (mentors.length > 0) {
  products = shuffle(mentors);
}

const ProductList = ({ mentorFilter }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterServiceLine, setFilterServiceLine] = useState([]);
  const [filterIndustry, setFilterIndustry] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [sortMentor, setSortMentor] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSort, setModalOpenSort] = useState(false);
  const [reset, setReset] = useState(false);
  const [countServiceLine, setCountServiceLine] = useState(0);
  const [countIndustry, setCountIndustry] = useState(0);
  const [countSkills, setCountSkills] = useState(0);
  const [countSort, setCountSort] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded3, setExpanded3] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));
  const fuse = new Fuse(products, {
    keys: ['name', 'title', 'experiences', 'skills'],
  });

  const isTablet = useMediaQuery('(min-width:768px)');
  // const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('page') || '1', 10);

  const pageSize = isTablet ? 20 : 10;
  const pageCount = Math.ceil(data?.length / pageSize);

  // if (currentPage > pageCount) {
  //   navigate("/app/mentoring");
  // }

  const firstPageIndex = (currentPage - 1) * pageSize;
  const lastPageIndex = firstPageIndex + pageSize;

  const paginatedData = data.slice(firstPageIndex, lastPageIndex);

  useEffect(() => {
    if (!user) {
      reactGA.event({
        category: 'Mentoring',
        action: 'User View Mentor List',
        label: 'anonymous',
      });
    }

    setNavbarTitle('Daftar Mentor');
    const getFilteredAssessment = async () => {
      setIsLoading(true);
      let resAssesst = [];
      let finalResult = [];
      const ref = firebase.database().ref();

      if (user) {
        reactGA.event({
          category: 'Mentoring',
          action: 'User View Mentor List',
          label: user.uid,
        });
        await ref
          .child('assessment')
          .orderByChild('customerId')
          .equalTo(user.uid)
          .once('value', (snapshot) => {
            snapshot.forEach((s) => {
              const b = s.val();
              resAssesst.push(b);
            });
          });
      }

      const filtered = [];
      if (!mentorFilter) {
        if (resAssesst[0] && resAssesst[0].results) {
          const assessts = resAssesst[0].results;
          // data preparation
          products.forEach((product) => {
            if (
              product.serviceLine.indexOf(
                assessts[0].value || assessts[1].value,
              ) !== -1 &&
              product.industry.indexOf(
                assessts[1].value || assessts[2].value,
              ) !== -1 &&
              product.skills.indexOf(
                assessts[2].value || (assessts[3] && assessts[3].value),
              ) !== -1
            ) {
              filtered.push(product);
            }
          });
          finalResult = [...new Set(filtered)];

          // filter preparation
          filterMentor.refServiceLine.forEach((srv) => {
            // refresh state
            srv['checked'] = false;
            if (
              srv.value === assessts[0].value ||
              srv.value === assessts[1].value
            ) {
              srv['checked'] = true;
            }
          });
          setFilterServiceLine(filterMentor.refServiceLine);
          const counterSrv = filterMentor.refServiceLine.filter(
            (srv) => srv.checked,
          );
          setCountServiceLine(counterSrv.length);
          filterMentor.refIndustry.forEach((ind) => {
            // refresh state
            ind['checked'] = false;
            if (
              ind.value === assessts[1].value ||
              ind.value === assessts[2].value
            ) {
              ind['checked'] = true;
            }
          });
          setFilterIndustry(filterMentor.refIndustry);
          const counterInd = filterMentor.refIndustry.filter(
            (ind) => ind.checked,
          );
          setCountIndustry(counterInd.length);
          filterMentor.refSkills.forEach((skl) => {
            // refresh state
            skl['checked'] = false;
            if (
              skl.value === assessts[2].value ||
              skl.value === (assessts[3] && assessts[3].value)
            ) {
              skl['checked'] = true;
            }
          });
          setFilterSkills(filterMentor.refSkills);
          const counterSkl = filterMentor.refSkills.filter(
            (skl) => skl.checked,
          );
          setCountSkills(counterSkl.length);
        } else {
          finalResult = products;
          filterMentor.refServiceLine.forEach((srv) => {
            srv['checked'] = false;
          });
          setFilterServiceLine(filterMentor.refServiceLine);
          filterMentor.refIndustry.forEach((ind) => {
            ind['checked'] = false;
          });
          setFilterIndustry(filterMentor.refIndustry);
          filterMentor.refSkills.forEach((skl) => {
            skl['checked'] = false;
          });
          setFilterSkills(filterMentor.refSkills);
        }
        refSort.refSort.forEach((srt) => {
          srt['checked'] = false;
        });
        setSortMentor(refSort.refSort);
        setCountSort(0);
        setIsLoading(false);
        setData(finalResult);
      } else {
        setData(mentorFilter.finalResult);
        setFilterServiceLine(mentorFilter.filterServiceLine);
        setFilterIndustry(mentorFilter.filterIndustry);
        setFilterSkills(mentorFilter.filterSkills);
        setCountServiceLine(mentorFilter.countServiceLine);
        setCountIndustry(mentorFilter.countIndustry);
        setCountSkills(mentorFilter.countSkills);
        setIsLoading(false);
      }
    };

    getFilteredAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorFilter]);

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalOpenSort = () => {
    setModalOpenSort(!modalOpenSort);
  };

  const handleChange = (e, type) => {
    if (type === 'serviceLine') {
      filterServiceLine.forEach((srv) => {
        if (srv.value === e.target.value) {
          srv['checked'] = e.target.checked;
        }
      });
      setFilterServiceLine(filterServiceLine);
      const counterSrv = filterServiceLine.filter((srv) => srv.checked);
      setCountServiceLine(counterSrv.length);
    } else if (type === 'industry') {
      filterIndustry.forEach((ind) => {
        if (ind.value === e.target.value) {
          ind['checked'] = e.target.checked;
        }
      });
      setFilterIndustry(filterIndustry);
      const counterInd = filterIndustry.filter((ind) => ind.checked);
      setCountIndustry(counterInd.length);
    } else if (type === 'skills') {
      filterSkills.forEach((skl) => {
        if (skl.value === e.target.value) {
          skl['checked'] = e.target.checked;
        }
      });
      setFilterSkills(filterSkills);
      const counterSkl = filterSkills.filter((skl) => skl.checked);
      setCountSkills(counterSkl.length);
    } else {
      sortMentor.forEach((srt) => {
        if (`${srt.value}-${srt.sort}` === e.target.value) {
          srt['checked'] = e.target.checked;
        } else {
          srt['checked'] = !e.target.checked;
        }
      });
      setSortMentor(sortMentor);
      const counterSrt = sortMentor.filter((srt) => srt.checked);
      setCountSort(counterSrt.length);
    }
  };

  const handleReset = () => {
    setReset(true);
    setTimeout(() => {
      filterServiceLine.forEach((srv) => {
        srv['checked'] = false;
      });
      setFilterServiceLine(filterServiceLine);
      filterIndustry.forEach((ind) => {
        ind['checked'] = false;
      });
      setFilterIndustry(filterIndustry);
      filterSkills.forEach((skl) => {
        skl['checked'] = false;
      });
      setFilterSkills(filterSkills);

      setReset(false);
      setCountServiceLine(0);
      setCountIndustry(0);
      setCountSkills(0);
    }, 0);
  };

  const handleResetSort = () => {
    setReset(true);
    setTimeout(() => {
      sortMentor.forEach((srt) => {
        srt['checked'] = false;
      });
      setSortMentor(sortMentor);

      setReset(false);
      setCountSort(0);
    }, 0);
  };

  const handleResetAll = () => {
    setReset(true);
    setTimeout(() => {
      filterServiceLine.forEach((srv) => {
        srv['checked'] = false;
      });
      setFilterServiceLine(filterServiceLine);
      filterIndustry.forEach((ind) => {
        ind['checked'] = false;
      });
      setFilterIndustry(filterIndustry);
      filterSkills.forEach((skl) => {
        skl['checked'] = false;
      });
      setFilterSkills(filterSkills);
      sortMentor.forEach((srt) => {
        srt['checked'] = false;
      });
      setSortMentor(sortMentor);

      setReset(false);
      setCountServiceLine(0);
      setCountIndustry(0);
      setCountSkills(0);
      setCountSort(0);
      handleSubmit();
    }, 0);
  };

  const handleExpandClick = (type) => {
    if (type === 1) {
      setExpanded(!expanded);
    } else if (type === 2) {
      setExpanded1(!expanded1);
    } else if (type === 3) {
      setExpanded2(!expanded2);
    } else {
      setExpanded3(!expanded3);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);

    setFilterMentor(
      products,
      filterServiceLine,
      filterIndustry,
      filterSkills,
      sortMentor,
      countServiceLine,
      countIndustry,
      countSkills,
    );

    setModalOpen(false);

    if (user) {
      reactGA.event({
        category: 'Mentoring',
        action: 'User Filter Mentor List',
        label: user.uid,
      });
    }
  };

  const handleSubmitSort = () => {
    setIsLoading(true);
    const dataCopy = [...data];
    setTimeout(() => {
      const sortedValue = sortMentor.filter((srt) => srt.checked);
      if (sortedValue[0]) {
        if (sortedValue[0].sort === 'desc') {
          dataCopy.sort((a, b) => {
            return b[`${sortedValue[0].value}`] - a[`${sortedValue[0].value}`];
          });
        } else if (sortedValue[0].sort === 'asc') {
          dataCopy.sort((a, b) => {
            return a[`${sortedValue[0].value}`] - b[`${sortedValue[0].value}`];
          });
        }
      }
      setData(dataCopy);
      setIsLoading(false);
    }, 700);

    setModalOpenSort(false);
  };

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  useEffect(
    () => {
      if (debouncedSearchValue.length > 0) {
        const searchResult = fuse.search(searchValue).map((d) => d.item);
        setData(searchResult);

        if (user) {
          reactGA.event({
            category: 'Mentoring',
            action: 'User Search Mentor List',
            label: user.uid,
          });
        }
      } else {
        setData(products);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchValue],
  );

  return (
    <>
      <Helmet>
        <title>Products - Aksel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          marginTop: '1rem',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    placeholder='Cari nama, pengalaman, jabatan atau skills mentor'
                    variant='outlined'
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    fullWidth
                    size='small'
                    sx={{
                      marginBottom: 2,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <MentorFilter
                  handleModalOpen={handleModalOpen}
                  countServiceLine={countServiceLine}
                  countIndustry={countIndustry}
                  countSkills={countSkills}
                  handleModalOpenSort={handleModalOpenSort}
                  countSort={countSort}
                  dataLength={data.length}
                />
                {paginatedData.map((product) => (
                  <Grid
                    item
                    key={product.id}
                    xs={12}
                    sm={6}
                    sx={{ marginBottom: 2 }}
                  >
                    <CardImage product={product} />
                  </Grid>
                ))}
                {data.length > 0 && (
                  <Grid item xs={12}>
                    <Pagination
                      page={currentPage}
                      count={pageCount}
                      variant='outlined'
                      shape='rounded'
                      className={classes.pagination}
                      renderItem={(item) => (
                        <PaginationItem
                          component={Link}
                          className={classes.paginationItem}
                          to={`/app/mentoring${
                            item.page === 1 ? '' : `?page=${item.page}`
                          }`}
                          {...item}
                        />
                      )}
                    />
                  </Grid>
                )}
                {!data.length && (
                  <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={filterMentorEmpty}
                        alt='filter-mentor-empty'
                        width='100%'
                        style={{ maxWidth: '300px' }}
                      />
                      <Typography variant='header'>
                        Mentor tidak ditemukan
                      </Typography>
                      <Typography variant='p'>
                        Coba cari dengan filter atau <br /> sort lainnya
                      </Typography>
                      <Button
                        variant='text'
                        onClick={handleResetAll}
                        sx={{ textTransform: 'none', mt: '34px' }}
                      >
                        <Typography
                          gutterBottom
                          variant='smBold'
                          sx={{ cursor: 'pointer' }}
                        >
                          Reset All
                        </Typography>
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </Container>
      </Box>
      <DialogFilter
        handleModalOpen={handleModalOpen}
        modalOpen={modalOpen}
        countServiceLine={countServiceLine}
        countIndustry={countIndustry}
        countSkills={countSkills}
        handleReset={handleReset}
        expanded2={expanded2}
        reset={reset}
        filterServiceLine={filterServiceLine}
        handleChange={handleChange}
        expanded={expanded}
        filterIndustry={filterIndustry}
        handleExpandClick={handleExpandClick}
        expanded1={expanded1}
        filterSkills={filterSkills}
        handleSubmit={handleSubmit}
      />
      {/* modal sort */}
      <DialogSort
        handleModalOpenSort={handleModalOpenSort}
        modalOpenSort={modalOpenSort}
        handleResetSort={handleResetSort}
        countSort={countSort}
        expanded3={expanded3}
        reset={reset}
        sortMentor={sortMentor}
        handleChange={handleChange}
        handleExpandClick={handleExpandClick}
        handleSubmitSort={handleSubmitSort}
        defaultValue={sortMentor.filter((srt) => srt.checked)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  mentorFilter: state.app.mentor.filter,
});

export default connect(mapStateToProps)(ProductList);
