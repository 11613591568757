import React from 'react';
import { Typography } from '@material-ui/core';
import { numberWithCommas } from 'src/utils/utilities';

const CardPackage = ({ selected, data, handleSelect }) => {
  return (
    <div
      style={{
        border: `1.5px solid ${selected === data.id ? '#3EC6FF' : '#C4C4C4'}`,
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '12px 0',
        textAlign: 'center',
        // width: "155.5px",
        margin: '16px 0',
        cursor: 'pointer',
        backgroundColor:
          selected === data.id ? 'rgba(62, 198, 255, 0.1)' : '#fff',
      }}
      onClick={handleSelect}
    >
      <Typography
        variant='pBold'
        gutterBottom
        style={{ color: 'rgba(37, 37, 37, 0.85)' }}
      >
        {data.title}
      </Typography>
      <Typography variant='sm' gutterBottom style={{ color: '#252525' }}>
        Rp {numberWithCommas(data.price)}
      </Typography>
    </div>
  );
};

export default CardPackage;
