import { useState } from 'react';

import { Grid } from '@material-ui/core';

import CustomButton from 'src/components/button';
import CustomInput from 'src/components/cvMaker/CustomInput';

const SkillForm = ({
  skills,
  dataDetail,
  handleAdd,
  setSkills,
  handleClose,
}) => {
  const [values, setValues] = useState(dataDetail);

  const handleHeadingChange = (event) => {
    setSkills({
      ...skills,
      heading: event.target.value,
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container spacing={1} marginTop={2}>
      <Grid item xs={12}>
        <CustomInput
          label='Heading'
          name='heading'
          onChange={handleHeadingChange}
          value={skills.heading}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomInput
          label='Skill'
          name='name'
          onChange={handleChange}
          value={values.name}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Level'
          name='level'
          onChange={handleChange}
          value={values.level}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={() => {
            handleAdd(values, dataDetail.id ? 'edit' : 'add');
          }}
          fullWidth
        >
          Save Skill
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          fullWidth
        >
          Cancel
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default SkillForm;
