import { Children, cloneElement } from 'react';
import { Divider } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DragNDropList = ({ children, handleOnDragEnd, items }) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd} style={{ overflow: 'auto' }}>
      <Droppable droppableId='dnd-list'>
        {(droppableProvided) => (
          <ul
            className='dnd-list'
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            style={{ listStyle: 'none' }}
          >
            {items?.map((item, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  disableInteractiveElementBlocking={false}
                  index={index}
                >
                  {(draggableProvided) => (
                    <li
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {Children.map(children, (child) => {
                        return cloneElement(child, {
                          item,
                        });
                      })}
                      <Divider style={{ color: '#E8E8E8' }} />
                    </li>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragNDropList;
