import firebase from 'firebase/app';
import 'firebase/storage';

import { useState, useEffect } from 'react';
import { Box, Button, Avatar, IconButton, Grid } from '@material-ui/core';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { logout } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import LoopRoundedIcon from '@material-ui/icons/LoopRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { Papercups } from '@papercups-io/chat-widget';
import CustomInput from '../forms/CustomInput';
import { FormProvider, useForm } from 'react-hook-form';
import CustomButton from '../button';
import JobSeekerForm from './JobSeekerForm';
import StudentForm from './StudentForm';
import EmployedForm from './EmployedForm';
import AccountButton from './AccountButton';
import {
  ChangePasswordIcon,
  CustomerSupportIcon,
  PrivacyPolicyIcon,
  TnCIcon,
} from 'src/assets/icons/Account';
import OnboardingForm from './OnboardingForm';

const jobType = ['Magang', 'Part-time', 'Full-time', 'Kontrak', 'Freelance'];

const AccountProfile = () => {
  let user = firebase.auth().currentUser;

  let displayName, email, uid;
  let photoURL = '';

  if (user != null) {
    displayName = user.displayName;
    email = user.email;
    uid = user.uid;
    photoURL = user.photoURL;
  }

  const navigate = useNavigate();

  const [url, setURL] = useState(photoURL);
  const [cvUrl, setCvUrl] = useState(null);
  const [csDisplay, setCsDisplay] = useState(false);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      displayName,
      email,
      wantedJobType: [],
    },
  });
  const { control, handleSubmit, setValue, watch } = methods;
  const status = watch('status');

  const renderedComponent = () => {
    switch (status) {
      case 'Pelajar Aktif':
        return <StudentForm />;
      case 'Mencari Pekerjaan':
        return <JobSeekerForm control={control} jobType={jobType} />;
      case 'Sudah Bekerja/Memiliki Usaha':
        return <EmployedForm jobType={jobType} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (uid) {
      const userRef = firebase.database().ref();

      userRef
        .child('users')
        .child(uid)
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();

            setCvUrl(userData.cvUrl);

            setValue('phone', userData.phone);
            setValue('domicile', userData.domicile);
            setValue('education', userData.education);
            setValue('status', userData.status);
            setValue('college', userData.college);
            setValue('linkedin', userData.linkedin);
            setValue('summary', userData.summary);
          } else {
            // eslint-disable-next-line no-console
            console.error('No data available');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [setValue, uid]);

  const handleCsClick = () => {
    if (csDisplay) {
      document.getElementsByClassName(
        'Papercups-toggleButtonContainer',
      )[0].style.visibility = 'hidden';
      document.getElementsByClassName(
        'Papercups-chatWindowContainer',
      )[0].style.visibility = 'hidden';
      Papercups.close();
      setCsDisplay(false);
    } else {
      document.getElementsByClassName(
        'Papercups-toggleButtonContainer',
      )[0].style.visibility = 'visible';
      document.getElementsByClassName(
        'Papercups-chatWindowContainer',
      )[0].style.visibility = 'visible';
      Papercups.open();
      setCsDisplay(true);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/', { replace: true });
  };

  const handleSave = (data) => {
    user
      .updateProfile({
        displayName: data.displayName,
        photoURL: url,
      })
      .then(function () {
        firebase
          .database()
          .ref(`users/${user.uid}`)
          .update({
            ...data,
            cvUrl,
          });
        // register({
        //   ...data,
        //   cvUrl,
        // });
      })
      .then(() => alert('Profile successfully saved'))
      .catch(function (error) {
        alert(`Cannot update profile. Error: ${error}`);
      });
  };

  const handleAvatarChange = (e) => {
    e.preventDefault();

    let file = e.target.files[0];

    const ref = firebase.storage().ref(`/images/${file.name}`);
    const uploadTask = ref.put(file);
    // eslint-disable-next-line no-console
    uploadTask.on('state_changed', console.log, console.error, () => {
      ref.getDownloadURL().then((url) => {
        setURL(url);
      });
    });
  };

  const handleAvatarRemove = (e) => {
    setURL('');

    // must remove latest URL on firebase storage

    user
      .updateProfile({
        photoURL: url,
      })
      .then(function () {
        alert('Successfully remove avatar.');
      })
      .catch(function (error) {
        alert(`Cannot update profile. Error: ${error}`);
      });
  };

  return (
    <div>
      <IconButton sx={{ color: '#111' }} onClick={handleLogout}>
        <ExitToAppRoundedIcon />
      </IconButton>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Avatar
          src={url}
          sx={{
            height: 100,
            width: 100,
            marginBottom: 2,
          }}
        />
        <div>
          <Button
            variant='outlined'
            size='small'
            sx={{
              marginRight: 1,
              marginBottom: 5,
              textTransform: 'capitalize',
            }}
            component='label'
            onChange={(e) => handleAvatarChange(e)}
            startIcon={<LoopRoundedIcon />}
          >
            Change
            <input type='file' hidden />
          </Button>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            sx={{
              marginBottom: 5,
              textTransform: 'capitalize',
            }}
            onClick={(e) => handleAvatarRemove(e)}
            startIcon={<DeleteOutlineRoundedIcon />}
          >
            Remove
          </Button>
        </div>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSave)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              marginTop: '32px',
            }}
          >
            <CustomInput
              label='Nama Lengkap'
              name='displayName'
              placeholder='Masukkan nama lengkap kamu'
              validation={{ required: 'Nama Lengkap harus diisi' }}
            />
            <CustomInput
              label='Alamat Email'
              name='email'
              placeholder='Masukkan alamat email yang aktif'
              validation={{ required: 'Alamat Email harus diisi' }}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
            {/* Kamu belum memasukkan kode negara. Contoh: +62... */}
            <CustomInput
              name='phone'
              label='Nomor Telepon'
              placeholder='Contoh : +628123456789'
              validation={{
                required: 'Nomor Telepon harus diisi',
                pattern: {
                  value: /^(\+)/,
                  message:
                    'Nomor telepon harus diawali dengan kode negara. Contoh: +628123456789',
                },
              }}
              style={{ marginTop: '16px' }}
            />
            <OnboardingForm
              cvUrl={cvUrl}
              renderedComponent={renderedComponent}
              setCvUrl={setCvUrl}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <CustomButton type='submit'>Simpan</CustomButton>
          </Box>
        </form>
      </FormProvider>
      <hr style={{ border: '1px solid #E8E8E8', margin: '20px 0 40px' }} />
      <Grid container spacing={3}>
        <AccountButton
          icon={<ChangePasswordIcon />}
          onClick={() => navigate('/app/profile/change-password')}
          text='Ubah Password'
        />
        <AccountButton
          icon={<CustomerSupportIcon />}
          onClick={() => handleCsClick()}
          text='Customer Support'
        />
        <AccountButton
          icon={<TnCIcon />}
          onClick={() => navigate('/term-and-condition')}
          text='Syarat & Ketentuan'
        />
        <AccountButton
          icon={<PrivacyPolicyIcon />}
          onClick={() => navigate('/privacy-policy')}
          text='Kebijakan Privasi'
        />
      </Grid>
    </div>
  );
};

export default AccountProfile;
