import { useEffect } from 'react';
import firebase from 'firebase/app';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { logout } from 'src/redux/actions';

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'none !important',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MainHeader = ({
  subs,
  setSubs,
  icon,
  label,
  title,
  description,
  tab,
  handleTab,
}) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));
  const ref = firebase.database().ref();

  useEffect(
    () => {
      const fetchData = async () => {
        if (user) {
          const atsArr = [];
          await ref
            .child('bookings')
            .orderByChild('customerId')
            .equalTo(user.uid)
            .once('value', async (snapshot) => {
              snapshot.forEach((s) => {
                const b = s.val();
                if (b.transactionType === 'ats') {
                  atsArr.push(b);
                }
              });
            });

          //   sort to get latest subscription
          atsArr.sort(function (a, b) {
            return b.createdAt - a.createdAt;
          });

          if (atsArr.length) {
            await ref
              .child('payments')
              .orderByChild('orderId')
              .equalTo(atsArr[0].bookId)
              .once('value', (snapshot) => {
                snapshot.forEach((s) => {
                  const p = s.val();
                  if (
                    p.transactionStatus === 'settlement' &&
                    atsArr[0].bookTime > 0
                  ) {
                    setSubs(atsArr[0]);
                  }
                });
              });
          }
        } else {
          logout();
        }
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid item xs={11} style={{ paddingTop: 48, maxWidth: '1280px' }}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginBottom: 13 }}
      >
        <Grid style={{ display: 'flex' }}>
          <img
            src={icon}
            alt={label}
            style={{ width: 40, height: 40, marginRight: 16 }}
          />
          <Typography
            sx={{
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: '32px',
              lineHeight: '120%',
              fontFamily: 'Montserrat',
            }}
          >
            {title}
          </Typography>
        </Grid>
        {subs && subs.bookTime > 0 && tab === 1 ? (
          <Grid>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              style={{
                padding: '4px 12px',
                width: '320px',
                height: '30px',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#1D2D5E',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontFamily: 'Roboto',
                }}
              >
                {subs.productName}: berlaku {subs.bookTime}x parse lagi
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid>
        <Typography
          sx={{
            color: '#FFFFFF',
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: 0.1,
            fontFamily: 'Roboto',
            maxWidth: '708px',
          }}
        >
          {description}
        </Typography>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: '40px' }}>
        <Tabs
          fullWidth
          centered
          value={tab}
          onChange={handleTab}
          TabIndicatorProps={{
            style: { background: '#FFFFFF', color: '#FFFFFF' },
          }}
          aria-label='basic tabs example'
        >
          <Tab
            className={classes.root}
            label='CV Maker'
            style={{ color: tab === 0 ? '#FFFFFF' : '#BDBDBD' }}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.root}
            label='ATS Checker'
            style={{ color: tab === 1 ? '#FFFFFF' : '#BDBDBD' }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
    </Grid>
  );
};

export default MainHeader;
