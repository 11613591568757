import {
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Document, Page, pdfjs } from 'react-pdf';

// import CustomButton from '../button';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  card: {
    width: '267px',
    height: '443px',
    margin: '24px 0',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.12))',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: '371px',
    objectFit: 'cover',
    borderRadius: '8px 8px 0px 0px',
  },
  '@global': {
    '.css-1dchlth': {
      display: 'none !important',
    },
    '.react-pdf__Page': {
      zIndex: -1,
    },
    '.react-pdf__Page__canvas': {
      borderRadius: '8px',
    },
  },
}));

const CardGrid = ({
  id,
  data,
  title,
  subTitle,
  cover,
  handleClick,
  rightButton,
  handleButton,
  selected,
  isButtonCvReview,
  handleReview,
  tooltipComponent,
  tooltipComponent0,
  tooltipOpen,
  tooltipOpen0,
  handleChangeTabAts,
  isButtonAtsChecker,
}) => {
  const classes = useStyles();
  return (
    <Grid id={id} style={{ marginRight: '24px' }} item>
      <div
        className={classes.card}
        style={
          selected === id
            ? {
                borderStyle: 'solid',
                borderWidth: '2px',
                borderColor: '#1D2D5E',
              }
            : null
        }
      >
        <div>
          {/* checking cv is upload */}
          {data.cv ? (
            <Document file={{ url: data.cv }} className={classes.image}>
              <Page
                pageNumber={1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={0.44}
              />
            </Document>
          ) : (
            <img
              alt={id}
              src={cover}
              className={classes.image}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png';
              }}
              onClick={() =>
                handleClick && handleClick({ id, title, subTitle, cover })
              }
            />
          )}
          {isButtonAtsChecker && tooltipOpen0 && (
            <Tooltip open={tooltipOpen0} title={tooltipComponent0} arrow>
              <Button
                sx={{
                  borderRadius: '32px !important',
                  position: 'absolute',
                  top: '16px',
                  right: '24px',
                  padding: '3px !important',
                  backgroundColor: '#FACD11 !important',
                }}
                onClick={handleChangeTabAts}
              >
                <Typography
                  variant={'smallButton'}
                  sx={{ textTransform: 'none', padding: '5px 16px' }}
                >
                  Ask for ATS Check
                </Typography>
              </Button>
            </Tooltip>
          )}

          {isButtonAtsChecker && !tooltipOpen0 && (
            <Tooltip
              title={tooltipComponent0}
              arrow
              style={{ maxWidth: '168px !important' }}
              disableInteractive
            >
              <Button
                sx={{
                  borderRadius: '32px !important',
                  position: 'absolute',
                  top: '16px',
                  right: '24px',
                  padding: '3px !important',
                  backgroundColor: '#FACD11 !important',
                }}
                onClick={handleChangeTabAts}
              >
                <Typography
                  variant={'smallButton'}
                  sx={{ textTransform: 'none', padding: '5px 16px' }}
                >
                  Ask for ATS Check
                </Typography>
              </Button>
            </Tooltip>
          )}

          {isButtonCvReview && tooltipOpen && (
            <Tooltip open={tooltipOpen} title={tooltipComponent} arrow>
              <Button
                sx={{
                  borderRadius: '32px !important',
                  position: 'absolute',
                  top: '56px',
                  right: '24px',
                  padding: '3px !important',
                  backgroundColor: '#FACD11',
                }}
                onClick={handleReview}
              >
                <Typography
                  variant={'smallButton'}
                  sx={{ textTransform: 'none', padding: '5px 16px' }}
                >
                  Ask for CV Review
                </Typography>
              </Button>
            </Tooltip>
          )}

          {isButtonCvReview && !tooltipOpen && (
            <Tooltip
              title={tooltipComponent}
              arrow
              style={{ maxWidth: '168px !important' }}
              disableInteractive
            >
              <Button
                sx={{
                  borderRadius: '32px !important',
                  position: 'absolute',
                  top: '56px',
                  right: '24px',
                  padding: '3px !important',
                  backgroundColor: '#FACD11',
                  '&:hover': { backgroundColor: '#FACD11' },
                }}
                onClick={handleReview}
              >
                <Typography
                  variant={'smallButton'}
                  sx={{ textTransform: 'none', padding: '5px 16px' }}
                >
                  Ask for CV Review
                </Typography>
              </Button>
            </Tooltip>
          )}
        </div>
        <div
          style={{
            padding: '15.5px 16px',
            boxShadow: '0px -4px 24px rgba(0, 0, 0, 0.04)',
            backgroundColor: '#fff',
            borderRadius: '0 0 8px 8px',
          }}
        >
          {id === 'blank' && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                sx={{ padding: 0 }}
                onClick={() =>
                  handleClick && handleClick({ id, title, subTitle, cover })
                }
                aria-owns={'popover'}
              >
                {rightButton}
              </IconButton>
            </div>
          )}

          {id !== 'blank' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: !subTitle && '7px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography variant='pBold'>{title}</Typography>
                {subTitle && (
                  <Typography variant='sm' sx={{ color: '#BDBDBD' }}>
                    {subTitle}
                  </Typography>
                )}
              </div>
              <IconButton
                sx={{ padding: 0 }}
                onClick={(e) => handleButton(e, { data })}
                aria-owns={'popover'}
              >
                {rightButton}
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default CardGrid;
