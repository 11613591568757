import { Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  card: {
    width: '193px',
    height: '211px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)',
    marginRight: '15px',
    border: '1px solid #5BC0EB',
    boxSizing: 'border-box',
    borderRadius: '8px',
    cursor: 'pointer',
  },
}));

const CardServices = ({ data, isLong }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id, title, icon, body, pushType, pushTo } = data;

  const handleClick = () => {
    if (pushType === 'app') {
      navigate(pushTo);
    } else {
      window.open(pushTo, '_blank');
    }
  };

  return (
    <div id={id} style={{ position: 'relative' }}>
      <div
        className={classes.card}
        style={isLong && { height: '246px' }}
        onClick={handleClick}
      >
        <div style={{ paddingLeft: '20px', paddingTop: '19px' }}>
          <img src={icon} alt={title} style={{ width: '30px' }} />
        </div>
        <Typography
          variant='subHeader16'
          style={{ padding: '9px 4px 8px 20px' }}
        >
          {title}
        </Typography>
        <Typography variant='sm' style={{ paddingLeft: 20, maxWidth: 172 }}>
          {body}
        </Typography>
        <div
          style={{
            position: 'absolute',
            right: 33,
            bottom: 10,
          }}
        >
          <NavigateNextIcon />
        </div>
      </div>
    </div>
  );
};

export default CardServices;
