const careerMentoring = [
  'Sesi trial untuk setiap mentor sebelum komitmen yang lebih besar',
  'Topik mentoring yang disesuaikan dengan kebutuhan kamu',
  'Bersifat on-demand dengan komitmen kecil',
  'Terdapat berbagai pilihan waktu yang dapat disesuaikan dengan jadwal kamu',
  'Dirancang sesuai dengan kebutuhan mahasiswa Indonesia',
  'Mentor dipilih dengan proses seleksi yang ketat dari berbagai latar belakang berbeda',
];

export default careerMentoring;
