import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import firebase from 'firebase/app';

import {
  cvMakerTemplates,
  finance,
  hr,
  marketing,
  product,
  blank,
} from 'src/templates/cvMakerTemplates';
import CardGrid from 'src/components/cvMaker/CardGrid';
import CustomButton from 'src/components/button';
import BottomBar from 'src/components/cvMaker/BottomBar';
import { setCvStep } from 'src/redux/actions';

const useStyles = makeStyles(() => ({
  '@global': {
    '.css-zw3mfo-MuiModal-root-MuiDialog-root': {
      zIndex: '1500 !important',
    },
    '.css-1bouxhj-MuiSnackbar-root': {
      top: '100px !important',
    },
    '.css-13jrc5o-MuiPaper-root-MuiSnackbarContent-root': {
      backgroundColor: '#FACD11 !important',
      color: '#1D2D5E !important',
    },
  },
}));

const TemplateCvMaker = () => {
  useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const handleButton = (e, { data }) => {
    setDataDetail(data);
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (val) => {
    setDataDetail(val);
  };

  const handleNext = async () => {
    setCvStep(0);
    if (dataDetail.id) {
      setLoading(true);
      let template = null;
      if (dataDetail.id === 'finance') template = await finance();
      if (dataDetail.id === 'hr') template = await hr();
      if (dataDetail.id === 'marketing') template = await marketing();
      if (dataDetail.id === 'product') template = await product();
      if (dataDetail.id === 'blank') template = await blank();

      await firebase
        .database()
        .ref(`resumes/${id}`)
        .update({
          ...template,
          updatedAt: firebase.database.ServerValue.TIMESTAMP,
        });
      setLoading(false);
      navigate(`/app/cv/builder/${id}`);
    } else {
      navigate(`/app/cv/builder/${id}`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflowX: 'hidden',
      }}
    >
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!loading && (
        <Grid container style={{ padding: 0, maxWidth: '768px' }}>
          <Grid item xs={12}>
            <div style={{ padding: '0 24px' }}>
              <Typography
                variant='subHeader16'
                sx={{ color: '#252525', padding: '18px 0 22px 0' }}
              >
                Pick template according to role you want to apply for!
              </Typography>
            </div>
            <Container
              style={{
                display: 'flex',
                overflowX: 'scroll',
                padding: 0,
                marginBottom: 63,
              }}
            >
              {cvMakerTemplates.map((idx) => (
                <CardGrid
                  key={idx.id}
                  id={idx.id}
                  cover={idx.cover}
                  title={idx.name}
                  data={idx}
                  rightButton={
                    <CustomButton
                      smallButton
                      sx={{
                        borderRadius: '32px !important',
                        padding: '3px !important',
                      }}
                      variant='secondary'
                      color='secondary'
                    >
                      {idx.id === 'blank' ? 'Create a Blank Resume' : 'Preview'}
                    </CustomButton>
                  }
                  handleButton={handleButton}
                  handleClick={handleSelect}
                  selected={dataDetail.id}
                />
              ))}
            </Container>
          </Grid>
        </Grid>
      )}
      <BottomBar>
        <CustomButton
          sx={{ padding: '5px 0', width: '100% !important' }}
          onClick={handleNext}
          disabled={!dataDetail.id}
        >
          Next Step
        </CustomButton>
      </BottomBar>

      <Dialog
        onClose={handleOpen}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle onClose={handleOpen} sx={{ padding: '16px' }}>
          {dataDetail.title}
          <IconButton
            aria-label='close'
            sx={{ padding: 0, position: 'absolute', right: '16px' }}
            onClick={handleOpen}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: '16px' }}>
          <TransformWrapper>
            <TransformComponent>
              <img
                alt={dataDetail.id}
                src={dataDetail.cover}
                style={{ width: '100%' }}
              />
            </TransformComponent>
          </TransformWrapper>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TemplateCvMaker;
