import { Box, Typography, IconButton } from '@material-ui/core';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const ProjectCard = ({ item, handleDelete, handleEdit }) => {
  return (
    <Box style={{ display: 'flex', alignItems: 'center', padding: '0.6rem 0' }}>
      <DragIndicatorIcon
        style={{
          color: '#BDBDBD',
          marginRight: '0.5rem',
        }}
      />

      <Box style={{ flex: 1 }}>
        <Typography variant='pBold'>{item.title}</Typography>
        <Typography variant='p'>{item.link}</Typography>
      </Box>

      <Box>
        <IconButton
          size='small'
          edge='end'
          style={{ color: '#BDBDBD' }}
          onClick={() => handleEdit(item)}
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          size='small'
          style={{ color: '#BDBDBD' }}
          onClick={() => handleDelete(item.id)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ProjectCard;
