/* eslint-disable no-useless-computed-key */
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import { usePopper } from 'react-popper';
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CloseIcon from '@material-ui/icons/Close';
import StarRoundedIcon from '@material-ui/icons/StarRounded';

import { setNavbarTitle } from 'src/redux/actions';
import CustomButton from 'src/components/button';
import CustomIconButton from 'src/components/button/CustomIconButton';
import ProfileTab from 'src/components/mentoring/ProfileTab';
import ServiceTab from 'src/components/mentoring/ServiceTab';
import { LinkedInIcon, ShareIcon } from 'src/assets/icons/Mentoring';
import SuperIcon from '../assets/img/superMentorLabel.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import products from 'src/templates/products';

const numberWithCommas = (x) => {
  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};

const useStyles = makeStyles({
  backdrop: {
    minHeight: '200px',
    maxHeight: '240px',
    width: '100%',
    objectFit: 'cover',
  },
  card: {
    position: 'relative',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: '24px',
    borderRadius: '12px !important',
    paddingTop: '56px',
    boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.25) !important',
    ['@media (min-width:768px)']: {
      display: 'flex',
      textAlign: 'left',
    },
  },
  cardContainer: {
    position: 'absolute',
    zIndex: 1000,
    top: '-70%',
    width: '100%',
    padding: '24px',
    ['@media (min-width:768px)']: {
      top: '-50%',
    },
  },
  container: {
    minHeight: 'calc(100vh - 66px)',
    maxWidth: '768px',
    margin: '0 auto',
  },
  mentorHeader: {
    marginTop: '15px',
    ['@media (min-width:768px)']: {
      marginTop: '0',
    },
  },
  profilePhoto: {
    height: '104px',
    width: '104px',
    objectFit: 'cover',
    position: 'absolute',
    top: '-27%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    border: '5px solid white',
    borderRadius: '50%',
    ['@media (min-width:768px)']: {
      left: '3%',
      top: '-40%',
      transform: 'none',
    },
  },
  rating: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px',
    marginBottom: '16px',
    ['@media (min-width:768px)']: {
      marginBottom: '0',
      justifyContent: 'start',
    },
  },
  title: {
    fontSize: '20px!important',
    ['@media (min-width:768px)']: {
      fontSize: '24px!important',
    },
  },
  tooltip: {
    backgroundColor: '#1D2D5E',
    borderRadius: '8px',
    padding: '8px 12px',
    width: '250px',
    color: '#FFFFFF',
    zIndex: 100,
  },

  bookingButtonContainer: {
    padding: '16px 24px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
    bottom: 0,
    position: 'fixed',
    width: '768px',
    backgroundColor: '#fff',
    ['@media (max-width:767px)']: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  },
  bookingButtonText: {
    display: 'flex',
    justifyContent: 'space-between',
    ['@media (max-width:767px)']: {
      display: 'block',
    },
  },
  bookingButton: {
    width: '100%',
    ['@media (max-width:767px)']: {
      width: 'auto',
    },
  },
});

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const BookingButton = ({ handleBook, mentor, classNames }) => {
  return (
    <Box className={classNames.container}>
      <Box className={classNames.text} mb={1}>
        <Typography
          variant='p'
          sx={{
            '@media (min-width:768px)': {
              fontSize: '16px!important',
            },
          }}
        >
          Mulai dari
        </Typography>
        <Typography
          variant='subHeader16'
          sx={{
            '@media (min-width:768px)': {
              fontSize: '18px!important',
            },
          }}
        >
          Rp. {numberWithCommas(mentor.price / 2)}
        </Typography>
      </Box>
      <CustomButton
        onClick={() => handleBook(mentor)}
        className={classNames.button}
      >
        Book Mentor
      </CustomButton>
    </Box>
  );
};

const MentorDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [rating, setRating] = useState(0);
  const [isHoverTooltip, setIsHoverTooltip] = useState(false);
  const [video, setVideo] = useState({
    modal: false,
    url: '',
    title: '',
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const superRef = useRef();
  const superTooltipRef = useRef();
  const superPopper = usePopper(superRef.current, superTooltipRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [100, 10],
        },
      },
    ],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ref = firebase.database().ref();
  const mentor = products.filter((product) => product.id === id)[0];

  useEffect(() => {
    setNavbarTitle('Profil Mentor');
    localStorage.removeItem('notesValue');
    localStorage.removeItem('book_mentor_id');

    const getRating = async () => {
      let rates = [];

      await ref
        .child('bookings')
        .orderByChild('productId')
        .equalTo(mentor.id)
        .once('value', (snapshot) => {
          snapshot.forEach((s) => {
            let b = s.val();

            if (b.rate) {
              rates.push(b.rate);
            }
          });
        });

      let sumRtg = rates.reduce((a, b) => a + b, 0);
      let avgRtg = sumRtg / rates.length || 0;

      setRating(avgRtg);
    };

    if (!mentor) {
      navigate('/app/mentoring');
    } else {
      getRating();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOpenVideo = () => {
  //   setVideo({
  //     modal: true,
  //     url: mentor.videoUrl,
  //     title: mentor.name,
  //   });
  // };

  const handleBook = (mentor) => {
    localStorage.setItem('book_mentor_id', mentor.id);
    navigate('/app/book');
  };

  return mentor ? (
    <>
      <Box
        display='flex'
        flexDirection='column'
        className={classes.container}
        sx={{
          backgroundColor: 'background.default',
          paddingBottom: '5rem',
        }}
      >
        {/* <img
          src="https://source.unsplash.com/random/800x600"
          alt="Background"
          className={classes.backdrop}
        /> */}
        <Box backgroundColor='#1d2d5e' className={classes.backdrop} />
        <Box
          sx={{
            position: 'relative',
            pt: 15,
          }}
        >
          <Box className={classes.cardContainer}>
            <Paper className={classes.card}>
              <img
                src={mentor.photoUrl}
                alt={mentor.name}
                className={classes.profilePhoto}
              />
              <Box className={classes.mentorHeader}>
                <Typography
                  variant='header'
                  color='#252525'
                  className={classes.title}
                  sx={{ fontWeight: 700 }}
                >
                  {mentor.super && (
                    <img
                      ref={superRef}
                      onMouseEnter={() => setIsHoverTooltip(true)}
                      onMouseLeave={() => setIsHoverTooltip(false)}
                      src={SuperIcon}
                      alt={'tooltip'}
                      style={{
                        marginRight: '4px',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )}{' '}
                  {mentor.name}
                </Typography>
                <Box
                  className={classes.tooltip}
                  ref={superTooltipRef}
                  style={{
                    ...superPopper.styles.popper,
                    visibility: isHoverTooltip === true ? 'visible' : 'hidden',
                    pointerEvents: 'none',
                  }}
                  {...superPopper.attributes.popper}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '12px',
                    }}
                  >
                    Mentor dengan label ini memberikan diskon khusus untukmu!
                  </Typography>
                </Box>
                <Typography variant='p' color='#252525'>
                  {mentor.title}
                </Typography>
                {parseInt(rating) > 0 && (
                  <Box className={classes.rating}>
                    <StarRoundedIcon sx={{ mr: 0.5, color: '#FACD11' }} />
                    <Typography variant='pBold' color='#252525'>
                      {rating.toFixed(1)}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                {/* TODO: video perkenalan ditakeout dulu */}
                {/* <CustomButton
                  variant="outlined"
                  size="small"
                  sx={{ p: 0 }}
                  smallButton
                  onClick={handleOpenVideo}
                >
                  Video Perkenalan
                </CustomButton> */}
                <CustomIconButton
                  variant='outlined'
                  size='small'
                  smallButton
                  href={mentor.linkedin}
                  target='_blank'
                  rel='noopener noreferrer'
                  sx={{ ml: 1 }}
                >
                  <LinkedInIcon />
                </CustomIconButton>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setTooltipOpen(true)}
                >
                  <Tooltip
                    open={tooltipOpen}
                    title={'Copied to clipboard!'}
                    leaveDelay={1500}
                  >
                    <CustomIconButton
                      variant='outlined'
                      size='small'
                      smallButton
                      sx={{ ml: 1 }}
                    >
                      <ShareIcon />
                    </CustomIconButton>
                  </Tooltip>
                </CopyToClipboard>
              </Box>
            </Paper>
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
          >
            <Tab
              label={
                <Typography
                  variant='subHeader16'
                  style={{ textTransform: 'none' }}
                >
                  Profil
                </Typography>
              }
            />
            <Tab
              label={
                <Typography
                  variant='subHeader16'
                  style={{ textTransform: 'none' }}
                >
                  Layanan
                </Typography>
              }
            />
            {/* <Tab label="Review" /> */}
          </Tabs>
        </Box>

        <Container maxWidth={false} sx={{ flex: 1, mb: '2rem' }}>
          <TabPanel value={value} index={0}>
            <ProfileTab mentor={mentor} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ServiceTab mentor={mentor} />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
          <ReviewTab mentor={mentor} />
        </TabPanel> */}
        </Container>
        <BookingButton
          handleBook={handleBook}
          mentor={mentor}
          classNames={{
            container: classes.bookingButtonContainer,
            text: classes.bookingButtonText,
            button: classes.bookingButton,
          }}
        />
      </Box>
      <Dialog
        onClose={() => setVideo({ modal: false })}
        aria-labelledby='customized-dialog-title'
        open={video.modal}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle
          onClose={() => setVideo({ modal: false })}
          sx={{ padding: '16px' }}
        >
          {video.title}
          <IconButton
            aria-label='close'
            sx={{ padding: 0, position: 'absolute', right: '16px' }}
            onClick={() => setVideo({ modal: false })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* <DialogContent dividers sx={{ padding: "16px" }}>
          <video autoPlay controls width="100%">
            <source src={video.url} type="video/mp4" />
          </video>
        </DialogContent> */}
      </Dialog>
    </>
  ) : (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      className={classes.container}
      sx={{
        backgroundColor: 'background.default',
        marginTop: '1rem',
      }}
    >
      <CircularProgress size={30} sx={{ mb: 2 }} />
    </Box>
  );
};

export default MentorDetail;
