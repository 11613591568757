import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
  Backdrop,
  Tooltip,
  Button,
  Stack,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import ATSResult from '../../../assets/img/ats-result.png';

import { useNavigate, useLocation } from 'react-router-dom';
import ShortUniqueId from 'short-unique-id';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import download from 'downloadjs';
import axios from 'axios';
import reactGA from 'react-ga';
import useDebounce from 'src/components/useDebounce';
import Fuse from 'fuse.js';
import { formatDistanceToNow } from 'date-fns';

import cvMaker from 'src/assets/img/cvMaker.svg';
import atsChecker from 'src/assets/img/atsChecker.svg';

import CardGrid from 'src/components/cvMaker/CardGrid';
import ListGrid from 'src/components/cvMaker/ListGrid';
// import CustomButton from 'src/components/button';
import CustomDialog from 'src/components/cvMaker/CustomDialog';
import DialogDownload from 'src/components/cvMaker/DialogDownload';
import CustomPopover from 'src/components/cvMaker/CustomPopover';
import ATSCheckerModal from 'src/components/cvMaker/ATSCheckerModal';
import CustomStepper from 'src/components/dashboard/Stepper';
import MainHeader from 'src/components/dashboard/MainHeader';
import PilihPaket from 'src/components/dashboard/PilihPaket';
import UploadCV from 'src/components/dashboard/UploadCV';
// TODO: page maintenance
// import TemporaryError from 'src/components/dashboard/TemporaryError';
import ResultCV from 'src/components/dashboard/ResultCV';
import {
  MoreIcon,
  UploadIcon,
  LightBulb,
  DangerIcon,
} from 'src/assets/icons/CvMaker';
import initialStateCv from 'src/templates/initialStateCv.json';
import DialogCvReview from 'src/components/cvReview/DialogCvReview';
import {
  getUnsplashPhoto,
  b64toBlob,
  isFilePDF,
  getBase64,
} from 'src/utils/utilities';
import {
  updateCvDetail,
  setCvStep,
  setNavbarTitle,
  setAtsParse,
} from 'src/redux/actions';

const useStyles = makeStyles(() => ({
  containerCard: {
    marginBottom: '162px',
  },

  viewButton: {
    display: 'flex',
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    marginRight: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  '@global': {
    '.MuiTooltip-tooltip': {
      backgroundColor: '#1D2D5E !important',
      padding: '16px !important',
      maxWidth: '340px !important',
      borderRadius: '8px !important',
    },
    '.MuiTooltip-arrow': {
      color: '#1D2D5E !important',
    },
  },
}));

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const steps = ['1. Pilih paket', '2. Upload CV', '3. Dapatkan hasilnya!'];

const DesktopCvMaker = ({ cvStep, atsParse }) => {
  const fileInputRef = useRef(null);
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });
  const query = useQuery();
  const [modalReview, setModalReview] = useState(false);
  setNavbarTitle('CV Maker');

  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [view, setView] = useState('carousel');
  const [name, setName] = useState('');
  const [resumes, setResumes] = useState([]);
  const [allResumes, setAllResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [datas, setDatas] = useState('');
  const [tab, setTab] = useState(0);
  const [file, setFile] = useState({});
  const [subs, setSubs] = useState({});
  const [errorUpload, setErrorUpload] = useState(false);
  const [dataATS, setDataATS] = useState({});
  const [checkCV, setCheckCV] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [isErrorUpload, setIsErrorUpload] = useState(false);
  const [tooltipStep, setTooltipStep] = useState(0);
  const [tooltipAskFirst, setTooltipAskFirst] = useState(false);
  const [tooltip0, setTooltip0] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  useEffect(() => {
    if (query.get('tab') === 'ats') {
      setTab(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (datas && Object.keys(datas).length !== 0) {
      setModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  useEffect(() => {
    getResumes();
    getAts()
      .then((_) => {
        setLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const getResumes = async () => {
    const resumesRef = 'resumes';
    const socketRef = '/.info/connected';
    if (query.get('tab') !== 'ats') {
      setCvStep(0);
    }

    if (user) {
      // const checkUser = await firebase
      //   .database()
      //   .ref(`users/${user.uid}`)
      //   .get();

      firebase
        .database()
        .ref(socketRef)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            firebase.database().ref(socketRef).off();
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const resumesArr = [];
            const data = snapshot.val();

            Object.keys(data).forEach((key) => resumesArr.push(data[key]));

            resumesArr.sort(function (a, b) {
              return b.updatedAt - a.updatedAt;
            });
            if (resumesArr.length === 1) setTooltip0(true);
            if (resumesArr.length === 0) setTooltipStep(1);

            setAllResumes(resumesArr);
            setResumes(resumesArr);
          } else {
            setTooltipStep(1);
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('child_removed', (snapshot) => {
          if (snapshot.val()) {
            setAllResumes(resumes.filter((x) => x.id === snapshot.val().id));
            setResumes(resumes.filter((x) => x.id === snapshot.val().id));
          }
        });

      return () => {
        firebase.database().ref(resumesRef).off();
      };
    }
  };

  const getAts = async () => {
    const ref = firebase.database().ref();

    if (user) {
      const atsArr = [];
      await ref
        .child('bookings')
        .orderByChild('customerId')
        .equalTo(user.uid)
        .once('value', async (snapshot) => {
          snapshot.forEach((s) => {
            const b = s.val();
            if (b.transactionType === 'ats') {
              // pastiin gaada yg booktimenya 0
              if (b.bookTime > 0) {
                atsArr.push(b);
              }
            }
          });
        });

      //   sort to get latest subscription
      atsArr.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });

      // Todo make more efficient query
      if (atsArr.length) {
        let isFound = false;
        atsArr.forEach(async (ats) => {
          await ref
            .child('payments')
            .orderByChild('orderId')
            .equalTo(ats.bookId)
            .once('value', (snapshot) => {
              snapshot.forEach((s) => {
                const p = s.val();
                if (p.transactionStatus === 'settlement' && ats.bookTime > 0) {
                  setSubs(ats);
                  const dataAtsParsed = ats.dataAts && JSON.parse(ats.dataAts);
                  if (
                    dataAtsParsed &&
                    Object.keys(dataAtsParsed).length !== 0
                  ) {
                    isFound = true;
                    setCvStep(2); //to result ats checker
                    setAtsParse(dataAtsParsed);
                    setDataATS(dataAtsParsed);
                  } else {
                    isFound = true;
                    setCvStep(1); //to upload cv
                  }
                } else {
                  setCvStep(0); //select package
                }
              });
            });

          if (isFound) {
            return;
          }
        });
      }
    }
  };

  useEffect(
    () => {
      if (debouncedSearchValue.length > 0) {
        const options = {
          includeScore: true,
          keys: ['name'],
        };

        const fuse = new Fuse(allResumes, options);

        const searched = fuse.search(searchValue).map((d) => d.item);

        setResumes(searched);
      } else {
        setResumes(allResumes);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchValue],
  );

  const handleButton = (e, { data }) => {
    setAnchorEl(e.currentTarget);
    setMenuOpen(!menuOpen);
    setDataDetail(data);
  };

  const handleSave = async () => {
    if (!name) return;
    if (dataDetail.id) {
      dataDetail.name = name;
      updateCvDetail(dataDetail);
      setOpen(false);
    } else {
      reactGA.event({
        category: 'CV Maker',
        action: 'User Create CV',
        value: user.uid,
      });

      setModalLoading(true);
      const id = uuid();
      const preview = await getUnsplashPhoto();
      const createdAt = firebase.database.ServerValue.TIMESTAMP;

      let firstName;
      let lastName;

      if (!user.isAnonymous) {
        [firstName, lastName] = user.displayName.split(' ');
      }

      const resume = {
        ...initialStateCv,
        id,
        name,
        user: user.uid,
        preview,
        profile: {
          ...initialStateCv.profile,
          firstName: firstName || '',
          lastName: lastName || '',
        },
        createdAt,
        updatedAt: createdAt,
      };

      firebase.database().ref(`resumes/${id}`).set(resume);
      setModalLoading(false);
      navigate(`/app/cv/template/${id}`, { replace: true });
    }
  };

  const handleCVUpload = async (e) => {
    const file = e.target.files[0];
    const newId = uuid();
    if (!file) {
      return null;
    }

    if (!isFilePDF(file)) {
      alert(
        'You tried to upload a file that was not an pdf. Please try again.',
      );
      return null;
    }

    if (file.size > 5242880) {
      setIsErrorUpload(true);
      return null;
    }

    setBackdropOpen(true);

    let resumeServer =
      process.env.REACT_APP_DEPLOYED === 'dev'
        ? 'https://aksel-resume-staging.herokuapp.com'
        : 'https://aksel-resume.herokuapp.com';

    const fileToB64 = await getBase64(file);
    const newUrl = await axios.post(
      `${resumeServer}/uploadResume`,
      // 'http://resume-backend-stagging.eba-hsbygz2h.ap-southeast-1.elasticbeanstalk.com/uploadResume',
      {
        id: newId,
        body: fileToB64.slice(28),
      },
    );

    const uploadTask = firebase
      .storage()
      .ref(`/users/${user.uid}/cv/${newId}-${file.name}`)
      .put(file);

    // let progress = 0;
    // toastId.current = toast('Firing up engines...', {
    //   progress,
    // });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // toast.update(toastId.current, {
        //   render: 'Uploading...',
        //   progress,
        //   hideProgressBar: false,
        // });
      },
      (error) => {
        console.log(error);
        setBackdropOpen(false);
      }, //toast.error(error),
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        const cvParams = {
          id: newId,
          idFile: `${newId}-${file.name}`,
          cv: downloadURL,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          updatedAt: firebase.database.ServerValue.TIMESTAMP,
          driveUrl: newUrl.data,
          user: user.uid,
          name: file.name,
          public: true,
          // isUpload: true,
        };
        firebase.database().ref(`resumes/${newId}`).set(cvParams);
        setBackdropOpen(false);

        // toast.update(toastId.current, {
        //   render: 'Your cv was uploaded successfully...',
        //   progress,
        //   autoClose: 2000,
        //   hideProgressBar: true,
        // });
      },
    );
  };

  const handleDeleteCv = async () => {
    await firebase.database().ref(`/resumes/${dataDetail.id}`).remove();
    setModalDelete(false);
  };

  const handleDuplicate = async () => {
    const id = uuid();
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    const resume = {
      ...dataDetail,
      id,
      name: `${dataDetail.name} Copy`,
      preview,
      createdAt,
      updatedAt: dataDetail.updatedAt - 1,
    };

    firebase.database().ref(`resumes/${id}`).set(resume);
    setMenuOpen(false);
  };

  const handleDownload = async (e) => {
    reactGA.event({
      category: 'CV Maker',
      action: 'User Download CV',
      value: user.uid,
    });

    e.preventDefault();
    setButtonLoading(true);
    const content = {
      id: dataDetail.id,
      type: 'single',
    };
    if (dataDetail.driveUrl) {
      window.open(dataDetail.cv, '_blank');
      setButtonLoading(false);
    } else {
      await axios
        .post(
          'https://opykx6wgj0.execute-api.ap-southeast-1.amazonaws.com/v1/printResume',
          content,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then((response) => {
          const blob = b64toBlob(response.data, 'application/pdf');
          download(
            blob,
            `AkselResume-${dataDetail.name}.pdf`,
            'application/pdf',
          );
          setButtonLoading(false);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
    setExportOpen(false);
  };

  const handleShare = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1500);
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const onFileChange = (event) => {
    if (!event.target.files[0].type.match('application/pdf')) {
      setErrorUpload(true);
      return;
    }

    if (event.target.files[0].size > 5120000) {
      setErrorUpload(true);
      return;
    }

    setFile({ selectedFile: event.target.files[0] });
  };

  const onFileDrop = (files) => {
    if (!files[0].type.match('application/pdf')) {
      setErrorUpload(true);
      return;
    }

    if (files[0].size > 5120000) {
      setErrorUpload(true);
      return;
    }

    setFile({ selectedFile: files[0] });
  };

  const handleReview = (d) => {
    setModalReview(true);
    setDataDetail(d);
  };

  const handleFinishTooltip = () => {
    setTooltipStep(0);
    if (user) {
      firebase
        .database()
        .ref()
        .child(`/users/${user.uid}`)
        .update({ notFirstCvMaker: true });
    }
  };

  const handleFinishAskFirst = () => {
    setTooltipAskFirst(false);
    if (user) {
      firebase
        .database()
        .ref()
        .child(`/users/${user.uid}`)
        .update({ notFirstAskCvReview: true });
    }
  };

  const TooltipComponent = () => {
    if (tooltipStep === 1) {
      return (
        <div>
          <Typography sx={{ fontWeight: 700, fontSize: '16px', mb: '8px' }}>
            Buat CV kamu sendiri di sini !
          </Typography>
          <Typography variant='p' sx={{ mb: '15px' }}>
            Kamu bisa buat CV dengan menggunakan template sesuai role yang kamu
            tuju!
          </Typography>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography>&#10094;{` 1 dari 2 `}&#10095;</Typography>
            <Typography
              variant='smallButtonD'
              sx={{ color: '#FACD11', cursor: 'pointer' }}
              onClick={() => {
                setTooltipStep(0); // reset first
                setTimeout(() => {
                  setTooltipStep(2);
                }, 400);
              }}
            >
              Selanjutnya
            </Typography>
          </Stack>
        </div>
      );
    } else if (tooltipStep === 2) {
      return (
        <div>
          <Typography sx={{ fontWeight: 700, fontSize: '16px', mb: '8px' }}>
            Unggah CV terbaikmu
          </Typography>
          <Typography variant='p' sx={{ mb: '15px' }}>
            Kamu juga bisa unggah CV-mu untuk di review atau di cek oleh sistem
            ATS!
          </Typography>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography>&#10094;{` 2 dari 2 `}&#10095;</Typography>
            <Typography
              variant='smallButtonD'
              sx={{ color: '#FACD11', cursor: 'pointer' }}
              onClick={() => handleFinishTooltip()}
            >
              Selesai
            </Typography>
          </Stack>
        </div>
      );
    }
  };

  const TooltipAskFirstComponent = () => {
    if (tooltipAskFirst) {
      return (
        <div>
          <Typography sx={{ fontWeight: 700, fontSize: '16px', mb: '8px' }}>
            Lakukan review pada CVmu
          </Typography>
          <Typography variant='p' sx={{ mb: '15px' }}>
            CV kamu juga bisa langsung di-review oleh profesional mentor loh!
          </Typography>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography>&#10094;{` 2 dari 2 `}&#10095;</Typography>
            <Typography
              variant='smallButtonD'
              sx={{ color: '#FACD11', cursor: 'pointer' }}
              onClick={() => handleFinishAskFirst()}
            >
              Tutup
            </Typography>
          </Stack>
        </div>
      );
    } else {
      return (
        <div style={{ width: '141px' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
            Yuk review CV mu oleh profesional mentor!
          </Typography>
        </div>
      );
    }
  };

  const TooltipComponent0 = () => {
    if (tooltip0) {
      return (
        <div>
          <Typography sx={{ fontWeight: 700, fontSize: '16px', mb: '8px' }}>
            Lakukan check ATS pada CVmu
          </Typography>
          <Typography variant='p' sx={{ mb: '15px' }}>
            CV yang kamu unggah dapat di cek dan diberikan feedback oleh sistem
            ATS kami!
          </Typography>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography>&#10094;{` 1 dari 2 `}&#10095;</Typography>
            {/* <Typography> </Typography> */}
            <Typography
              variant='smallButtonD'
              sx={{ color: '#FACD11', cursor: 'pointer' }}
              onClick={() => {
                setTooltipAskFirst(true);
                setTooltip0(false);
              }}
            >
              Selanjutnya
            </Typography>
          </Stack>
        </div>
      );
    } else {
      return (
        <div style={{ width: '168px' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
            Kamu bisa mengecek apakah CV mu sesuai dengan sistem ATS!
          </Typography>
        </div>
      );
    }
  };

  const renderedComponent = () => {
    switch (cvStep) {
      case 0:
        return <PilihPaket />;
      case 1:
        return (
          <UploadCV
            subs={subs}
            setSubs={setSubs}
            file={file}
            setFile={setFile}
            dataATS={dataATS}
            setDataATS={setDataATS}
            onFileChange={onFileChange}
            onFileDrop={onFileDrop}
            errorUpload={errorUpload}
            setErrorUpload={setErrorUpload}
          />
        );
      // TODO: page mainenance
      // return <TemporaryError />
      case 2:
        return <ResultCV dataATS={dataATS} setOpen={setOpen} subs={subs} />;
      default:
        return null;
    }
  };

  if (loading)
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Grid>
    );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflowX: 'hidden',
      }}
    >
      <ATSCheckerModal
        handleModalOpen={handleModalOpen}
        modalOpen={modalOpen}
        datas={datas}
      />

      <Grid container justifyContent='center' alignItems='center'>
        <Grid
          container
          xs={12}
          justifyContent='center'
          alignItems='center'
          style={{ backgroundColor: '#1D2D5E' }}
        >
          <MainHeader
            subs={subs}
            setSubs={setSubs}
            icon={tab === 0 ? cvMaker : atsChecker}
            label={tab === 0 ? 'cv-maker' : 'ats-checker'}
            title={tab === 0 ? 'CV Maker' : 'ATS Checker'}
            description={
              tab === 0
                ? `Buat CV ats-friendly dengan gratis dan mudah dengan berbagai template sesuai kebutuhanmu!
                CV-mu juga bisa di-review secara personal oleh profesional mentor yang ahli di bidang industrinya! `
                : 'Cek apakah CV kamu terbaca oleh ATS dengan cepat dan mudah!'
            }
            tab={tab}
            handleTab={handleTab}
          />
        </Grid>
        <TabPanel
          value={tab}
          index={0}
          style={{ width: '1280px', margin: '0px 32px' }}
        >
          <Grid item xs={11} style={{ paddingTop: 32, maxWidth: '1280px' }}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid>
                <Typography
                  variant='subHeader16'
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 26,
                    lineHeight: '140%',
                    letterSpacing: 0.1,
                    color: '#252525',
                    marginBottom: '16px',
                  }}
                >
                  My CV
                </Typography>
              </Grid>

              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      placeholder='Cari'
                      variant='outlined'
                      onChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue}
                      fullWidth
                      size='small'
                      sx={{
                        display: 'flex',
                        width: '326px',
                        height: '40px',
                        marginRight: '8px',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ display: 'flex' }}>
                      <div
                        onClick={() => setView('carousel')}
                        className={classes.viewButton}
                        style={
                          view === 'carousel'
                            ? {
                                backgroundColor: '#1D2D5E',
                                border: '1px solid #1D2D5E',
                              }
                            : {
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #BDBDBD',
                              }
                        }
                      >
                        <GridViewIcon
                          style={
                            view === 'carousel'
                              ? { color: '#FFFFFF' }
                              : { color: '#BDBDBD' }
                          }
                        />
                      </div>
                      <div
                        onClick={() => setView('list')}
                        className={classes.viewButton}
                        style={
                          view !== 'carousel'
                            ? {
                                backgroundColor: '#1D2D5E',
                                border: '1px solid #1D2D5E',
                              }
                            : {
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #BDBDBD',
                              }
                        }
                      >
                        <ViewListIcon
                          style={
                            view !== 'carousel'
                              ? { color: '#FFFFFF' }
                              : { color: '#BDBDBD' }
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                {tooltipStep ? (
                  <Grid>
                    <Tooltip
                      open={tooltipStep === 2}
                      title={<TooltipComponent />}
                      arrow
                    >
                      <IconButton
                        sx={{ padding: 0, marginRight: '8px' }}
                        onClick={() => {
                          if (!user) {
                            localStorage.setItem('to', 'cvMaker');
                            navigate('/login');
                            return;
                          }
                          fileInputRef.current.click();
                        }}
                      >
                        <UploadIcon size='40' />
                        <input
                          name='file'
                          type='file'
                          accept='application/pdf'
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleCVUpload}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      open={tooltipStep === 1}
                      title={<TooltipComponent />}
                      arrow
                    >
                      <Button
                        onClick={() => {
                          if (!user) {
                            localStorage.setItem('to', 'cvMaker');
                            navigate('/login');
                          } else {
                            setOpen(true);
                            setDataDetail({});
                          }
                        }}
                        variant='contained'
                        sx={{ borderRadius: '8px' }}
                      >
                        <Typography
                          variant='button'
                          sx={{ textTransform: 'none', p: '5px 16px' }}
                        >
                          Create CV
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                ) : null}

                {!tooltipStep && (
                  <Grid>
                    <Tooltip
                      title={
                        <div>
                          <Typography
                            sx={{ fontWeight: 700, fontSize: '16px' }}
                          >
                            Unggah CV
                          </Typography>
                        </div>
                      }
                      arrow
                    >
                      <IconButton
                        sx={{ padding: 0, marginRight: '8px' }}
                        onClick={() => {
                          if (!user) {
                            localStorage.setItem('to', 'cvMaker');
                            navigate('/login');
                            return;
                          }
                          fileInputRef.current.click();
                        }}
                      >
                        <UploadIcon size='40' />
                        <input
                          name='file'
                          type='file'
                          accept='application/pdf'
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleCVUpload}
                        />
                      </IconButton>
                    </Tooltip>
                    <Button
                      onClick={() => {
                        if (!user) {
                          localStorage.setItem('to', 'cvMaker');
                          navigate('/login');
                        } else {
                          setOpen(true);
                          setDataDetail({});
                        }
                      }}
                      variant='contained'
                      sx={{ borderRadius: '8px' }}
                    >
                      <Typography
                        variant='button'
                        sx={{ textTransform: 'none', p: '5px 16px' }}
                      >
                        Create CV
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!isErrorUpload && (
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: '#E8F4FD', p: '16px 24px', mt: '60px' }}
              >
                <Typography>
                  <LightBulb />
                  {'  '} <b>Note :</b> File CV yang kamu unggah harus dengan
                  format PDF dan berukuran maks 5 MB
                </Typography>
              </Grid>
            )}
            {isErrorUpload && (
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: '#FDECEA', p: '16px 24px', mt: '60px' }}
              >
                <Typography>
                  <DangerIcon /> Harap masukan CV dengan format PDF dan
                  berukuran maksimal 5 MB
                </Typography>
              </Grid>
            )}
            <Grid
              container
              // justifyContent="space-evenly"
              className={classes.containerCard}
              style={{
                display: view === 'carousel' ? 'flex' : 'block',
                overflowX: view === 'carousel' ? '' : 'hidden',
              }}
            >
              {resumes.length ? (
                view === 'carousel' ? (
                  <Grid
                    container
                    paddingLeft='24px'
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    {resumes.map((idx) =>
                      idx.isUpload ? null : (
                        <Grid item lg={3} md={4}>
                          <CardGrid
                            key={idx.id}
                            id={idx.id}
                            cover={idx.preview}
                            title={idx.name}
                            data={idx}
                            rightButton={<MoreIcon />}
                            subTitle={`Last Updated ${formatDistanceToNow(
                              idx.updatedAt,
                              { addSuffix: true },
                            )}`}
                            handleClick={() =>
                              navigate(`/app/cv/preview/${idx.id}`, {
                                replace: true,
                              })
                            }
                            handleButton={handleButton}
                            isButtonCvReview
                            isButtonAtsChecker
                            setLoading={setLoading}
                            handleModalOpen={handleModalOpen}
                            setDatas={setDatas}
                            datas={datas}
                            handleReview={() => handleReview(idx)}
                            tooltipComponent={<TooltipAskFirstComponent />}
                            tooltipComponent0={<TooltipComponent0 />}
                            tooltipOpen={tooltipAskFirst}
                            tooltipOpen0={tooltip0}
                            handleChangeTabAts={() => setTab(1)}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                ) : (
                  resumes.map((idx) =>
                    idx.isUpload ? null : (
                      <ListGrid
                        key={idx.id}
                        id={idx.id}
                        cover={idx.preview}
                        title={idx.name}
                        data={idx}
                        rightButton={<MoreIcon />}
                        subTitle={`Last Updated ${formatDistanceToNow(
                          idx.updatedAt,
                          { addSuffix: true },
                        )}`}
                        handleClick={() =>
                          navigate(`/app/cv/preview/${idx.id}`, {
                            replace: true,
                          })
                        }
                        handleButton={handleButton}
                        handleReview={() => handleReview(idx)}
                        tooltipComponent={<TooltipAskFirstComponent />}
                        tooltipComponent0={<TooltipComponent0 />}
                        tooltipOpen={tooltipAskFirst}
                        tooltipOpen0={tooltip0}
                        handleChangeTabAts={() => setTab(1)}
                      />
                    ),
                  )
                )
              ) : (
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                  <p style={{ fontSize: '56px', margin: '50% 0 24px 0' }}>
                    &#x1F44B;
                  </p>
                  <Typography
                    variant='subHeader16'
                    gutterBottom
                    sx={{ color: '#323232' }}
                  >
                    Welcome to Your Dashboard!
                  </Typography>
                  <Typography variant='sm' sx={{ color: '#323232' }}>
                    Your CV will be here. Tap the “Create CV” <br /> button to
                    add CV to your dashboard
                  </Typography>
                </div>
              )}
              {/* <Grid style={{ flex: "auto", content: "" }}></Grid> */}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {checkCV === true ? (
            <Grid
              container
              xs={11}
              style={{
                marginTop: '41px',
                marginBottom: '96px',
                maxWidth: '1280px',
              }}
            >
              <Grid xs={12}>
                <Typography
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                    fontWeight: '600',
                    fontSize: '22px',
                    lineHeight: '27px',
                    letterSpacing: '0.1px',
                    color: '#252525',
                  }}
                >
                  Cek CV mu dengan 3 langkah mudah berikut ini
                </Typography>
              </Grid>

              <Grid xs={12}>
                <CustomStepper steps={steps} activeStep={cvStep}>
                  {renderedComponent()}
                </CustomStepper>
              </Grid>

              <Grid xs={12}></Grid>
            </Grid>
          ) : (
            <Grid
              container
              xs={11}
              style={{
                marginTop: '48px',
                marginBottom: '89px',
                padding: '88px 50px 74px 50px',
                maxWidth: '1280px',
                background: '#F3F5FB',
                borderRadius: '16px',
              }}
            >
              {/* TODO: screen maintenance */}
              {/* <Grid item>
                        <img alt='maintenance' src={maintenanceImg} style={{width: '350px'}} />
                      </Grid>
                      <Grid item style={{maxWidth: '412px'}}>
                        <h1>ATS Checker sedang dalam maintenance</h1>  
                      </Grid> */}

              {/* TODO: Uncomment this */}
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                style={{ marginBottom: '50px' }}
              >
                <Grid xs='6' style={{ textAlign: 'center' }}>
                  <Typography
                    style={{
                      marginBottom: '20px',
                      textAlign: 'left',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: '26px',
                      lineHeight: '36px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Tau gak 3 dari 4 CV itu gak pernah sampai ke tangan
                    rekruter?
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'left',
                      fontFamily: 'Roboto',
                      fontSize: '20px',
                      lineHeight: '28px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Alasannya gara-gara gak kebaca sama Applicant Tracking
                    System (ATS). Tenang, kamu gausah nebak-nebak lagi CV kamu
                    udah kebaca sama ATS atau belum karena ada ATS Checker
                    Aksel. Lewat sistem ini, kamu bisa langsung cek sendiri
                    hasil proses CV kamu setelah masuk ATS tuh kaya gimana
                    dengan harga super murah!
                  </Typography>
                </Grid>
                <Grid xs='6' style={{ textAlign: 'right' }}>
                  <img
                    src={ATSResult}
                    alt={'ats-result'}
                    style={{
                      maxHeight: '410px',
                      borderRadius: '10px',
                      shadowOffset: {
                        width: 0,
                        height: 4,
                      },
                      shadowColor: '#252525',
                      imageRendering: '-webkit-optimize-contrast',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid
                  container
                  onClick={() => {
                    setCheckCV(true);
                  }}
                  justifyContent='center'
                  alignItems='center'
                  style={{
                    cursor: 'pointer',
                    width: '327px',
                    height: '40px',
                    backgroundColor: '#1D2D5E',
                    borderRadius: '8px',
                    color: '#FFFFFF',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      lineHeight: '22px',
                      letterSpacing: '0.1px',
                    }}
                  >
                    Cek CVmu Sekarang
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </Grid>

      {/* Dialog */}
      <CustomDialog
        title='Name your CV'
        open={open}
        onClose={() => setOpen(false)}
        handleSubmit={handleSave}
        btnSubmitTxt={
          modalLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size='24px' sx={{ color: '#fff' }} />
            </div>
          ) : (
            'Save'
          )
        }
      >
        <TextField
          autoFocus
          margin='dense'
          label='Your CV’s name'
          fullWidth
          onChange={(e) => setName(e.target.value)}
          defaultValue={dataDetail.name}
          required
        />
      </CustomDialog>

      <CustomDialog
        title='Are you sure to delete CV?'
        description='Your CV will be removed'
        open={modalDelete}
        onClose={() => setModalDelete(false)}
        btnSubmitTxt='Yes, delete CV'
        btnCancelTxt='Back to dashboard'
        handleSubmit={handleDeleteCv}
        isReverseButton
      />

      {/* Popover */}
      <CustomPopover
        open={menuOpen}
        anchorEl={anchorEl}
        handleClose={() => setMenuOpen(false)}
        listMenu={[
          {
            label: 'Rename',
            handle: () => {
              setOpen(true);
              setMenuOpen(false);
            },
          },
          {
            label: 'Delete',
            handle: () => {
              setModalDelete(true);
              setMenuOpen(false);
            },
          },
          {
            label: 'Duplicate',
            handle: handleDuplicate,
          },
          {
            label: 'Export',
            handle: () => {
              setExportOpen(true);
              setMenuOpen(false);
            },
          },
        ]}
      />

      <CustomDialog
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        isCustomComponent
      >
        <DialogDownload
          handleDownload={handleDownload}
          handleShare={handleShare}
          buttonLoading={buttonLoading}
          cvLink={`https://cv.aksel.co.id/r/${dataDetail.id}`}
          tooltipOpen={tooltipOpen}
        />
      </CustomDialog>

      {user && (
        <DialogCvReview
          open={modalReview}
          handleClose={() => setModalReview(false)}
          data={dataDetail}
        />
      )}

      <Backdrop
        sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cvStep: state.cv.cvStep,
  atsParse: state.cv.atsParse,
});

export default connect(mapStateToProps)(DesktopCvMaker);
