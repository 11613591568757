/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Button,
  TextField,
  Container,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stack,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import CustomButton from 'src/components/button';
import BookingPreview from 'src/components/booking/BookingPreview';
import ServicesAccordion from 'src/components/booking/ServicesAccordion';
import { withStyles, makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import reactGA from 'react-ga';
import { connect } from 'react-redux';
import axios from 'axios';
import { setNavbarTitle } from 'src/redux/actions';
// import voucherCode from 'src/templates/vouchers';
import CustomDrawer from 'src/components/CustomDrawer';
import { ArrowIcon } from 'src/assets/icons/Mentoring';
import { VoucherIcon } from 'src/assets/icons/Booking';
import firebase from 'firebase/app';
import mentors from 'src/templates/products';
import CloseIcon from '@mui/icons-material/Close';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed

const numberWithCommas = (x) => {
  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};

const disabledProductId = [
  'af1735bd-110b-479b-a7e2-433f34cca2b6',
  'e745ba65-23f2-4544-8012-b4b0bd41e4dc',
];

const useStyles = makeStyles({
  container: {
    margin: '15px auto',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    marginBottom: 5,
  },
  bookingButtonContainer: {
    padding: '16px 24px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
    bottom: 0,
    position: 'fixed',
    width: '768px',
    backgroundColor: '#fff',
    ['@media (max-width:767px)']: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  },
  bookingButtonText: {
    display: 'flex',
    justifyContent: 'space-between',
    ['@media (max-width:767px)']: {
      display: 'block',
    },
  },
  bookingButton: {
    width: '100%',
    ['@media (max-width:767px)']: {
      width: 'auto',
    },
  },
});

const TimeButton = withStyles({
  root: {
    textTransform: 'capitalize !important',
    backgroundColor: 'white !important',
    border: '1px solid #C4C4C4 !important',
    color: '#111 !important',
    marginRight: '5px !important',
    height: '60px',
    width: '100%',
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: 'rgba(62, 198, 255, 0.1) !important',
    borderColor: '#3ec6ff !important',
  },
})(Button);

const Booking = () => {
  const classes = useStyles();
  const [onLoadingPayment, setOnLoadingPayment] = React.useState(false);
  const [timeValue, setTimeValue] = React.useState(0.5);
  const [notesValue, setNotesValue] = React.useState(
    localStorage.getItem('notesValue') || '',
  );
  // Voucher yang digunakan
  const [usedVoucher, setUsedVoucher] = React.useState('');
  // Voucher yang diselect di drawer
  const [currentVoucher, setCurrentVoucher] = React.useState(null);
  const [voucherValue, setVoucherValue] = React.useState('');
  const [currentMenu, setCurrentMenu] = useState('general');
  const [endPriceValue, setEndPriceValue] = React.useState(0);
  const [isVoucherOpen, setIsVoucherOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bookDraft, setBookDraft] = useState({});
  const [paymentUrl, setPaymentUrl] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const date = new Date();
  const sevenDaysDate = date.setDate(date.getDate() + 7);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const sevenDaysDateString = new Date(sevenDaysDate).toLocaleDateString(
    'id-ID',
    options,
  );

  // const publicVoucher = voucherCode
  //   .filter(
  //     (voucher) => voucher.type === 'mentoring' && voucher.public === true,
  //   )
  //   .sort((a, b) => new Date(a.until) - new Date(b.until));

  useEffect(() => {
    setNavbarTitle('Pesan Mentor');
    if (localStorage.getItem('book_mentor_id')) {
      const mentor = mentors.filter(
        (m) => m.id === localStorage.getItem('book_mentor_id'),
      )[0];
      setBookDraft(mentor);

      if (mentor.price !== endPriceValue) {
        setEndPriceValue(mentor.price * timeValue);
      }
      setLoading(false);
    } else {
      navigate('/app/mentoring');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('book_mentor_id')]);

  const handleSubmitVoucher = async () => {
    setIsVoucherOpen(false);
    setEndPriceValue(bookDraft.price * timeValue);

    const ref = firebase.database().ref();
    const resVoucher = await ref.child('vouchers').child(voucherValue).get();

    if (!resVoucher.val()) return alert('Voucher tidak ditemukan');

    // check voucher code in date range
    const epochNow = Math.round(new Date().getTime() / 1000);
    const { startAt, closeAt, id, users, types, discount, counter, max } =
      resVoucher.val();

    if (epochNow < startAt || epochNow > closeAt) {
      alert('Voucher expired');
      return;
    }

    if (JSON.parse(users).includes(user.uid)) {
      alert('Voucher sudah Anda digunakan');
      return;
    }

    if (counter >= max) {
      alert('Voucher sudah mencapai batas penggunaan');
      return;
    }

    if (id) {
      // cek jika voucher kodenya bener
      const userExist = JSON.parse(users).findIndex(
        (user) => user === user.uid,
      );
      if (userExist === -1) {
        if (types.indexOf('mentoring') !== -1) {
          setUsedVoucher(id);
          setCurrentVoucher(resVoucher.val());
          if (discount > 1) {
            // kalau diatas 1.0 itu dianggap potongan langsung
            setEndPriceValue(bookDraft.price * timeValue - discount);
          } else {
            setEndPriceValue(bookDraft.price * timeValue * (1 - discount));
          }
        } else {
          alert('Voucher tidak ditemukan');
          return;
        }
      } else {
        alert('Anda sudah menggunakan kode ini');
      }
    } else {
      alert('voucher code gagal diterapkan');
    }

    // reactGA.event({
    //   category: 'Mentoring',
    //   action: 'User Set Voucher',
    //   label: user.uid,
    // });
    // let code = voucherCode.filter((c) => {
    //   if (!c.used) {
    //     if (
    //       c.code === currentVoucher?.code.toUpperCase() &&
    //       c.type === 'mentoring'
    //     ) {
    //       c.used = true;
    //       return true;
    //     }
    //   }

    //   return false;
    // })[0];

    // if (code) {
    //   if (code.discount > 1) {
    //     setEndPriceValue((bookDraft.price * timeValue) - code.discount);
    //   } else {
    //     setEndPriceValue((bookDraft.price * timeValue) * (1 - code.discount));
    //   }
    //   setUsedVoucher(currentVoucher.code);

    //   reactGA.event({
    //     category: 'Mentoring',
    //     action: 'User Set Voucher',
    //     label: user.uid,
    //   });
    // } else {
    //   alert(`Voucher ${currentVoucher?.code} gagal diterapkan`);
    // }
  };

  const handleOpen = () => {
    if (!user) {
      localStorage.setItem('to', 'mentoring');
      navigate('/login');
      return;
    }
    if (notesValue.length > 50) {
      setOpen(true);
    } else {
      alert('Tuliskan catatan di booking, catatan harus diatas 50 karakter.');
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePayment = () => {
    reactGA.event({
      category: 'Mentoring',
      action: 'User Click Book',
      label: user.uid,
    });

    setOnLoadingPayment(true);
    let firstName;
    let lastName;

    if (user != null) {
      [firstName, lastName] = user.displayName.split(' ');

      const content = {
        customerFirstName: firstName,
        customerLastName: lastName,
        customerEmail: user.email,
        customerId: user.uid,
        bookId: bookDraft.bookId,
        productId: bookDraft.id,
        productName: bookDraft.name,
        productPrice: endPriceValue,
        productPhoneNumber: bookDraft.phoneNumber,
        productEmail: bookDraft.email,
        bookTime: timeValue,
        bookNotes: notesValue,
        transactionType: 'mentoring',
        voucherCode: usedVoucher,
      };

      if (usedVoucher) {
        const voucherRef = firebase.database().ref(`vouchers/${usedVoucher}`);
        // check voucher belum sampai max
        voucherRef.transaction((postVoucher) => {
          if (!postVoucher) {
            return {};
          }

          // nambahin ke voucer counter & daftarin usernya
          postVoucher.counter++;
          const currUsers = JSON.parse(postVoucher.users);
          postVoucher.users = JSON.stringify([...currUsers, user.uid]);
          return postVoucher;
        });
      }

      let paymentServer =
        process.env.REACT_APP_DEPLOYED === 'dev'
          ? 'https://aksel-payment-staging.herokuapp.com/create/invoice'
          : 'https://aksel-payment.herokuapp.com/create/invoice';
      // let paymentServer =
      //   process.env.REACT_APP_DEPLOYED === 'dev'
      //     ? 'https://aksel-payment-staging.herokuapp.com/token'
      //     : 'https://aksel-payment.herokuapp.com/token';

      axios
        .post(paymentServer, content, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          const resp = response.data;
          setOnLoadingPayment(false);
          setOpen(false);
          // window.snap.pay(resp.transactionToken);
          setPaymentUrl(resp.resp.invoice_url);
          localStorage.removeItem('book_mentor_id');
        })
        .catch((e) => {
          alert(
            'Cannot connecting to payment. Please contact customer support or wait a minutes.',
          );
          setOnLoadingPayment(false);
          setOpen(false);
        });
    } else {
      alert('User does not exist. Please contact customer support');
      setOnLoadingPayment(false);
      setOpen(false);
    }
  };

  if (loading) {
    return (
      <Stack sx={{ width: '100%', mt: '3rem' }}>
        <CircularProgress sx={{ m: 'auto' }} />
      </Stack>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Booking - Aksel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth='lg' style={{ paddingBottom: '40px' }}>
          <div className={classes.container}>
            <BookingPreview booking={bookDraft} />
          </div>
          <hr style={{ border: '1px solid #E8E8E8', margin: '20px 0' }} />
          <div className={classes.container}>
            <Typography gutterBottom variant='h6' component='h6'>
              Pilih Durasi Mentoring
            </Typography>
            <Grid container spacing={2}>
              {[0.5, 1, 1.5].map((h) => (
                <Grid item xs={4} sm={4}>
                  <TimeButton
                    key={h}
                    className={classes.time}
                    variant={timeValue === h ? 'contained' : 'outlined'}
                    disableElevation
                    onClick={() => {
                      setTimeValue(h);
                      if (currentVoucher) {
                        if (currentVoucher.discount > 1) {
                          setEndPriceValue(
                            bookDraft.price * h - currentVoucher.discount,
                          );
                        } else {
                          setEndPriceValue(
                            bookDraft.price * h * (1 - currentVoucher.discount),
                          );
                        }
                      } else {
                        setEndPriceValue(bookDraft.price * h);
                      }
                    }}
                  >
                    {h * 60} Menit
                  </TimeButton>
                </Grid>
              ))}
            </Grid>
          </div>
          <hr style={{ border: '1px solid #E8E8E8', marginBottom: '20px 0' }} />
          <div className={classes.container}>
            <Typography gutterBottom variant='h6' component='h6'>
              Layanan Mentoring
            </Typography>
            <ServicesAccordion
              serviceLine={
                bookDraft.serviceLine && bookDraft.serviceLine.split(',')
              }
            />
          </div>
          <hr style={{ border: '1px solid #E8E8E8', marginBottom: '20px 0' }} />
          <div className={classes.container}>
            <Typography gutterBottom variant='h6' component='h6'>
              Catatan Buat Mentor*
            </Typography>
            <TextField
              size='small'
              fullWidth
              placeholder='Apa yang kamu butuhkan dari sesi mentoring ini? (minimal 50 karater)'
              margin='normal'
              name='confirm'
              variant='outlined'
              multiline
              rows={4}
              InputLabelProps={{
                shrink: true,
              }}
              value={notesValue}
              onChange={(e) => {
                setNotesValue(e.target.value);
                localStorage.setItem('notesValue', e.target.value);
              }}
            />
            <Typography
              gutterBottom
              variant='p'
              component='p'
              sx={{ color: 'red' }}
            >
              <p>
                {notesValue.length < 50
                  ? 'Catatan harus diatas 50 karakter'
                  : null}
              </p>
            </Typography>
          </div>
          <hr style={{ border: '1px solid #E8E8E8', marginBottom: '20px 0' }} />
          <div className={classes.container} style={{ marginBottom: '100px' }}>
            <Box
              px={2}
              py={1}
              border='1px solid #BDBDBD'
              borderRadius='8px'
              onClick={() => {
                if (!user) {
                  localStorage.setItem('to', 'mentoring');
                  navigate('/login');
                  return;
                }
                setIsVoucherOpen(true);
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '16px',
              }}
            >
              <VoucherIcon />
              <Typography
                flex={1}
                variant='p'
                component='p'
                color='black'
                pl={1}
              >
                {usedVoucher || 'Gunakan Kode Promo'}
              </Typography>
              <ArrowIcon />
            </Box>
          </div>
        </Container>
        <div style={{ maxWidth: '768px', margin: '0 auto' }}>
          <div className={classes.bookingButtonContainer}>
            <Box className={classes.bookingButtonText} mb={1}>
              <Typography
                variant='p'
                sx={{
                  '@media (min-width:768px)': {
                    fontSize: '16px!important',
                  },
                }}
              >
                Total
              </Typography>
              <Box display='flex' flexDirection='column' alignItems='flex-end'>
                {currentVoucher ? (
                  <h5
                    className={classes.heading}
                    style={{ textDecoration: 'line-through' }}
                  >
                    Rp. {numberWithCommas(bookDraft.price * timeValue)}
                  </h5>
                ) : null}
                <Typography
                  variant='subHeader16'
                  sx={{
                    '@media (min-width:768px)': {
                      fontSize: '18px!important',
                    },
                  }}
                >
                  Rp. {numberWithCommas(endPriceValue)}
                </Typography>
              </Box>
            </Box>
            <CustomButton
              onClick={handleOpen}
              disabled={disabledProductId.includes(bookDraft.id)}
              className={classes.bookingButton}
            >
              Book Session
            </CustomButton>
          </div>
        </div>
      </Box>

      <CustomDrawer
        title={currentMenu === 'general' ? 'Promo' : 'Detail Promo'}
        open={isVoucherOpen}
        onClose={() => setIsVoucherOpen(false)}
        style={{ maxWidth: '768px', margin: '0 auto' }}
      >
        {currentMenu === 'general' ? (
          <>
            <Box
              display='flex'
              width='100%'
              alignItems='center'
              marginBottom={3}
            >
              <TextField
                size='small'
                fullWidth
                placeholder='Masukan kode voucher'
                margin='normal'
                name='confirm'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  display: 'inline-block',
                  flex: 1,
                  height: '100%',
                  margin: 0,
                  marginRight: '2%',
                }}
                value={currentVoucher?.code}
                onChange={(e) => setVoucherValue(e.target.value)}
              />
              <CustomButton variant='outlined' onClick={handleSubmitVoucher}>
                Terapkan
              </CustomButton>
            </Box>
            {/* TODO: public voucher disable dulu */}
            {/* {publicVoucher.length > 0 && (
              <>
                <Typography variant='subHeader16'>Pilih Promo</Typography>
                {publicVoucher.map((voucher) => {
                  return (
                    <Box
                      onClick={() => setCurrentVoucher(voucher)}
                      sx={{
                        background:
                          currentVoucher === voucher
                            ? 'rgba(91, 192, 235, 0.1)'
                            : 'white',
                        border:
                          currentVoucher === voucher
                            ? '2px solid #5BC0EB1A'
                            : 'none',
                        cursor: 'pointer',
                        padding: '12px',
                        marginTop: '12px',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      <Typography variant='pBold'>{voucher.code}</Typography>
                      <Box
                        display='flex'
                        marginTop={1}
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Box display='flex' alignItems='center'>
                          <HourGlassIcon />
                          <Typography
                            variant='sm'
                            color='#8D8D8D'
                            marginLeft={1}
                          >
                            Berlaku hingga{' '}
                            {new Date(voucher.until).toLocaleString('id-ID', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </Typography>
                        </Box>
                        <Typography
                          variant='smBold'
                          color='#1D2D5E'
                          onClick={() => setCurrentMenu('detail')}
                        >
                          Detail
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
                <Box
                  position='sticky'
                  bottom='0'
                  sx={{ background: 'white', padding: '24px 0' }}
                >
                  <CustomButton onClick={handleSubmitVoucher} fullWidth>
                    Gunakan Promo
                  </CustomButton>
                </Box>
              </>
            )} */}
          </>
        ) : (
          <>
            <Typography variant='subHeader16'>{currentVoucher.code}</Typography>
            <Typography variant='p' mt={2}>
              {currentVoucher.description}
            </Typography>
            <Typography variant='p' mt={2}>
              Berlaku hingga{' '}
              {new Date(currentVoucher.until).toLocaleString('id-ID', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
            <CustomButton
              onClick={() => setCurrentMenu('general')}
              style={{ marginTop: '24px' }}
              fullWidth
            >
              Oke
            </CustomButton>
          </>
        )}
      </CustomDrawer>

      <Dialog onClose={handleClose} open={open}>
        <DialogContent>
          <Typography variant='p' component='p'>
            Mentoring session dapat dilakukan bersama {bookDraft.name} selama{' '}
            {timeValue * 60} menit hingga {sevenDaysDateString} (1 minggu dari
            sekarang).
          </Typography>
          {notesValue !== '' ? (
            <div>
              <Typography
                variant='p'
                component='p'
                sx={{ mt: '10px', mb: '20px' }}
              >
                Notes: {notesValue}
              </Typography>
            </div>
          ) : null}
          <Typography variant='p' component='p' sx={{ mt: '10px' }}>
            Apabila kamu setuju, kami akan meneruskan booking ini ke mentor dan
            tim Aksel.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            sx={{
              float: 'right',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              textTransform: 'capitalize',
            }}
            variant='contained'
            disableElevation
            onClick={() => handlePayment()}
          >
            {onLoadingPayment ? (
              <CircularProgress sx={{ color: 'white' }} size={20} />
            ) : (
              'Setuju dan Bayar'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={paymentUrl}
        keepMounted
        fullWidth
        maxWidth='xs'
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>
          <Typography variant='h4'>Pembayaran</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setPaymentUrl(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: '500px',
            paddingTop: '56.25%',
          }}
        >
          <iframe
            src={paymentUrl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
            }}
            title='payment'
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bookDraft: state.book.bookDraft,
});

export default connect(mapStateToProps)(Booking);
