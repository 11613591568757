import { Typography, Button, Grid } from '@material-ui/core';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import FilterListIcon from '@material-ui/icons/FilterList';

const MentorFilter = ({
  handleModalOpen,
  countServiceLine,
  countIndustry,
  countSkills,
  handleModalOpenSort,
  countSort,
  dataLength,
}) => {
  return (
    <>
      <Grid item xs={5} sm={3}>
        <Button
          fullWidth
          size='large'
          variant='outlined'
          style={{
            borderRadius: '80px',
            backgroundColor: 'white',
            borderColor: '#1D2D5E',
            padding: '9px 10px',
            maxWidth: '200px',
          }}
          startIcon={<TuneRoundedIcon />}
          onClick={() => handleModalOpen()}
        >
          <Typography variant='button' sx={{ textTransform: 'capitalize' }}>
            {`Filters (${countServiceLine + countIndustry + countSkills})`}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={5} sm={3} style={{ marginLeft: '0' }}>
        <Button
          fullWidth
          size='large'
          variant='outlined'
          style={{
            borderRadius: '80px',
            backgroundColor: 'white',
            borderColor: '#1D2D5E',
            padding: '9px 10px',
            maxWidth: '200px',
          }}
          startIcon={<FilterListIcon />}
          onClick={() => handleModalOpenSort()}
        >
          <Typography variant='button' sx={{ textTransform: 'capitalize' }}>
            {`Sort (${countSort})`}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant='p'
          sx={{ color: '#252525' }}
        >{`Diperoleh ${dataLength} hasil`}</Typography>
      </Grid>
    </>
  );
};

export default MentorFilter;
