import paketPointer from 'src/assets/img/paket-pointer.svg';
import paketEkonomis from 'src/assets/img/paket-ekonomis.svg';
import paketPas from 'src/assets/img/paket-pas.svg';
import paketSuper from 'src/assets/img/paket-super.svg';

const atsPackages = [
  {
    id: 'pkg-ekonomis',
    icon: paketEkonomis,
    name: 'Paket Ekonomis',
    price: 18000,
    duration: 4,
    iconPointer: paketPointer,
    listDesc: [
      '3 kali penggunaan ATS Checker',
      'Free 1 kali penggunaan ATS checker',
    ],
    backgroundColor: '#fff',
    backgroundButton: '#1D2D5E',
    fontColorButton: '#fff',
    fontColor: '#000',
  },
  {
    id: 'pkg-pas',
    icon: paketPas,
    name: 'Paket Pas',
    price: 34000,
    duration: 8,
    iconPointer: paketPointer,
    listDesc: [
      '6 kali penggunaan ATS Checker',
      'Free 2 kali penggunaan ATS Checker',
    ],
    backgroundColor: '#fff',
    backgroundButton: '#1D2D5E',
    fontColorButton: '#fff',
    fontColor: '#000',
  },
  {
    id: 'pkg-super',
    icon: paketSuper,
    name: 'Paket Super',
    price: 49000,
    duration: 14,
    iconPointer: paketPointer,
    listDesc: [
      '10 kali penggunaan ATS Checker',
      'Free 4 kali penggunaan ATS Checker',
    ],
    backgroundColor: '#1D2D5E',
    backgroundButton: '#fff',
    fontColorButton: '#1D2D5E',
    fontColor: '#fff',
  },
];

export default atsPackages;
