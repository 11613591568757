const BottomBar = ({ children, noDisplay }) => (
  <div
    style={{
      position: 'fixed',
      bottom: 0,
      backgroundColor: '#fff',
      width: '100%',
      maxWidth: '768px',
      maxHeight: '71.78px',
      padding: '15px 24px',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
      display: noDisplay ? 'none' : 'flex',
      justifyContent: 'space-between',
      zIndex: '1400',
    }}
  >
    {children}
  </div>
);

export default BottomBar;
