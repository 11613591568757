import BottomBar from 'src/components/cvMaker/BottomBar';
import CustomButton from 'src/components/button';
import {
  IconButton,
  // Divider
} from '@material-ui/core';
import {
  PaperEditIcon,
  // ListIcon,
  // LayoutIcon,
  // ColorIcon,
  // TextIcon,
} from 'src/assets/icons/CvMaker';

// const ListMenu = ({ handleMenu, id, activeMenu, label, icon }) => (
//   <CustomButton
//     smallButton
//     variant="outlined"
//     color="tertiary"
//     sx={
//       activeMenu[id]
//         ? {
//             color: "#fff !important",
//             backgroundColor: "#5BC0EB !important",
//             padding: "5.5px 0",
//             marginRight: "16px",
//             minWidth: "max-content",
//           }
//         : { padding: "5.5px 0", marginRight: "16px", minWidth: "max-content" }
//     }
//     onClick={handleMenu}
//   >
//     <IconButton sx={{ padding: 0, mt: "-3px" }}>{icon}</IconButton>
//     &nbsp;{label}
//   </CustomButton>
// );

// const menus = [
//   {
//     id: "template",
//     label: "Template",
//     icon: <ListIcon />,
//   },
//   {
//     id: "layout",
//     label: "Layout",
//     icon: <LayoutIcon />,
//   },
//   {
//     id: "color",
//     label: "Color",
//     icon: <ColorIcon />,
//   },
//   {
//     id: "text",
//     label: "Text",
//     icon: <TextIcon />,
//   },
// ];

const MenuBottom = ({ activeMenu, handleMenu, noDisplay }) => {
  return (
    <BottomBar noDisplay={noDisplay}>
      <CustomButton
        smallButton
        sx={{
          padding: '5.5px 0',
          marginRight: '16px',
          minWidth: 'max-content',
        }}
        onClick={() => handleMenu('edit')}
      >
        <IconButton sx={{ padding: 0 }}>
          <PaperEditIcon />
        </IconButton>
        &nbsp;Edit
      </CustomButton>
      {/* <Divider sx={{ mr: "16px" }} orientation="vertical" flexItem />

      <div style={{ overflowX: "scroll", display: "flex" }}>
        {menus.map((menu) => (
          <ListMenu
            id={menu.id}
            label={menu.label}
            icon={menu.icon}
            minWidth={menu.minWidth}
            handleMenu={() => handleMenu(menu.id)}
            activeMenu={activeMenu}
          />
        ))}
      </div> */}
    </BottomBar>
  );
};

export default MenuBottom;
