import { Grid, Typography } from '@material-ui/core';
import logoAksel from 'src/assets/img/landing/logoAksel.png';
import instagram from 'src/assets/img/landing/instagram.png';
import twitter from 'src/assets/img/landing/twitter.png';
import linkedin from 'src/assets/img/landing/linkedin.png';
import whatsapp from 'src/assets/img/landing/whatsapp.png';
import youtube from 'src/assets/img/landing/youtube.png';
import telegram from 'src/assets/img/landing/telegram.png';

const Footer = () => (
  <Grid
    item
    xs={12}
    style={{ textAlign: 'center', marginTop: 24, marginBottom: 32 }}
  >
    <Grid
      container
      spacing={3}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid item xs={12}>
        <img
          style={{ width: '48.91px', height: '64px' }}
          alt='logo-aksel'
          src={logoAksel}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 9 }}>
        <Typography variant='subHeader14' style={{ color: '#949494' }}>
          <span>&#169;</span> {new Date().getFullYear()} Aksel (PT Kreasi Pemuda
          Bangsa)
        </Typography>
      </Grid>
      <Grid item>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.instagram.com/aksel.official/'
        >
          <img src={instagram} alt='instagram' />
        </a>
      </Grid>
      <Grid item>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://twitter.com/akselofficial_'
        >
          <img src={twitter} alt='twitter' />
        </a>
      </Grid>
      <Grid item>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.linkedin.com/company/aksel-official/'
        >
          <img src={linkedin} alt='linked-in' />
        </a>
      </Grid>
      <Grid item>
        <a target='_blank' rel='noreferrer' href='https://wa.me/6281286404334'>
          <img src={whatsapp} alt='whatsapp' />
        </a>
      </Grid>
      <Grid item>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.youtube.com/channel/UC_-w4knJEZn9W1km3sNnAFQ'
        >
          <img src={youtube} alt='youtube' />
        </a>
      </Grid>
      <Grid item>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://t.me/AkselOppurtunitySeeker'
        >
          <img src={telegram} alt='telegram' />
        </a>
      </Grid>
    </Grid>
  </Grid>
);

export default Footer;
