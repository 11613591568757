import { pick } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/auth';
import { config } from '../../firebase.config';
import store from '../store';
import { AUTHENTICATED, DEAUTHENTICATED } from '../events';
import { authClient } from '../../clients';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const defaultUser = {
  uid: null,
  email: null,
  displayName: null,
  isAnonymous: false,
};

const { dispatch } = store;

export const authGoogle = async () => {
  const data = await authClient.googleAuth();

  if (data.user) {
    dispatch({
      type: AUTHENTICATED,
      payload: { isAuthenticated: true, dataUser: data.user },
    });
  }
};

export const logout = () => {
  authClient.logout();
  dispatch({
    type: DEAUTHENTICATED,
    payload: { isLoading: false },
  });
};

export const register = async (data) => {
  await authClient.register(data);
};

export const registerWithEmail = async (newUserData) => {
  const { name, email, phone, password } = newUserData;

  const response = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password);

  localStorage.setItem(
    'user',
    JSON.stringify(
      pick({ ...response.user, displayName: name }, Object.keys(defaultUser)),
    ),
  );

  await response.user.updateProfile({
    displayName: name,
  });

  await firebase.database().ref(`users/${response.user.uid}`).set({
    displayName: name,
    email,
    phone,
    uid: response.user.uid,
  });
};

export const loginWithEmail = async (credentials) => {
  const { email, password } = credentials;

  const response = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);

  dispatch({
    type: AUTHENTICATED,
    payload: { isAuthenticated: true, dataUser: response.user },
  });
};

export const refreshClientAuth = async () => {
  firebase
    .auth()
    .getRedirectResult()
    .then(
      async () => {},
      async (error) => {
        if (error.code === 'auth/web-storage-unsupported') {
          const provider = new firebase.auth.GoogleAuthProvider();
          const data = await firebase.auth().signInWithPopup(provider);
          if (data.user) {
            const remoteUser = pick(data.user, Object.keys(defaultUser));
            localStorage.setItem('user', JSON.stringify(remoteUser));

            const localUser = JSON.parse(localStorage.getItem('user'));

            document.getElementsByClassName(
              'Papercups-toggleButtonContainer',
            )[0].style.visibility = 'hidden';
            document.getElementsByClassName(
              'Papercups-chatWindowContainer',
            )[0].style.visibility = 'hidden';

            dispatch({
              type: AUTHENTICATED,
              payload: {
                isAuthenticated: true,
                isLoading: false,
                dataUser: localUser,
              },
            });
          }
        }
      },
    );

  firebase.auth().onAuthStateChanged(
    (user) => {
      if (user) {
        const remoteUser = pick(user, Object.keys(defaultUser));
        localStorage.setItem('user', JSON.stringify(remoteUser));

        const localUser = JSON.parse(localStorage.getItem('user'));
        dispatch({
          type: AUTHENTICATED,
          payload: {
            isAuthenticated: true,
            isLoading: false,
            dataUser: localUser,
          },
        });
      }

      if (!user) {
        document.getElementsByClassName(
          'Papercups-toggleButtonContainer',
        )[0].style.visibility = 'visible';
        document.getElementsByClassName(
          'Papercups-chatWindowContainer',
        )[0].style.visibility = 'visible';

        return dispatch({
          type: DEAUTHENTICATED,
          payload: { isLoading: false },
        });
      }

      // const remoteUser = pick(user, Object.keys(defaultUser));
      // localStorage.setItem('user', JSON.stringify(remoteUser));

      // const localUser = JSON.parse(localStorage.getItem('user'));

      document.getElementsByClassName(
        'Papercups-toggleButtonContainer',
      )[0].style.visibility = 'hidden';
      document.getElementsByClassName(
        'Papercups-chatWindowContainer',
      )[0].style.visibility = 'hidden';

      // dispatch({
      //   type: AUTHENTICATED,
      //   payload: {
      //     isAuthenticated: true,
      //     isLoading: false,
      //     dataUser: localUser,
      //   },
      // });
    },
    (error) => {
      dispatch({ type: DEAUTHENTICATED, payload: { isLoading: false } });
    },
  );
};
