import { useState } from 'react';
import { format } from 'date-fns';

import { Grid } from '@material-ui/core';

import CustomButton from 'src/components/button';
import CustomInput from 'src/components/cvMaker/CustomInput';
import CustomDatePicker from 'src/components/cvMaker/CustomDatePicker';

const ProjectForm = ({
  projects,
  dataDetail,
  handleAdd,
  setProjects,
  handleClose,
}) => {
  const [values, setValues] = useState(dataDetail);

  const handleHeadingChange = (event) => {
    setProjects({
      ...projects,
      heading: event.target.value,
    });
  };

  const handleDateChange = (date, name) => {
    setValues({
      ...values,
      [name]: format(new Date(date), 'yyyy-MM-dd'),
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container spacing={1} marginTop={2}>
      <CustomInput
        label='Heading'
        name='heading'
        onChange={handleHeadingChange}
        value={projects.heading}
      />

      <Grid item xs={12}>
        <CustomInput
          label='Title'
          name='title'
          onChange={handleChange}
          value={values.title}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Website'
          name='link'
          onChange={handleChange}
          value={values.link}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          label='Start Date'
          name='startDate'
          onChange={(date) => handleDateChange(date, 'date')}
          value={values.date}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          label='End Date'
          name='endDate'
          onChange={(date) => handleDateChange(date, 'endDate')}
          value={values.endDate}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Project Description'
          name='summary'
          onChange={handleChange}
          value={values.summary}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={() => {
            handleAdd(values, dataDetail.id ? 'edit' : 'add');
          }}
          fullWidth
        >
          Save Project
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          fullWidth
        >
          Cancel
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default ProjectForm;
