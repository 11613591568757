const prodMentors = [
  {
    id: '0a5109a3-7391-4a56-b264-c31e34c2f233',
    name: 'Rahmat Fajri',
    title: 'People Management for CS at Xendit',
    phoneNumber: '+6285710135608',
    email: 'rahmat.fajri@xendit.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up",
    experiences:
      'People Management for CS at Xendit - Present,Human Resources Team (HRBP) at Unilever - 1 tahun 8 bulan,People and Organization Consultant at PwC - 1 tahun 4 bulan,Country Director of AIESEC in Indonesia 17/18 - 1 tahun,National Head of Organizational Development of AIESEC in Indonesia 16/18  - 1 tahun',
    industry: 'Teknologi/Startup,Financial Services',
    skills: 'Human Resource',
    schedule: 'Senin - Jumat : 18.00 - 21.00 WIB',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/rfajri',
    photoUrl: '/static/images/mentors1/rahmat.jpg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  // {
  //   id: '0f9129ab-3b03-41c0-a260-fac1ae13293e',
  //   name: 'Dio Rizky Anamia',
  //   title: 'Regional HR Manager at Zen Rooms',
  //   phoneNumber: '+6282230909643',
  //   email: 'dioranamia@gmail.com',
  //   serviceLine:
  //     "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
  //   experiences:
  //     'Regional HR Manager at Zen Rooms - Present,Leadership Development Diversity & Inclusion Associate at TATA Consultancy - 1 year,Financial Inclusion Project Coordinator at Asian Development Bank - 8 months',
  //   industry: 'Teknologi/Startup',
  //   skills: 'Human Resource',
  //   schedule:
  //     'Senin - Jumat 19:00 - 21:00 WIB,Sabtu - Minggu 10:00 - 14:00 WIB',
  //   price: 100000,
  //   linkedin: 'https://www.linkedin.com/in/diorizkya/',
  //   photoUrl: '/static/images/mentors1/dio.jpg',
  //   videoUrl: '',
  //   super: true,
  //   addedAt: '1624208400',
  //   description:
  //     'Officially, I am an HR Practitioner working across regions in South-East Asia, but more importantly, I am a self-driven individual who produces, values, and supports ideas of inclusive learning and leadership. Experienced in human resources, learning & development, and project management. Talk to me about your questions, doubts, and thoughts about exploring career options, discovery, and preparation!',
  // },
  {
    id: '8e89be05-d9ee-4886-9e16-645b6fa325ff',
    name: 'Maurisius Edward',
    title: 'Head of Marketing at Indobild',
    phoneNumber: '+6285155337375',
    email: 'maurisiusedward@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Head of Marketing at Indobild - current ,Assistant Manager of Marketing Innovation & Incubation at Danone - 1 tahun 8 bulan ,People Performance Intern Happy Fresh - 3 bulan ,Vice President External Relations AIESEC in Prasetiya Mulya  - 1 tahun ,National Finalist of Alibaba Business Plan Competition 2020',
    industry: 'FMCG',
    skills: 'Business Development',
    schedule: 'Sabtu: 9.00 - 12.00 dan 15.00 - 18.00,Minggu: 13:00 - 17.00',
    price: 100000,
    linkedin: 'https://bit.ly/maurisiusedward',
    photoUrl: '/static/images/mentors1/maurisiuse.jpg',
    videoUrl: '',
    super: true,
    addedAt: '1624208400',
    description:
      "I am self-driven to deliver impact in every aspect of his life to contribute and inspire people. This is why I constantly strive to be the best version of myself in a personal and professional context to empower my surrounding. From my passion in entrepreneurship and leadership, I am privileged to be a National Finalist in Alibaba Business Plan Competition, which sharpened my business analysis and acumen. and various leadership experiences in AIESEC.  I was able to contribute up to 10% of our Objective Key Results, and up to IDR 100.000.000 value of partnerships and revenue by doing strategical collaborations and partnerships with internal and external stakeholders. In my professional career, I'm honored to be part of Danone's MT program as Key Account Manager. In this role, I was able to deliver 30% service level increase and 30% sales growth along with a handful of projects execution. Not settling for less, I'm ready to face bigger challenges for my development and deliver impact!",
  },
  {
    id: '176d753d-c29f-4669-8b91-ff17ee18401c',
    name: 'Citra Zainuri',
    title: 'Sr. Talent Management at IDN Media',
    phoneNumber: '+6281384108329',
    email: 'citrazainuri@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Opportunity and Network Sourcing",
    experiences:
      'Sr. Talent Management at IDN Media - Present,Talent Acquisition and Management at Bhinneka.com - 1.5 tahun,Local Committee President of AIESEC in Universitas Indonesia 15/16 - 1 Tahun',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource',
    schedule:
      'Selasa & Rabu : 19.00 - 21.00 WIB,Sabtu: 14.00 - 16.00 WIB,Minggu: 11.00 - 13.00 WIB',
    price: 100000,
    linkedin: 'https://linkedin.com/in/citrazainuri',
    photoUrl: '/static/images/mentors1/citra.jpg',
    videoUrl: '',
    super: true,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '1d397237-a00f-43c5-aa95-603d436cb8c8',
    name: 'Girindra Mahesazikri',
    title: 'Marketing Management Trainee at L’Oreal',
    phoneNumber: '+6287823497301',
    email: 'girindramahesadzikri@yahoo.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Management Trainee - L’Oreal - June 2021 -  present,Product Marketing Analyst - Pinhome - September 2020 - June 2021',
    industry: 'Teknologi/Startup,FMCG',
    skills: 'Product',
    schedule:
      'Senin & Kamis : 20.00-21.00 WIB,Sabtu & Minggu : 09.30-10.30 WIB',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/girindram',
    photoUrl: '/static/images/mentors1/girindra.jpg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '9e727b45-336b-49f6-9088-cfc8d12509d7',
    name: 'Jeremy Valentino Manik',
    title: 'Software Engineer at Tokopedia',
    phoneNumber: '+628111114294',
    email: 'jeremymanik@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up",
    experiences:
      'Software Engineer - Tokopedia - 7 Bulan,Android Developer Intern at Blibli.com - 3 months',
    industry: 'Teknologi/Startup',
    skills: 'Tech Engineering',
    schedule: 'Senin - Minggu: 19.00 - 21.00 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/jeremymanik/',
    photoUrl: '/static/images/mentors1/jeremy.jpg',
    videoUrl: '',
    super: true,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '2a86a6de-1327-4734-97e6-5ae0f2aab2b9',
    name: 'Stephanie',
    title: 'Data Scientist at Gojek',
    phoneNumber: '+6282126722212',
    email: 'stephaniefani0803@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation",
    experiences: 'Data Scientist - Gojek - 15 bulan',
    industry: 'Teknologi/Startup',
    skills: 'Data Science',
    schedule: 'senin 19.00 - 21.00,Rabu 19.00 - 21.00,Kamis 19.00-20.00',
    price: 100000,
    linkedin: 'https://linkedin.com/in/stephanie-stephanie',
    photoUrl: '/static/images/mentors1/stephanie.jpg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: 'd39360f5-20a5-41d2-b522-fdb669aa47d6',
    name: 'Vera Wijaya',
    title: 'Online Brand Management Trainee at L’Oréal',
    phoneNumber: '+6281285895585',
    email: 'verawijaya77@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Online Brand Management Trainee at L’Oréal - 3 months,Brand Marketing Intern at Loreal HFI - 6 months,Treasure Funding Officer (TFO) at DBS Bank - 1 year',
    industry: 'FMCG',
    skills: 'Marketing & PR,Business Development',
    schedule: 'Senin - Jumat : 18.00 - 22.00,Sabtu - Minggu : 11:00 - 14.00',
    price: 150000,
    linkedin: 'https://linkedin.com/in/verawijaya',
    photoUrl: '/static/images/mentors1/vera.jpg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: 'e745ba65-23f2-4544-8012-b4b0bd41e4dc',
    name: 'Wenvint',
    title: 'Associate at E-commerce',
    phoneNumber: '+6285265783338',
    email: 'wenvint@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up",
    experiences:
      'Associate at E-commerce - Present,Marketing Intern at Unilever - 6 months,Enterprise Growth Junior Associate at Kalibrr - 3 Months,Merchant Development Intern at Tokopedia - 3 Months,Young Leaders for Indonesia Wave 11,Global Winner of Unilever Future Leaders’ League,Global Runner Up of Maybank Go Ahead Challenge',
    industry: 'Teknologi/Startup,FMCG',
    skills: 'Marketing & PR,Business Development',
    schedule: 'Senin - Jumat : 19.00 - 21.00 WIB,Sabtu 16.00 - 21.00 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/wenvint/',
    photoUrl: '/static/images/mentors1/wenvint.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: 'a1086efa-7ede-40f3-a812-e784ffae2af2',
    name: 'Samuel Davidsen Widjaja',
    title: 'Co-founder and CEO at Aksel',
    phoneNumber: '+6287770303540',
    email: 'samuel@aksel.co.id',
    serviceLine: 'College Life Sharing,CV Preparation & Feedback',
    experiences:
      'Co-founder and CEO of Aksel - 1 tahun,Project Lead Decacorn Startup - 3 bulan,Young Leaders for Indonesia - 6 bulan,Startup Accelerator Project Team - 6 bulan',
    industry: 'Teknologi/Startup',
    skills: 'Product,Marketing & PR',
    schedule: 'Senin - Jumat : 13.00-17.00 WIB',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/samuel-davidsen/',
    photoUrl: '/static/images/mentors1/samuel.jpg',
    videoUrl: '',
    super: true,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '423b9253-aa54-4913-8f58-d949e4f39239',
    name: 'Nyoman Meta Rosanti',
    title: 'Talent manager at Glints Singapore',
    phoneNumber: '+6285900464420',
    email: 'rosantimeta@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Talent Manager at Glints Singapore - Present,HRBP Intern at Shopee Indonesia - 3 Months,Business Development Manager at AIESEC Asia Pacific - 3 Months',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource',
    schedule: 'Senin - Jumat 19.00 - 22.00 WIB',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/nyomanmetarosanti/',
    photoUrl: '/static/images/mentors1/nyoman.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '423b9253-aa54-4913-8f58-d949e4f39240',
    name: 'Bayudi Ramadani',
    title: 'Organization Development Specialist at kitabisa.com',
    phoneNumber: '+6285376153889',
    email: 'bayudiramadani@gmail.com',
    serviceLine: 'CV Preparation & Feedback,Interview Preparation & Mock-up',
    experiences:
      'Organization Development Specialist at kitabisa.com - current,Recruitment Specialist at Kitabisa.com - 2 years 2 months,Senior Consultant at Glints Indonesia 8 months',
    industry: 'Teknologi/Startup,NGO',
    skills: 'Human Resource,Recruitment',
    schedule: 'Selasa - Kamis : 19.00 - 20.00 WIB',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/bayudi-ramadani/',
    photoUrl: '/static/images/mentors1/bayudi.jpg',
    videoUrl:
      'https://drive.google.com/file/d/1lcWCBvNFHf5_dfuKdOqSRiITbMMwjXlp/view?usp=sharing',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '0a5109a3-7391-4a56-b264-c31e34c2f234',
    name: 'Primawan Satrio Bindono',
    title: 'Career Advisor Specialist - Inbound Technology Japan',
    phoneNumber: '+821072140115',
    email: 'primawan.satrio@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Opportunity and Network Sourcing",
    experiences:
      'Career Advisor Lead on Inbound Technology Japan - Present,Product Manager at Bukalapak - 2 years,Associate Product Manager on multinational online media company Indonesia - 2 years',
    industry: 'Teknologi/Startup',
    skills: 'Product,Human Resource,Consulting/Advisory Services',
    schedule:
      'Senin - Jumat : 18:00 - 22:00 WIB,Sabtu : 09:00 - 11:00 WIB,Minggu :10:00 - 18:00 WIB',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/primawan/',
    photoUrl: '/static/images/mentors1/prima.jpeg',
    videoUrl:
      'https://drive.google.com/file/d/1i151XNeHAj_lgOeml3WjYkiTEr5Cgz93/view?usp=sharing',
    super: true,
    addedAt: '1624208400',
    description: '',
  },
  // {
  //   id: 'ed91d0db-fcaa-41e2-b4ca-f9b0510d65c5',
  //   name: 'Dyah Alya Gretiana',
  //   title: 'Recruitment Consultant at Michael Page',
  //   phoneNumber: '+6282113459854',
  //   email: 'dyahalya14@gmail.com',
  //   serviceLine:
  //     "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
  //   experiences:
  //     'Recruitment Consultant - Michael Page - 1 year,Recruiter - Gojek - 2 years,\t\tVice President Talent Management of AIESEC UI - 1 year',
  //   industry: 'Teknologi/Startup,Consulting/Advisory Services',
  //   skills: 'Tech Engineering,Human Resource',
  //   schedule: 'Rabu-Kamis,20.00-22.00,Sabtu-Minggu 11.00-14.00',
  //   price: 100000,
  //   linkedin: 'https://www.linkedin.com/in/dyahalya/',
  //   photoUrl: '/static/images/mentors1/dyah.jpg',
  //   videoUrl: '',
  //   super: false,
  //   addedAt: '1624208400',
  //   description: '',
  // },
  {
    id: '80a30850-c223-41f4-9e6d-2f7112bc33d5',
    name: 'Agil Haykal',
    title: 'Data Lead at Moladin',
    // title: 'Senior Data Analyst at Unicorn E-Commerce',
    phoneNumber: '+6289632364590',
    email: 'agil.alatas@gmail.com',
    serviceLine:
      "Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Senior Data Analyst - One of Unicorn E-Commerce - 6 months,Data Scientist - Qoala - 1year 9 months,Data Scientist - Datalabs.id - 11 months,Tutor in Data Science - DigitalSkola - 7 months,Co-Trainer in data technology - IYKRA - 3 months',
    industry: 'Teknologi/Startup',
    skills: 'Data Science',
    schedule: 'Jumat 18.00 - 21.00,Sabtu 18.00 - 21.00,Minggu 18.00 - 21.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/haykalatas?_l=en_US',
    photoUrl: '/static/images/mentors1/agil.jpg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '0ae6f3ca-5a86-486a-862f-734284ef742a',
    name: 'Elsa Kartika Wikarsa',
    title: 'PG Central Marketing Strategist at Tokopedia',
    phoneNumber: '+628176386388',
    email: 'elsawikarsa@gmail.com',
    serviceLine:
      'College Life Sharing,Professional Life Sharing,Internship Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing',
    experiences:
      'PG Central Marketing Strategist - Tokopedia - October to present,ULIP Marketing - Unilever Indonesia - 6 months,Marketing Intern - PT Telekomunikasi Internasional Indonesia (TELIN) at Taiwan - 3 months',
    industry: 'Teknologi/Startup,FMCG',
    skills: 'Marketing & PR',
    schedule: 'Kamis 20.00-22.00,Selasa 20.00-22.00',
    price: 100000,
    linkedin: 'https://linkedin.com/in/elsawikarsa',
    photoUrl: '/static/images/mentors1/elsa.jpg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '8fff8a92-a586-4410-9cbb-e44a9e628860',
    name: 'Azalia Zatadini',
    title: 'Solution Lead for Leadership Community',
    phoneNumber: '+6285782069893',
    email: 'azaliaztd@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Interview Preparation & Mock-up",
    experiences:
      'Solution Lead for Leadership Community at HR Consulting - Present,Country Director of AIESEC in Indonesia 18/19 - 1 year,National Head of Organizational Development of AIESEC in Indonesia 17/18 - 1 year',
    industry: 'Consulting/Advisory Services,NGO',
    skills: 'Human Resource,Consulting/Advisory Services',
    schedule:
      'Selasa 20.00-22.00,Kamis 20.00-22.00,Jumat 20.00-22.00,Minggu 13.00-18.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/azaliazatadini/',
    photoUrl: '/static/images/mentors1/azalia.jpg',
    videoUrl: '',
    super: false,
    addedAt: '1624208400',
    description: '',
  },
  {
    id: '822af55f-06da-4227-82dc-caac09d73149',
    name: 'Richard Harris Wahyudi',
    title: 'E-Business Asst. Manager at Danone',
    phoneNumber: '+6281944941861',
    email: 'richardharrisw@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'E-Business Asst. Manager - Danone - Present,Business & Product Development - Tokopedia - 1.5 Year',
    industry: 'Teknologi/Startup,FMCG',
    skills: 'Marketing & PR,Business Development',
    schedule: 'Senin - Jumat: 17.00 - 21.00,Minggu: 18.00 - 21.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/richardhw/',
    photoUrl: '/static/images/mentors1/richard.jpeg',
    super: false,
    addedAt: '1625245200',
    description: '',
  },
  {
    id: '5f6daf52-b24e-40c0-b1a7-e5625b518e39',
    name: 'Alysha Paxia Susilo',
    title: 'Lead Product Manager at Amartha',
    phoneNumber: '+6287780566119',
    email: 'paxia.alysha@google.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Lead Product Manager - Amartha - 1 tahun,CRM Specialist & Product Manager - Kitabisa - 1.5 tahun,Lifecycle Marketing - CROWDE - 1 tahun',
    industry: 'Teknologi/Startup,Financial Services',
    skills: 'Product,Marketing & PR,Business Development,Human Resource',
    schedule:
      'Rabu : 19.00 - 21.00,Jumat : 19.00 - 21.00,Sabtu : 10.00 - 14.00,Minggu : 11:00 - 15:00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/alyshapaxiasusilo/',
    photoUrl: '/static/images/mentors1/paxia.jpeg',
    super: false,
    addedAt: '1625245200',
    description: '',
  },
  {
    id: '5d184423-fbaa-4c4d-84e1-072c310c4d1a',
    name: 'Fabian Eliusyana',
    title: 'Asst. Response & Execution Planning Manager at Unilever',
    phoneNumber: '+6285710187722',
    email: 'fabian.eliusyana@gmai.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Asst. Response & Execution Planning Manager - Unilever - Start Aug 2021,Asst. SU & MSO Planning Manager - Unilever - November 2020 - July 2021,ULIP - Procurement Marketing Business Services - Unilever - January 2020 - June 2020',
    industry: 'FMCG',
    skills:
      'Product,Marketing & PR,Human Resource,Consulting/Advisory Services,Business Development',
    schedule: 'Sabtu 09.00-23.00,Minggu 09.00-23.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/fabian-eliusyana-97621512a/',
    photoUrl: '/static/images/mentors1/fabian.jpeg',
    super: true,
    addedAt: '1625245200',
    description: '',
  },
  {
    id: 'af1735bd-110b-479b-a7e2-433f34cca2b6',
    name: 'Stefanus Abdipranoto',
    title: 'Digital Transformation - Philip Morris (Sampoerna)',
    phoneNumber: '+6285939139436',
    email: 'stefanus.apb@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Digital Transformation at Philip Morris (Sampoerna) - current, Tokopedia Travel & Entertainment Senior Lead at Tokopedia - 1 tahun 2 bulan,Tokopedia Business Development Lead of Digital at Tokopedia - 1 tahun',
    industry: 'Teknologi/Startup',
    skills: 'Product,Business Development',
    schedule: 'Kamis 18.00 - 20.00,Rabu 18.00 - 20.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/stefanus-abdipranoto-7398159b/',
    photoUrl: '/static/images/mentors1/stefanus.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1625590800',
    description: '',
  },
  {
    id: 'a5d2d16d-0613-4beb-8a5e-58852b3382bf',
    name: 'Benediktus Rendy',
    title: 'Partnership Manager at Unicorn Startup',
    phoneNumber: '+6282232070697',
    email: 'rendydiopasca97@gmail.com',
    serviceLine:
      'College Life Sharing,Professional Life Sharing,Knowledge and Skill Preparation',
    experiences:
      "Partnership Manager - Indonesia's Leading Travel & Lifestyle Startup - 2 months (current),Product & Partnership Coordinator - Indonesia's Leading Travel & Lifestyle Startup - 18 months,SMB Product Team - Gojek - 5 months",
    industry: 'Teknologi/Startup',
    skills: 'Product,Business Development',
    schedule:
      'Sabtu 09.00-11.00,Minggu 09.00-10.00,Minggu 19.00-20.00,Senin 19.00-20.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/rendy-diopasca/',
    photoUrl: '/static/images/mentors1/benediktus.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1625590800',
    description: '',
  },
  {
    id: 'bbade32d-b9cc-4630-970c-5d9431d55437',
    name: 'Vivianne Kusnowo',
    title: "Digital and Community Manager at L'Oréal",
    phoneNumber: '+6287888557564',
    email: 'viviannekusnowo@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      "E-commerce Executive - L'Oréal  - 6 months,Digital Marketing and Business Development Intern  - L'Oréal - 6 months,Business Analyst Intern - Tokopedia - 3 months",
    industry: 'Teknologi/Startup,FMCG',
    skills: 'Marketing & PR,Business Development',
    schedule: 'Senin & Jumat 20.00-22.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/viviannekusnowo',
    photoUrl: '/static/images/mentors1/vivianne.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1625590800',
    description: '',
  },
  {
    id: 'c49a0d9a-52aa-40f1-b830-9bae84aa6381',
    name: 'Savira Qurrata Aini',
    title: 'Product Marketing Manager at Glints',
    phoneNumber: '+6287709194531',
    email: 'saviraqaini@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up",
    experiences:
      'Product Marketing Manager at Glints - Maret 2020-current,Project Manager at Asian Development Bank - 2 years,People Development Intern at Tokopedia - 4 months',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule: 'Rabu,Kamis,Jumat 7-9 PM',
    price: 60000,
    linkedin: 'https://id.linkedin.com/in/savira-qurrata-aini',
    photoUrl: '/static/images/mentors1/savira.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1625590800',
    description: '',
  },
  {
    id: '3f6279c4-935d-4388-bb2b-77178f5b913f',
    name: 'James Christopher',
    title: 'Product Manager at detikcom',
    phoneNumber: '+6285210461702',
    email: 'jameschristophersan@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Product Manager at detikcom - Mei 2021-current,Associate Product Manager at Startup - 1 tahun,Finance Staff at Startup - 1 tahun',
    industry: 'Teknologi/Startup,Financial Services',
    skills: 'Product,Consulting/Advisory Services',
    schedule:
      'Sabtu: 10.00 - 12.00,14.00 - 18.00,Minggu: 10.00 - 12.00,14.00 - 18.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/jameschristophersan',
    photoUrl: '/static/images/mentors1/james.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1625590800',
    description: '',
  },
  {
    id: 'ae146a1a-dd4e-4e0c-a2ab-1b94b49b6b53',
    name: 'Herren Calida',
    title: 'Director Office - E-commerce',
    phoneNumber: '+6287778888795',
    email: 'herren.calida@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Director Office - E-commerce - present,Investment - Central Capital Ventura (CCV) - 2 Tahun,Project Specialist - Boston Consulting Group (BCG) - 1 Tahun',
    industry: 'Financial Services',
    skills: 'Consulting/Advisory Services',
    schedule:
      'Kamis: 20.00 - 21.00,Sabtu: 13.00 - 15.00 / 18.00 - 19.00,Minggu: 13.00 - 15.00 / 18.00 - 19.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/herrencalida/',
    photoUrl: '/static/images/mentors1/herren.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1625590800',
    description: '',
  },
  {
    id: '60c8a313-d2bc-4ef2-a587-f40e55ba2258',
    name: 'Ikra Halki',
    title: 'Commercial Strategy Development Asst. Manager at Danone',
    phoneNumber: '+6281514981234',
    email: 'ikra.halki@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Commercial Strategy Development Asst. Manager at Danone - Present,Associate Consultant at Management Consulting - 1 tahun',
    industry: 'FMCG,Consulting/Advisory Services',
    skills: 'Consulting/Advisory Services,Business Development',
    schedule: 'Senin 19.00-21.00,Rabu 19.00-21.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/ikra-halki/',
    photoUrl: '/static/images/mentors1/ikra.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1626422891',
    description: '',
  },
  {
    id: '319e6323-7343-4162-ba09-8c68e85c0a4f',
    name: 'Claudia Natasya',
    title: 'Senior Insight & Growth,Sales & Marketing at Tokopedia',
    phoneNumber: '+6282129922252',
    email: 'claudianatasya.tam@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Senior Insight & Growth (Sales & Marketing) - Tokopedia - Jan 2021 until Current,Digital Marketing Tutor - Digital Skola - Mar 2021 until current,Campaign Specialist Associate (Sales & Marketing) - Tokopedia - Jan 2019 until Dec 2020,Project Planner Intern - Tokopedia - Oct 2018 until Dec 2018',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR,Business Development',
    schedule: 'Rabu,Kamis,Jumat 19.00-22.00,Sabtu,Minggu 11.00-15.00',
    price: 100000,
    linkedin: 'https://linkedin.com/in/claudianatasyatambunan',
    photoUrl: '/static/images/mentors1/claudia.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1626422891',
    description:
      'Hi! I’m Claudia Natasya. I’m Digital Marketing enthusiasm and data driven person. Currently working in Indonesia technology company specializing in e-commerce as Senior Insight and Growth and also do sharing & mentoring about Digital Marketing in Digital Skola. I have intern and entry level employee experience as Campaign Specialist that made me understand in creating end to end Campaign such as promo and marketing communication. Lastly, for college life, I graduated from School of Business and Management, ITB and have one semester of student exchange experience in Vrije Universiteit Amsterdam.',
  },
  {
    id: '68a11514-21a4-407d-9709-f528eee011c7',
    name: 'Tara Aziza Adelia',
    title: 'Customer Success at Xendit',
    phoneNumber: '+6285155499483',
    email: 'taraaziza16@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up",
    experiences:
      'Customer Success at Xendit - current,Business Development Manager at ZEN Rooms - 8 months,Chief of Partnership at AIESEC in Indonesia - 6 months',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR,Business Development',
    schedule:
      "Senin : 19.00 - 21.00,Rabu,Kamis,Jum'at : 19.30 - 21.30,Sabtu,Minggu : 10.00 - 14.00",
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/taraaziza/',
    photoUrl: '/static/images/mentors1/tara.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1626422891',
    description:
      'Tara has taken several leadership experiences, programs, and volunteer since her college life until today. She highly experience in Business Development, Partnership, and Project Management. She love to shares her personal stories, hearing other perspectives, & always interested to discuss everything about people development.',
  },
  {
    id: 'a991252e-485f-4d45-90d7-3d1294dd5a4a',
    name: 'Pratama Agus Nugroho',
    title: 'Digital & Product Growth',
    phoneNumber: '+628568134849',
    email: 'pratamaagusnugroho@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Digital Growth Marketing Manager - Home Credit Indonesia - 2 tahun,Marketing Automation & Analytics - CROWDE - 1 tahun,Senior Game Operation - Garena Indonesia - 3 tahun',
    industry: 'Teknologi/Startup,Financial Services',
    skills: 'Product,Marketing & PR',
    schedule: 'Senin - Jumat: 19.00 - 21.00 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/pratama-agus-nugroho/',
    photoUrl: '/static/images/mentors1/pratama.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1626422891',
    description:
      'A professional product-marketer who keen to learn something new, and willing to share it to the new people.',
  },
  {
    id: '5cf4f096-325a-4d41-8256-6c10de024efd',
    name: 'Frengki Chua',
    title: 'Start-Up Enthusiast',
    phoneNumber: '+6281932395754',
    email: 'frengkichuaa@gmail.com',
    serviceLine:
      'College Life Sharing,Internship Preparation,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing',
    experiences:
      'Chief of Staff at P2P Lending Company - 3 years,Indonesia Representative for P&G CEO Challenge Asia Pacific 2019,Awardee of Young Leaders for Indonesia Wave 10 by McKinsey & Co 2018',
    industry: 'Teknologi/Startup,Financial Services',
    skills:
      'Consulting/Advisory Services,Business Development,Finance,Human Resource',
    schedule:
      'Senin - Jumat : 20.00 - 22.00,Sabtu :  10.00 - 12.00,14.00 - 15.00,20.00 - 22.00,Minggu : 17.00 - 18.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/frengkichua/',
    photoUrl: '/static/images/mentors1/frengki.jpeg',
    videoUrl: '',
    super: false,
    addedAt: '1626422891',
    description:
      'Frengki is a business, education and technology enthusiast who is a self-driven individual with outstanding leadership experiences and academic background. Graduated from University of Indonesia as 3rd highest GPA in Management Department with several awards such as Indonesia Representative for P&G CEO Challenge Asia Pacific on 2019 and 1st of Brunei Darussalam Indonesia Malaysia Business Case Competition 2018. He is one of the awardees of Young Leaders for Indonesia by McKinsey & Co across South East Asia and Australia through a rigorous selection process. Moreover, he is also part of XL Future Leaders by XL Axiata selected as one of the awardees with a 1.2% acceptance rate. He loves to socialize and enjoy sharing happiness with people proven by his experience as the speaker. His favorite quote is “Be The Diamond for Ourselves and Others”.',
  },
  {
    id: 'a561eb96-9488-4c6e-bab5-6e701cf42991',
    name: 'Gopala Arcana',
    phoneNumber: '+6282248185266',
    email: 'gopala.arcana@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Product Manager at Ride Hailing Group,Associate Gamification at E-Commerce,Associate Product Manager at BukuWarung,Digital Transformation Strategy Intern at Deloitte',
    industry: 'Teknologi/Startup,Consulting/Advisory Services',
    skills: 'Product,Consulting/Advisory Services',
    schedule: 'Senin - Jumat (20.00-22.00),Sabtu - Minggu (17.00-18.00)',
    price: 150000,
    linkedin: 'linkedin.com/in/gopalaarcana',
    photoUrl: '/static/images/mentors1/gopala.jpeg',
    super: true,
    addedAt: '1626685501',
    title: 'Product Manager at Ridehailling Group',
    description:
      'A generalist who love to explore tech development and investment',
  },
  {
    id: 'f2b4f1d4-29f0-43a2-9e98-5d7303a7817b',
    name: 'Raihan Zahirah',
    phoneNumber: '+6281222305771',
    email: 'raihanzahirahmr@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up",
    experiences:
      'Account Strategist at Google - Present,Partnership Manager at TikTok - 12 months,Vocational Employee (VE) Deals Advisory: Capital Project & Infrastructures at PwC - 3 months,Summer Intern at NCB INTERPOL - 2 months',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR,Consulting/Advisory Services,Business Development',
    schedule: 'Senin - Sabtu 19.00 s/d 21.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/raihanzahirahmr/',
    photoUrl: '/static/images/mentors1/raihan.jpeg',
    super: false,
    addedAt: '1626685501',
    title: 'Account Strategist at Google - Present',
    description:
      'Raihan is a 23 years old Partnership Manager at TikTok (world most valuable startup) and international relations graduate with outstanding achievements both in the academic and non-academic fields. She is the first and fastest student in her batch who finished the thesis defense with Magna Cum Laude. Her consistency, persistency, and perseverance made her received Danone Aqua Scholarship since high school until bachelor degree and Parahyangan Catholic University Non-Academic Category Scholarship. She is the only international relations student from private university who managed to reach the the Top 6 of the Most Outstanding Student Indonesia 2019 by the Ministry of Research, Technology, and Higher Education (Kemenristekdikti) Republic of Indonesia which reflected her great analytical thinking, critical thinking, and problem solving skill. Raihan also awarded as the Most Outstanding Student of West Java-Banten 2019, and the Most Outstanding Student of UNPAR 2019.',
  },
  {
    id: '53b9e123-dae4-498b-8127-da06471744ad',
    name: 'Kenny Hutomo',
    phoneNumber: '+6282261600700',
    email: 'hutomokenny@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      "Brand Partnerships Manager at Tiktok Indonesia - November 2021 - current,New Channel Development Executive at Loreal - Agustus 2020 - Oktober 2021,New Channel Development Executive at L'Oreal - Agustus 2020 - Present,Young Entrepreneur Program (YEP) at Loreal - 11 months,Management Trainee Commercial at Hutchison 3 - 6 months",
    industry: 'FMCG',
    skills:
      'Marketing & PR,Human Resource,Consulting/Advisory Services,Business Development',
    schedule: 'Senin - Kamis : 21:00 - 22:00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/kennyhutomo/',
    photoUrl: '/static/images/mentors1/kenny.jpeg',
    super: true,
    addedAt: '1626685501',
    title: 'Brand Partnerships Manager at Tiktok Indonesia',
    description:
      'A hustler and quick learner. Versatile proficiency with experience in Business Development, Trade Marketing and Corporate Communications. Received multiple leadership development and critical thinking tools programs. Excellent public speaking, negotiation and team management skills with 50+ occasions as personal dan professional development speakers for youths across Indonesia and Asia Pacific.',
  },
  // {
  //   id: 'fe319eb8-466a-4fa6-8403-9699c8945ea4',
  //   name: 'Elsa Karunia Ilahi',
  //   phoneNumber: '+6285802738150',
  //   email: 'elsakaruniailahi@gmail.com',
  //   serviceLine:
  //     "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
  //   experiences:
  //     'Account Manager at ZEN Rooms - Present,Customer Success Manager at PT KitaLulus International - 5 Months,National Project Manager at AIESEC Indonesia - 1 Year 8 Months,Public Relations Interns at Nutrifood Indonesia - 3 Months',
  //   industry: 'Teknologi/Startup,FMCG',
  //   skills: 'Marketing & PR,Human Resource,Consulting/Advisory Services',
  //   schedule: 'Sabtu: 16:00 - 21:00,Minggu: 15:00 - 21:00',
  //   price: 60000,
  //   linkedin: 'linkedin.com/in/elsailahi/',
  //   photoUrl: '/static/images/mentors1/elsak.jpeg',
  //   super: false,
  //   addedAt: '1626685501',
  //   title: 'Account Manager at ZEN Rooms',
  //   description:
  //     'Elsa Karunia Ilahi adalah seorang Communications Specialist yang bekerja sebagai Account Manager di Hospitality Industry Company yang memiliki 3 tahun pengalaman bekerja dibidang FMCG, NGO, dan Start-Up sejak tahun 2018. Memiliki pengalaman memimpin lebih dari 48 team members dan menghandle lebih dari 1200 clients dan partners.',
  // },
  {
    id: 'cad255a4-5eba-407d-b88b-5128596a0587',
    name: 'Bethari Bintang Oktavia',
    phoneNumber: '+628118575662',
    email: 'bethari.oktavia@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Sales & Marketing at Unilever - Present,Quantitative Researcher at Kantar - 10 months,Young Leaders for Indonesia - 6 months',
    industry: 'FMCG',
    skills: 'Marketing & PR,Business Development',
    schedule: 'Senin,Kamis,Jumat: 20.30-22.00,Sabtu,Minggu: 11.00-15.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/betharibintango/',
    photoUrl: '/static/images/mentors1/bethari.jpeg',
    super: true,
    addedAt: '1627207366',
    title: 'UFLP Marketing & Customer Development MT at Unilever',
    description:
      "Hi, Bethari's here!,In Sanskrit, my name means 'angel', a spiritual being believed to deliver the God's messenger. Hence in life, I want to continuously be a lifelong learner and be the best of God's messenger to my surroundings by sharing my experiences and thoughts. Being a mentor here allows me to do that. I am not the smartest of all, I just happen to (perhaps) live longer & experience something earlier than you so I am able to share one thing or two, especially in Sales & Marketing fields that I am in. I love meeting new people & hearing their stories. I promise that the mentoring session would be so much fun! Not only that you're going to learn something, I am going to learn something from you too :)",
  },
  {
    id: 'd968d35c-0d9a-4012-9522-d060ae68f585',
    name: 'Aqmalia Shafira Dilandari',
    phoneNumber: '+6281333185303',
    email: 'shafiraaqmalia@gmail.com',
    serviceLine:
      "College Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Professional Life Sharing",
    experiences:
      'Business Manager at Pemimpin.id - current ,Chief Marketing Officer at Aille Natura Indonesia - current ,Strategic Partnership Merchant Marketing at Gojek - 7 bulan ,MPDD Creating Shared Value Intern at Nestle - 2 bulan',
    industry: 'Teknologi/Startup,FMCG',
    skills: 'Marketing & PR,Business Development',
    schedule:
      'Selasa,Rabu & Jum’at: 19.00 - 21.00,Sabtu & Minggu: 15.00 - 21.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/aqmalia-shafira/',
    photoUrl: '/static/images/mentors1/aqmalia.jpeg',
    super: false,
    addedAt: '1627207366',
    title: 'Business Manager at Pemimpin.id',
    description:
      'Having 3 years experiences of strategic partnership with achievement improve work efficiency using my communication and negotiation skills. I have a career interest as a strategic marketer and proficient in problem-solving, leading teams, good manner in influencing, and building strong relationship. I also build my own personal body care business that have concern in the environment and mental-wellness.',
  },
  {
    id: '3582e653-4b90-4dd5-9e92-ea78ca03051c',
    name: 'Kenny Ivanzaky Augusta',
    phoneNumber: '+628176420521',
    email: 'kivanzaky@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Principal at Mekari - Current,Co - Owner & CCO Kopi Cakap - Current,Y Combinator (W21)',
    industry: 'Teknologi/Startup',
    skills: 'Consulting/Advisory Services,Business Development',
    schedule:
      "Senin 19.00 - 06.00,Selasa 19.00 - 06.00,Rabu 19.00 - 06.00,Kamis 19.00 - 06.00,Jum'at 19.00 - 06.00,Sabtu 06.00 - 23.59,Minggu 06.00 - 23.59",
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/kenny-ivanzaky-augusta',
    photoUrl: '/static/images/mentors1/kennyi.jpeg',
    super: true,
    addedAt: '1627207366',
    title: 'Principal at Mekari - Current',
    description:
      "I'm Kenny, graduated from an unpopular university doesn't mean you couldn't grow and compete with others. We got funding from Y Combinator from Silicon Valley and I'm being an advisor at some startups now to challenge my business skills. Furthermore, I also being an angel investor for some businesses",
  },
  {
    id: 'fc8b42e8-6de5-4f39-9cfd-6fd45c713c3e',
    name: 'Aqila Sheila Zhafira',
    phoneNumber: '+6282177386776',
    email: 'aqilasheilaz17@gmail.com',
    serviceLine:
      'College Life Sharing,Professional Life Sharing,Internship Preparation,Knowledge and Skill Preparation,Opportunity and Network Sourcing',
    experiences:
      "Social Media & Digital Manager at Mama's Choice Indonesia (FMCG Manufacture Company) - Current",
    industry: 'Teknologi/Startup,FMCG',
    skills: 'Marketing & PR',
    schedule: 'Kamis 21:00 - ke atas,Jumat 20:00 - ke atas',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/aqilasheila/',
    photoUrl: '/static/images/mentors1/aqila.jpeg',
    super: false,
    addedAt: '1627207366',
    title: "Social Media & Digital Manager at Mama's Choice Indonesia",
    description:
      "Aqila is a Social Media & Digital Manager at Mama's Choice Indonesia - The leading brand for safe, natural, and halal products for new Mamas and their families in Indonesia. She is now responsible for planning & executing social media strategies and brand campaigns collaboratively with Mama's Choice Social Media Regional team and theAsianparent team. Previously, she spent ~2 years exploring digital marketing and paid marketing. She was a Social Media Executive at CoLearn - an education tech start-up based in Indonesia from the early stage 0 to 3 million users. Aqila evolved both in academic and non-academic sides. In 2018 trusted as Board of Executives AIESEC, in President University, a global youth-led organization across 114 countries, in 2019 experienced as Social Media & Marketing Intern at IQ Education for six months - K12 offline tutoring centers, yet still managed to maintain her studies with a GPA of 3.78 as a BA in Communications. During her spare time, Aqila creates TikTok educational content since October 2020 - so far, she has accomplished 200k+ followers. On top of everything, she believes in the value of a growth mindset, and her favorite motto is \"learning is a treasure that will follow its owner everywhere.",
  },
  {
    id: '787e6354-6343-48e3-8442-b72c18764c0b',
    name: 'Leonardus Murialdo',
    phoneNumber: '+6282138164188',
    email: 'leonardus.andita@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'UX Designer at Tera Fusion Tech - Current,Head of Design and Digital Marketing at Pasar Hamburg - 2 tahun,Head of Design at Iconic Hamburg - 1 tahun',
    industry: 'Teknologi/Startup,Consulting/Advisory Services,NGO',
    skills: 'Marketing & PR,UI/UX',
    schedule:
      'Senin 19.00 - 20.00; Selasa 19.00 - 20.00; Rabu 19.00 - 20.00; Kamis 19.00 - 19.30; Jumat 19.00 - 19.30',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/leonardus-samodro-4775b5124/',
    photoUrl: '/static/images/mentors1/leonardo.jpeg',
    super: false,
    addedAt: '1627207366',
    title: 'UX Designer at Tera Fusion Tech',
    description: '',
  },
  {
    id: '1ef2315a-10d6-4cea-9e02-6dd10a01b080',
    name: 'Rifqi Meidianto',
    phoneNumber: '+6281211533100',
    email: 'rifqimeidi@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up",
    experiences:
      'People Development Associate & Product Lead at Stevland Bridge - Present,Marketing Intern & Project Leader at Unilever Indonesia - 6 Months,President at AIESEC in Universitas Jenderal Soedirman - 1 Year',
    industry: 'FMCG,Consulting/Advisory Services,NGO',
    skills: 'Human Resource',
    schedule: 'Selasa 19:00 - 21:00,Rabu 19:00 - 21:00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/rifqimeidi/',
    photoUrl: '/static/images/mentors1/rifqi.jpeg',
    super: false,
    addedAt: '1628166420',
    title: 'People Development Associate & Product Lead at Stevland Bridge',
    description:
      'Rifqi is a people-oriented person, who really have a deep interest in developing human resource, already had experiences in this field for 3 years. As a plus, currently, an MBA Student in Institut Teknologi Bandung with various background experiences in Marketing & Product.',
  },
  {
    id: '609eca1f-d210-452a-a78e-526929073bab',
    name: 'Dinar Syarita Bakti',
    phoneNumber: '+6281287552271',
    email: 'dinar@virus.co.id',
    serviceLine:
      "Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Vice President of People & Culture at Virus Media - Present,Senior Talent Acquisition Specialist at Lamudi Indonesia - 6 months,Recruitment Specialist & Employer Branding at kumparan.com - 1.5 years',
    industry: 'Teknologi/Startup,Consulting/Advisory Services,NGO',
    skills: 'Human Resource',
    schedule: 'Selasa: 19.00 - 21.00,Rabu: 19.00 - 21.00,Kamis: 19.00 - 21.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/dinar-syarita-bakti-82480864/',
    photoUrl: '/static/images/mentors1/dinar.jpeg',
    super: true,
    addedAt: '1628166420',
    title: 'Vice President of People & Culture at Virus Media',
    description:
      "People have been telling me their personal expectation, daily struggles, and bucket list. I am quite good at encouraging people to keep moving on because whatever happens the show must go on. Keep our head in the clouds and our feet on the ground. That's how I accommodate people finding their best interest. As in 'people' means the company and the employees.",
  },
  {
    id: 'c3e0f4f7-a5ec-4e0d-9df0-7fa31cccf334',
    name: 'Danzel Aryo Soerjohadi',
    phoneNumber: '+6287877234038',
    email: 'danzelaryo@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Opportunity and Network Sourcing",
    experiences:
      'Investments at MDI Ventures (Venture Capital) - Present,PwC Capital Project and Infrastructure - VE (Advisory) - 2020,CFA Institute Research Challenge 2020 Country Winner & Indonesia Representative,Junior Analyst YCP Solidiance (2019),Grand Champion ICMSS 2019,Runner Up & MVP IIBC 2018',
    industry:
      'Teknologi/Startup,Financial Services Company,Consulting/Advisory Services',
    skills: 'Consulting & Advisory,Business Development,Finance',
    schedule: 'Senin - Jumat : diatas jam 21:00,Sabtu & Minggu : 11:00 - 21:00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/danzel-aryo-soerjohadi-20b2a6137/',
    photoUrl: '/static/images/mentors1/danzel.jpeg',
    super: false,
    addedAt: '1628166420',
    title: 'Investments at MDI Ventures',
    description:
      "Hi! I'm Aryo, I currently work as an Investment Analyst in MDI Ventures, the biggest CVC in Indonesia backed by Telkom with more than 820mn USD AUM. I am a double degree graduate from University of Indonesia and University of Melbourne, where I took double major in Accounting and Finance. Since I hate school, I used most of my time joining various competitions such as CFA IRC, ICMSS, IIBC, and Melbourne Business Case Competition. I'm not that smart in finance, but I can win these competitions, because I think I might know secrets no one knows, thats why i'm here. So you can win.",
  },
  {
    id: '2f8c53e3-a0d9-4e39-8bf8-3ee24c14786e',
    name: 'Dina Wahyuningsari',
    phoneNumber: '+6282141560349',
    email: 'dinajc90@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Public Speaking Trainer - 2019 s/d Saat Ini,Micro Banking Manager at PT Bank Mandiri (Persero) Tbk - 3.3 tahun,Customer Service Representative at PT Bank Mandiri (Persero) Tbk - 3.6 tahun',
    industry:
      'Teknologi/Startup,Financial Services Company,Consulting/Advisory Services',
    skills: 'Marketing & PR,Consulting & Advisory',
    schedule:
      "Rabu: 19.00-21.00,Kamis: 19.00-21.00,Jum'at 19.00-21.00,Sabtu: 13.00-19.00,Minggu: 14.00-18.00",
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/dina-wahyuningsari-82813b193',
    photoUrl: '/static/images/mentors1/dinaw.jpeg',
    super: false,
    addedAt: '1628166420',
    title: 'Public Speaking Professional Trainer & Digital Content Creator',
    description:
      "I'm a passionate and cheerful person as i know (and how most people see me). I was working while in college as a Part Time Radio Broadcaster since the 3rd semester and proudly graduate on time as Bachelor of Communication Science Degree. I came from nothing, but my education, skill and excitement of life, brought me to start my career in one of Indonesian BUMN's Company as Customer Service then have been promoted as a Manager in a quite short time. Nothing is too impossible when you believe and START DOING WHAT YOU HAVE TO DO. See you soon ;)",
  },
  {
    id: '3485528b-3ab0-445d-94f2-f287638ce654',
    name: 'Emeralda Puspa Kalyana',
    phoneNumber: '+6282110618167',
    email: 'emeralda.kalyana@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Recruitment Partner - Evermos - Current,Corporate HR Experience Associate - JAPFA - 2 tahun,Assistant Supply Chain Finance Manager - Unilever Indonesia - 1 tahun 5 bulan,Mentor - OpportuCamp Batch 1 & 2 - 6 bulan',
    industry: 'FMCG',
    skills: 'Human Resource,Finance',
    schedule: 'Selasa-Rabu 9.00-12.00;Kamis 14.00-17.00;Sabtu 14.00-16.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/emeralda-kalyana',
    photoUrl: '/static/images/mentors1/emeralda.jpeg',
    super: true,
    addedAt: '1628166420',
    title: 'Recruitment Partner at Evermos',
    description:
      'After a 1.5-year career in finance, I decided to move to human resource. Until now I’ve been working in that field for 1.5 years too. Having realized that I have a fulfilment in developing people, I also have an experience as a Mentor to help people improved their personal branding, self-development and pre-work preparation.',
  },
  {
    id: '01b43f88-9e8f-40be-bf98-b2e15504bfe0',
    name: 'Pande Putu Tara Anggita',
    phoneNumber: '+6285829043332',
    email: 'taraanggita2@gmail.com',
    serviceLine:
      'College Life Sharing,Professional Life Sharing,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing',
    experiences:
      'Assistant Manager Digital Marketing at BUMN - current,Management Development Program - PT. Pegadaian (persero) - 6 bulan,Regional Human Capital Specialist - PT. BFI Finance Indonesia (tbk) - 3 tahun',
    industry: 'Financial Services Company',
    skills: 'Marketing & PR,Human Resource',
    schedule:
      'Senin,18.00 WIB - 20.00 WIB,Kamis,18.00 WIB - 20.00 WIB,Sabtu,15.00 WIB - 16.30 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/mwlite/in/tara-anggita-807703127',
    photoUrl: '/static/images/mentors1/pande.jpeg',
    super: false,
    addedAt: '1628166420',
    title: 'Assistant Manager Digital Marketing at BUMN',
    description:
      'Making several contents on social media related to career, recruitment, and work life. Helping you to build up your confidence to prepare your work life.',
  },
  {
    id: '55d3dea0-a8ec-439b-bacb-850940ac7019',
    name: 'Joe William Wartono',
    phoneNumber: '+6287782318410',
    email: 'joewilliamwartono@ruangguru.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Senior COO Analyst at Ruangguru - Present,Business Associate (Customer Success) at Skill Academy - 6 bulan,Research and Development Specialist at Emancipate Indonesia - 1 tahun',
    industry: 'Teknologi/Startup,NGO',
    skills: 'Business Development',
    schedule:
      'Selasa,Rabu,Kamis: 19.00-21.00 PM WIB,Minggu: 10.00 AM - 12.00 PM WIB',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/joewilliam/',
    photoUrl: '/static/images/mentors1/joe.jpeg',
    super: true,
    addedAt: '1628166420',
    title: 'Senior COO Analyst at Ruangguru',
    description:
      'Currently, Joe is responsible to reports directly to Iman Usman, Co-Founder, Chief of Product and Partnership of Ruangguru. He also manages Customer Success at Skill Academy, an after-sales service with over 175.000 members with two curriculum (career and entrepreneur path). Giving impact in developing their skills and acquiring favorable job employment. Outside of his occupation scope, he also actively investing both in market stocks as well as SME (small and medium-sized enterprises).',
  },
  {
    id: 'ef7ddc12-2f19-45fc-bcd6-c1bff1c7ad51',
    name: 'Henri Widiyanto',
    phoneNumber: '+6281991246466',
    email: 'widiyanto.henri@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Corporate and Investment Banking Analyst at JPMorgan Chase & Co. - 2 years,Advisory Intern at KPMG Malaysia - 3 Months,INKOMPASS Intern at Philip Morris International - 3 Months',
    industry: 'FMCG,Financial Services Company,Consulting/Advisory Services',
    skills: 'Consulting & Advisory,Business Development,Finance',
    schedule: 'Senin - Minggu : diatas jam 18:00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/henriwidiyanto/',
    photoUrl: '/static/images/mentors1/henri.jpeg',
    super: false,
    addedAt: '1628166420',
    title: 'Management Consultant at McKinsey & Co.',
    description:
      'Henri is a Corporate Banking Analyst at J.P. Morgan, with 2 years of experience. He completed 5 paid internships (1+ year exp) throughout his undergraduate study, in various industries: FMCG, telco, auditing, banking, and advisory. In addition to his working experience, Henri is also part of the Young Leaders for Indonesia (YLI - supported by McKinsey & Co.) and XL Future Leaders programs, a KPMG ASEAN Scholar, and the Co-Lead for JPM Indonesia philanthropy/CSR initiatives. Henri graduated cum laude, with a degree in Accounting/Finance from Universitas Gadjah Mada. He also completed an Erasmus+ Student Exchange (fully funded) program at Anadolu University, Turkey (2018-2019).',
  },
  {
    id: '6a19d6af-809b-4ba4-9aff-0991513420e2',
    name: 'Iestri Kusumah Wardhani',
    phoneNumber: '+6282310126535',
    email: 'iestrikusumah.official@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'HR Generalist - Mining Industry - Current,Tester and Scorer Psychology Test - 1 Tahun',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource',
    schedule: 'Senin & Selasa : 19.00-21.00',
    price: 150000,
    linkedin: '',
    photoUrl: '/static/images/mentors1/iestri.jpeg',
    super: false,
    addedAt: '1628166420',
    title: 'HR Generalist at Mining Industry',
    description:
      'Seorang lulusan Fakultas Psikologi dengan pengalaman bekerja sebagai Psychology Test Scorer and Tester, dan sekarang bekerja sebagai HR Generalist di Industri Pertambangan. Iestri juga memiliki platform di berbagai media sosial untuk berbagi ilmu seputar Karir dan Psikologi, serta memiliki banyak pengalaman sebagai Speaker di berbagai Webinar/ Talkshow Universitas dan Lembaga ternama di Indonesia.',
  },
  {
    id: '930109b6-9c99-4e94-b988-9cb13ec09d1f',
    name: 'Nadira Safitri Mohammad',
    phoneNumber: '+628119600087',
    email: 'nsmohammad288@gmail.com',
    serviceLine:
      "College Life Sharing,Internship Preparation,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Career Discovery",
    experiences:
      'Management Trainee at Ecommerce in South East Asia - Current,Advisor and Ex President of StudentsCatalyst - 2018 - Present,1st Winner Heineken Business Case Competition - 2019,1st Winner Harvard Deloitte Impact Challenge - 2018',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule: 'Sabtu 19:00-21:00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/nadirasafitri',
    photoUrl: '/static/images/mentors1/nadira.jpeg',
    super: true,
    addedAt: '1628166420',
    title: 'Management Trainee,E-commerce in South East Asia',
    description:
      'Halo aku Nadira! Saat ini aku bekerja di salah satu E-commerce yang cukup berkembang pesat di Asia Tenggara sebagai Management Trainee. Looking forward to share & discuss with you guys!',
  },
  // {
  //   id: 'dd1d9269-c70d-4361-992d-ba89cd0a0b81',
  //   name: 'Karl Christian',
  //   phoneNumber: '+6281369718692',
  //   email: 'karlchristian11.kc@gmail.com',
  //   serviceLine:
  //     "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
  //   experiences:
  //     'Data Engineer - Tech Company - Current,Business Intelligence - Tech Company - 1.5 tahun,Data Analyst - Tokopedia - 6 bulan',
  //   industry: 'Teknologi/Startup',
  //   skills: 'Tech Engineering,Data Science,Consulting & Advisory',
  //   schedule: 'Sabtu 08.00-10.00,Minggu 08.00-10.00',
  //   price: 100000,
  //   linkedin: 'https://www.linkedin.com/in/karllchris/',
  //   photoUrl: '/static/images/mentors1/karl.jpeg',
  //   super: false,
  //   addedAt: '1628166420',
  //   title: 'Data Engineer at Tech Company',
  //   description:
  //     'Karl Christian is a professional Data Engineer engineer, had 4 years working experiences in multiple data positions across tech companies. Graduating from engineering major in ITB made Karl so passionate about Engineering. Karl has tremendous skills in problem solving, project management, collaborating in team and also technical skills. Skilled in SQL, Python, Tableau, GCP, and also other data tech stacks.',
  // },
  {
    id: '6b7c726d-c815-49b0-a080-3b538a363c78',
    name: 'Geary Jonas Felix Sirait',
    phoneNumber: '+628116130060',
    email: 'jonasfelixsirait@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Career Discovery",
    experiences:
      'Head of Area at Nestle Indonesia - 2020 - Current,President of AIESEC in Semarang - 2020,XL Future Leader Awardee - 2018',
    industry: 'FMCG',
    skills: 'Business Development',
    schedule: 'Senin, Rabu, Kamis : 20.00 - 21.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/gearysirait/',
    photoUrl: '/static/images/mentors1/geary.jpeg',
    super: false,
    addedAt: '1628166420',
    title: 'Head of Area at Nestle Indonesia',
    description:
      'Currently working as a #ForceForGood at Nestlé. Responsible to manage distributors, area & markets. An AIESEC & XL Future Leaders Batch 7 Alumni.',
  },
  {
    id: 'b7627805-5b0e-4b4a-a33a-0f5dab4bb9c6',
    name: 'Charlie Edwin',
    phoneNumber: '+628989260173',
    email: 'cmathgician@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Career Discovery",
    experiences:
      'Engineering Manager at Style Theory - Current,Product Manager at Omnichannel Retail - 1 year 3 months,Mobile Development Team Lead at Gramedia Digital - 3 years 2 months',
    industry: 'Teknologi/Startup',
    skills: 'Tech Engineering,Product,Consulting & Advisory',
    schedule:
      'Rabu: 19.00 - 21.00,Jumat: 19.00 - 21.00,Sabtu: 10.00 - 12.00,Sabtu: 15.00 - 20.00,Minggu: 15:00 - 17.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/charlie-edwin/',
    photoUrl: '/static/images/mentors1/charlie.jpeg',
    super: true,
    addedAt: '1628166421',
    title: 'Engineering Manager at Style Theory',
    description:
      'Seasoned Engineering Manager adept at seamlessly integrating project technical aspects and making information relatable to non-technical personnel. An experienced leader has been involved in building a solid and consistent company culture. Develop Leaders! Create leadership legacy for tomorrow.',
  },
  {
    id: 'f252f641-1125-4a69-b603-a19430c89c60',
    name: 'Lavina Sabila',
    phoneNumber: '+628116817179',
    email: 'lavina_sabila@yahoo.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Career Discovery",
    experiences:
      'Immigration Consultant at Deloitte - Present,News Anchor at TVRI - 1 year 11 months,Announcer and Producer at Oz Radio - 2 years 2 months',
    industry: 'Consulting/Advisory Services',
    skills: 'Human Resource,Consulting & Advisory',
    schedule: 'Senin,Selasa,Rabu,Jumat : 20.00-22.00,Sabtu,Minggu :10.00-12.00',
    price: 100000,
    linkedin: 'https://id.linkedin.com/in/lavina-sabila',
    photoUrl: '/static/images/mentors1/lavina.jpeg',
    super: false,
    addedAt: '1628956035',
    title: 'Immigration Consultant at Deloitte',
    description:
      "Lavina (23) is working as an associate in Immigration division at Deloitte Indonesia. She provides consultancy and advisory related to immigration in order to enhance multi national companies' employee mobility. Her works includes conducting continuous research regarding Immigration Policy and Regulations, prepares Work Permit and Stay Permit for expatriates, and handles other immigration matters for foreign nationals and Indonesian. Beside her full-time job, Lavina is passionate in sharing positivity through her Tiktok account @lovlavina. She has over 100k followers and some of her videos have been being watched for more than 1 million times. She creates contents focusing on Self Development, Education and Motivation. She was a former News Anchor at TVRI and Radio Announcer at Oz Radio.",
  },
  {
    id: '0c904699-b99c-4aa9-9c85-d1c76a1ac3c0',
    name: 'Elmyra Noor Khalida',
    phoneNumber: '+628119981458',
    email: 'khalida.elmyra96@gmail.com',
    serviceLine:
      'College Life Sharing,Professional Life Sharing,Internship Preparation,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up',
    experiences:
      'Case Team Assistant at Bain & Company - Present,Project Support at Boston Consulting Group - 6 months,Business Analyst at Boston Consulting Group - 1 year',
    industry: 'Consulting/Advisory Services',
    skills: 'Consulting & Advisory',
    schedule: 'Kamis & Jumat : 19.00-20.00,Sabtu & Minggu : 11.00-12.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/elmyra-noor-khalida-bb397aa5/',
    photoUrl: '/static/images/mentors1/elmyra.jpeg',
    super: true,
    addedAt: '1628956035',
    title: 'Case Team Assistant at Bain & Company',
    description:
      "A young professional currently pursuing career in management consulting. Discussing about personal development always energizes me, so let's chat :)",
  },
  {
    id: '66e2ce52-7f41-4084-b245-fadf537ee358',
    name: 'Dwike Aprilia Cesarini',
    phoneNumber: '+6281932395881',
    email: 'dwikeapriliacesarini@gmail.com',
    serviceLine:
      'College Life Sharing,Internship Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing',
    experiences:
      'Management Associate at Ruangguru - Present,Co Founder of @heartproject.id - Present,Local Committee Vice President of AIESEC in UNS - 2018/2019',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource,Business Development',
    schedule: 'Jumat: 19.00-20.00,Sabtu & Minggu: 18.30-20.30',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/dwike-aprilia-c-509b7283',
    photoUrl: '/static/images/mentors1/dwike.jpeg',
    super: true,
    addedAt: '1628956035',
    title: 'Ex-Management Associate',
    description:
      'I want to help people find their own "inner peace" through self development" has been one of my drive in creating impacts through my job or other leadership development platform I got to be involved in. I\'ve invested 3.5 years of my time in college in people development program. In the past 1,5 years in my first career, I\'ve enhanced my project management and people management skills where I involved to be a leader of hyper impact projects in leading EdTech company in Southeast Asia.',
  },
  {
    id: '270a0593-4da7-48bf-a7d8-220ec96830b7',
    name: 'Rafi Maulana Hikmat',
    phoneNumber: '+6281340311473',
    email: 'rafimh.biz@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Career Discovery",
    experiences:
      'Product Analyst at Glints - (2020 - Present),Revenue Management Analyst at ZEN Rooms - (2020 - 2020),Data Analyst at Mobile Premiere League (Mobile Gaming) - (2019 - 2020),Growth Marketing Intern at Fave - (3 months)',
    industry: 'Teknologi/Startup',
    skills: 'Product,Data Science',
    schedule:
      'Senin: 19.30 - 21.00,Rabu: 19.30 - 21.00,Kamis: 19.30 - 21.00,Jumat: 19.30 - 21.00,Minggu: 19.00 - 22.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/rafimaulana/',
    photoUrl: '/static/images/mentors1/rafim.jpeg',
    super: false,
    addedAt: '1628956035',
    title: 'Product Analyst at Glints',
    description:
      "Hi! I'm Rafi. My work mainly revolves around product, data, growth and ranging from several industries: e-commerce, mobile gaming, ed-tech, and travel-tech. From all of the roles, I found my forte in solving business problems through data driven analytics.",
  },
  {
    id: '597b7000-4bc2-4db6-8529-418f20f048c9',
    name: 'Gianita Andriana Rinaldi',
    phoneNumber: '+6281212049625',
    email: 'na.andrina@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Campaign Manager at Lazada (Present) ,Senior Associate Marketing at ShopeePay (Shopee) - 1 year 6 months,Market Associate at Expedia - 1 year 2 months',
    industry: 'Teknologi/Startup',
    skills: 'Product,Marketing & PR',
    schedule: 'Senin - Jumat: 10:00 - 16:00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/gianita/',
    photoUrl: '/static/images/mentors1/gianita.jpeg',
    super: false,
    addedAt: '1628956035',
    title: 'Campaign Manager at Lazada',
    description:
      'I am a Certified Digital Marketing Professional with solid analytical and strategic thinking skills. I offer an in-depth understanding of digital marketing and campaign strategy for brands. Throughout my career, I have developed marketing skills and gained campaign management experience at various global companies such as Expedia, Shopee and Lazada. I have a master’s degree in Strategic Marketing from Imperial College London. I hold digital marketing certifications from the American Marketing Association and the Digital Marketing Institute. I am happy to contribute as a mentor and talk through the challenges and opportunities faced by mentees as well as helping them achieve their career goals.',
  },
  {
    id: '73c42c66-ca88-4d20-b161-950ec37acbf4',
    name: 'Muhammad Ridho Illahi',
    phoneNumber: '+6281223093120',
    email: 'ridhoillahi290897@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'KOL Management Executive - Famous Allstars 2019-2021,Social Media Specialist - Matoa Indonesia 2019,Public Relations Intern - Bank Indonesia Provinsi Jawa Barat - 2018',
    industry: 'Consulting/Advisory Services,Teknologi/Startup',
    skills: 'Marketing & PR,Business Development',
    schedule: 'Kamis: 17.00 - 20.00,Sabtu: 11.00 - 16.00',
    price: 100000,
    linkedin: 'https://linkedin.com/in/muhammad-ridho-illahi-0b5364180',
    photoUrl: '/static/images/mentors1/ridhoi.jpeg',
    super: true,
    addedAt: '1628956035',
    title: 'Growth Associate at IDN Media',
    description:
      'People who really believe when we give maximum effort from ourselves, slowly will get maximum results too. Never think that great results are obtained in easy and short challenges. People who eager to learn and put people matters!',
  },
  {
    id: '133e49d1-c5a3-44ab-80b8-65d4682a91ef',
    name: 'Moch Rizqi Hijriah',
    phoneNumber: '+6281212073406',
    email: 'moch.rizqi.hijriah@gmail.com',
    serviceLine:
      'College Life Sharing,Professional Life Sharing,Internship Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing',
    experiences:
      'CRM Associate (Full Time) at Ruangguru Mar 2021 - Present,CRM Marketing (Intern) at Ruangguru Nov 2020 - Mar 2021',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule: 'Selasa 20.00 - 21.30,Kamis 20.00 - 21.30',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/mochrizqihijriah/',
    photoUrl: '/static/images/mentors1/rizqih.jpeg',
    super: true,
    addedAt: '1628956035',
    title: 'CRM Associate at Ruangguru',
    description: '',
  },
  {
    id: '4a75f74d-2398-451e-9f47-2a3fa5e6c052',
    name: 'Nurul Aini Sabila Iriana',
    phoneNumber: '+62895344735181',
    email: 'sabilairn@gmail.com',
    serviceLine:
      'College Life Sharing,Internship Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up',
    experiences:
      'Talent Acquisition at LinkAja - Current,Talent Acquisition Intern at SIRCLO - 5 months,Talent Management Intern at Ministry of EC - 2 months',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource',
    schedule: 'Senin - Kamis 19.00 - 21.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/sabilairiana/',
    photoUrl: '/static/images/mentors1/nurula.jpeg',
    super: false,
    addedAt: '1630506312',
    title: 'Talent Acquisition at LinkAja',
    description:
      'Sabila, Fresh Graduate dari Fakultas Psikologi Universitas Diponegoro yang tertarik dengan People Behavior serta Self Development. Berpengalaman sebagai konselor sebaya, speaker di beberapa acara, dan sekarang sedang berkarir di dunia Human Resources di salah satu Start-Up di Indonesia.',
  },
  {
    id: '677ca5c8-fa41-4eba-94a6-ad33b99c4722',
    name: 'Yogi Arief Putra',
    phoneNumber: '+6285718976597',
    email: 'yogiariefputra@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Career Discovery",
    experiences:
      'HR Lead at Hacktiv8 - Current,HR Business Partner at Gojek,HR OD Analyst at Netika HR Consulting',
    industry: 'Teknologi/Startup,Consulting/Advisory Services',
    skills: 'Marketing & PR,Human Resource,Consulting & Advisory',
    schedule:
      'Senin 20-22.00 Rabu 20-22.00 Jumat 20-22.00. Sabtu 13-15 Minggu 20-22.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/yogiariefputra',
    photoUrl: '/static/images/mentors1/yogi.jpeg',
    super: true,
    addedAt: '1631029613',
    title: 'HR Lead at Hacktiv8',
    description:
      'Merasa sulit dan bingung untuk mendapatkan pekerjaan di big company? Tenang aja, masing-masing punya jodohnya. Mungkin perusahaan yang tepat buatmu saat ini adalah yang skalanya kecil dan medium. Berbekal pengalaman kerja yang gak sesuai jurusan dan memulai kerja dari small company membuat aku banyak eksperimen dan belajar berbagai bidang dan akhirnya menentukan untuk berkarir di HR.  Yuk sharing tentang persiapan dan perjalan di small medium company.',
  },
  {
    id: 'bbbdd80d-fe4d-4281-95de-411f8b8e73ca',
    name: 'Fatma Fitrianuari Fatima',
    phoneNumber: '+6281249036002',
    email: 'urseaminoris.work@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'UX Writer at Telkom Indonesia (1 year),UX Writer at Indomaret Group (1 year),UX Writer intern at Ruangguru (6 months)',
    industry: 'Teknologi/Startup',
    skills: 'UI/UX',
    schedule: 'Rabu & Kamis: 19.00 - 21.00,Sabtu: 14.00 - 17.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/urseaminoris',
    photoUrl: '/static/images/mentors1/angelf.jpeg',
    super: false,
    addedAt: '1631029613',
    title: 'UX Writer at Gojek',
    description:
      "Passion and career finding was never an easy journey. You might find the right job after graduating or you have to work at many companies to find what you actually desire. Hi! I'm Angel, Law Graduate who work at tech company as a UX Writer. Started my journey as a writer in Uni has sharpened my knowledge about college-work life. As a career-shifter, I manage to adapt myself from a Law Gradute to techy writer.",
  },
  {
    id: '52b6698e-7446-4f26-91cb-58325db7af7e',
    name: 'Emeralda Aryuni',
    phoneNumber: '+6281365201007',
    email: 'emeraldameryl@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Technical Recruiter at Lancesoft Indonesia - current,Talent Acquisition Specialist - Lemonilo - 1,5 tahun,Human Resource Specialist - Lubna - 9 bulan,People Development Intern - Tokopedia -  4 bulan',
    industry: 'Teknologi/Startup,FMCG,Financial Services Company',
    skills: 'Human Resource',
    schedule: 'Senin-Jumat: 9.00-12.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/emeralda-aryuni/',
    photoUrl: '/static/images/mentors1/emeraldaa.jpeg',
    super: true,
    addedAt: '1631029613',
    title: 'Recruitment Partner at Evermos',
    // title: 'Technical Recruiter at Lancesoft Indonesia',
    description:
      'A highly energetic and passionate Talent Acquisition Specialist with diverse background in strategic sourcing, staffing, team management and human resources. Also have an experience working in Startup and FMCG full life cycle Technical Recruiting with a solid track record of screening, interviewing, negotiating, sourcing, and closing candidates.',
  },
  {
    id: 'e5613335-0d61-4d99-b28f-04a2e6e89599',
    name: 'Indah Utami Abudiman',
    phoneNumber: '+6281375694362',
    title: 'Assistant Brand Manager at Unilever',
    email: 'indahabudiman@gmail.com',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    industry: 'FMCG',
    skills: 'Marketing & PR, Business Development',
    schedule: 'Jumat: 7-10pm Sabtu: 9-6pm Minggu: 9-6pm',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/indah-abudiman-9955b1120',
    photoUrl: '/static/images/mentors1/indahu.jpeg',
    super: false,
    addedAt: '1631029614',
    experiences:
      'Assistant Brand Manager - Unilever - May 2021 until Present,Former Brand Executive & Management Trainee - Nestle -3 years,Tutor Digital Marketing - Rakamin - 10 months',
    description:
      'A passionate brand enthusiast who started a career from internship and management trainee program. FMCG worker experienced managing big brand and master students at University of Indonesia.',
  },
  {
    id: '6b87080b-9714-4940-9825-01371eac78f4',
    name: 'Singgih Eka Pratama',
    phoneNumber: '+6281223736909',
    email: 'singgihpratama4@gmail.com',
    serviceLine:
      'College Life Sharing,Professional Life Sharing,Internship Preparation,Knowledge and Skill Preparation,CV Preparation & Feedback,Opportunity and Network Sourcing,Career Discovery',
    experiences:
      'Business Anaylst - Tokopedia (Current),Tenaga Ahli Ekonomi Kreatif Kota Bandung - Dinas Budaya dan Pariwisata Kota Bandung (2020 - Current),Culture and Tourism Ambassador - Dinas Budaya dan Pariwisata Kota Bandung (2019-2020)',
    industry: 'Teknologi/Startup',
    skills: 'Product, Business Development',
    schedule:
      'Senin - Jumat (07.00 - 08.00) (20.00 - 22.00), Sabtu (09.00 - 12.00)',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/singgih-pratama-91977a170/',
    photoUrl: '/static/images/mentors1/singgih.jpeg',
    super: false,
    addedAt: '1631029614',
    title: 'Business Anaylst at Tokopedia',
    description: '',
  },
  {
    id: '54755d81-5236-4626-aad4-edbcc509c225',
    name: 'Andrio Septianto',
    phoneNumber: '+85267425917',
    email: 'andrioseptianto@gmail.com',
    description:
      'Currently working at a multi-national unicorn startup in Hong Kong, Andrio is leading a tech team that builds and manages high-traffic critical systems and works closely with product managers to deliver impactful features. Andrio is happy to give some insights about working outside Indonesia, the working culture, and also finding opportunities in working on other countries, particularly Hong Kong',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Software Engineering Team Lead at Lalamove - 2019 - current,Backend Software Engineer at Lalamove - 2016 - 2019',
    industry: 'Teknologi/Startup',
    skills: 'Tech Engineering',
    schedule: 'Jumat 20:30 - 22:00,Sabtu 09.30 - 11:30,Minggu 09.30 - 11:30',
    price: 60000,
    linkedin: '-',
    photoUrl: '/static/images/mentors1/andrios.jpeg',
    super: false,
    addedAt: '1634621733',
    title: 'Software Engineering Team Lead at Lalamove',
  },
  {
    id: '900f7249-6d26-4649-8efe-bc78286efd5f',
    name: 'Gabrielle Shannen Leonard',
    phoneNumber: '+6287881021288',
    email: 'shannen@aksel.co.id',
    description: '',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'CMO & Founder at Aksel - 2019 - current,Future Female Business School - 2021 - current,Pitching Mentor at Startup Weekend Indonesia - 2021 - current',
    industry: 'Teknologi/Startup',
    skills: 'Product, Marketing & PR, Business Development',
    schedule: 'Sabtu - Minggu (All day)',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/gabrielleshannen/',
    photoUrl: '/static/images/mentors1/shannen.jpeg',
    super: true,
    addedAt: '1634621733',
    title: 'CMO & Founder at Aksel',
  },
  {
    id: '44452414-d97a-4992-8932-1e9d0f315397',
    name: 'Rachel Larasati',
    phoneNumber: '+6285695471103',
    email: 'theresia.rachel99@gmail.com',
    description:
      'Rachel is an Associate Product Manager that has been actively involved in 7+ internship experiences during college. The tech industries covered are e-commerce, logistics, financial, education, and property. Rachel got 1st place 4 times in National Business Case & Business Plan Competition. Rachel thought that it is a privilege to have those experiences therefore she always want to give back and inspire others by becoming a mentor.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Associate Product Manager at Tokopedia - present,Associate Product Manager at tiket.com - 7 months,Product Manager Intern at Tokopedia - 4 months ,Product Manager Intern at Pinhome - 9 months',
    industry: 'Teknologi/Startup',
    skills: 'Product',
    schedule:
      'Senin: 20.00-21.30,Rabu: 20:00-21.30,Kamis: 20:00-21.30,Sabtu: 09.00-11.30,Minggu: 19.00-20.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/rachel-larasati-k/',
    photoUrl: '/static/images/mentors1/rachell.jpeg',
    super: false,
    addedAt: '1634905179',
    title: 'Associate Product Manager at tiket.com',
  },
  {
    id: '3c9eca52-7f57-4b6f-96fd-ea5f83d879bf',
    name: 'Daniel Andri Setyawan',
    phoneNumber: '+6281289035536',
    email: 'dnlandri@gmail.com',
    description:
      'A hard working, passionate, and well-motivated person with good teamwork, communication and leadership skills. Interested in Account Management, Business Development and Category Management. Willing to learn and Share to Others',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Key Account Manager at Danone - current,Assistant Brand Manager at Danone - 6 months,MT STAR Sales & Business Development at Danone - 1 year',
    industry: 'FMCG',
    skills: 'Marketing & PR, Business Development',
    schedule: 'Rabu 19.00-21.00,Kamis 19.00-21.00',
    price: 60000,
    linkedin: 'https://linkedin.com/in/daniel-andri-setyawan',
    photoUrl: '/static/images/mentors1/daniela.jpeg',
    super: true,
    addedAt: '1634905179',
    title: 'Key Account Manager at Danone',
  },
  {
    id: '8e4155ff-13b0-4976-96b1-4a7ff9f8aaa8',
    name: 'Yurico Iglesias Bethuel',
    phoneNumber: '+6282121550721',
    email: 'yurico.sinambela@Idntimes.com',
    description:
      'Experienced in growth and product marketing, Own the full-stack marketing portfolio which includes branding to out-of-the-box growth hacking projects, on-ground activations, digital, CRM, Mobile app communication (push notification, in-app activity), partnerships, work with influencers, and AAARRR marketing funnel with A/B test and execute winning marketing strategies to generate leads, consideration, conversion, and retention.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Brand and Content Marketing Manager at IDN Media - current,Sr. Account Manager at IDN Media - 2 years,Sr Account Executive at Kompas Gramedia - 3 years',
    industry: 'Teknologi/Startup',
    skills: 'Product, Marketing & PR, Business Development',
    schedule:
      'Rabu: 18.00 - 21.00,Kamis: 18.00 - 21.00,Jumat: 18.00 - 21.00,Minggu: 14.00 - 17.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/yuricoiglesias/',
    photoUrl: '/static/images/mentors1/yuricoi.jpeg',
    super: true,
    addedAt: '1634905179',
    title: 'Brand and Content Marketing Manager at IDN Media',
  },
  {
    id: '89acba09-0c17-497a-87b5-3ab896992ac6',
    name: 'Yosephine Devina Wijaya',
    phoneNumber: '+6282298881812',
    email: 'devina@tutorkuid.com',
    description:
      'Yosdev is currently leading General Trade (GT) Sales Team in North Sumatera - a team of 3 Area Sales Managers, 18 Field Supervisors, and >150 Field Sellers & Merchandisers. She strategically builds customer collaborative partnership and develops joint business plans with P&G business partners to sustain and grow the business. She also works closely with Multi-Functional Team (Finance, Logistics, IT, and HR) to ensure excellence in product distribution, pricing, shelving, and merchandising. Aside from being a Key Account Manager, she also became the Co-Founder & Strategic Advisor of Tutorkuid.com. Aside from FMCG, Yosdev possess strong problem-solving skill. She has won multiple international business case competitions. She was also the valedictorian of Young Leaders for Indonesia (YLI) by McKinsey & Company Wave 11.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Key Account Manager at P&G - 1.2 years (present),Co-Founder & Strategic Advisor at Tutorku - 1.2 years (present),CEO Tutorku - 3 years',
    industry: 'FMCG',
    skills: 'Marketing & PR, Consulting & Advisory, Business Development',
    schedule: 'Jumat, 20.00-21.00, Sabtu 10.00-12.00',
    price: 200000,
    linkedin: 'https://www.linkedin.com/in/yosephinedevina/',
    photoUrl: '/static/images/mentors1/yosephined.jpeg',
    super: false,
    addedAt: '1634905179',
    title: 'Key Account Manager at Procter & Gamble',
  },
  {
    id: '466c4ea9-024c-402a-9f80-c391efef3e3a',
    name: 'Muhammad Bagaskara Reza Pratama',
    phoneNumber: '+6282363188710',
    email: 'bagaskarareza@gmail.com',
    description:
      'Memiliki minat khusus terhadap people development, business dan marketing, mengembangkan komunitas Pharmatalk UGM, NGO Youth Ranger Indonesia, termasuk sebagai Founder & CEO Phargrow Indonesia. Mengawali karir sebagai Customer Development Executive dan saat ini sebagai Area Marketing Associate Manager.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Founder & CEO at Phargrow Indonesia at current,Area Marketing Associate Manager at Nutrifood - 2 years 3 months (current),The Most Favorite Nutrifood Leadership Award - 1 month,President BEM KM Farmasi UGM - 1 years 2 months',
    industry: 'Teknologi/Startup, FMCG, NGO',
    skills: 'Marketing & PR, Consulting & Advisory, Business Development',
    schedule: 'Rabu 19.00-21.00,Kamis 19.00-21.00,Minggu 19.00 - 21.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/bagaskarareza/',
    photoUrl: '/static/images/mentors1/muhammadb.jpeg',
    super: false,
    addedAt: '1634905179',
    title: 'Area Marketing Associate Manager at Nutrifood',
  },
  {
    id: '356ba085-0961-4cc6-88bb-f322b353c3c6',
    name: 'M. Hilmy Fawwazy',
    phoneNumber: '+6285335225791',
    email: 'mhfawwazy@gmail.com',
    description:
      'I deconstruct complex problems into user-approachable digital design solutions. Currently managing Design System at Tiket.com.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences: 'Product Designer Lead - Tiket.com - current',
    industry: 'Teknologi/Startup',
    skills: 'Product, Consulting & Advisory, UI/UX',
    schedule: 'Weekdays, 19:00-20:00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/mhfawwazy/',
    photoUrl: '/static/images/mentors1/hilmyf.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'Product Designer at Tiket.com',
  },
  {
    id: '9b7ef950-da06-40c3-90be-ebe517cbbb92',
    name: 'Nanda Syukron Roziqin',
    phoneNumber: '+6287838848586',
    email: 'nanda.roziqin.27@gmail.com',
    description:
      'Currently, I am supervisor production in one of the biggest FMCG in Indonesia. I work hard to deliver well quality food for everyone with responsible sourcing. Furthermore, I fully support in development on community with value and leadership. I used to work in office which not related to my educational background but I do enjoy the journey. So, I want you have the same experience and preparation. Hit me if you have any self-doubt to chose your career path between passion and educational background. cheers!',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'NMT TECHNICAL - PT Nestle Indonesia - Current,MT HRBP - Danone Indonesia - 8 months',
    industry: 'FMCG',
    skills: 'Tech Engineering, Product, Human Resource',
    schedule:
      'Wednesday 19.00 - 21.00,Jumat - 19.00 - 21.00,Sabtu - 13.00 - 15.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/nanda-s-roziqin-787868156/',
    photoUrl: '/static/images/mentors1/nandas.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'Management Trainee Technical at Multinational FMCG',
  },
  {
    id: '8d952f56-9d18-4a06-9707-f9f6a4cb39b6',
    name: 'Rosabelle Natiur Sibarani',
    phoneNumber: '+6281298808836',
    email: 'rosabellesibarani@gmail.com',
    description:
      "I have been passionate in the world of communications ever since I was in college. To this date, as I've worked in the field of Public Relations for over seyen years, I am still in awe with how brands and firms around the world creatively crafted its ways to communicate products, activities and cause to the public. I believe that we all should be, in some ways, passionate in the things that we do. And I’m excited to help you discover that! Let’s talk about the things that excites you, and see how we can incorporate that to your career goals!",
    serviceLine:
      'Internship Preparation,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing',
    experiences:
      'SEA Marketing Specialist at Boston Consulting Group - current,Account Manager at FleishmanHillard 2019 - 2020,Sr. Client Executive at Edelman 2018 - 2019',
    industry: 'Consulting/Advisory Services',
    skills: 'Marketing & PR',
    schedule: 'Senin: 19.00 - 21.00,Rabu: 19.00 - 21.00,Minggu: 12.00 - 15.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/rosabellesibarani/',
    photoUrl: '/static/images/mentors1/rosabelles.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'South East Asia Marketing Specialist',
  },
  {
    id: '8cd72c5b-fab6-4bfb-a98f-482aadc8e8d3',
    name: 'Aldridge Christian Seubelan',
    phoneNumber: '+628881853252',
    email: 'aldridge.seubelan@gmail.com',
    description:
      'Data & result oriented, with over 7 years of experience in the digital marketing strategy (owned media, earn media, & paid media) , brand management and communication strategy. Executing integrated marketing communication end-to-end. Having good relation with influencers. Believe with teamwork can produce maximum results',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Digital Brand & Marketing Communication Strategist Manager at Telco Company - 8 years - current',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule: 'Weekdays start from 19.30 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/aldridge-seubelan/',
    photoUrl: '/static/images/mentors1/aldridgec.jpeg',
    super: false,
    addedAt: '1635861489',
    title:
      'Digital Brand & Marketing Communication Strategist Manager at Telco Company',
  },
  {
    id: 'f6646a67-8f43-4951-b1e3-b488efff2a0a',
    name: 'Rahmah Sakinah',
    phoneNumber: '+6281210605565',
    email: 'rahmahsakinah94@gmail.com',
    description:
      'Currently working as Product Marketing at Tech Company. Interested in learning and having discussion related to several topic, including building relationship, communication, marketing, and data analytics.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Product Marketing Manager at Gojek - Current,Product Marketing at GoPay - 2019-2021,Process Improvement Analyst at GoPay - 2017-2019',
    industry: 'Teknologi/Startup, Financial Services Company',
    skills: 'Marketing & PR, Business Development',
    schedule:
      'Senin: 19.00 - 21.30,Selasa: 19.00 - 21.30,Rabu: 19.00 - 21.30,Kamis: 19.00 - 21.30,Jumat: 19.00 - 21.30,Sabtu: 19.00 - 21.30',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/rahmahsakinah/',
    photoUrl: '/static/images/mentors1/rahmahs.jpeg',
    super: true,
    addedAt: '1635861489',
    title: 'Product Marketing Manager at Gojek',
  },
  {
    id: 'a9e17bb7-ba3e-4f51-b4fc-9e47a3187b9f',
    name: 'Kevin Stewart',
    phoneNumber: '+628994585183',
    email: 'vin.stewart93@gmail.com',
    description:
      'A Data Driven enthusiast with 5+ years experience in the analytics field, who was selected as a Chevening Scholar to pursue an MSc Business Analytics at the University of Edinburgh. Experienced in driving growth for tech  companies by leveraging data capabilities and applying various machine learning techniques.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Country Data Analytics - Tech Company,Chevening Scholar - MSc Business Analytics - University of Edinburgh,Senior Data Analytics - Traveloka (2 years and 10 months),Data Analyst - Tokopedia (2 years)',
    industry: 'Teknologi/Startup',
    skills: 'Product, Data Science, Consulting & Advisory',
    schedule: 'Selasa, Rabu dan Kamis (19.00 - 21.00)',
    price: 100000,
    linkedin: 'linkedin.com/in/vinstewart/',
    photoUrl: '/static/images/mentors1/kevins.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'Country Data Analytics at Tech Company',
  },
  {
    id: '58bad469-30c3-4982-a90f-46754e4144ad',
    name: 'Krishna Aziz Malik',
    phoneNumber: '+628561101206',
    email: 'krishna.malik85@gmail.com',
    description:
      'Product and Program management enthusiast, experienced in delivering hi impact product and nurturing high impact teams. Specialties in Crafting complexities into smaller chunks of actionable items and insights',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences: 'Lead Product Manager - Flip (Fintech) - 1 tahun',
    industry: 'Teknologi/Startup, Consulting/Advisory Services',
    skills: 'Tech Engineering, Product, Consulting & Advisory',
    schedule: 'Senin: 17.00 - 18.00,Rabu: 19.30 - 21.00,Jumat : 17.00 - 18.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/krishnamalik/',
    photoUrl: '/static/images/mentors1/krishnaa.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'Lead Product Manager - Flip',
  },
  {
    id: '5d6d6762-2cd8-4aa5-94c1-ec378555244d',
    name: 'Jovita',
    phoneNumber: '+6289654059991',
    email: 'buinijojo@gmail.com',
    description:
      'A content creator who enthusiast in brand strategist and social media marketing. Globally minded woman with passion for creative thinking, strategic planning, and creating meaningful communications that make an impact for each other. A working style focused on listening, thinking and solving. Last but not least! I’m an ENFJ who always enjoy to spread love and laugh, sharing experiences to people around because Jovita means happiness.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Social Media Specialist - Blibli.com - current,Social Media Manager - Tech in Asia - 1.5 years,Freelance writer - Beautynesia.id 1.5 years',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule:
      'Tuesday 19.00 - 21.00,Thursday 19.00 - 21.00,Sunday 09.00 - 10.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/jovita2501/',
    photoUrl: '/static/images/mentors1/jovital.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'Social Media Specialist at E-Commerce',
  },
  {
    id: '821011f9-b445-47ee-b210-cc9f543c7123',
    name: 'Deassy Rizky Syahputri',
    phoneNumber: '+6285257078887',
    email: 'deassy.rizky@gmail.com',
    description:
      'Dea is a driven product enthusiast with 5 years+ of experiences and proven track-record in delivering growth and innovate new products in B2C business. She has experiences in initiating and building Growth team, as well as leading a full team consists of Engineers, QA and Product Designers and mentoring Product Managers.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Career Discovery",
    experiences: 'Product and Strategy - Krealogi - 2021 - current',
    industry: 'Teknologi/Startup, NGO',
    skills: 'Product, Consulting & Advisory, Business Development',
    schedule:
      'Senin - Jumat: 19.00 - 21.00,Sabtu: 10.00 - 16.00,Minggu: 10.00 - 16.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/deassyrizky',
    photoUrl: '/static/images/mentors1/deassyr.jpeg',
    super: true,
    addedAt: '1635861489',
    title: 'Product, Growth and Strategy Manager at Krealogi',
  },
  {
    id: '56666972-e794-46b1-9796-0c9b5f41eb92',
    name: 'Valeri Aurelia',
    phoneNumber: '+6281221484300',
    email: 'aureliavaleri09@gmail.com',
    description:
      "I have been in this world for 22 years. Life has taught me to be who I am today. Starting my very first job in the banking field, which is not aligned with my engineering bachelor's degree - but grateful for what I have in this life, so here's my way to give back.",
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Management Development Program - PT Bank Central Asia Tbk - current,Mentor (Banking Path) - Internnet - current,Beswan Djarum - Djarum Beasiswa Plus - 2019/2020',
    industry: 'Financial Services Company, NGO',
    skills: 'Product, Marketing & PR, Finance, UI/UX',
    schedule: 'Senin - Jumat , 20.00 - 21.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/valeriaurelia/',
    photoUrl: '/static/images/mentors1/valeria.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'Management Development Program at PT Bank Central Asia Tbk',
  },
  {
    id: '7e7df40b-8fa5-4606-99dd-c49f05584ae2',
    name: 'Eriawan',
    phoneNumber: "'+6281212301988",
    email: 'eri.eriawan@cicil.co.id',
    description:
      'Eriawan is Human Resources specialist with over 11 years of experience & exposure in Recruitment, talent management, and learning and development. currently, he takes care of human resources at CICIL, from early-stage until today, He recruits a lot of people from any background, and the most important and challenging he create and implement the value of the company to employee culture. Eri obtained her bachelor’s degree in computer studies from the Bina Nusantara University and Master’s degree in Strategic Management from Gajah Mada University.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Head of Human Resources - PT CICIL - current,Human Resources Manager - PT Ebizu Indonesia',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource, Consulting & Advisory',
    schedule: 'Kamis dan Jumat (19.00 - 21.00)',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/eriawan/',
    photoUrl: '/static/images/mentors1/eriawan.jpeg',
    super: false,
    addedAt: '1635861489',
    title: 'Head of Human Resources PT CICIL',
  },
  {
    id: '5ab8b9b3-1a24-4daa-b20c-b1ea41fa7698',
    name: 'Erwanda',
    phoneNumber: '+6287878737847',
    email: 'erwandaliao@gmail.com',
    description:
      'I am building ad tech products together with the PM, tech, analyst, research, and UX team. Before this rollercoaster ride, digital marketing and analytics were my soul. Joining an agency and startup gave me a lot of joys - and headache too. I am happy to share my experience, things that I wish I knew earlier in my career. Treat me as your friend.',
    serviceLine:
      'College Life Sharing,Internship Preparation,Opportunity and Network Sourcing,Career Discovery',
    experiences: 'AdTech Business Strategist - Gojek - 2 Years',
    industry:
      'Teknologi/Startup, Financial Services Company, Consulting/Advisory Services',
    skills: 'Product, Marketing & PR',
    schedule: 'Sabtu 11.00-12.00,Minggu 11.00-12.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/erwanda-liao/',
    photoUrl: '/static/images/mentors1/erwandal.jpeg',
    addedAt: '1636707410',
    title: 'AdTech Business Strategist at Gojek',
  },
  {
    id: 'a834b513-f389-4bed-82db-c1de6c40beb2',
    name: 'Dania Arsella',
    phoneNumber: '+6281293126965',
    email: 'dania.arsella@gmail.com',
    description:
      'A professional with 3+ years of experience. Strengths lie in Marketing, Business Development, Project Management, & Entrepreneurship. Proven to be resilient, hard working, and collaborative through diverse work experiences. Currently working in one of the leading tech start-up companies. Feel free to share your thoughts about professionals life, business-related, or life experiences as I will gladly help to walk you through our mentoring session.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Campaign Management Associate - Gojek (Present),Brand Partnership Supervisor - Gojek (2019),Business Development Supervisor - Gojek (2018),ULIP Brand Building Intern - Unilever (2017 - 2018)',
    industry: 'Teknologi/Startup, FMCG',
    skills: 'Marketing & PR, Business Development',
    schedule:
      'Saturday: 10.00 - 11.00 / 11.00 - 12.00,Sunday: 10.00 - 11.00 / 11.00 - 12.00',
    price: 100000,
    linkedin: 'https://id.linkedin.com/in/daniaarsella',
    photoUrl: '/static/images/mentors1/daniaa.jpeg',
    addedAt: '',
    title: 'Campaign Management Associate at Gojek',
  },
  {
    id: '11d6bc1b-e421-4590-8d66-65a9b5365525',
    name: 'Hanifika Indriarida',
    phoneNumber: '+6281215945062',
    email: 'hanifikaindriarida@gmail.com',
    description:
      'An individual who’s passionately facing challenge and exploring opportunity. A global citizen with focus in business, economics and human-potential development through my experience background.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Head of Area at Nestle - current,Sales Category Development at Nestle - 9 months,General Trade Executive at Nestle - 8 months',
    industry: 'FMCG',
    skills: 'Product, Marketing & PR, Business Development',
    schedule: 'Senin 18.30 - 20.00,Selasa 18.30 - 20.00,Rabu 18.30 - 20.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/hanifikaa/',
    photoUrl: '/static/images/mentors1/hanifikai.jpeg',
    addedAt: '1636707410',
    title: 'Head of Area at Nestle',
  },
  {
    id: '1129f1fe-282e-4b95-bf7d-06fc43b1066c',
    name: 'Derry Pratama Reza',
    phoneNumber: '+6281293459065',
    email: 'derryr58@gmail.com',
    description:
      'Experienced Talent Acquisition and Development with a demonstrated history of working in the internet industry. Skilled in Negotiation, Management, and Leadership. Strong human resources professional.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      '- Sr Talent Acquisition & Development at Sayurbox - (Agustus - Current),- Talent Acquisition Specialist at Ruangguru - 2.5 Tahun,- Talent Acquisition Staff at Blibli.com - (9 Bulan),- Recruitment Specialist at Sribulancer.com - (2.3 Tahun)',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource',
    schedule: 'Selasa 19.00 - 21.00,Rabu 19.00 - 21.00,Sabtu 9.00 - 10.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/derry-pratama-reza-043839a5/',
    photoUrl: '/static/images/mentors1/derryp.jpeg',
    addedAt: '1636707410',
    title: 'Senior Talent Acquisition & Development at Sayurbox',
  },
  {
    id: '762e0e47-a27e-44f1-acd3-ae14d67af844',
    name: 'Wigy Wahyu Rizqi Ramadhan',
    phoneNumber: '+62895631302661',
    email: 'wigyramadhan@gmail.com',
    description:
      'Hi, I am a multiverse researcher, I do product/UX and market research. I came for research from an Industrial Design background; I learned about how physical and human behavior influence decisions about design and vice versa. I have helped a lot of tech company from various industries create their products that follow their user needs across southeast asia. Currently I am mastering my research for fintech industries with Xendit, while previously I did research for style theory to spread their mission on circular economy.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Product Researcher - Xendit 2020 - current,Product Researcher - Style Theory 2020,Bukalapak 2018 - 2020',
    industry: 'Teknologi/Startup, Financial Services Company',
    skills: 'Product, Finance, UI/UX',
    schedule: 'Weekday above 19.000',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/wigyramadhan/',
    photoUrl: '/static/images/mentors1/wigyw.jpeg',
    addedAt: '1636707410',
    title: 'Product Researcher at Xendit',
  },
  {
    id: '2c03cbf9-c85c-4b1d-bd31-2a66129f5a38',
    name: 'Fabiola Bernadette Fioni Josephine Santoso',
    phoneNumber: '+6289623792014',
    email: 'fbfioni@gmail.com',
    description:
      'Fioni, a civil engineering fresh graduate from Institut Teknologi Bandung who got into banking industry.',
    serviceLine:
      'CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing',
    experiences:
      'Management Development Program - PT Bank Central Asia Tbk - current',
    industry: 'Financial Services Company',
    skills: 'Finance',
    schedule: 'Rabu 19.00-21.00, Kamis 19.00-21.00',
    price: 100000,
    linkedin:
      'https://www.linkedin.com/in/fabiolabernadettefionijosephinesantoso',
    photoUrl: '/static/images/mentors1/fabiolab.jpeg',
    addedAt: '1636707410',
    title: 'Management Development Program at PT Bank Central Asia Tbk',
  },
  {
    id: '8902518e-0f4f-4f21-9ebc-f556395492af',
    name: 'Wan Muhammad Iqbal',
    phoneNumber: '+6285245721735',
    email: 'wanmiqbal@gmail.com',
    description:
      'Digital Marketing merupakan posisi yang saat ini semakin populer dan juga banyak dibutuhkan oleh banyak perusahaan. Tapi apakah profesi ini hanya cocok untuk start up ataupun perusahaan digital?,Saya akan bantu kamu untuk mengetahui mengenai profesi ini dan juga dunia marketing dan sales dari sudut pandang industri yang lebih luas dan bagaimana pengalaman bekerja di multinasional company, berikut juga bagaimana untuk membuat pilihan karir yang tepat. Ayo diskusi bersama!',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      '2020 - Current: Digital Marketing Senior Manager - Bank DBS,2015 - 2019: Digital Marketing - Prudential Indonesia,2015: Business Development Executive - Dealoka',
    industry: 'Financial Services Company',
    skills: 'Marketing & PR, Business Development',
    schedule: 'Sabtu/Minggu. 10:00 - 14:00 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/waniqbal/',
    photoUrl: '/static/images/mentors1/wanm.jpeg',
    addedAt: '1637106340',
    title: 'Digital Marketing Senior Manager at DBS Bank',
  },
  {
    id: 'ead1f177-b0ea-4605-bfab-31a2e46d2231',
    name: 'Maria Giovanni',
    phoneNumber: '+6281390746468',
    email: 'mariagiovanni11@gmail.com',
    description:
      'A lifelong learner who is profoundly experienced in HRM, Learning & Development scope, Mentoring, Employee Improvement Analysing, and Mental Health Awareness. Have been issued and certified Human Capital Officer & Basic Human Capital Management by BNSP in 2021',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Career Discovery",
    experiences:
      'Corporate Learning & Development - FMCG Company - April 2019 - present,Human Resources Internship - PT DJARUM - October-December 2018,Professional Speaker in Human Resources Learning & Development and Mental Health - 2021',
    industry: 'FMCG',
    skills: 'Human Resource',
    schedule: 'Friday : 19.00 - 21.00,Saturday & Sunday : 18.00 - 21.30',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/mariagiovannihniosie/',
    photoUrl: '/static/images/mentors1/mariag.jpeg',
    addedAt: '1637106340',
    title: 'Corporate Learning & Development at FMCG Company',
  },
  {
    id: 'bbe177db-6390-48a1-a159-772b1c9a25cc',
    name: 'Ahdiat Permana',
    phoneNumber: '+6285281621736',
    email: 'ahdiatp@gmail.com',
    description:
      "Hi! My name is Ahdiat, I currently work as a HR professional at Southeast Asia’s leading payment service provider in the gaming industry. Got into the industry due to my avid hobby as a gamer, and I personally enjoy learning and orientating my everyday experience so that I can understand more Indonesia's cultural working behavior. If you think my expertise & experience can help you in any form, just contact me!",
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Head of People Ops & GA - UniPin -  Current,Head of People Development - Bhinneka -  4 Years 7 Months',
    industry: 'Teknologi/Startup, Consulting/Advisory Services',
    skills: 'Human Resource, Consulting & Advisory',
    schedule:
      'Monday : 19.00 - 21.00,Thursday : 19.00 - 21.00,Saturday : 9.00 - 11.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/ahdiat-permana-6995a65a/',
    photoUrl: '/static/images/mentors1/ahdiatp.jpeg',
    addedAt: '1637106340',
    title: 'Head of People Ops & GA at UniPin',
  },
  {
    id: '4a8c5097-4f44-48f4-ae3e-89124b4f8727',
    name: 'Helga Myrachel',
    phoneNumber: '+628170982024',
    email: 'helgamyrachel@gmail.com',
    description:
      'Currently working as a paid ads optimiser. I turn data into strategic action to increase online presence and sales on various digital platforms. I enjoy exploring new things to make them beneficial and perform best under pressure (cause life is a pressure anyway). I hope you have a good day! Peace, love, and gawl✌',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Digital Marketing Specialist at Gojek (April 2021 - Sekarang),Growth Marketing Strategist at Darshana Design Studio/SelfMade Indonesia (Desember 2020 - Maret 2021),Operation Analyst Intern at Darshana Design Studio/SelfMade Indonesia (Oktober 2020 - November 2020)',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule:
      'Senin-Rabu (19.00-21.00), Jumat&Minggu(12.00-21.00) Sabtu(14.00-21.00)',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/helgamyrachel/',
    photoUrl: '/static/images/mentors1/helgam.jpeg',
    addedAt: '1637106340',
    title: 'Digital Marketing Specialist at Gojek',
  },
  {
    id: '7ed9f7db-83a8-4071-a916-6890108f2e08',
    name: 'Ra Garjita Puspita Arum Sari S',
    phoneNumber: '+628111165544',
    email: 'Garjita.arumsari@gmail.com',
    description:
      "Garjita is a sales person that believe in salesmanship and stewardship. Where delivering value, solutions, and building long term relationships with partners is a strong core of the business and personal success. Building a winning team and creating efficient way of work in every company she joined, she's leading up to 156 people within her organization. ,Garjita is a strong team player and always believe in develop strong emotional intelligence within her team to help them successful in the future regardless the role or jobscope they are in.",
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Head of Sales & Business Development Indonesia at HappyFresh April 2021 - current,Head of Sales - GrabFood Jabodetabek - 2 years,Area Sales Manager - Unilever - 2 years,Key Account Manager Foodstores (Modern Channel) / Management Trainee - Coca Cola Amatil Indonesia - 3 years',
    industry: 'Teknologi/Startup, FMCG',
    skills: 'Human Resource, Business Development',
    schedule: 'Kamis/Jumat 18.00-21.00, Sabtu 08.00-11.00',
    price: 200000,
    linkedin: 'https://www.linkedin.com/in/garjitaarumsari',
    photoUrl: '/static/images/mentors1/garjitaa.jpeg',
    addedAt: '1637106340',
    title: 'Head of Sales & Business Development Indonesia at HappyFresh',
  },
  {
    id: 'b3f112e6-a01d-4d0e-93f6-ee4916010203',
    name: 'Pradithasari Dewi Saumi',
    phoneNumber: '+6281281093025',
    email: 'dithasaumi@gmail.com',
    description: '-',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,Career Discovery",
    experiences: 'Sales & Marketing Associate - E-Commerce Company (2.5 years)',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule: 'Kamis & Jumat: 19.00-21.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/pradithasaridewisaumi',
    photoUrl: '/static/images/mentors1/pradithad.jpeg',
    addedAt: '1638284888',
    title: 'Sales & Marketing Associate at e-commerce company',
  },
  {
    id: 'ae23ae84-4722-45ea-89cd-fb90ba99eb4f',
    name: 'Alvin Dwi Putra',
    phoneNumber: '+628990398398',
    email: 'alvinputra@outlook.com.au',
    description:
      'Accounting and Finance graduate from the University of Melbourne, currently pursuing CPA Program, equipped with extensive experience in organisations, including internship experiences in Australia and Indonesia. Currently exploring the banking industry to further develop my passion and experience.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Management Development Program - Current,Accounting intern at FMCG industry - 3 months,Business advisory intern in Financial industry - 3 months',
    industry: 'Financial Services Company',
    skills: 'Finance',
    schedule: 'Selasa: 19.00 - 21.00,Kamis: 19.00 - 21.00,Sabtu: 8:00-10:00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/alvin-putra/',
    photoUrl: '/static/images/mentors1/alvind.jpeg',
    addedAt: '1638284888',
    title: 'Management Development Program at PT Bank Central Asia Tbk',
  },
  {
    id: '79e00599-eeb6-4b59-b568-3d58d20a0542',
    name: 'Melissa Stephanie Kartjito',
    phoneNumber: '+62817543478',
    email: 'melissa.s.kartjito@gmail.com',
    description:
      'A strategic and meticulous individual from Nutrition and Health background, Melissa is passionate in translating evidence-based science to daily practice through public speaking, research, social media, and content creation. ,As one of the few health/nutrition majors to break into FMCG, Melissa has been helping those from non-traditional background make it into their dream career - will you be one of them?',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      '- MT Medical Affairs - Healthcare Nutrition at Danone (2020-2021),- Wakil Ketua II Keluarga Alumni Gizi Kesehatan (KAGIKA) UGM (2021-now),- XL Future Leaders Batch 7 (2018-2020)',
    industry: 'FMCG',
    skills: 'Marketing & PR',
    schedule: 'Rabu, 20.00 - 21.30,Jumat, 20.00 - 21.30',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/melissa-stephanie-kartjito',
    photoUrl: '/static/images/mentors1/melisssas.jpeg',
    addedAt: '1638284888',
    title: 'Assistant Brand Manager at Danone Specialized Nutrition',
  },
  {
    id: '0f0fc022-7dc4-44f3-ae26-2a391d52474b',
    name: 'Sandra Cory Clarisa',
    phoneNumber: '+6281313581110',
    email: 'scclarisa@gmail.com',
    description:
      'Two life-changing internships experience has set the course for Sandra Clarisa to build a career in Marketing, even though she holds an Engineering background from ITB. Working her way up, she has 7+ years of experience in FMCG, Unicorn and Decacorn companies - acquiring diverse skillsets when experiencing different roles from Trade Marketing, Brand Marketing, and Product Marketing. Majority of her peers and ex-leads describe her as a high-ownership, friendly and curious person. When not busy addressing user problems, she finds delight in mentoring and bringing out the best from young professionals, translated in a numerous Marketing and Career-related webinars, 1:1 Coaching sessions, Mock-up Interview to name a few.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Senior Brand & Product Marketing Manager - Gojek - (May 2021-Current),Product Marketing Manager - Traveloka - 3 years (2018-2021),Sr. Associate Trade & Brand Marketing - SC Johnson 3.5 years (2014-2018)',
    industry: 'Teknologi/Startup, FMCG',
    skills: 'Marketing & PR, Human Resource',
    schedule:
      'Senin - Jumat 19.00-21.00,Senin - Jumat 09.30-12.00,Sabtu - Minggu 14.00-17.00',
    price: 200000,
    linkedin: 'https://www.linkedin.com/in/sandraclarisa/',
    photoUrl: '/static/images/mentors1/sandrac.jpeg',
    addedAt: '1638284888',
    title: 'Senior Brand & Product Marketing Manager at Gojek | ex-Traveloka',
  },
  // {
  //   id: 'a739978f-4bab-49fa-8e49-997cb65a613e',
  //   name: 'Herman Yosef Paryono',
  //   phoneNumber: '+6281804182066',
  //   email: 'hermansbm@gmail.com',
  //   description:
  //     "Hi, I'm Herman, an HR Manager with a background in Social-Organizational Psychology and interest in Digital Technology. During my study I led several student organizations, including becoming the Head of PPI (Perhimpunan Pelajar Indonesia) Leiden, Netherlands. Along the way, I've helped many people to get their professional project right on track and find their way of life. Reach me if you want to know more or listen to my personal stories.",
  //   serviceLine:
  //     "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
  //   experiences:
  //     'Managing all HR Fields (Recruitment, Selection, Training, Development, Internal Communication, Personnel, Organizational Development, People Analytics) - Nutrifood Indonesia - 2010-current, Promotion Project Coach - Nutrifood Indonesia - 2017-current, Digital Transformation Lead - Nutrifood Indonesia - 2017-2018',
  //   industry: 'FMCG, Consulting/Advisory Services, NGO',
  //   skills: 'Data Science, Human Resource, Consulting & Advisory',
  //   schedule: 'by appointment',
  //   price: 100000,
  //   linkedin: 'https://www.linkedin.com/in/herman-yosef-paryono-826b2a25/',
  //   photoUrl: '/static/images/mentors1/hermany.jpg',
  //   addedAt: '1638284888',
  //   title: 'HR Manager at Nutrifood Indonesia',
  // },
  {
    id: 'c47f1875-baae-4c7d-93e3-d3959c8fbeb7',
    name: 'Azza Meyrisqa Andilolo',
    phoneNumber: '+6287850864630',
    email: 'azzamey@gmail.com',
    description: '-',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Career Discovery",
    experiences:
      'Product Management & Design Operations - OVO - Current,Design Operations & UX Research Intern - OVO - Dec 2020/Aug 2021,Product Marketing Intern - Glints - Aug 2020/Dec 2020',
    industry: 'Teknologi/Startup',
    skills: 'Product, UI/UX',
    schedule: 'Rabu 19.00 - 21.00 WITA',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/azzameyrisqa',
    photoUrl: '/static/images/mentors1/azzam.jpeg',
    addedAt: '1638284888',
    title: 'Product Management & Design Operations at OVO',
  },
  {
    id: '662e50e9-d0ef-4a7d-875c-112de901fbc8',
    name: 'Rendhy Ardya Pradhita',
    phoneNumber: '+6282118528157',
    email: 'ckardya@gmail.com',
    description:
      'More than 11 years of working experience in various industry & companies, such as logistics, manufacture, digital, start-up & corporate. Experience in Management Strategy & Human Resources, also a high passion in data analytics.',
    serviceLine:
      "Career Discovery,College Life Sharing,Professional Life Sharing,Mentor's Career Path,Selection Preparations",
    experiences:
      'Head of People - Flip - Current,General Manager of People - Moka - 1 tahun 10 bulan,Head of Talent Experience - Bukalapak - 4 tahun 4 bulan',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource, Consulting & Advisory',
    schedule: 'Weekday: 20.00-22.00',
    price: 200000,
    linkedin: 'https://www.linkedin.com/in/rendhyardya/',
    photoUrl: '/static/images/mentors1/rendhya.jpeg',
    addedAt: '1639138625',
    title: 'Head of People at Flip',
  },
  {
    id: 'cbe4605d-7e30-409f-b736-0022ea47c92d',
    name: 'Risvi Maulana',
    phoneNumber: '+6281286821278',
    email: 'risvi.maulana@gmail.com',
    description:
      'An experienced content specialist with more than 5 years experience. Currently working in the leading tobacco company in Indonesia for the employer branding team and previously worked in multinational agencies and start-ups. Optimizing content marketing, CRM, website, social media, and/or other digital channels are my daily basis to achieve the KPI. To support that, I am capable in creating digital strategies, writing (from content, copy, to UX writing), visual concepting, and digital analysis. Create a content that achieve the business goals and fulfil the audience needs is what I am expert on. I am highly interested in the tech, healthcare, lifestyle, travel, and financial industry.',
    serviceLine:
      'Career Discovery,Selection Preparation,Skill & Competence Preparations',
    experiences:
      'Sr. Content & Creative Marketing Specialist di Pintu - Current ,PT HM Sampoerna Tbk - 8 bulan ,DANA Indonesia - 10 bulan ,Gojek - 2 tahun 3 bulan',
    industry: 'Teknologi/Startup, FMCG',
    skills: 'Marketing & PR, ,Human Resource',
    schedule: 'Senin, 18.30-19.30 ,Selasa, 18.30-19.30 ,Rabu, 18.30-19.30',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/risvim/',
    photoUrl: '/static/images/mentors1/risvim.jpeg',
    addedAt: '1639138625',
    title: 'Sr. Content & Creative Marketing Specialist di Pintu',
  },
  // {
  //   id: 'c647f005-9d99-4980-8d6a-2340b2e919f0',
  //   name: 'Kenneth Budiman',
  //   phoneNumber: '+628993240660',
  //   email: 'kenneth.budiman.edu@hotmail.com',
  //   description:
  //     'A corporate and digital strategist with solid experience in global consulting firm, financial services, and tech industry. ,Currently building and supporting digitalization of Indonesian MSME at the biggest tech company in Indonesia, Gojek.',
  //   serviceLine:
  //     "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
  //   experiences:
  //     'Senior Manager at Gojek | Present,Head of Corporate Planning & Investor Relation at Linkaja (2020-2021),Head of Digital Strategy & Investment at Adira Finance (2019-2020)',
  //   industry:
  //     'Teknologi/Startup, Financial Services Company, Consulting/Advisory Services',
  //   skills:
  //     'Product, Data Science, Marketing & PR, Consulting & Advisory, Business Development, Finance.',
  //   schedule: 'Sabtu, 09.00-16.00 ,Minggu, 13.00-16.00 (By Appointment Only)',
  //   price: 150000,
  //   linkedin: 'https://www.linkedin.com/in/kenneth-budiman-500521b1/',
  //   photoUrl: '/static/images/mentors1/kennethb.jpeg',
  //   addedAt: '1639138625',
  //   title: 'Senior Manager Merchant Strategy & Engagement at Gojek',
  // },
  {
    id: 'ec9b0cca-f7d8-4e82-86aa-3b2e54d4051f',
    name: 'Theresia Nadya M Panjaitan',
    phoneNumber: '+6281294632054',
    email: 'theresianadyamp@gmail.com',
    description:
      'A passionate marketer with a deep interest in human behavior, now building a brand to succeed in the Indonesian market even beyond in the bigger space. Strong in developing a consumer-centric and insight-driven marketing strategies. Also, enjoy being involved in the creative development aspects of marketing executions.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Head of Brand Marketing at Gojek (Aug 2021 - Present),Brand Manager at Gojek (Aug 2019 - Aug 2021),Jr Brand Manager at Gojek (Mar 2018 - Aug 2019)',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR',
    schedule: 'Jumat 08.00 - 09.00,Minggu 08.00 - 09.00 / 13.00-14.00',
    price: 200000,
    linkedin: 'https://www.linkedin.com/in/theresianadya/',
    photoUrl: '/static/images/mentors1/theresiap.jpeg',
    addedAt: '1639138625',
    title: 'Head of Brand Marketing at Gojek',
  },
  // {
  //   id: '1ca80035-1aea-4aca-9279-4274490e71cb',
  //   name: 'Adizta Putri Sekarwangi',
  //   phoneNumber: '+6282112720975',
  //   email: 'adistasekarwangi@gmail.com',
  //   description:
  //     "An attentive and detail-oriented team player with 3+ years experiences in developing strategic digital marketing campaigns. Currently responsible to lead digital marketing activities & projects for brands under Kalbe Consumer Health's Respiratory Care and Digestive & Skin categories.",
  //   serviceLine:
  //     "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,Career Discovery",
  //   experiences:
  //     'Digital Marketing Executive - Kalbe Consumer Health - Current,Digital Strategist - Idea Imaji Creative Agency - 2019 to 2021',
  //   industry: 'FMCG',
  //   skills: 'Marketing & PR',
  //   schedule: 'Rabu: 19.00 - 21.00,Kamis: 19.00 - 21.00,Jumat: 19.00 - 21.00',
  //   price: 100000,
  //   linkedin: 'https://linkedin.com/in/adistasekarwangi',
  //   photoUrl: '/static/images/mentors1/adiztap.jpeg',
  //   addedAt: '1639138625',
  //   title: 'Digital Marketing Executive at Kalbe Consumer Health',
  // },
  {
    id: '88f6553d-c229-4e8a-a9a8-51b375c87074',
    name: 'Rendy Fernando',
    phoneNumber: '+6287788485605',
    email: 'ryferdo@gmail.com',
    description:
      'I was graduated from London School Of Public Relations, Jakarta in Mass Communications major. I have worked in several industries such as Public Relations and Marketing. Now, I am working as Business Development and Partnership Manager for GoPayLater. Work smart is always better than work hard!',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Partnership and Business Development Manager at GoTo Financial (Current),B2B Sr. Associate at LinkAja (2019 - 2021),Banking Partnership Supervisor at PT Indo Lotte Makmur (2018 - 2019)',
    industry: 'Financial Service Company',
    skills: 'Marketing & PR, Business Development',
    schedule:
      'Selasa : 19.00 - 21.00,Rabu : 20.00 - 21.00,Kamis : 19.00 - 21.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/rendyf/',
    photoUrl: '/static/images/mentors1/rendyf.jpeg',
    addedAt: '1639138630',
    title: 'Partnership and Business Development at Gopay Later',
  },
  {
    id: 'fcd9a3c2-57d4-4d55-8792-5baa75d44ed4',
    name: 'Deandra Rizkyandita',
    phoneNumber: '+6281298280308',
    email: 'deandra.rizkyandita@gmail.com',
    description:
      'Currently working as Business Planning and Analysis for O2O business in BukaLapak. Previously working as a full-time financial auditor with the ability to implement effective audit procedures and evaluate accounting systems for efficiency purposes. Self-motivated, hard-working, adaptive and deliver results on time. Also, a fast learner who can perform well under pressure and is very comfortable working as part of a team.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Finance Manager - BukaLapak - August 2021 to current,ex-Senior Associate on Financial Assurance - PwC Indonesia ,- May 2018 to August 2021',
    industry: 'Teknologi/Startup,Financial Services Company',
    skills: 'Consulting & Advisory',
    schedule: 'Rabu: 19.00 - 21.30,Kamis: 19.00 - 21.30,Jumat: 19.00 - 21.30',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/deandra-rizkyandita/',
    photoUrl: '/static/images/mentors1/deandrar.jpeg',
    addedAt: '1639138630',
    title: 'Finance Manager at BukaLapak',
  },
  {
    id: '33803fbc-efab-47bd-abf6-b194572d7509',
    name: 'Fadly Sumantri',
    phoneNumber: '+6281223645256',
    email: 'fadly.sumantri@bukuwarung.com',
    description:
      'Professional person with hard work skills. He is kind of person who loves to explore with spirited curiosity and practical realism, independent, love challenge, and risk-taker. Fadly has delved into understanding what it means to work for a collective group and an individual and gaining proficient knowledge in doing strategic communication, public relations, recruitment flow, sales, marketing, account management, developing skills, and also giving impact to others.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Consultant - Glints Indonesia - Jan 2019-Apr 2020,Consultant - HRNetGroup - Jun 2020-Jun 2021,Talent Acquisition - BukuWarung - Jul 2021-current',
    industry: 'Teknologi/Startup, Consulting/Advisory Services',
    skills:
      'Marketing & PR, Human Resource, ,Consulting & Advisory, ,Business Development',
    schedule: 'Senin - Kamis ,19.00 - 21.00',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/fadlysmtr/',
    photoUrl: '/static/images/mentors1/fadlys.jpeg',
    addedAt: '1639138630',
    title: 'Talent Acquisition at Bukuwarung',
  },
  {
    id: '8d1c4502-adc2-45f3-9570-328ffc154444',
    name: 'Arnold Darius',
    phoneNumber: '+6281808126800',
    email: 'arnold.darius@gmail.com',
    description:
      '14 years of experience in Tech-relevant industry with exposure to various business sectors. Full-stack Product Enthusiast: Product Management-Development-Marketing, Well-experienced System/Business Analyst, Process improvement evangelist with a solid understanding on Project Management, with extra hustler instinct in Sales & Marketing. Well-used to act as a connecting bridge between clients, team-mates, stakeholders and,different parties. Familiar with Commerce, EduTech, CSM/CRM, B2B, B2C, Startup/Vendor, SaaS life and can adapt easily.',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'Senior Product Manager - Sociolla - 2019 until 2021,Head of Product and Growth Marketing - KelasPintar - since 2021',
    industry:
      'Teknologi/Startup, FMCG, Financial Services Company, Consulting/Advisory Services, Manufacture',
    skills: 'Product',
    schedule:
      'Everyday after working hours on weekdays, and anytime on weekend',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/arnolddarius/',
    photoUrl: '/static/images/mentors1/arnoldd.jpeg',
    addedAt: '1639138630',
    title: '',
  },
  {
    id: '5313686d-2657-49df-ae23-f1f851cfd3be',
    name: 'Holdi Agam',
    phoneNumber: '+6281517156630',
    email: 'holdiagam@gmail.com',
    description:
      'Human Capital Generalist / HRBP, with experience in HR functions; hiring, performance management, employee relation (business partner), learning and organisational development, culture & engagement in startup/Technology & Financial Services Company. Also will helping you to find & build your career goals.',
    serviceLine:
      "Professional Life Sharing,Mentor's Career Path Exploration,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing,Career Discovery",
    experiences:
      'HR Generalist / HRBP Ruangguru - 2019 sd current,HR Recruitment & Training Supervisor Financial Services Company - 2016 sd 2018',
    industry: 'Teknologi/Startup',
    skills: 'Human Resource',
    schedule: 'Senin sd Minggu : 18.00 sd 21.00',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/holdiagam',
    photoUrl: '/static/images/mentors1/holdia.jpg',
    addedAt: '1639138630',
    title: 'People Partner (HRBP) at Ruangguru',
  },
  {
    id: '0359757b-5043-4ba4-9433-f2b13c340070',
    name: 'Dinda Putri Thalia',
    phoneNumber: '+6285659999496',
    email: 'dindathaliaaa@gmail.com',
    description: '-',
    serviceLine:
      "Professional Life Sharing ,Mentor's Career Path Exploration CV Preparation & Feedback Interview Preparation & Mock-up Opportunity and Network Sourcing Career Discovery",
    experiences:
      'Senior Associate Product Manager  at HappyFresh - 2 years 8 months - current,Product Manager Intern at Bizzy - 3 months',
    industry: 'Teknologi/Startup',
    skills: 'Product',
    schedule: 'Sabtu: 16.00 - 17.00',
    price: 60000,
    linkedin: 'https://id.linkedin.com/in/dinda-thalia',
    photoUrl: '/static/images/mentors1/dindat.jpeg',
    addedAt: '1639138630',
    title: 'Product Manager - Senior Associate at HappyFresh',
  },
  {
    id: '35292b67-150e-4c43-b91b-0a72bd44fab9',
    name: 'Adinda Nindyachandra',
    phoneNumber: '+6281384365386',
    email: 'adindanindyachandra@gmail.com',
    description:
      'Experienced producer with a demonstrated history of 5+ years of working in the performing arts industry in Jakarta, Melbourne and New York City. ,Graduated with a Cum Laude predicate from Atma Jaya Catholic University of Indonesia with a Bachelor in Marketing Communication. Skilled in Event Management and Public Speaking with a Master of Arts (MA) focused in Arts and Cultural Management from The Faculty of Arts at The University of Melbourne. ',
    serviceLine:
      "College Life Sharing, Mentor's Career Path Exploration, dan Professional Life Sharing,Opportunity and Network Sourcing, CV Preparation and Feedback, dan Interview Preparation & Mock-up,Internship Preparation dan Knowledge and Skill Preparation",
    experiences:
      'Executive Director, Co-Founder, and Producer of ASKARA (2019 - current),English Tutor & Content Creator of Cetta English (2022 - current),Associate Casting Director of Pekan Olahraga Nasional 2020 (June 2021 - October 2021),',
    industry: 'Teknologi/Startup',
    skills: 'Marketing & PR, Consulting & Advisory, Business Development',
    schedule:
      'Tuesday (09:00 - 18:00), Wednesday (15:00 - 17:00), Friday (14:00 - 17:00)',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/adinda-nindyachandra3103/',
    photoUrl: '/static/images/mentors1/adindan.jpeg',
    addedAt: '1639138630',
    title: 'Executive Director, Co-Founder of ASKARA',
  },
  {
    id: '1223227b-351c-494a-9fbe-de238c5babed',
    name: 'Kent Nathaniel Virgossa',
    phoneNumber: '+6282122766707',
    email: 'alexius.kent@gmail.com',
    description: `A Product Marketer & an entrepreneur. I believe learning is a lifelong process. Keep learning, and embrace failures.
      I consistently displayed a remarkable ability to cope not only in high-stress situations but to actually thrive in them.
      Currently learning how to make smarter decisions despite a lot of uncertainties, being a better leader and a better individual.`,
    serviceLine:
      "College Life Sharing,Mentor's Career Path Exploration,Professional Life Sharing,Opportunity and Network Sourcing,CV Preparation and Feedback,Interview Preparation & Mock-up,Internship Preparation,Knowledge and Skill Preparation",
    experiences:
      'Product Marketing - Traveloka - 1 Tahun 9 Bulan,Associate Product Marketing Manager - Halodoc - 1 Tahun,Senior Associate Product Marketing Manager - Pluang - 1 Tahun',
    industry: 'Teknologi/Startup, Financial Services Company, NGO',
    skills: 'Marketing & PR',
    schedule:
      'Senin 19.30 - 21.30, Selasa 19.30 - 21.30, Rabu 19.30 - 21.30, Kamis 19.30 - 21.30, Jumat 19.30 - 21.30, Sabtu 08.00 - 13.00, Minggu 08.00 - 13.00',
    price: 150000,
    linkedin: 'https://www.linkedin.com/in/kent-nathaniel-2b9ab3a7/',
    photoUrl: '/static/images/mentors1/KentNathaniel.jpg',
    addedAt: '1652101998',
    title: 'Senior Associate Product Marketing Manager',
    // super: true,
  },
  {
    id: 'c7dad26d-5de9-4522-b178-6a38d0897a28',
    name: 'Nethania Dinari Ramadhani',
    phoneNumber: '+6281382229055',
    email: 'nethaniadinari13@gmail.com',
    description: `Experienced at a hyper growth tech startup company. Focusing more in Product Management and driving various key business metrics: adoption, usage, and revenue by working cross-functional for Go-to-Market & Product Strategy.`,
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Senior Product Operations & Strategy Associate at GOJEK Indonesia (1.6 YoE),Product Marketing at Bukalapak (3 Months),Product Marketing at Glints (7 Months)',
    industry: 'Teknologi/Startup, Financial Services Company',
    skills: 'Product, Marketing & PR',
    schedule: 'Senin - Jumat (19.00 - 22.00)',
    price: 100000,
    linkedin: 'https://www.linkedin.com/in/nethaniadinari/',
    photoUrl: '/static/images/mentors1/nethania.jpeg',
    addedAt: '1654613566',
    title: 'Senior Product Operations & Strategy Associate at GOJEK Indonesia',
    // super: true,
  },
];

const devMentors = [
  {
    id: 'f6a959ad-04a4-4f4e-801d-1b8e641bfaf1',
    name: 'Samuel Test',
    title: 'Co-founder and CEO at Aksel',
    phoneNumber: '+6285155070615',
    email: 'team@aksel.co.id',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Co-founder and CEO of Aksel - 1 tahun,Project Lead Decacorn Startup - 3 bulan,Young Leaders for Indonesia - 6 bulan,Startup Accelerator Project Team - 6 bulan',
    industry: 'Teknologi/Startup',
    skills: 'Product,Marketing & PR',
    schedule: 'Senin - Jumat : 13.00-17.00 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/samuel-davidsen/',
    photoUrl: '/static/images/mentors1/samuel.jpg',
    videoUrl:
      'https://drive.google.com/file/d/1i151XNeHAj_lgOeml3WjYkiTEr5Cgz93/view?usp=sharing',
    super: true,
    addedAt: '1624208400',
  },
  {
    id: '1a8338e5-a6f7-490a-ab40-bd3c2ffb3d52',
    name: 'Samuel Test',
    title: 'Co-founder and CEO at Aksel',
    phoneNumber: '+6285155070615',
    email: 'team@aksel.co.id',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Co-founder and CEO of Aksel - 1 tahun,Project Lead Decacorn Startup - 3 bulan,Young Leaders for Indonesia - 6 bulan,Startup Accelerator Project Team - 6 bulan',
    industry: 'Teknologi/Startup',
    skills: 'Product,Marketing & PR',
    schedule: 'Senin - Jumat : 13.00-17.00 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/samuel-davidsen/',
    photoUrl: '/static/images/mentors1/samuel.jpg',
    videoUrl:
      'https://drive.google.com/file/d/1i151XNeHAj_lgOeml3WjYkiTEr5Cgz93/view?usp=sharing',
    super: false,
    addedAt: '1624208400',
  },
  {
    id: 'a1086efa-7ede-40f3-a812-e784ffae2af1',
    name: 'Samuel Test',
    title: 'Co-founder and CEO at Aksel',
    phoneNumber: '+6285155070615',
    email: 'team@aksel.co.id',
    serviceLine:
      "College Life Sharing,Professional Life Sharing,Internship Preparation,Mentor's Career Path Exploration,Knowledge and Skill Preparation,CV Preparation & Feedback,Interview Preparation & Mock-up,Opportunity and Network Sourcing",
    experiences:
      'Co-founder and CEO of Aksel - 1 tahun,Project Lead Decacorn Startup - 3 bulan,Young Leaders for Indonesia - 6 bulan,Startup Accelerator Project Team - 6 bulan',
    industry: 'Teknologi/Startup',
    skills: 'Product,Marketing & PR',
    schedule: 'Senin - Jumat : 13.00-17.00 WIB',
    price: 60000,
    linkedin: 'https://www.linkedin.com/in/samuel-davidsen/',
    photoUrl: '/static/images/mentors1/samuel.jpg',
    videoUrl:
      'https://drive.google.com/file/d/1i151XNeHAj_lgOeml3WjYkiTEr5Cgz93/view?usp=sharing',
    super: false,
    addedAt: '1624208400',
  },
];

let toExport =
  process.env.REACT_APP_DEPLOYED === 'dev' ? devMentors : prodMentors;

export default toExport;
