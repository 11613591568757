import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  IconButton,
  Container,
  CircularProgress,
  Slider,
  TextField,
  Snackbar,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import firebase from 'firebase/app';
import ShortUniqueId from 'short-unique-id';
import download from 'downloadjs';
import axios from 'axios';
import { connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { makeStyles, withStyles } from '@material-ui/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import CustomButton from 'src/components/button';
import {
  DownloadIcon,
  ZoomOutIcon,
  ZoomInIcon,
  ProductAkselIcon,
} from 'src/assets/icons/CvMaker';
import MenuBottom from 'src/components/cvMaker/MenuBottom';
import CustomDrawer from '../../../components/CustomDrawer';
import CustomDialog from 'src/components/cvMaker/CustomDialog';
import CustomPopover from 'src/components/cvMaker/CustomPopover';
import DialogDownload from 'src/components/cvMaker/DialogDownload';
import DialogCvReview from 'src/components/cvReview/DialogCvReview';
import {
  b64toBlob,
  checkIsFeedbackExist,
  getUnsplashPhoto,
} from 'src/utils/utilities';
import { setCvStep, getCvDetail, updateCvDetail } from 'src/redux/actions';
import './preview.css';
import {
  openCVRating,
  openGeneralRating,
} from 'src/redux/actions/rating-actions';
import popUpCvMaker from 'src/assets/img/popup-banner-cvmaker.png';
import reactGA from 'react-ga';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  navTitle: {
    padding: '13px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 22,
  },
  zoom: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    marginBottom: 4,
    maxWidth: '297px',
  },
  '@global': {
    '.css-1uaxfgw-MuiButtonBase-root-MuiIconButton-root': {
      padding: '0 !important',
    },
    '.css-1dztziv': {
      display: 'none !important',
    },
  },
}));

const AkselSlider = withStyles({
  root: {
    color: '#1D2D5E !important',
    height: '8px !important',
    cursor: 'default !important',
  },
  thumb: {
    width: '16px !important',
    height: '16px !important',
    color: '#fff !important',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    // display: "none !important",
  },
  track: {
    height: '8px !important',
    borderRadius: '4px !important',
  },
  rail: {
    height: '8px !important',
    borderRadius: '4px !important',
  },
})(Slider);

const CvPreview = ({ cvDetail, noFooter, noHeader }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [urlTemp, setUrlTemp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(1);
  const [tempFlag, setTemFlag] = useState(false);
  const [blobData, setBlobData] = useState('');
  const [modalDownload, setModalDownload] = useState(false);
  const [modalReview, setModalReview] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(localStorage.getItem('bannerCv'));
  const [modalAlert, setModalAlert] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [name, setName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeMenu, setActiveMenu] = useState({
    edit: false,
    template: false,
    layout: false,
    color: false,
    text: false,
  });
  const isTablet = useMediaQuery('(min-width:768px)');
  const [tabletView, setTabletView] = useState(isTablet);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    setTabletView(isTablet);
  }, [isTablet]);

  const menuEdit = [
    {
      value: 0,
      name: 'Personal Details',
    },
    {
      value: 1,
      name: 'Work Experience',
    },
    {
      value: 2,
      name: 'Education',
    },
    {
      value: 3,
      name: 'Project',
    },
    {
      value: 4,
      name: 'Skill',
    },
  ];

  useEffect(() => {
    if (!noHeader) {
      setCvStep(0);
    }
    async function fetchData() {
      setIsLoading(true);
      await getCvDetail(id);
      const content = {
        id,
        type: 'multi',
      };
      await axios
        .post(
          'https://opykx6wgj0.execute-api.ap-southeast-1.amazonaws.com/v1/printResume',
          content,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then((response) => {
          setUrlTemp(response.data);
          const blob = b64toBlob(response.data, 'application/pdf');
          setBlobData(blob);
          setIsLoading(false);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = async (e) => {
    e.preventDefault();
    download(blobData, `AkselResume-${cvDetail.name}.pdf`, 'application/pdf');
    setModalDownload(false);

    if (!(await checkIsFeedbackExist('cvmaker'))) {
      openCVRating();
    } else if (!(await checkIsFeedbackExist('general'))) {
      openGeneralRating();
    }
  };

  const handleShare = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1500);
  };

  const handleChangeSlider = (e, val) => {
    setScale(val / 20 + 1);
    setTemFlag(true);
    setTimeout(() => {
      setTemFlag(false);
    }, 200);
  };

  const handleChangeZoom = (type) => {
    if (type === 'in') {
      setScale(scale + 1);
    } else {
      setScale(scale - 1);
    }
    setTemFlag(true);
    setTimeout(() => {
      setTemFlag(false);
    }, 200);
  };

  const handleMenu = (val) => {
    setActiveMenu({ [val]: !activeMenu[val] });
  };

  const handleMenuEdit = (stepId) => {
    setCvStep(stepId);
    navigate(`/app/cv/builder/${id}`);
  };

  const handleMore = (e) => {
    setAnchorEl(e.currentTarget);
    setMenuOpen(!menuOpen);
    setName(cvDetail.name);
  };

  const handleSave = async () => {
    if (!name) return;

    cvDetail.name = name;
    updateCvDetail(cvDetail);
    setRenameOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const handleDuplicate = async () => {
    const newId = uuid();
    setMenuOpen(false);
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    const resume = {
      ...cvDetail,
      id: newId,
      name: `${cvDetail.name} Copy`,
      preview,
      createdAt,
      updatedAt: createdAt,
    };

    firebase.database().ref(`resumes/${newId}`).set(resume);
    setAlertOpen(true);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const PdfPagination = () => (
    <Grid container justifyContent='center' style={{ margin: '10px 0' }}>
      <Grid item>
        <IconButton
          onClick={() => setPageNumber(pageNumber - 1)}
          disabled={pageNumber <= 1}
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant='sm'>
          {pageNumber} of {numPages}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => setPageNumber(pageNumber + 1)}
          disabled={pageNumber >= numPages}
        >
          <NavigateNextIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const DownloadButton = () => (
    <CustomButton
      color='tertiary'
      sx={{ padding: 0 }}
      disabled={!blobData}
      onClick={() => setModalDownload(true)}
    >
      <div style={{ display: 'flex' }}>
        <DownloadIcon size={35} />
        <Typography
          variant='button'
          sx={{ textTransform: 'none', margin: 'auto' }}
        >
          Download
        </Typography>
      </div>
    </CustomButton>
  );

  const CvReviewButton = () => (
    <CustomButton
      variant='contained'
      color='secondary'
      disabled={!blobData}
      sx={{ padding: 0 }}
      onClick={() => setModalReview(true)}
      // target='_blank'
      // href='https://bit.ly/CVreviewAksel'
    >
      <>
        <IconButton sx={{ padding: 0 }}>
          <ProductAkselIcon />
        </IconButton>
        &nbsp; Ask for CV Review
      </>
    </CustomButton>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ width: '100%', marginBottom: '92px' }}>
          {/* header */}
          <Paper
            className={classes.navTitle}
            style={noHeader ? { display: 'none' } : null}
          >
            <IconButton onClick={() => setModalAlert(true)}>
              <KeyboardBackspaceIcon sx={{ color: '#000' }} fontSize='large' />
            </IconButton>
            <Typography
              variant='subHeader14'
              sx={{
                margin: tabletView ? 'auto auto auto 1rem' : 'auto',
                color: '#000',
              }}
            >
              {cvDetail.name}
            </Typography>
            {!tabletView && (
              <IconButton onClick={(e) => handleMore(e)}>
                <MoreHorizIcon sx={{ color: '#BDBDBD' }} fontSize='large' />
              </IconButton>
            )}

            {tabletView && (
              <div style={{ display: 'flex' }}>
                <DownloadButton />
                <div style={{ marginRight: '1rem' }}></div>
                <CvReviewButton />
              </div>
            )}
          </Paper>

          {/* content */}
          <Container>
            {noHeader && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  maxWidth: '330px',
                  margin: 'auto',
                  marginBottom: '22px',
                }}
              >
                <div style={{ maxWidth: '80px !important' }}>
                  <PdfPagination />
                </div>
                <DownloadButton />
              </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {!noHeader && (
                <div
                  className='edit-tablet'
                  style={tabletView ? {} : { display: 'none' }}
                >
                  <Typography variant='button' sx={{ textTransform: 'none' }}>
                    Edit Section
                  </Typography>
                  <div style={{ marginBottom: '32px' }}></div>
                  {menuEdit.map((mEdit) => (
                    <div key={mEdit.value} style={{ textAlign: 'center' }}>
                      <CustomButton
                        sx={{
                          width: '227px',
                          borderRadius: '80px !important',
                          marginBottom: '16px',
                        }}
                        onClick={() => handleMenuEdit(mEdit.value)}
                      >
                        <Typography variant='pBold'>{mEdit.name}</Typography>
                      </CustomButton>
                    </div>
                  ))}
                </div>
              )}
              <div style={{ margin: 'auto' }}>
                <div className='container-preview'>
                  {!tempFlag && (
                    <TransformWrapper
                      onZoom={(ref, e) => {
                        setScale(ref.state.scale);
                      }}
                      wheel={{ step: 0.1 }}
                      maxScale={6}
                      initialScale={scale}
                      initialPositionX={0}
                      minPositionX={0}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <TransformComponent>
                          <Document
                            file={`data:application/pdf;base64,${urlTemp}`}
                            renderMode='svg'
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page scale={0.5} pageNumber={pageNumber} />
                          </Document>
                        </TransformComponent>
                      )}
                    </TransformWrapper>
                  )}
                </div>
                {!noHeader && <PdfPagination />}
                <div className={classes.zoom}>
                  <IconButton
                    style={{
                      margin: 'auto',
                      marginRight: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleChangeZoom('out')}
                    disabled={scale < 2}
                  >
                    <ZoomOutIcon size={24} />
                  </IconButton>
                  <AkselSlider
                    value={(scale - 1) * 20}
                    onChange={handleChangeSlider}
                  />
                  <IconButton
                    style={{
                      margin: 'auto',
                      marginLeft: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleChangeZoom('in')}
                    disabled={scale > 5}
                  >
                    <ZoomInIcon size={24} />
                  </IconButton>
                </div>
              </div>
            </div>

            {!noHeader && (
              <div
                style={{
                  display: tabletView ? 'none' : 'flex',
                  justifyContent: 'space-evenly',
                  margin: 'auto',
                  maxWidth: '400px',
                }}
              >
                <CvReviewButton />
                <IconButton
                  sx={{ padding: 0 }}
                  disabled={!blobData}
                  onClick={() => setModalDownload(true)}
                >
                  <DownloadIcon size={45} />
                </IconButton>
              </div>
            )}
          </Container>
        </div>
      )}

      {!noFooter && (
        <MenuBottom
          handleMenu={handleMenu}
          activeMenu={activeMenu}
          noDisplay={tabletView}
        />
      )}

      <CustomDialog
        open={modalDownload}
        onClose={() => setModalDownload(false)}
        isCustomComponent
      >
        <DialogDownload
          handleDownload={handleDownload}
          handleShare={handleShare}
          cvLink={`https://cv.aksel.co.id/r/${id}`}
          tooltipOpen={tooltipOpen}
        />
      </CustomDialog>

      <CustomDialog
        title='Do you want to leave?'
        description='No worries! You can still continue editing your CV through the dashboard'
        open={modalAlert}
        onClose={() => setModalAlert(false)}
        btnSubmitTxt='Back to dashboard'
        btnCancelTxt='Continue edit CV'
        handleSubmit={() => navigate(`/app/cv/dashboard`)}
      />

      <CustomDialog
        title='Name your CV'
        open={renameOpen}
        onClose={() => setRenameOpen(false)}
        handleSubmit={handleSave}
        btnSubmitTxt={'Save'}
      >
        <TextField
          autoFocus
          margin='dense'
          label='Your CV’s name'
          fullWidth
          onChange={(e) => setName(e.target.value)}
          defaultValue={cvDetail.name}
          required
        />
      </CustomDialog>

      <CustomDialog
        title=''
        open={popUpOpen === '1'}
        onClose={() => {
          setPopUpOpen('0');
          localStorage.setItem('bannerCv', '0');
        }}
        handleSubmit={() => {
          setPopUpOpen('0');
          localStorage.setItem('bannerCv', '0');
        }}
        btnSubmitTxt={'Close'}
      >
        <div style={{ width: '100%', padding: '1rem' }}>
          <img
            src={popUpCvMaker}
            alt='banner-mentoring'
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => {
              reactGA.event({
                category: 'Pop Up',
                action: 'User Click Cv Maker to Mentoring',
                label: user.uid,
              });
              navigate('/app/mentoring');
            }}
          />
        </div>
      </CustomDialog>

      <CustomDrawer
        title='Select a section'
        open={activeMenu.edit}
        onClose={() => handleMenu('edit')}
      >
        {menuEdit.map((mEdit) => (
          <div key={mEdit.value} style={{ textAlign: 'center' }}>
            <CustomButton
              sx={{
                width: '327px',
                borderRadius: '80px !important',
                marginBottom: '16px',
              }}
              onClick={() => handleMenuEdit(mEdit.value)}
            >
              <Typography variant='pBold'>{mEdit.name}</Typography>
            </CustomButton>
          </div>
        ))}
        <div style={{ paddingBottom: '8px' }} />
      </CustomDrawer>

      <CustomDrawer
        title='Pick a template'
        open={activeMenu.template}
        onClose={() => handleMenu('template')}
      >
        <div style={{ paddingBottom: '8px' }} />
      </CustomDrawer>

      <CustomDrawer
        title='Adjust layout'
        open={activeMenu.layout}
        onClose={() => handleMenu('layout')}
      >
        <div style={{ paddingBottom: '8px' }} />
      </CustomDrawer>

      <CustomDrawer
        title='Pick a color'
        open={activeMenu.color}
        onClose={() => handleMenu('color')}
      >
        <div style={{ paddingBottom: '8px' }} />
      </CustomDrawer>

      <CustomDrawer
        title='Adjust text & paragraph'
        open={activeMenu.text}
        onClose={() => handleMenu('text')}
      >
        <div style={{ paddingBottom: '8px' }} />
      </CustomDrawer>

      <CustomPopover
        open={menuOpen}
        anchorEl={anchorEl}
        handleClose={() => setMenuOpen(false)}
        listMenu={[
          {
            label: 'Rename',
            handle: () => {
              setRenameOpen(true);
              setMenuOpen(false);
            },
          },
          {
            label: 'Duplicate',
            handle: handleDuplicate,
          },
          {
            label: 'Export',
            handle: () => {
              setModalDownload(true);
              setMenuOpen(false);
            },
          },
        ]}
      />

      <DialogCvReview
        open={modalReview}
        handleClose={() => setModalReview(false)}
        data={cvDetail}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        message='This cv has been successfully duplicated'
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ cvDetail: state.cv.cvDetail });

export default connect(mapStateToProps)(CvPreview);
