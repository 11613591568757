import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';

import { Divider, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import DragNDropList from 'src/components/cvMaker/DragNDropList';
import SkillCard from 'src/components/cvMaker/skill/SkillCard';
import SkillForm from 'src/components/cvMaker/skill/SkillForm';
import { updateCvDetail } from 'src/redux/actions';
import CustomButton from '../button';

const Skill = ({ cvDetail }) => {
  const [skills, setSkills] = useState(cvDetail.skills);
  const [dataDetail, setDataDetail] = useState({});
  const [formOpen, setFormOpen] = useState(false);

  const handleAdd = (values, type) => {
    let newSkill = {};
    if (type === 'edit') {
      const customSkl = { ...skills };
      customSkl.items.forEach((skl) => {
        if (skl.id === values.id) {
          skl.id = values.id;
          skl.level = values.level || '';
          skl.name = values.name || '';
        }
      });
      newSkill = customSkl;
    } else {
      if (skills.items) {
        newSkill = {
          ...skills,
          items: [...skills.items, { ...values, id: uuid() }].sort((a, b) => {
            return new Date(b.endDate) - new Date(a.endDate);
          }),
        };
      } else {
        const newItems = [];
        newItems.push({ ...values, id: uuid() });
        newSkill = { ...skills, items: newItems };
      }
    }

    cvDetail['skills'] = newSkill;
    updateCvDetail(cvDetail);
    setSkills(newSkill);
    setFormOpen(false);
  };

  const handleEdit = (d) => {
    setDataDetail(d);
    setFormOpen(true);
  };

  const handleDelete = (id) => {
    const newSkill = {
      ...skills,
      items: skills.items.filter((skill) => skill.id !== id),
    };
    cvDetail['skills'] = newSkill;
    updateCvDetail(cvDetail);
    setSkills(newSkill);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(skills.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newSkill = {
      ...skills,
      items,
    };
    cvDetail['skills'] = newSkill;
    updateCvDetail(cvDetail);
    setSkills(newSkill);
  };

  return (
    <>
      {!formOpen && (
        <>
          <Typography
            variant='sectionHeader'
            style={{ margin: '1rem 0 0.5rem 0' }}
          >
            {skills.heading}
          </Typography>
          <Divider style={{ color: '#E8E8E8' }} />

          {/* <NormalList items={skills?.items}> */}
          <DragNDropList
            handleOnDragEnd={handleOnDragEnd}
            items={skills?.items}
            setItems={setSkills}
          >
            <SkillCard handleDelete={handleDelete} handleEdit={handleEdit} />
          </DragNDropList>
          {/* </NormalList> */}
          <br />
          <CustomButton
            onClick={() => {
              setFormOpen(true);
              setDataDetail({});
            }}
            color='grey'
            startIcon={<Add />}
            fullWidth
          >
            Add another skill
          </CustomButton>
          {/* {!isMobile && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '70px',
              }}
            >
              <CustomButton
                variant='contained'
                color='primary'
                onClick={() => navigate(`/app/cv/preview/${cvDetail.id}`)}
              >
                Finish
              </CustomButton>
            </div>
          )} */}
        </>
      )}

      {formOpen && (
        <SkillForm
          skills={skills}
          handleAdd={handleAdd}
          setSkills={setSkills}
          dataDetail={dataDetail}
          handleClose={() => setFormOpen(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cvDetail: state.cv.cvDetail,
});

export default connect(mapStateToProps)(Skill);
