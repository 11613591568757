const voucherCode = [
  // {
  //   code: 'REGI10',
  //   used: false,
  //   description: 'Lorem ipsum si dolor amet',
  //   type: 'cv-review',
  //   until: '06-01-2022',
  //   discount: 0.1,
  //   public: false,
  // },
  {
    code: 'REGI10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '03-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'ELSA10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '03-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'ULI10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '03-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'PIYU10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '03-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'PAD20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'AZMI20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'AYU20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'MAI20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'NANA20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'FIQH20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'EMMA20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'HACI20',
    used: false,
    type: 'mentoring',
    until: '02-02-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'ABID10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '03-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'DIORZKY10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'EDWARD10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'CITRAZNR10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'JEREMYVM10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'SAMUELDW10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'PRIMASB10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'FABIAN10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'GOPALA10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'KENNYHTM10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'BETHARI10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'KENNYIA10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'DINARSB10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'KALYANA10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'JOEWILL10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'NADIRASM10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'CHARLIED10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'ELMYRANK10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'DWIKEAC10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'MRIDHO10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'MRIZQI10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'YOGIAP10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'ARYUNI10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'GSHANNEN10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'DANIELAS10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'YURICOIB10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-01-2022',
    discount: 0.1,
    public: false,
  },
  {
    code: 'KENT10',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'mentoring',
    until: '01-12-2022',
    discount: 0.1,
    public: false,
  },
  // {
  //   code: 'DISKONATS40VEP',
  //   used: false,
  //   description: 'Lorem ipsum si dolor amet',
  //   type: 'atsc',
  //   until: '01-02-2022',
  //   discount: 0.4,
  // },
  // {
  //   code: 'ATSC30',
  //   used: false,
  //   description: 'Lorem ipsum si dolor amet',
  //   type: 'atsc',
  //   until: '26-04-2022',
  //   discount: 0.3,
  // },
  {
    code: 'AIESECUB35',
    used: false,
    description: 'Lorem ipsum si dolor amet',
    type: 'cv-review',
    until: '2022-06-25',
    discount: 0.35,
    appliesTo: ['7-days'],
  },
  {
    code: 'AkselxInitiatee',
    used: false,
    description: 'ATS Checker Paket Ekonomis',
    type: 'atsc',
    until: '2022-07-31',
    discount: 4000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['pkg-ekonomis'],
  },
  {
    code: 'AkselxHMPSMPUNS',
    used: false,
    description: 'ATS Checker Paket Ekonomis',
    type: 'atsc',
    until: '2022-05-31',
    discount: 4000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['pkg-ekonomis'],
  },
  {
    code: 'AKSELXRG',
    used: false,
    description: 'voucher 5000 for all',
    type: 'atsc',
    until: '2022-05-28',
    discount: 5000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['pkg-ekonomis', 'pkg-pas', 'pkg-super'],
  },
  {
    code: 'AKSELXRG',
    used: false,
    description: 'voucher 5000 for all',
    type: 'cv-review',
    until: '2022-05-28',
    discount: 5000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['7-days', '3-days'],
  },
  {
    code: 'AKSELXRG',
    used: false,
    description: 'voucher 5000 for all',
    type: 'mentoring',
    until: '2022-05-28',
    discount: 5000,
    public: false,
  },
  {
    code: 'AkselxIISE2022',
    used: false,
    description: 'voucher 5000 for all',
    type: 'atsc',
    until: '2022-06-30',
    discount: 4000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['pkg-ekonomis'],
  },
  {
    code: 'AKSELXSALT',
    used: false,
    description: 'voucher 5000 for all',
    type: 'atsc',
    until: '2022-06-03',
    discount: 5000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['pkg-ekonomis', 'pkg-pas', 'pkg-super'],
  },
  {
    code: 'AKSELXSALT',
    used: false,
    description: 'voucher 5000 for all',
    type: 'cv-review',
    until: '2022-06-03',
    discount: 5000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['7-days', '3-days'],
  },
  {
    code: 'AKSELXSALT',
    used: false,
    description: 'voucher 5000 for all',
    type: 'mentoring',
    until: '2022-06-03',
    discount: 5000,
    public: false,
  },
  {
    code: 'AkselxHMIKUNDIP',
    used: false,
    description: 'voucher 4000 package ekonomis',
    type: 'atsc',
    until: '2022-06-30',
    discount: 4000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['pkg-ekonomis'],
  },
  {
    code: 'AkselxProduktifkuy',
    used: false,
    description: 'voucher 4000 package ekonomis',
    type: 'atsc',
    until: '2022-07-07',
    discount: 4000, // kalau diatas 1.0 itu dianggap potongan langsung
    appliesTo: ['pkg-ekonomis'],
  },
];

export default voucherCode;
