export const bundlingAtsc = [
  {
    id: 'pkg-ekonomis',
    name: 'Paket Ekonomis',
    price: 18000,
    discountPrice: 3000,
    desc: '1x Penggunaan',
    duration: 4,
  },
  {
    id: 'pkg-pas',
    name: 'Paket Pas',
    price: 34000,
    discountPrice: 6000,
    desc: '3x Penggunaan',
    duration: 8,
  },
  {
    id: 'pkg-super',
    name: 'Paket Super',
    price: 49000,
    discountPrice: 9000,
    desc: '5x Penggunaan',
    duration: 14,
  },
];

// export default bundling;
