import { useState, useEffect } from 'react';
import {
  IconButton,
  CircularProgress,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  useMediaQuery,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import reactGA from 'react-ga';

import CustomButton from 'src/components/button';
import CustomDialog from 'src/components/cvMaker/CustomDialog';
import CustomStepper from 'src/components/cvMaker/Stepper';
import PersonalDetail from 'src/components/cvMaker/PersonalDetail';
import WorkExperience from 'src/components/cvMaker/WorkExperience';
import Education from 'src/components/cvMaker/Education';
import Project from 'src/components/cvMaker/Project';
import Skill from 'src/components/cvMaker/Skill';
import CvPreview from 'src/pages/cvMaker/preview/index';
import { BackIcon, PreviewIcon } from 'src/assets/icons/CvMaker';
import { getCvDetail, setCvStep, setNavbarTitle } from 'src/redux/actions';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed

function getSteps() {
  return [
    '1. Personal Details',
    '2. Work Experience',
    '3. Education',
    '4. Projects',
    '5. Skill',
  ];
}

const headers = [
  'Tell us about yourself!',
  'Let’s fill out your work experience!',
  'Now, tell us about your education!',
  'What projects have you worked on?',
  'Last, what skills do you have?',
];

const Builder = ({ cvDetail, cvStep }) => {
  setNavbarTitle('CV Maker');
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalExperience, setModalExperience] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [b64, setB64] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const user = JSON.parse(localStorage.getItem('user'));
  const steps = getSteps();

  const isMobile = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    // setCvStep(0);

    reactGA.event({
      category: 'CV Maker',
      action: 'User Edit CV',
      label: user.uid,
    });

    (async () => {
      setLoading(true);
      const data = await getCvDetail(id);
      if (!data.id) {
        navigate(`/app/cv/dashboard`);
        alert('resume not exist');
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    if (cvStep === steps.length - 1) {
      navigate(`/app/cv/preview/${id}`);
    } else if (
      cvStep === 1 &&
      (!cvDetail.work.items || !cvDetail.work.items.length)
    ) {
      setModalExperience(true);
    } else {
      setCvStep(cvStep + 1);
    }
  };

  const handleBack = () => {
    if (cvStep === 0) {
      setModalAlert(true);
    } else {
      setCvStep(cvStep - 1);
    }
  };

  const handleModalSubmit = () => {
    setCvStep(1);
    setModalExperience(false);
  };

  const handleModalClose = (event, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return;
    }

    setCvStep(cvStep + 1);
    setModalExperience(false);
  };

  const handleOpen = async (type) => {
    setOpen(!open);
    if (type === 'render') {
      setModalLoading(true);
      const content = {
        id: id,
        type: 'multi',
      };
      await axios
        .post(
          'https://opykx6wgj0.execute-api.ap-southeast-1.amazonaws.com/v1/printResume',
          content,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then((response) => {
          setB64(response.data);
          setModalLoading(false);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const renderedComponent = () => {
    switch (cvStep) {
      case 0:
        return <PersonalDetail />;
      case 1:
        return <WorkExperience />;
      case 2:
        return <Education />;
      case 3:
        return <Project />;
      case 4:
        return <Skill />;
      default:
        return null;
    }
  };

  const NavigateButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '70px',
        }}
      >
        <CustomButton variant='contained' color='primary' onClick={handleNext}>
          {cvStep === steps.length - 1
            ? 'Finish'
            : cvStep === 0
            ? 'Save Personal Details'
            : 'Next Step'}
        </CustomButton>
        <CustomButton
          variant='outlined'
          sx={{
            borderStyle: 'none',
            borderColor: '#fff !important',
            margin: '8px 0 32px 0',
            // color: '#BDBDBD !important',
          }}
          onClick={handleBack}
        >
          <IconButton sx={{ padding: '0 10px 2px 0' }}>
            <BackIcon color='#1D2D5E' />
          </IconButton>
          Previous Step
        </CustomButton>
      </div>
    );
  };

  return (
    <CustomStepper header={headers[cvStep]} steps={steps} activeStep={cvStep}>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && isMobile && (
        <IconButton
          sx={{ padding: '0 !important', marginTop: '22px' }}
          onClick={() => handleOpen('render')}
        >
          <PreviewIcon />
        </IconButton>
      )}
      {!loading && (
        <Grid container>
          <Grid item xs={isMobile ? 12 : 7}>
            {renderedComponent()}
            {!isMobile && <NavigateButton />}
          </Grid>
          {!isMobile && (
            <Grid item xs={5} sx={{ mt: '1rem' }}>
              <CvPreview noHeader noFooter />
            </Grid>
          )}
        </Grid>
      )}

      <CustomDialog
        title='Don’t have work experience?'
        description='We suggest you to adding volunteer or internship experience.'
        open={modalExperience}
        onClose={handleModalClose}
        btnSubmitTxt='+ Add experience'
        btnCancelTxt='Skip for now'
        handleSubmit={handleModalSubmit}
      />

      <CustomDialog
        title='Do you want to leave?'
        description='No worries! You can still continue editing your CV through the dashboard'
        open={modalAlert}
        onClose={() => setModalAlert(false)}
        btnSubmitTxt='Back to dashboard'
        btnCancelTxt='Continue edit CV'
        handleSubmit={() => navigate(`/app/cv/dashboard`)}
      />

      <Dialog
        onClose={handleOpen}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
        style={{ width: 'max-content', margin: 'auto' }}
      >
        {modalLoading ? (
          <span style={{ padding: '2rem' }}>
            <CircularProgress />
          </span>
        ) : (
          <DialogContent sx={{ padding: '12px !important' }}>
            <IconButton
              aria-label='close'
              sx={{
                padding: 0,
                position: 'absolute',
                right: '16px',
                zIndex: 10,
              }}
              onClick={handleOpen}
            >
              <CloseIcon />
            </IconButton>
            <TransformWrapper
              wheel={{ step: 0.1 }}
              maxScale={6}
              initialScale={1}
              initialPositionX={0}
              minPositionX={0}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <TransformComponent>
                  <Document
                    file={`data:application/pdf;base64,${b64}`}
                    renderMode='svg'
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page scale={0.5} pageNumber={pageNumber} />
                  </Document>
                </TransformComponent>
              )}
            </TransformWrapper>
            <Grid container justifyContent='center'>
              <Grid item>
                <IconButton
                  onClick={() => setPageNumber(pageNumber - 1)}
                  disabled={pageNumber <= 1}
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='sm'>
                  {pageNumber} of {numPages}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => setPageNumber(pageNumber + 1)}
                  disabled={pageNumber >= numPages}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>

      {isMobile && <NavigateButton />}
    </CustomStepper>
  );
};

const mapStateToProps = (state) => ({
  cvDetail: state.cv.cvDetail,
  cvStep: state.cv.cvStep,
});

export default connect(mapStateToProps)(Builder);
