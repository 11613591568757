import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CustomButton from 'src/components/button';
import CustomInput from 'src/components/cvMaker/CustomInput';

function TabPanel({
  children,
  value,
  index,
  title,
  description,
  handleSubmit,
  btnSubmitTxt,
  cvLink,
  tooltipOpen,
  ...other
}) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography
            variant='sectionHeader'
            sx={{ mt: '24px', textAlign: 'center' }}
          >
            {title}
          </Typography>
          {description}

          {children}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '16px',
              marginTop: '24px',
            }}
          >
            {cvLink && (
              <CopyToClipboard text={cvLink} onCopy={handleSubmit}>
                <Tooltip
                  open={tooltipOpen}
                  title={'Copied to clipboard!'}
                  leaveDelay={1500}
                >
                  <CustomButton sx={{ padding: '6px 0', width: '240px' }}>
                    {btnSubmitTxt || 'save'}
                  </CustomButton>
                </Tooltip>
              </CopyToClipboard>
            )}

            {!cvLink && (
              <CustomButton
                sx={{ padding: '6px 0', width: '240px' }}
                onClick={handleSubmit}
              >
                {btnSubmitTxt || 'save'}
              </CustomButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function DialogDownload({
  handleDownload,
  handleShare,
  cvLink,
  tooltipOpen,
  buttonLoading,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position="static"> */}
      <Tabs value={value} onChange={handleChange} centered>
        <Tab
          label={
            <Typography variant='smBold' sx={{ textTransform: 'none' }}>
              Download
            </Typography>
          }
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Typography variant='smBold' sx={{ textTransform: 'none' }}>
              Share
            </Typography>
          }
          {...a11yProps(1)}
        />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel
        value={value}
        index={0}
        title='Download your CV'
        description={
          <Typography variant='p' sx={{ textAlign: 'center', mb: '16px' }}>
            Your CV is ready to be download. <br />
            Hope it helps you!
          </Typography>
        }
        btnSubmitTxt={buttonLoading ? 'Generate your CV...' : 'Download CV'}
        handleSubmit={handleDownload}
      />
      <TabPanel
        value={value}
        index={1}
        title='Share your CV'
        description={
          <Typography variant='p' sx={{ textAlign: 'center', mb: '16px' }}>
            Your CV is ready to be share. <br />
            Hope it helps you!
          </Typography>
        }
        btnSubmitTxt='Copy Link'
        handleSubmit={handleShare}
        cvLink={cvLink}
        tooltipOpen={tooltipOpen}
      >
        <CustomInput label=' ' name=' ' disabled value={cvLink} />
      </TabPanel>
    </div>
  );
}
