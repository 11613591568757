import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';

import { Divider, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import DragNDropList from 'src/components/cvMaker/DragNDropList';
import EducationCard from 'src/components/cvMaker/education/EducationCard';
import EducationForm from 'src/components/cvMaker/education/EducationForm';
import { updateCvDetail } from 'src/redux/actions';
import CustomButton from '../button';

const Education = ({ cvDetail }) => {
  const { education } = cvDetail;
  const [educations, setEducations] = useState(education);
  const [dataDetail, setDataDetail] = useState({});
  const [formOpen, setFormOpen] = useState(false);

  const handleAdd = (values, type) => {
    let newEducation = {};
    if (type === 'edit') {
      const customEdu = { ...educations };
      customEdu.items.forEach((edu) => {
        if (edu.id === values.id) {
          edu.degree = values.degree || '';
          edu.endDate =
            values.endDate || new Date().toISOString().split('T')[0];
          edu.field = values.field || '';
          edu.gpa = values.gpa || '';
          edu.id = values.id || '';
          edu.institution = values.institution || '';
          edu.startDate =
            values.startDate || new Date().toISOString().split('T')[0];
          edu.summary = values.summary || '';
        }
      });
      newEducation = customEdu;
    } else {
      if (educations.items) {
        newEducation = {
          ...educations,
          items: [...educations.items, { ...values, id: uuid() }].sort(
            (a, b) => {
              return new Date(b.endDate) - new Date(a.endDate);
            },
          ),
        };
      } else {
        const newItems = [];
        newItems.push({ ...values, id: uuid() });
        newEducation = { ...educations, items: newItems };
      }
    }
    cvDetail['education'] = newEducation;
    updateCvDetail(cvDetail);
    setEducations(newEducation);
    setFormOpen(false);
  };

  const handleEdit = (d) => {
    setDataDetail(d);
    setFormOpen(true);
  };

  const handleDelete = (id) => {
    const newEducation = {
      ...educations,
      items: educations.items.filter((education) => education.id !== id),
    };
    cvDetail['education'] = newEducation;
    updateCvDetail(cvDetail);
    setEducations(newEducation);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(educations.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newEducation = {
      ...educations,
      items,
    };
    cvDetail['education'] = newEducation;
    updateCvDetail(cvDetail);
    setEducations(newEducation);
  };

  return (
    <>
      {!formOpen && (
        <>
          <Typography
            variant='sectionHeader'
            style={{ margin: '1rem 0 0.5rem 0' }}
          >
            {educations.heading}
          </Typography>
          <Divider style={{ color: '#E8E8E8' }} />

          <DragNDropList
            handleOnDragEnd={handleOnDragEnd}
            items={educations?.items}
            setItems={setEducations}
          >
            <EducationCard
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </DragNDropList>
          <br />
          <CustomButton
            onClick={() => {
              setFormOpen(true);
              setDataDetail({});
            }}
            color='grey'
            startIcon={<Add />}
            fullWidth
          >
            Add another education
          </CustomButton>
        </>
      )}
      {formOpen && (
        <EducationForm
          educations={educations}
          handleAdd={handleAdd}
          setEducations={setEducations}
          dataDetail={dataDetail}
          handleClose={() => setFormOpen(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cvDetail: state.cv.cvDetail,
});

export default connect(mapStateToProps)(Education);
