import college from 'src/assets/img/landing/college.png';
import group from 'src/assets/img/landing/group.png';
import task1 from 'src/assets/img/landing/task1.png';
import navigation from 'src/assets/img/landing/navigation.png';
import book from 'src/assets/img/landing/book.png';
import done from 'src/assets/img/landing/done.png';
import chat from 'src/assets/img/landing/chat.png';
import share from 'src/assets/img/landing/share.png';

const mentoringServices = [
  {
    id: 1,
    title: 'College Life Sharing',
    body: `Belajar dari si high achiever dan dapatkan bocoran untuk menjadi salah satu dari mereka!`,
    icon: college,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
  {
    id: 2,
    title: 'Professional Life Sharing',
    body: 'Belajar dari si mentor sukses dan dapatkan bimbingan karier sesuai cita-citamu!',
    icon: group,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
  {
    id: 3,
    title: 'Internship Preparation',
    body: 'Maksimalkan persiapan magangmu dengan si mentor keren untuk dapatkan posisi impianmu!',
    icon: task1,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
  {
    id: 4,
    title: 'Mentor’s Career Path Exploration',
    body: 'Eksplorasikan profesi dan perusahaan si mentor untuk mengetahui pilihan terbaikmu!',
    icon: navigation,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
  {
    id: 5,
    title: 'Knowledge and Skill Preparation',
    body: 'Gali pengetahuan dan kemampuan spesifik yang kamu butuhkan dari si mentor untuk persiapan kariermu!',
    icon: book,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
  {
    id: 6,
    title: 'CV Preparation & Feedback',
    body: 'Dapatkan masukan langsung dari si mentor untuk cek kesiapan CV kamu dalam melamar posisi impianmu!',
    icon: done,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
  {
    id: 7,
    title: 'Interview Preparation & Mock-up',
    body: 'Siapkan interview kamu dengan si mentor profesional untuk hasil yang maksimal!',
    icon: chat,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
  {
    id: 8,
    title: 'Opportunity and Network Sourcing',
    body: 'Dapatkan koneksi, informasi, job referral, dan bimbingan terkait job opportunity yang dapat kamu butuh!',
    icon: share,
    pushType: 'app',
    pushTo: '/app/mentoring',
  },
];

export default mentoringServices;
