import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DatePicker from '@material-ui/lab/DatePicker';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#5BC0EB',
      },
    },
  },
}));

const CustomInput = ({ children, label, name, onChange, value, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      <Typography>{label}</Typography>
      <DatePicker
        value={value}
        name={name}
        onChange={onChange}
        inputFormat='DD/MM/YYYY'
        renderInput={(params) => <TextField {...params} />}
        style={{ marginTop: '0.4rem', borderRadius: '6px' }}
        className={classes.root}
        {...rest}
      />
    </>
  );
};

export default CustomInput;
