import { useState } from 'react';
import { format } from 'date-fns';

import { Checkbox, FormControlLabel, Grid, MenuItem } from '@material-ui/core';

import CustomButton from 'src/components/button';
import CustomInput from 'src/components/cvMaker/CustomInput';
import CustomDatePicker from 'src/components/cvMaker/CustomDatePicker';

const EducationForm = ({
  educations,
  dataDetail,
  handleAdd,
  setEducations,
  handleClose,
}) => {
  const [values, setValues] = useState(dataDetail);

  const [isStudyHere, setIsStudyHere] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsStudyHere(!isStudyHere);

    if (event.target.checked) {
      setValues({
        ...values,
        endDate: format(new Date(), 'yyyy-MM-dd'),
      });
    }
  };

  const handleHeadingChange = (event) => {
    setEducations({
      ...educations,
      heading: event.target.value,
    });
  };

  const handleDateChange = (date, name) => {
    setValues({
      ...values,
      [name]: format(new Date(date), 'yyyy-MM-dd'),
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container spacing={1} marginTop={2}>
      <Grid item xs={12}>
        <CustomInput
          label='Heading'
          name='heading'
          onChange={handleHeadingChange}
          value={educations.heading}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomInput
          label='Institution'
          name='institution'
          onChange={handleChange}
          value={values.institution}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Field of Study'
          name='field'
          onChange={handleChange}
          value={values.field}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomInput
          label='Type of Degree'
          name='degree'
          select
          onChange={handleChange}
          value={values.degree}
        >
          <MenuItem value='High School'>High School</MenuItem>
          <MenuItem value='Associate'>Associate Degree</MenuItem>
          <MenuItem value='Bachelors'>Bachelor's Degree</MenuItem>
          <MenuItem value='Masters'>Master's Degree</MenuItem>
          <MenuItem value='Doctoral'>Doctoral Degree</MenuItem>
        </CustomInput>
      </Grid>
      <Grid item xs={6}>
        <CustomInput
          label='GPA'
          name='gpa'
          onChange={handleChange}
          value={values.gpa}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          label='Start Date'
          name='startDate'
          onChange={(date) => handleDateChange(date, 'startDate')}
          value={values.startDate}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          label='End Date'
          name='endDate'
          onChange={(date) => handleDateChange(date, 'endDate')}
          value={values.endDate}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isStudyHere}
              onChange={handleCheckboxChange}
              name='isStudyHere'
              color='primary'
              size='small'
            />
          }
          label='I currently study here'
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label='Achievements and activities'
          name='summary'
          onChange={handleChange}
          value={values.summary}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={() => {
            handleAdd(values, dataDetail.id ? 'edit' : 'add');
          }}
          fullWidth
        >
          Save Education
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          fullWidth
        >
          Cancel
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default EducationForm;
