import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  Collapse,
  IconButton,
  DialogActions,
  DialogTitle,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from 'src/components/button';

const DialogSort = ({
  handleModalOpenSort,
  modalOpenSort,
  handleResetSort,
  countSort,
  expanded3,
  reset,
  sortMentor,
  handleChange,
  handleSubmitSort,
  handleExpandClick,
  defaultValue,
}) => {
  return (
    <Dialog
      onClose={handleModalOpenSort}
      aria-labelledby='customized-dialog-title'
      open={modalOpenSort}
      scroll={'paper'}
      maxWidth={'sm'}
      fullWidth
      sx={{ zIndex: '10000000000 !important' }}
    >
      <DialogTitle id='scroll-dialog-title'>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleModalOpenSort} style={{ padding: '0' }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography gutterBottom variant='subHeader16' sx={{ m: 'auto 0' }}>
            Sort
          </Typography>
          <Button variant='text' onClick={handleResetSort}>
            <Typography
              gutterBottom
              variant='smBold'
              sx={{ cursor: 'pointer' }}
            >
              Hapus Sort
            </Typography>
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='subHeader14' sx={{ m: 'auto 0' }}>
            {`Sort (${countSort})`}
          </Typography>
          <IconButton
            onClick={() => handleExpandClick(4)}
            aria-expanded={expanded3}
            aria-label='show more'
          >
            {expanded3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Collapse in={expanded3}>
          <FormControl component='fieldset'>
            <FormGroup>
              {!reset && (
                <RadioGroup
                  onChange={(e) => handleChange(e, 'sort')}
                  defaultValue={
                    defaultValue[0]
                      ? `${defaultValue[0].value}-${defaultValue[0].sort}`
                      : ''
                  }
                >
                  {sortMentor.map((dataFilter, seq) => (
                    <FormControlLabel
                      key={seq}
                      id={`${dataFilter.name}-${seq}`}
                      value={`${dataFilter.value}-${dataFilter.sort}`}
                      control={<Radio color='primary' />}
                      label={
                        <Typography variant='p'>{dataFilter.name}</Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              )}
            </FormGroup>
          </FormControl>
        </Collapse>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
          }}
        >
          <CustomButton onClick={handleSubmitSort}>Sort Mentor</CustomButton>
        </div> */}
      </DialogContent>
      <DialogActions>
        <div style={{ margin: 'auto', marginTop: '1rem' }}>
          <CustomButton onClick={handleSubmitSort}>Sort Mentor</CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSort;
