import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';

import { Divider, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import DragNDropList from 'src/components/cvMaker/DragNDropList';
import ProjectCard from 'src/components/cvMaker/project/ProjectCard';
import ProjectForm from 'src/components/cvMaker/project/ProjectForm';
import { updateCvDetail } from 'src/redux/actions';
import CustomButton from '../button';

const Project = ({ cvDetail }) => {
  const [projects, setProjects] = useState(cvDetail.projects);
  const [dataDetail, setDataDetail] = useState({});
  const [formOpen, setFormOpen] = useState(false);

  const handleAdd = (values, type) => {
    let newProject = {};
    if (type === 'edit') {
      const customPrj = { ...projects };
      customPrj.items.forEach((prj) => {
        if (prj.id === values.id) {
          prj.date = values.date || new Date().toISOString().split('T')[0];
          prj.endDate =
            values.endDate || new Date().toISOString().split('T')[0];
          prj.id = values.id;
          prj.link = values.link || '';
          prj.summary = values.summary || '';
          prj.title = values.title || '';
        }
      });
      newProject = customPrj;
    } else {
      if (projects.items) {
        newProject = {
          ...projects,
          items: [...projects.items, { ...values, id: uuid() }].sort((a, b) => {
            return new Date(b.endDate) - new Date(a.endDate);
          }),
        };
      } else {
        const newItems = [];
        newItems.push({ ...values, id: uuid() });
        newProject = { ...projects, items: newItems };
      }
    }

    cvDetail['projects'] = newProject;
    updateCvDetail(cvDetail);
    setProjects(newProject);
    setFormOpen(false);
  };

  const handleEdit = (d) => {
    setDataDetail(d);
    setFormOpen(true);
  };

  const handleDelete = (id) => {
    const newProject = {
      ...projects,
      items: projects.items.filter((project) => project.id !== id),
    };

    cvDetail['projects'] = newProject;
    updateCvDetail(cvDetail);
    setProjects(newProject);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(projects.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newProject = {
      ...projects,
      items,
    };
    cvDetail['projects'] = newProject;
    updateCvDetail(cvDetail);
    setProjects(newProject);
  };

  return (
    <>
      {!formOpen && (
        <>
          <Typography
            variant='sectionHeader'
            style={{ margin: '1rem 0 0.5rem 0' }}
          >
            {projects.heading}
          </Typography>
          <Divider style={{ color: '#E8E8E8' }} />

          <DragNDropList
            handleOnDragEnd={handleOnDragEnd}
            items={projects?.items}
            setItems={setProjects}
          >
            <ProjectCard handleDelete={handleDelete} handleEdit={handleEdit} />
          </DragNDropList>
          <br />
          <CustomButton
            onClick={() => {
              setFormOpen(true);
              setDataDetail({});
            }}
            color='grey'
            startIcon={<Add />}
            fullWidth
          >
            Add another project
          </CustomButton>
        </>
      )}

      {formOpen && (
        <ProjectForm
          projects={projects}
          handleAdd={handleAdd}
          setProjects={setProjects}
          dataDetail={dataDetail}
          handleClose={() => setFormOpen(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cvDetail: state.cv.cvDetail,
});

export default connect(mapStateToProps)(Project);
