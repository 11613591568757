import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { usePopper } from 'react-popper';
import ReactToPrint from 'react-to-print';
import { format, parse } from 'date-fns';
import idLocale from 'date-fns/locale/id';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@mui/material/IconButton';
import { setCvStep } from 'src/redux/actions';
import { checkIsFeedbackExist } from 'src/utils/utilities';
import {
  openATSRating,
  openGeneralRating,
} from 'src/redux/actions/rating-actions';

import ResultNote from '../../assets/img/result-note.svg';
import DownloadImg from '../../assets/img/download-img.svg';
import WarningImg from '../../assets/img/warning.png';
import CheckMark from '../../assets/img/check-mark.svg';
import XMark from '../../assets/img/x-mark.svg';
import Tooltip from '../../assets/img/tooltip.svg';
import reUpload from '../../assets/img/reUpload.svg';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#1D2D5E',
    borderRadius: '8px',
    padding: '16px',
    width: '437px',
    color: '#FFFFFF',
  },
}));

const ResultCV = ({ dataATS, setOpen, subs }) => {
  const classes = useStyles();

  let summary = dataATS.sections.filter(
    (data) => data.sectionType === 'Summary',
  );
  let skills = dataATS.sections.filter(
    (data) => data.sectionType === 'Skills/Interests/Languages',
  );
  skills = skills.length > 0 && skills[0].text.replace('SKILLS', '');
  skills = skills.length > 0 && skills.split(',');

  const [checkData, setCheckData] = useState(false);

  const [expandPengalaman, setExpandPengalaman] = useState(false);
  const [expandPendidikan, setExpandPendidikan] = useState(false);
  const [expandKeahlian, setExpandKeahlian] = useState(false);
  const [expandSertifikasi, setExpandSertifikasi] = useState(false);
  const [expandBahasa, setExpandBahasa] = useState(false);

  const [isHoverNameRef, setIsHoverNameRef] = useState(false);
  const [isHoverProfessionRef, setIsHoverProfessionRef] = useState(false);
  const [isHoverExperienceRef, setIsHoverExperienceRef] = useState(false);
  const [isHoverEducationRef, setIsHoverEducationRef] = useState(false);
  const [isHoverSkillRef, setIsHoverSkillRef] = useState(false);
  const [isHoverCertificationRef, setIsHoverCertificationRef] = useState(false);
  const [isHoverLanguageRef, setIsHoverLanguageRef] = useState(false);

  const nameRef = useRef();
  const nameTooltipRef = useRef();
  const professionRef = useRef();
  const professionTooltipRef = useRef();
  const experienceRef = useRef();
  const experienceTooltipRef = useRef();
  const educationRef = useRef();
  const educationTooltipRef = useRef();
  const skillRef = useRef();
  const skillTooltipRef = useRef();
  const certificationRef = useRef();
  const certificationTooltipRef = useRef();
  const languageRef = useRef();
  const languageTooltipRef = useRef();
  const componentRef = useRef();

  const namePopper = usePopper(nameRef.current, nameTooltipRef.current, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const professionPopper = usePopper(
    professionRef.current,
    professionTooltipRef.current,
    {
      placement: 'right',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );

  const experiencePopper = usePopper(
    experienceRef.current,
    experienceTooltipRef.current,
    {
      placement: 'right',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );

  const educationPopper = usePopper(
    educationRef.current,
    educationTooltipRef.current,
    {
      placement: 'right',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );

  const skillPopper = usePopper(skillRef.current, skillTooltipRef.current, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const certificationPopper = usePopper(
    certificationRef.current,
    certificationTooltipRef.current,
    {
      placement: 'right',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );

  const languagePopper = usePopper(
    languageRef.current,
    languageTooltipRef.current,
    {
      placement: 'right',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );

  useEffect(
    () => {
      let counter = 0;

      if (dataATS.name.raw === '') counter++;
      if (dataATS.workExperience.length < 1) counter++;
      if (dataATS.education.length < 1) counter++;
      if (dataATS.skills.length < 1 || skills.length < 1) counter++;
      if (dataATS.certifications.length < 1) counter++;
      if (dataATS.languages.length < 1) counter++;

      setCheckData(counter === 6 ? true : false);

      setTimeout(async () => {
        if (!(await checkIsFeedbackExist('atsc'))) {
          openATSRating();
        } else if (!(await checkIsFeedbackExist('general'))) {
          openGeneralRating();
        }
      }, 3000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleExpandClick = (type) => {
    if (type === 'pengalaman') setExpandPengalaman(!expandPengalaman);
    if (type === 'pendidikan') setExpandPendidikan(!expandPendidikan);
    if (type === 'keahlian') setExpandKeahlian(!expandKeahlian);
    if (type === 'sertifikasi') setExpandSertifikasi(!expandSertifikasi);
    if (type === 'bahasa') setExpandBahasa(!expandBahasa);
  };

  const expandAll = async (bool) => {
    await setExpandPengalaman(bool);
    await setExpandPendidikan(bool);
    await setExpandKeahlian(bool);
    await setExpandSertifikasi(bool);
    await setExpandBahasa(bool);
  };

  return (
    <Grid container justifyContent='center' style={{ marginTop: '61px' }}>
      <Grid style={{ width: '843px' }}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '17px' }}
        >
          <Grid
            container
            direction='row'
            alignItems='baseline'
            style={{ width: '270px', height: '40px' }}
          >
            <img
              src={ResultNote}
              alt={'result-note'}
              style={{
                marginRight: '16px',
                width: '19px',
                height: '21px',
              }}
            />
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '34px',
                color: '#252525',
              }}
            >
              Hasil Pengecekan
            </Typography>
          </Grid>
          <Grid display='flex'>
            {checkData ? (
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                style={{
                  cursor: 'pointer',
                  width: '156px',
                  height: '40px',
                  backgroundColor: '#EFF9FD',
                  borderRadius: '8px',
                }}
                onClick={() => setOpen(true)}
              >
                <Typography
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#1D2D5E',
                  }}
                >
                  Create CV
                </Typography>
              </Grid>
            ) : (
              <ReactToPrint
                onBeforeGetContent={() => expandAll(true)}
                onAfterPrint={() => expandAll(false)}
                trigger={() => (
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    style={{
                      cursor: 'pointer',
                      width: '156px',
                      height: '40px',
                      backgroundColor: '#EFF9FD',
                      borderRadius: '8px',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    <img
                      src={DownloadImg}
                      alt={'download-img'}
                      style={{
                        marginRight: '8px',
                        width: '13px',
                        height: '17px',
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1D2D5E',
                      }}
                    >
                      Download
                    </Typography>
                  </Grid>
                )}
                content={() => componentRef.current}
              />
            )}
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              style={{
                cursor: 'pointer',
                width: '156px',
                height: '40px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                border: '1.5px solid #C4C4C4',
                marginLeft: '12px',
              }}
              onClick={() => (subs.bookTime > 0 ? setCvStep(1) : setCvStep(0))}
            >
              <img
                src={reUpload}
                alt={'reUpload'}
                style={{
                  marginRight: '8px',
                  width: '13px',
                  height: '17px',
                }}
              />
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '22px',
                  color: '#1D2D5E',
                }}
              >
                Re-upload CV
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: '24px' }}>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#252525',
            }}
          >
            Ini yang sistem ATS kami dapatkan dari CV kamu.
            <br />
            Pastikan informasi yang dibaca oleh sistem ATS sudah sesuai dengan
            CV yang kamu upload.
          </Typography>
        </Grid>
        <Grid style={{ marginBottom: '24px' }}>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              lineHeight: '24px',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            <img
              src={WarningImg}
              alt={'icon-warning'}
              style={{
                marginRight: '5px',
                height: '16px',
              }}
            />{' '}
            Pastikan kamu telah mencatat poin-poin yang diperlukan sebelum
            mengupload CV baru!
          </Typography>
        </Grid>
      </Grid>
      <Grid
        ref={componentRef}
        id='result-table'
        style={{
          height: '100%',
          width: '843px',
          padding: '32px',
          border: '1px solid #BDBDBD',
          borderRadius: '12px',
        }}
      >
        <Grid style={{ marginBottom: '20px' }}>
          <Grid container direction='row' alignItems='baseline'>
            <img
              src={dataATS.name.raw !== '' ? CheckMark : XMark}
              alt={dataATS.name.raw !== '' ? 'check-mark' : 'x-mark'}
              style={{
                marginRight: '12px',
                width: '18px',
                height: '18px',
              }}
            />
            <Typography
              style={{
                marginBottom: '8px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '26px',
                lineHeight: '36px',
                color: '#252525',
              }}
            >
              {dataATS.name.raw !== '' ? dataATS.name.raw : 'Tidak terdeteksi'}
            </Typography>
            <img
              ref={nameRef}
              onMouseEnter={() => setIsHoverNameRef(true)}
              onMouseLeave={() => setIsHoverNameRef(false)}
              src={Tooltip}
              alt={'tooltip'}
              style={{
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            <Grid
              className={classes.tooltip}
              ref={nameTooltipRef}
              style={{
                ...namePopper.styles.popper,
                visibility: isHoverNameRef === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...namePopper.attributes.popper}
            >
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '22px',
                  }}
                >
                  Nama
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                  }}
                >
                  Hal ini berisi nama yang terdeteksi di CV kamu.
                  <br />
                  Contoh: <b>Peter Maxwell</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='center'>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                fontSize: '16px',
                letterSpacing: '0.1px',
                lineHeight: '24px',
                color: '#595959',
              }}
            >
              {dataATS.workExperience.length > 0
                ? dataATS.workExperience[dataATS.workExperience.length - 1]
                    .jobTitle || 'Tidak terdeteksi'
                : 'Tidak terdeteksi'}
              &nbsp;|&nbsp;
              {dataATS.totalYearsExperience > 0
                ? 'Total ' + dataATS.totalYearsExperience + ' tahun pengalaman'
                : 'Tidak terdeteksi'}
            </Typography>
            <img
              ref={professionRef}
              onMouseEnter={() => setIsHoverProfessionRef(true)}
              onMouseLeave={() => setIsHoverProfessionRef(false)}
              src={Tooltip}
              alt={'tooltip'}
              style={{
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            <Grid
              className={classes.tooltip}
              ref={professionTooltipRef}
              style={{
                ...professionPopper.styles.popper,
                visibility:
                  isHoverProfessionRef === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...professionPopper.attributes.popper}
            >
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '22px',
                  }}
                >
                  Profesi | Total Pengalaman
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                  }}
                >
                  Hal ini berisi profesi dan total tahun pengalaman yang
                  didapatkan dari CV kamu.
                  <br />
                  Contoh: <b>UI/UX Designer | Total 2 tahun pengalaman</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <hr style={{ border: '1px solid #E8E8E8' }} />

        <Grid style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction='row'
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <Grid style={{ width: '211px', marginRight: '40px' }}>
              <Grid
                container
                direction='row'
                alignItems='baseline'
                style={{ marginBottom: '8px' }}
              >
                <img
                  src={dataATS.emails.length > 0 ? CheckMark : XMark}
                  alt={dataATS.emails.length > 0 ? 'check-mark' : 'x-mark'}
                  style={{
                    marginRight: '6px',
                    width: '10px',
                    height: '10px',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                    color: '#8D8D8D',
                  }}
                >
                  Email
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '24px',
                    color: '#252525',
                  }}
                >
                  {dataATS.emails.length > 0
                    ? dataATS.emails[0]
                    : 'Tidak terdeteksi'}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ width: '140px', marginRight: '40px' }}>
              <Grid
                container
                direction='row'
                alignItems='baseline'
                style={{ marginBottom: '8px' }}
              >
                <img
                  src={
                    dataATS.location !== null &&
                    dataATS.location.rawInput !== ''
                      ? CheckMark
                      : XMark
                  }
                  alt={
                    dataATS.location !== null &&
                    dataATS.location.rawInput !== ''
                      ? 'check-mark'
                      : 'x-mark'
                  }
                  style={{
                    marginRight: '6px',
                    width: '10px',
                    height: '10px',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                    color: '#8D8D8D',
                  }}
                >
                  Alamat
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '24px',
                    color: '#252525',
                  }}
                >
                  {dataATS.location !== null && dataATS.location.rawInput !== ''
                    ? dataATS.location.rawInput
                    : 'Tidak Terdeteksi'}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ width: '328px' }}>
              <Grid
                container
                direction='row'
                alignItems='baseline'
                style={{ marginBottom: '8px' }}
              >
                <img
                  src={
                    dataATS.linkedin !== '' && dataATS.linkedin !== null
                      ? CheckMark
                      : XMark
                  }
                  alt={
                    dataATS.linkedin !== '' && dataATS.linkedin !== null
                      ? 'check-mark'
                      : 'x-mark'
                  }
                  style={{
                    marginRight: '6px',
                    width: '10px',
                    height: '10px',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                    color: '#8D8D8D',
                  }}
                >
                  Linkedin
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '24px',
                    color: '#252525',
                  }}
                >
                  {dataATS.linkedin !== '' && dataATS.linkedin !== null
                    ? dataATS.linkedin
                    : 'Tidak terdeteksi'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='center'>
            <Grid style={{ width: '211px', marginRight: '40px' }}>
              <Grid
                container
                direction='row'
                alignItems='baseline'
                style={{ marginBottom: '8px' }}
              >
                <img
                  src={dataATS.phoneNumbers.length > 0 ? CheckMark : XMark}
                  alt={
                    dataATS.phoneNumbers.length > 0 ? 'check-mark' : 'x-mark'
                  }
                  style={{
                    marginRight: '6px',
                    width: '10px',
                    height: '10px',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                    color: '#8D8D8D',
                  }}
                >
                  Nomor Telepon
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '24px',
                    color: '#252525',
                  }}
                >
                  {dataATS.phoneNumbers.length > 0
                    ? dataATS.phoneNumbers[0]
                    : 'Tidak terdeteksi'}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ width: '140px', marginRight: '40px' }}>
              <Grid
                container
                direction='row'
                alignItems='baseline'
                style={{ marginBottom: '8px' }}
              >
                <img
                  src={
                    dataATS.dateOfBirth !== '' && dataATS.dateOfBirth !== null
                      ? CheckMark
                      : XMark
                  }
                  alt={
                    dataATS.dateOfBirth !== '' && dataATS.dateOfBirth !== null
                      ? 'check-mark'
                      : 'x-mark'
                  }
                  style={{
                    marginRight: '6px',
                    width: '10px',
                    height: '10px',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                    color: '#8D8D8D',
                  }}
                >
                  Tanggal Lahir
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '24px',
                    color: '#252525',
                  }}
                >
                  {dataATS.dateOfBirth !== '' && dataATS.dateOfBirth !== null
                    ? dataATS.dateOfBirth
                    : 'Tidak terdeteksi'}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ width: '328px' }}>
              <Grid
                container
                direction='row'
                alignItems='baseline'
                style={{ marginBottom: '8px' }}
              >
                <img
                  src={dataATS.websites.length > 0 ? CheckMark : XMark}
                  alt={dataATS.websites.length > 0 ? 'check-mark' : 'x-mark'}
                  style={{
                    marginRight: '6px',
                    width: '10px',
                    height: '10px',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                    color: '#8D8D8D',
                  }}
                >
                  Website
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '24px',
                    color: '#252525',
                  }}
                >
                  {dataATS.websites.length > 0
                    ? dataATS.websites[0]
                    : 'Tidak terdeteksi'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: '24px' }}>
          <Grid
            container
            direction='row'
            alignItems='baseline'
            style={{ marginBottom: '4px' }}
          >
            <img
              src={
                summary.length > 0 &&
                summary[0].text !== '' &&
                summary[0].text !== null
                  ? CheckMark
                  : XMark
              }
              alt={
                summary.length > 0 &&
                summary[0].text !== '' &&
                summary[0].text !== null
                  ? 'check-mark'
                  : 'x-mark'
              }
              style={{
                marginRight: '6px',
                width: '10px',
                height: '10px',
              }}
            />
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                fontSize: '14px',
                letterSpacing: '0.1px',
                lineHeight: '20px',
                color: '#8D8D8D',
              }}
            >
              Ringkasan/Tujuan
            </Typography>
          </Grid>
          <Grid>
            <Typography
              style={{
                textAlign: 'justify',
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                fontSize: '16px',
                letterSpacing: '0.1px',
                lineHeight: '24px',
                color: '#252525',
              }}
            >
              {summary.length > 0 &&
              summary[0].text !== '' &&
              summary[0].text !== null
                ? summary[0].text.replace('OBJECTIVE', '')
                : 'Tidak terdeteksi'}{' '}
            </Typography>
          </Grid>
        </Grid>

        <hr style={{ border: '1px solid #E8E8E8', marginBottom: '24px' }} />

        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          style={{ marginBottom: '24px' }}
        >
          <Grid
            style={{ width: '250px' }}
            container
            direction='row'
            alignItems='center'
          >
            <img
              src={dataATS.workExperience.length > 0 ? CheckMark : XMark}
              alt={dataATS.workExperience.length > 0 ? 'check-mark' : 'x-mark'}
              style={{
                marginRight: '12px',
                width: '18px',
                height: '18px',
              }}
            />
            <Typography
              style={{
                marginBottom: '8px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '22px',
                letterSpacing: '0.1px',
                lineHeight: '27px',
                margin: '0px',
                color: '#252525',
              }}
            >
              Pengalaman
            </Typography>
            <img
              ref={experienceRef}
              onMouseEnter={() => setIsHoverExperienceRef(true)}
              onMouseLeave={() => setIsHoverExperienceRef(false)}
              src={Tooltip}
              alt={'tooltip'}
              style={{
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            <Grid
              className={classes.tooltip}
              ref={experienceTooltipRef}
              style={{
                ...experiencePopper.styles.popper,
                visibility:
                  isHoverExperienceRef === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...experiencePopper.attributes.popper}
            >
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '22px',
                  }}
                >
                  Pengalaman
                </Typography>
              </Grid>
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                  }}
                >
                  Hal ini berisi pengalaman yang terdeteksi di CV kamu.
                  <br />
                  Contoh:
                </Typography>
              </Grid>
              <Grid
                style={{
                  backgroundColor: '#EFF9FD',
                  borderRadius: '8px',
                  padding: '12px',
                }}
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  style={{ marginBottom: '3px' }}
                >
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#1D2D5E',
                      }}
                    >
                      UI/UX Designer
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#8D8D8D',
                      }}
                    >
                      October 2020 - Januari 2021
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  style={{ marginBottom: '17px' }}
                >
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#8D8D8D',
                      }}
                    >
                      ABC Company
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#8D8D8D',
                      }}
                    >
                      Jakarta
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      textAlign: 'justify',
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Melakukan research produk secara kuantitatif dan kualitatif.
                    Bekerja sama dengan Product Manager dan Engineer dalam
                    membuat desain produk digital.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '32px' }}>
            <IconButton
              onClick={() => handleExpandClick('pengalaman')}
              aria-expanded={expandPengalaman}
              aria-label='show more'
            >
              {expandPengalaman === true ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>

        <Collapse in={expandPengalaman}>
          <Grid>
            {dataATS.workExperience.length > 0
              ? dataATS.workExperience.map((data, index) => (
                  <Grid
                    index={index}
                    style={{
                      width: '786px',
                      backgroundColor: '#EFF9FD',
                      borderRadius: '12px',
                      marginBottom: '24px',
                      padding: '20px',
                    }}
                  >
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      style={{ marginBottom: '3px' }}
                    >
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            lineHeight: '28px',
                            letterSpacing: '0.1px',
                            color: '#1D2D5E',
                          }}
                        >
                          {data.jobTitle !== '' && data.jobTitle !== null
                            ? data.jobTitle
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.1px',
                            color: '#8D8D8D',
                          }}
                        >
                          {data.dates !== null &&
                          data.dates.startDate !== '' &&
                          data.dates.startDate !== null
                            ? format(
                                parse(
                                  data.dates.startDate,
                                  'yyyy-MM-dd',
                                  new Date(),
                                ),
                                'MMMM Y',
                                {
                                  locale: idLocale,
                                },
                              )
                            : 'Tidak terdeteksi'}
                          &nbsp;-&nbsp;
                          {data.dates !== null &&
                          data.dates.endDate !== '' &&
                          data.dates.endDate !== null
                            ? format(
                                parse(
                                  data.dates.endDate,
                                  'yyyy-MM-dd',
                                  new Date(),
                                ),
                                'MMMM Y',
                                {
                                  locale: idLocale,
                                },
                              )
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      style={{ marginBottom: '17px' }}
                    >
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.1px',
                            color: '#8D8D8D',
                          }}
                        >
                          {data.organization !== '' &&
                          data.organization !== null
                            ? data.organization
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.1px',
                            color: '#8D8D8D',
                          }}
                        >
                          {data.location !== null &&
                          data.location.country !== '' &&
                          data.location.country !== null
                            ? data.location.country
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          textAlign: 'justify',
                          fontFamily: 'Roboto',
                          fontWeight: 'normal',
                          fontSize: '16px',
                          lineHeight: '24px',
                          letterSpacing: '0.1px',
                          color: '#252525',
                        }}
                      >
                        {data.jobDescription !== '' &&
                        data.jobDescription !== null
                          ? data.jobDescription
                          : 'Tidak terdeteksi'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              : null}
          </Grid>
        </Collapse>

        <hr style={{ border: '1px solid #E8E8E8', marginBottom: '24px' }} />

        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          style={{ marginBottom: '24px' }}
        >
          <Grid
            style={{ width: '250px' }}
            container
            direction='row'
            alignItems='center'
          >
            <img
              src={dataATS.education.length > 0 ? CheckMark : XMark}
              alt={dataATS.education.length > 0 ? 'check-mark' : 'x-mark'}
              style={{
                marginRight: '12px',
                width: '18px',
                height: '18px',
              }}
            />
            <Typography
              style={{
                marginBottom: '8px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '22px',
                letterSpacing: '0.1px',
                lineHeight: '27px',
                margin: '0px',
                color: '#252525',
              }}
            >
              Pendidikan
            </Typography>
            <img
              ref={educationRef}
              onMouseEnter={() => setIsHoverEducationRef(true)}
              onMouseLeave={() => setIsHoverEducationRef(false)}
              src={Tooltip}
              alt={'tooltip'}
              style={{
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            <Grid
              className={classes.tooltip}
              ref={educationTooltipRef}
              style={{
                ...educationPopper.styles.popper,
                visibility: isHoverEducationRef === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...educationPopper.attributes.popper}
            >
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '22px',
                  }}
                >
                  Pengalaman
                </Typography>
              </Grid>
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                  }}
                >
                  Hal ini berisi pengalaman yang terdeteksi di CV kamu.
                  <br />
                  Contoh:
                </Typography>
              </Grid>
              <Grid
                style={{
                  backgroundColor: '#EFF9FD',
                  borderRadius: '8px',
                  padding: '12px',
                }}
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  style={{ marginBottom: '3px' }}
                >
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#1D2D5E',
                      }}
                    >
                      Universitas Aksel
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#8D8D8D',
                      }}
                    >
                      Juli 2018 - Januari 2022
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#8D8D8D',
                      }}
                    >
                      Bachelors of Arts | 4.0 from 4.0
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        color: '#8D8D8D',
                      }}
                    >
                      Jakarta
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '32px' }}>
            <IconButton
              onClick={() => handleExpandClick('pendidikan')}
              aria-expanded={expandPendidikan}
              aria-label='show more'
            >
              {expandPendidikan === true ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>

        <Collapse in={expandPendidikan}>
          <Grid>
            {dataATS.education.length > 0
              ? dataATS.education.map((data, index) => (
                  <Grid
                    index={index}
                    style={{
                      width: '786px',
                      backgroundColor: '#EFF9FD',
                      borderRadius: '12px',
                      marginBottom: '24px',
                      padding: '20px',
                    }}
                  >
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      style={{ marginBottom: '3px' }}
                    >
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            lineHeight: '28px',
                            letterSpacing: '0.1px',
                            color: '#1D2D5E',
                          }}
                        >
                          {data.organization !== '' &&
                          data.organization !== null
                            ? data.organization
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.1px',
                            color: '#8D8D8D',
                          }}
                        >
                          {data.dates !== null &&
                          data.dates.startDate !== '' &&
                          data.dates.startDate !== null
                            ? format(
                                parse(
                                  data.dates.startDate,
                                  'yyyy-MM-dd',
                                  new Date(),
                                ),
                                'MMMM Y',
                                {
                                  locale: idLocale,
                                },
                              )
                            : 'Tidak terdeteksi'}
                          &nbsp;-&nbsp;
                          {data.dates !== null &&
                          data.dates.completionDate !== '' &&
                          data.dates.completionDate !== null
                            ? format(
                                parse(
                                  data.dates.completionDate,
                                  'yyyy-MM-dd',
                                  new Date(),
                                ),
                                'MMMM Y',
                                {
                                  locale: idLocale,
                                },
                              )
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.1px',
                            color: '#8D8D8D',
                          }}
                        >
                          {data.accreditation !== null &&
                          data.accreditation.inputStr !== '' &&
                          data.accreditation.inputStr !== null
                            ? data.accreditation.inputStr
                            : 'Tidak terdeteksi'}
                          &nbsp;|&nbsp;
                          {data.grade !== null &&
                          data.grade.raw !== '' &&
                          data.grade.raw !== null
                            ? data.grade.raw
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.1px',
                            color: '#8D8D8D',
                          }}
                        >
                          {data.location !== null &&
                          data.location.formatted !== '' &&
                          data.location.formatted !== null
                            ? data.location.formatted
                            : 'Tidak terdeteksi'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              : null}
          </Grid>
        </Collapse>

        <hr style={{ border: '1px solid #E8E8E8', marginBottom: '24px' }} />

        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid
            style={{ width: '250px' }}
            container
            direction='row'
            alignItems='center'
          >
            <img
              src={
                dataATS.skills.length > 0 || skills.length > 0
                  ? CheckMark
                  : XMark
              }
              alt={
                dataATS.skills.length > 0 || skills.length > 0
                  ? 'check-mark'
                  : 'x-mark'
              }
              style={{
                marginRight: '12px',
                width: '18px',
                height: '18px',
              }}
            />
            <Typography
              style={{
                marginBottom: '8px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '22px',
                letterSpacing: '0.1px',
                lineHeight: '27px',
                margin: '0px',
                color: '#252525',
              }}
            >
              Keahlian
            </Typography>
            <img
              ref={skillRef}
              onMouseEnter={() => setIsHoverSkillRef(true)}
              onMouseLeave={() => setIsHoverSkillRef(false)}
              src={Tooltip}
              alt={'tooltip'}
              style={{
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            <Grid
              className={classes.tooltip}
              ref={skillTooltipRef}
              style={{
                ...skillPopper.styles.popper,
                visibility: isHoverSkillRef === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...skillPopper.attributes.popper}
            >
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '22px',
                  }}
                >
                  Keahlian
                </Typography>
              </Grid>
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                  }}
                >
                  Hal ini berisi keahlian yang terdeteksi di CV kamu.
                  <br />
                  Contoh:
                </Typography>
              </Grid>
              <Grid container direction='row'>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    UI/UX Design
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Public Speaking
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Marketing
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Web Programming
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '32px' }}>
            <IconButton
              onClick={() => handleExpandClick('keahlian')}
              aria-expanded={expandKeahlian}
              aria-label='show more'
            >
              {expandKeahlian === true ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>

        <Collapse in={expandKeahlian}>
          <Grid container direction='row'>
            {dataATS.skills.length > 0
              ? dataATS.skills.map((data, index) => (
                  <Grid
                    items
                    index={index}
                    style={{
                      border: '1px solid #BDBDBD',
                      boxSizing: 'border-box',
                      borderRadius: '24px',
                      textAlign: 'center',
                      padding: '8px 14px',
                      marginRight: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.1px',
                        color: '#252525',
                      }}
                    >
                      {data.name !== '' && data.name !== null
                        ? data.name
                        : 'Tidak terdeteksi'}
                    </Typography>
                  </Grid>
                ))
              : null}
            {skills.length > 0
              ? skills.map((data, index) => (
                  <Grid
                    items
                    index={index}
                    style={{
                      border: '1px solid #BDBDBD',
                      boxSizing: 'border-box',
                      borderRadius: '24px',
                      textAlign: 'center',
                      padding: '8px 14px',
                      marginRight: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.1px',
                        color: '#252525',
                      }}
                    >
                      {data !== '' && data !== null
                        ? data.trim()
                        : 'Tidak terdeteksi'}
                    </Typography>
                  </Grid>
                ))
              : null}
          </Grid>
        </Collapse>

        <hr style={{ border: '1px solid #E8E8E8', margin: '24px 0px' }} />

        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid
            style={{ width: '250px' }}
            container
            direction='row'
            alignItems='center'
          >
            <img
              src={dataATS.certifications.length > 0 ? CheckMark : XMark}
              alt={dataATS.certifications.length > 0 ? 'check-mark' : 'x-mark'}
              style={{
                marginRight: '12px',
                width: '18px',
                height: '18px',
              }}
            />
            <Typography
              style={{
                marginBottom: '8px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '22px',
                letterSpacing: '0.1px',
                lineHeight: '27px',
                margin: '0px',
                color: '#252525',
              }}
            >
              Sertifikasi
            </Typography>
            <img
              ref={certificationRef}
              onMouseEnter={() => setIsHoverCertificationRef(true)}
              onMouseLeave={() => setIsHoverCertificationRef(false)}
              src={Tooltip}
              alt={'tooltip'}
              style={{
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            <Grid
              className={classes.tooltip}
              ref={certificationTooltipRef}
              style={{
                ...certificationPopper.styles.popper,
                visibility:
                  isHoverCertificationRef === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...certificationPopper.attributes.popper}
            >
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '22px',
                  }}
                >
                  Sertifikasi
                </Typography>
              </Grid>
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                  }}
                >
                  Hal ini berisi sertifikasi yang terdeteksi di CV kamu.
                  <br />
                  Contoh:
                </Typography>
              </Grid>
              <Grid container direction='row'>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    The Fundamental of Digital Marketing
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Google Data Analytics
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '32px' }}>
            <IconButton
              onClick={() => handleExpandClick('sertifikasi')}
              aria-expanded={expandSertifikasi}
              aria-label='show more'
            >
              {expandSertifikasi === true ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>

        <Collapse in={expandSertifikasi}>
          <Grid container direction='row'>
            {dataATS.certifications.length > 0
              ? dataATS.certifications.map((data, index) => (
                  <Grid
                    items
                    index={index}
                    style={{
                      border: '1px solid #BDBDBD',
                      boxSizing: 'border-box',
                      borderRadius: '24px',
                      textAlign: 'center',
                      padding: '8px 14px',
                      marginRight: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.1px',
                        color: '#252525',
                      }}
                    >
                      {data !== '' && data !== null ? data : 'Tidak terdeteksi'}
                    </Typography>
                  </Grid>
                ))
              : null}
          </Grid>
        </Collapse>

        <hr style={{ border: '1px solid #E8E8E8', margin: '24px 0px' }} />

        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid
            style={{ width: '250px' }}
            container
            direction='row'
            alignItems='center'
          >
            <img
              src={dataATS.languages.length > 0 ? CheckMark : XMark}
              alt={dataATS.languages.length > 0 ? 'check-mark' : 'x-mark'}
              style={{
                marginRight: '12px',
                width: '18px',
                height: '18px',
              }}
            />
            <Typography
              style={{
                marginBottom: '8px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '22px',
                letterSpacing: '0.1px',
                lineHeight: '27px',
                margin: '0px',
                color: '#252525',
              }}
            >
              Bahasa
            </Typography>
            <img
              ref={languageRef}
              onMouseEnter={() => setIsHoverLanguageRef(true)}
              onMouseLeave={() => setIsHoverLanguageRef(false)}
              src={Tooltip}
              alt={'tooltip'}
              style={{
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            <Grid
              className={classes.tooltip}
              ref={languageTooltipRef}
              style={{
                ...languagePopper.styles.popper,
                visibility: isHoverLanguageRef === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...languagePopper.attributes.popper}
            >
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    letterSpacing: '0.1px',
                    lineHeight: '22px',
                  }}
                >
                  Bahasa
                </Typography>
              </Grid>
              <Grid style={{ marginBottom: '8px' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '20px',
                  }}
                >
                  Hal ini berisi bahasa yang terdeteksi di CV kamu.
                  <br />
                  Contoh:
                </Typography>
              </Grid>
              <Grid container direction='row'>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Indonesia
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    English
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Korean
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Japanese
                  </Typography>
                </Grid>
                <Grid
                  items
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #BDBDBD',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '6px 12px',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: '#252525',
                    }}
                  >
                    Spanish
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '32px' }}>
            <IconButton
              onClick={() => handleExpandClick('bahasa')}
              aria-expanded={expandBahasa}
              aria-label='show more'
            >
              {expandBahasa === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>

        <Collapse in={expandBahasa}>
          <Grid container direction='row'>
            {dataATS.languages.length > 0
              ? dataATS.languages.map((data, index) => (
                  <Grid
                    items
                    index={index}
                    style={{
                      border: '1px solid #BDBDBD',
                      boxSizing: 'border-box',
                      borderRadius: '24px',
                      textAlign: 'center',
                      padding: '8px 14px',
                      marginRight: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.1px',
                        color: '#252525',
                      }}
                    >
                      {data !== '' && data !== null ? data : 'Tidak terdeteksi'}
                    </Typography>
                  </Grid>
                ))
              : null}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default ResultCV;
