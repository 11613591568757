import { Navigate } from 'react-router-dom';
import PrivateRoute from 'src/components/router/PrivateRoute';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import FullScreenLayout from 'src/components/FullScreenLayout';
import CvLayout from 'src/components/CvLayout';
import Account from 'src/pages/Account';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import BookingList from 'src/pages/BookingList';
import MentorList from 'src/pages/MentorList';
import Register from 'src/pages/Register';
import Booking from 'src/pages/Booking';
import LandingPage from 'src/pages/LandingPage';
import Assessment from 'src/pages/Assessment';
import Mentoring from 'src/pages/Mentoring';
import Success from 'src/pages/Success';
import BookingDetail from 'src/pages/BookingDetail';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import TnC from 'src/pages/TnC';
import DashboardCvMaker from 'src/pages/cvMaker/dashboard/index';
import TemplateCvMaker from 'src/pages/cvMaker/Template';
import Builder from 'src/pages/cvMaker/Builder';
import CvPreview from 'src/pages/cvMaker/preview/index';
import FAQ from 'src/pages/FAQ';
// import About from "src/pages/About";
import MentorDetail from 'src/pages/MentorDetail';
import DownloadCV from 'src/pages/DownloadCV';
import ChangePassword from 'src/pages/ChangePassword';
import ForgotPassword from 'src/pages/ForgotPassword';
import ProfileOnboarding from './pages/ProfileOnboarding';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'profile', element: <PrivateRoute component={Account} /> },
      {
        path: 'profile/change-password',
        element: <PrivateRoute component={ChangePassword} />,
      },

      { path: 'booking', element: <PrivateRoute component={BookingList} /> },
      {
        path: 'booking/detail',
        element: <PrivateRoute component={BookingDetail} />,
      },
      { path: 'mentoring', element: <MentorList /> },
      // { path: 'mentoring', element: <PrivateRoute component={MentorList} /> },
      {
        path: 'mentor/:id',
        element: <MentorDetail />,
        // element: <PrivateRoute component={MentorDetail} />,
      },
      { path: 'book', element: <Booking /> },
      // { path: 'book', element: <PrivateRoute component={Booking} /> },
      // { path: "cv", element: <PrivateRoute component={CV} /> },
      { path: 'home', element: <Mentoring /> },
      // { path: 'home', element: <PrivateRoute component={Mentoring} /> },
      { path: 'success', element: <PrivateRoute component={Success} /> },
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
  {
    path: 'app/cv',
    element: <CvLayout />,
    children: [
      {
        path: 'template/:id',
        element: <PrivateRoute component={TemplateCvMaker} />,
      },
      {
        path: 'builder/:id',
        element: <PrivateRoute component={Builder} />,
      },
      { path: '/', element: <Navigate to='dashboard' /> },
    ],
  },
  {
    path: 'app/cv',
    element: <MainLayout />,
    children: [
      {
        path: 'dashboard',
        element: <DashboardCvMaker />,
      },
      // {
      //   path: 'dashboard',
      //   element: <PrivateRoute component={DashboardCvMaker} />,
      // },
    ],
  },
  {
    path: 'app/cv',
    element: <FullScreenLayout />,
    children: [
      {
        path: 'preview/:id',
        element: <PrivateRoute component={CvPreview} />,
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      {
        path: '/profile/onboarding',
        element: <ProfileOnboarding />,
      },
      { path: '/', element: <LandingPage /> },
      { path: '404', element: <NotFound /> },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      { path: 'term-and-condition', element: <TnC /> },
      // { path: "about", element: <About/> },
      { path: 'frequently-asked-question', element: <FAQ /> },
      // { path: "/", element: <Navigate to="/landing" /> },
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
  {
    path: '/',
    element: <FullScreenLayout />,
    children: [
      { path: 'assessment', element: <Assessment /> },
      { path: 'rd/:id', element: <DownloadCV /> },
      {
        path: '/app/booking/detail',
        element: <PrivateRoute component={BookingDetail} />,
      },
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
];

export default routes;
