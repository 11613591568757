import CustomButton from 'src/components/button';
import { Typography } from '@material-ui/core';

const ButtonView = ({ icon, handleClick, label }) => {
  return (
    <CustomButton
      style={{
        padding: '0',
        borderRadius: '50px',
        backgroundColor: '#F7F7F7',
        color: '#252525',
        boxShadow: 'none',
      }}
      onClick={handleClick}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ padding: 0, marginRight: '8px' }}>{icon}</div>
        <Typography variant='sm' sx={{ mt: '1px' }}>
          {label}
        </Typography>
      </div>
    </CustomButton>
  );
};

export default ButtonView;
