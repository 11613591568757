const refSort = [
  {
    name: 'Added (newest first)',
    value: 'addedAt',
    checked: false,
    sort: 'desc',
  },
  {
    name: 'Added (oldest first)',
    value: 'addedAt',
    checked: false,
    sort: 'asc',
  },
  {
    name: 'Price (low to high)',
    value: 'price',
    checked: false,
    sort: 'asc',
  },
  {
    name: 'Price (high to low)',
    value: 'price',
    checked: false,
    sort: 'desc',
  },
];

module.exports = { refSort };
