import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#5BC0EB',
      },
    },
  },
}));

const CustomInput = ({
  children,
  label,
  name,
  onChange,
  value,
  defaultValue,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography>{label}</Typography>
      <TextField
        value={value}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        size='small'
        margin='normal'
        variant='outlined'
        className={classes.root}
        style={{ marginTop: '0.4rem', borderRadius: '6px' }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        {...rest}
      >
        {children}
      </TextField>
    </>
  );
};

export default CustomInput;
