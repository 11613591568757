import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { updateCvDetail } from 'src/redux/actions';

import CustomInput from 'src/components/cvMaker/CustomInput';

const PersonalDetail = ({ cvDetail }) => {
  const { objective, profile } = cvDetail;

  const handleChange = (e, rootPath, path, subPath) => {
    if (subPath) {
      cvDetail[rootPath][path][subPath] = e.target.value;
    } else if (path) {
      cvDetail[rootPath][path] = e.target.value;
    } else {
      cvDetail[rootPath] = e.target.value;
    }
    updateCvDetail(cvDetail);
  };

  return (
    <>
      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={6}>
          <CustomInput
            label='First Name'
            name='firstName'
            onBlur={(e) => handleChange(e, 'profile', 'firstName')}
            defaultValue={profile.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            label='Last Name'
            name='lastName'
            onBlur={(e) => handleChange(e, 'profile', 'lastName')}
            defaultValue={profile.lastName}
          />
        </Grid>
        {/* TODO: not yet implemented in cv maker web */}
        {/* <Grid item xs={12}>
          <CustomInput
            label="Profession"
            name="lastName"
            onBlur={(e) => handleChange(e, "profile", "lastName")}
            defaultValue={profile.lastName}
          />
        </Grid> */}
        <Grid item xs={6}>
          <CustomInput
            label='City'
            name='city'
            onBlur={(e) => handleChange(e, 'profile', 'address', 'city')}
            defaultValue={profile.address.city}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            label='Zip Code'
            name='pincode'
            onBlur={(e) => handleChange(e, 'profile', 'address', 'pincode')}
            defaultValue={profile.address.pincode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            label='Phone Number'
            name='phone'
            onBlur={(e) => handleChange(e, 'profile', 'phone')}
            defaultValue={profile.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            label='Email Address'
            name='email'
            onBlur={(e) => handleChange(e, 'profile', 'email')}
            defaultValue={profile.email}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            label='Linkedin'
            name='linkedin'
            onBlur={(e) => handleChange(e, 'profile', 'linkedin')}
            defaultValue={profile.linkedin}
          />
        </Grid>
      </Grid>
      <Typography variant='pBold' marginTop={1}>
        CV Objective
      </Typography>
      <Grid container spacing={1} marginTop={1}>
        <Grid item xs={12}>
          <CustomInput
            label='Heading'
            name='lastName'
            onBlur={(e) => handleChange(e, 'objective', 'heading')}
            defaultValue={objective.heading}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            label='Description'
            name='lastName'
            onBlur={(e) => handleChange(e, 'objective', 'body')}
            defaultValue={objective.body}
            multiline
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  cvDetail: state.cv.cvDetail,
});

export default connect(mapStateToProps)(PersonalDetail);
