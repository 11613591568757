import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import axios from 'axios';
import reactGA from 'react-ga';

import { setCvStep } from 'src/redux/actions';

import UploadDropzone from '../../assets/img/upload-dropzone.svg';
import Stop from '../../assets/img/stop.svg';
import Spinner from '../../assets/img/spinner.gif';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});

const useStyles = makeStyles(() => ({
  dropbox: {
    width: '824px !important',
    height: '320px !important',
    boxSizing: 'border-box',

    paddingTop: '50px',
    paddingBottom: '50px',

    marginBottom: '24px !important',

    backgroundColor: '#FFFFFF',
    border: '2px dashed #1D2D5E',
    borderRadius: '16px',

    textAlign: 'center',
  },

  errorbox: {
    width: '824px !important',
    height: '56px !important',
    boxSizing: 'border-box',

    padding: '16px 24px',
    marginBottom: '16px ',

    backgroundColor: '#FDECEA',
  },
}));

const PilihPaket = ({
  subs,
  setSubs,
  file,
  setFile,
  dataATS,
  setDataATS,
  onFileChange,
  onFileDrop,
  errorUpload,
  setErrorUpload,
}) => {
  const classes = useStyles();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const handleATSChecker = async (e) => {
    setLoadingUpload(true);

    const req = new FormData();
    req.append('file', file.selectedFile);

    axios
      .post('https://api.affinda.com/v1/resumes', req, {
        headers: {
          Authorization: 'Bearer e0d83ad7caf8801a69f74a40703497ab5fc4bb9a',
        },
      })
      .then(async (res) => {
        await updateDataSubs(res.data.data);

        setDataATS(res.data.data);
        setFile({});
        setErrorUpload(false);
        setLoadingUpload(false);
        setCvStep(2);

        reactGA.event({
          category: 'ATS Checker',
          action: 'Success Parse',
          label: user.uid,
        });
      })
      .catch((err) => {
        setErrorUpload(true);
        setLoadingUpload(false);
        console.log(err);

        reactGA.event({
          category: 'ATS Checker',
          action: 'Error Parse',
          label: user.uid,
        });
      });
  };

  const handlePreviousResult = async () => {
    setCvStep(2);
  };

  const updateDataSubs = async (affindaResponse) => {
    await firebase
      .database()
      .ref(`bookings/${subs.bookId}`)
      .update({
        ...subs,
        bookTime: subs.bookTime - 1,
        updatedAt: firebase.database.ServerValue.TIMESTAMP,
        dataAts: JSON.stringify(affindaResponse),
      });

    setSubs({
      ...subs,
      bookTime: subs.bookTime - 1,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  };

  useEffect(
    () => {
      // eslint-disable-next-line no-prototype-builtins
      if (file.hasOwnProperty('selectedFile')) {
        handleATSChecker();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileDrop(acceptedFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const Normal = () => {
    return (
      <>
        <Grid item>
          <img
            src={UploadDropzone}
            alt={'upload-dropzone'}
            style={{
              marginBottom: '14px',
              width: '48px',
              height: '48px',
            }}
          />
        </Grid>

        <Grid item style={{ marginBottom: '8px' }}>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '27px',
              letterSpacing: '0.1px',
              color: '#1D2D5E',
            }}
          >
            Drag dan drop CV mu disini
          </Typography>
        </Grid>

        <Grid item style={{ marginBottom: '8px' }}>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.1px',
              color: '#1D2D5E',
            }}
          >
            atau
          </Typography>
        </Grid>

        <Grid item style={{ marginBottom: '12px' }}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{
              cursor: 'pointer',
              width: '126px',
              height: '40px',
              backgroundColor: '#1D2D5E',
              borderRadius: '8px',
              color: '#FFFFFF',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
            >
              Upload CV
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Typography>
            (Hanya menerima file PDF dan berukuran maks 5 MB)
          </Typography>
        </Grid>
      </>
    );
  };

  const Dragged = () => {
    return (
      <>
        <Grid item>
          <img
            src={UploadDropzone}
            alt={'upload-dropzone'}
            style={{
              marginBottom: '14px',
              width: '64px',
              height: '64px',
            }}
          />
        </Grid>

        <Grid item style={{ marginBottom: '8px' }}>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '27px',
              letterSpacing: '0.1px',
              color: '#1D2D5E',
            }}
          >
            Drag dan drop CV mu disini
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      direction='column'
      alignContent='center'
      style={{ marginTop: '52px' }}
    >
      {errorUpload === true ? (
        <Grid
          container
          direction='row'
          alignItems='center'
          className={classes.errorbox}
        >
          <img
            src={Stop}
            alt={'error'}
            style={{
              width: '16px',
              height: '16px',
            }}
          />
          <Typography
            style={{
              marginLeft: '14px',
              fontFamily: 'Montserrat',
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.1px',
              color: '#252525',
            }}
          >
            Harap masukan CV yang memiliki ekstensi PDF dan berukuran maksimal 5
            MB
          </Typography>
        </Grid>
      ) : null}
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        style={{ backgroundColor: isDragActive ? '#F1FAFD' : '' }}
        className={classes.dropbox}
        {...getRootProps()}
      >
        {loadingUpload === false ? (
          isDragActive ? (
            Dragged()
          ) : (
            Normal()
          )
        ) : (
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <img
              src={Spinner}
              alt={'loading'}
              style={{
                width: '100px',
                height: '100px',
              }}
            />
            <Typography
              style={{
                marginLeft: '14px',
                fontFamily: 'Montserrat',
                fontWeight: '600',
                fontSize: '22px',
                lineHeight: '27px',
                letterSpacing: '0.1px',
                color: '#1D2D5E',
              }}
            >
              CV mu sedang diproses
            </Typography>
          </Grid>
        )}

        <input
          id='upload-cv'
          type='file'
          onChange={onFileChange}
          accept='application/pdf'
          style={{ opacity: '0', position: 'absolute', zIndex: '-1' }}
          {...getInputProps()}
        />
      </Grid>

      {Object.keys(dataATS).length > 0 && (
        <Grid style={{ marginBottom: '100px' }}>
          <Grid style={{ marginBottom: '5px' }}>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'center',
                letterSpacing: '0.1px',
                color: '#252525',
              }}
            >
              Ingin lihat hasil pengecekan CV mu yang sebelumnya?
            </Typography>
          </Grid>
          <Grid>
            <Typography
              onClick={handlePreviousResult}
              style={{
                cursor: 'pointer',
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '20px',
                textAlign: 'center',
                letterSpacing: '0.1px',
                color: '#1D2D5E',
              }}
            >
              Lihat CV sebelumnya
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({ cvStep: state.cv.cvStep });

export default connect(mapStateToProps)(PilihPaket);
