import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Tooltip,
  IconButton,
  Backdrop,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import ShortUniqueId from 'short-unique-id';
import { formatDistanceToNow } from 'date-fns';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import download from 'downloadjs';
import axios from 'axios';
import reactGA from 'react-ga';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CardGrid from 'src/components/cvMaker/CardGrid';
import ListGrid from 'src/components/cvMaker/ListGrid';
import CustomButton from 'src/components/button';
import BottomBar from 'src/components/cvMaker/BottomBar';
import CustomDialog from 'src/components/cvMaker/CustomDialog';
import DialogDownload from 'src/components/cvMaker/DialogDownload';
import CustomPopover from 'src/components/cvMaker/CustomPopover';
import {
  CarouselIcon,
  ListViewIcon,
  MoreIcon,
  UploadIcon,
} from 'src/assets/icons/CvMaker';
import ButtonView from 'src/components/cvMaker/ButtonView';
import Desktop from './desktop';
import initialStateCv from 'src/templates/initialStateCv.json';
import {
  getUnsplashPhoto,
  b64toBlob,
  isFilePDF,
  getBase64,
} from 'src/utils/utilities';
import { updateCvDetail, setCvStep, setNavbarTitle } from 'src/redux/actions';
import DialogCvReview from 'src/components/cvReview/DialogCvReview';
import laptopLogo from 'src/assets/img/laptopLogo.svg';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const DashboardCvMaker = ({ cvStep, isAuthenticated }) => {
  const fileInputRef = useRef(null);
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });
  const query = useQuery();
  setNavbarTitle('CV Maker');

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [view, setView] = useState('carousel');
  const [name, setName] = useState('');
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataDetail, setDataDetail] = useState({});
  const [modalBlock, setModalBlock] = useState(false);
  const [modalReview, setModalReview] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const isDesktop = useMediaQuery('(min-width:960px)');

  const [isDesktopModalOpen, setIsDesktopModalOpen] = useState(isDesktop);

  useEffect(() => {
    if (query.get('tab') === 'ats') {
      setModalBlock(true);
    } else {
      setModalBlock(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('tab')]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [isAuthenticated]);

  useEffect(() => {
    const resumesRef = 'resumes';
    const socketRef = '/.info/connected';
    if (query.get('tab') !== 'ats') {
      setCvStep(0);
    }

    if (user) {
      reactGA.event({
        category: 'CV Maker',
        action: 'User View CV Maker',
        value: user.uid,
      });
      firebase
        .database()
        .ref(socketRef)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            firebase.database().ref(socketRef).off();
            setLoading(false);
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const resumesArr = [];
            const data = snapshot.val();
            Object.keys(data).forEach((key) => resumesArr.push(data[key]));
            resumesArr.sort(function (a, b) {
              return b.updatedAt - a.updatedAt;
            });
            setResumes(resumesArr);
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('child_removed', (snapshot) => {
          if (snapshot.val()) {
            setResumes(resumes.filter((x) => x.id === snapshot.val().id));
          }
        });

      return () => {
        firebase.database().ref(resumesRef).off();
      };
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButton = (e, { data }) => {
    setAnchorEl(e.currentTarget);
    setMenuOpen(!menuOpen);
    setDataDetail(data);
  };

  const handleSave = async () => {
    if (!name) return;
    if (dataDetail.id) {
      dataDetail.name = name;
      updateCvDetail(dataDetail);
      setOpen(false);
    } else {
      reactGA.event({
        category: 'CV Maker',
        action: 'User Create CV',
        value: user.uid,
      });

      setModalLoading(true);
      const id = uuid();
      const preview = await getUnsplashPhoto();
      const createdAt = firebase.database.ServerValue.TIMESTAMP;

      let firstName;
      let lastName;

      if (!user.isAnonymous) {
        [firstName, lastName] = user.displayName.split(' ');
      }

      const resume = {
        ...initialStateCv,
        id,
        name,
        user: user.uid,
        preview,
        profile: {
          ...initialStateCv.profile,
          firstName: firstName || '',
          lastName: lastName || '',
        },
        createdAt,
        updatedAt: createdAt,
      };

      firebase.database().ref(`resumes/${id}`).set(resume);
      setModalLoading(false);
      navigate(`/app/cv/template/${id}`, { replace: true });
    }
  };

  const handleCVUpload = async (e) => {
    const file = e.target.files[0];
    const newId = uuid();
    if (!file) {
      return null;
    }

    if (!isFilePDF(file)) {
      alert(
        'You tried to upload a file that was not an pdf. Please try again.',
      );
      return null;
    }

    if (file.size > 10485760) {
      alert(
        "Your pdf seems to be bigger than 10 MB. That's way too much. Maybe consider reducing it's size?",
      );
      return null;
    }

    let resumeServer =
      process.env.REACT_APP_DEPLOYED === 'dev'
        ? 'https://aksel-resume-staging.herokuapp.com'
        : 'https://aksel-resume.herokuapp.com';

    setBackdropOpen(true);
    const fileToB64 = await getBase64(file);
    const newUrl = await axios.post(
      `${resumeServer}/uploadResume`,
      // 'http://resume-backend-stagging.eba-hsbygz2h.ap-southeast-1.elasticbeanstalk.com/uploadResume',
      {
        id: newId,
        body: fileToB64.slice(28),
      },
    );

    const uploadTask = firebase
      .storage()
      .ref(`/users/${user.uid}/cv/${newId}-${file.name}`)
      .put(file);

    // let progress = 0;
    // toastId.current = toast('Firing up engines...', {
    //   progress,
    // });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // toast.update(toastId.current, {
        //   render: 'Uploading...',
        //   progress,
        //   hideProgressBar: false,
        // });
      },
      (error) => {
        console.log(error);
        setBackdropOpen(false);
      }, //toast.error(error),
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        const cvParams = {
          id: newId,
          idFile: `${newId}-${file.name}`,
          cv: downloadURL,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          updatedAt: firebase.database.ServerValue.TIMESTAMP,
          driveUrl: newUrl.data,
          user: user.uid,
          name: file.name,
          public: true,
          // isUpload: true,
        };
        firebase.database().ref(`resumes/${newId}`).set(cvParams);
        setBackdropOpen(false);

        // toast.update(toastId.current, {
        //   render: 'Your cv was uploaded successfully...',
        //   progress,
        //   autoClose: 2000,
        //   hideProgressBar: true,
        // });
      },
    );
  };

  const handleDeleteCv = async () => {
    await firebase.database().ref(`/resumes/${dataDetail.id}`).remove();
    setModalDelete(false);
  };

  const handleDuplicate = async () => {
    const id = uuid();
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    const resume = {
      ...dataDetail,
      id,
      name: `${dataDetail.name} Copy`,
      preview,
      createdAt,
      updatedAt: dataDetail.updatedAt - 1,
    };

    firebase.database().ref(`resumes/${id}`).set(resume);
    setMenuOpen(false);
  };

  const handleDownload = async (e) => {
    reactGA.event({
      category: 'CV Maker',
      action: 'User Download CV',
      value: user.uid,
    });

    e.preventDefault();
    setButtonLoading(true);
    const content = {
      id: dataDetail.id,
      type: 'multi',
    };
    if (dataDetail.driveUrl) {
      window.open(dataDetail.cv, '_blank');
      setButtonLoading(false);
    } else {
      await axios
        .post(
          'https://opykx6wgj0.execute-api.ap-southeast-1.amazonaws.com/v1/printResume',
          content,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then((response) => {
          const blob = b64toBlob(response.data, 'application/pdf');
          download(
            blob,
            `AkselResume-${dataDetail.name}.pdf`,
            'application/pdf',
          );
          setButtonLoading(false);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
    setExportOpen(false);
  };

  const handleShare = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1500);
  };

  const handleCopyAtsLink = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1500);
  };

  const handleDesktopModalClose = (event, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return;
    }

    setIsDesktopModalOpen(false);
  };

  const handleDesktopModalSubmit = () => {
    window.open('https://cv.aksel.co.id/', '_blank', 'noopener,noreferrer');
  };

  const handleReview = (d) => {
    setModalReview(true);
    setDataDetail(d);
  };

  if (loading)
    return (
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{ paddingTop: '25%' }}
      >
        <CircularProgress size={50} />
      </Grid>
    );

  return (
    <>
      {!isDesktop && (
        <>
          {modalBlock && (
            <div
              style={{
                height: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                padding: '0 30px',
              }}
            >
              <img
                src={laptopLogo}
                width='100px'
                height='100px'
                style={{ margin: '90px auto 41px auto' }}
                alt='desktop-logo'
              />
              <Typography variant='header' sx={{ color: '#252525' }}>
                Untuk saat ini
                <br /> ATS Checker hanya
                <br /> tersedia di Dektop.
              </Typography>
              <Typography
                variant='p'
                sx={{ mt: '25px', mb: '41px', color: '#252525' }}
              >
                Gunakan ATS Checker melalui dekstop dan cek apakah CV kamu
                terbaca oleh ATS dengan cepat dan mudah!
              </Typography>
              <CopyToClipboard
                text={window.location.href}
                onCopy={handleCopyAtsLink}
              >
                <Tooltip
                  open={tooltipOpen}
                  title={'Copied to clipboard!'}
                  leaveDelay={1500}
                >
                  <CustomButton>Copy Link</CustomButton>
                </Tooltip>
              </CopyToClipboard>
            </div>
          )}

          {!modalBlock && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                overflowX: 'hidden',
              }}
            >
              <CustomDialog
                title='Want to switch to CV Maker desktop version?'
                open={isDesktopModalOpen}
                onClose={handleDesktopModalClose}
                btnSubmitTxt='Go to desktop version'
                btnCancelTxt='Stay Here'
                handleSubmit={handleDesktopModalSubmit}
              />

              <Grid container style={{ padding: 0, maxWidth: '768px' }}>
                <Grid item xs={12}>
                  <div style={{ padding: '24px 0 0 24px' }}>
                    <Typography variant='mainHeader' gutterBottom>
                      Aksel CV Maker
                    </Typography>
                    <Typography
                      variant='p'
                      sx={{ color: '#252525', padding: '0 24px 17px 0' }}
                    >
                      Create you own professional CV free and finish it within
                      30 minutes.
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingRight: '24px',
                      }}
                    >
                      <Typography
                        variant='subHeader16'
                        sx={{ color: '#252525' }}
                      >
                        My CV
                      </Typography>

                      {resumes.length ? (
                        view === 'carousel' ? (
                          <ButtonView
                            label='Carousel View'
                            icon={<CarouselIcon />}
                            handleClick={() => setView('list')}
                          />
                        ) : (
                          <ButtonView
                            label='List View'
                            icon={<ListViewIcon />}
                            handleClick={() => setView('carousel')}
                          />
                        )
                      ) : null}
                    </div>
                  </div>
                  <Container
                    style={{
                      display: view === 'carousel' ? 'flex' : 'block',
                      overflowX: view === 'carousel' ? 'scroll' : 'hidden',
                      padding: 0,
                      marginBottom: 63,
                    }}
                  >
                    {resumes.length ? (
                      view === 'carousel' ? (
                        resumes.map((idx) =>
                          idx.isUpload ? null : (
                            <CardGrid
                              key={idx.id}
                              id={idx.id}
                              cover={idx.preview}
                              title={idx.name}
                              data={idx}
                              rightButton={<MoreIcon />}
                              subTitle={`Last Updated ${formatDistanceToNow(
                                idx.updatedAt,
                                { addSuffix: true },
                              )}`}
                              handleClick={() =>
                                navigate(`/app/cv/preview/${idx.id}`, {
                                  replace: true,
                                })
                              }
                              handleButton={handleButton}
                              isButtonCvReview
                              isButtonAtsChecker
                              handleReview={() => handleReview(idx)}
                              handleChangeTabAts={() => setModalBlock(true)}
                            />
                          ),
                        )
                      ) : (
                        resumes.map((idx) =>
                          idx.isUpload ? null : (
                            <ListGrid
                              key={idx.id}
                              id={idx.id}
                              cover={idx.preview}
                              title={idx.name}
                              data={idx}
                              rightButton={<MoreIcon />}
                              subTitle={`Last Updated ${formatDistanceToNow(
                                idx.updatedAt,
                                { addSuffix: true },
                              )}`}
                              handleClick={() =>
                                navigate(`/app/cv/preview/${idx.id}`, {
                                  replace: true,
                                })
                              }
                              handleButton={handleButton}
                              handleReview={() => handleReview(idx)}
                            />
                          ),
                        )
                      )
                    ) : (
                      <div style={{ textAlign: 'center', margin: 'auto' }}>
                        <p style={{ fontSize: '56px', margin: '10% 0 24px 0' }}>
                          &#x1F44B;
                        </p>
                        <Typography
                          variant='subHeader16'
                          gutterBottom
                          sx={{ color: '#323232' }}
                        >
                          Welcome to Your Dashboard!
                        </Typography>
                        <Typography variant='sm' sx={{ color: '#323232' }}>
                          Your CV will be here. Tap the “Create CV” <br />{' '}
                          button to add CV to your dashboard
                        </Typography>
                      </div>
                    )}
                  </Container>
                </Grid>
              </Grid>

              {/* bottom bar */}
              <BottomBar>
                <CustomButton
                  sx={{ padding: '5px 0px', width: '85% !important' }}
                  onClick={() => {
                    if (!user) {
                      localStorage.setItem('to', 'cvMaker');
                      navigate('/login');
                    } else {
                      setOpen(true);
                      setDataDetail({});
                    }
                  }}
                >
                  Create CV
                </CustomButton>
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    if (!user) {
                      localStorage.setItem('to', 'cvMaker');
                      navigate('/login');
                      return;
                    }
                    fileInputRef.current.click();
                  }}
                >
                  <UploadIcon size='40' />
                  <input
                    name='file'
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleCVUpload}
                  />
                </IconButton>
              </BottomBar>

              {/* Dialog */}
              <CustomDialog
                title='Name your CV'
                open={open}
                onClose={() => setOpen(false)}
                handleSubmit={handleSave}
                btnSubmitTxt={
                  modalLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress size='24px' sx={{ color: '#fff' }} />
                    </div>
                  ) : (
                    'Save'
                  )
                }
              >
                <TextField
                  autoFocus
                  margin='dense'
                  label='Your CV’s name'
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                  defaultValue={dataDetail.name}
                  required
                />
              </CustomDialog>

              <CustomDialog
                title='Are you sure to delete CV?'
                description='Your CV will be removed'
                open={modalDelete}
                onClose={() => setModalDelete(false)}
                btnSubmitTxt='Yes, delete CV'
                btnCancelTxt='Back to dashboard'
                handleSubmit={handleDeleteCv}
                isReverseButton
              />

              {/* Popover */}
              <CustomPopover
                open={menuOpen}
                anchorEl={anchorEl}
                handleClose={() => setMenuOpen(false)}
                listMenu={
                  dataDetail.cv
                    ? [
                        {
                          label: 'Rename',
                          handle: () => {
                            setOpen(true);
                            setMenuOpen(false);
                          },
                        },
                        {
                          label: 'Delete',
                          handle: () => {
                            setModalDelete(true);
                            setMenuOpen(false);
                          },
                        },
                      ]
                    : [
                        {
                          label: 'Rename',
                          handle: () => {
                            setOpen(true);
                            setMenuOpen(false);
                          },
                        },
                        {
                          label: 'Delete',
                          handle: () => {
                            setModalDelete(true);
                            setMenuOpen(false);
                          },
                        },
                        {
                          label: 'Duplicate',
                          handle: handleDuplicate,
                        },
                        {
                          label: 'Export',
                          handle: () => {
                            setExportOpen(true);
                            setMenuOpen(false);
                          },
                        },
                      ]
                }
              />

              <CustomDialog
                open={exportOpen}
                onClose={() => setExportOpen(false)}
                isCustomComponent
              >
                <DialogDownload
                  handleDownload={handleDownload}
                  handleShare={handleShare}
                  buttonLoading={buttonLoading}
                  cvLink={`https://cv.aksel.co.id/r/${dataDetail.id}`}
                  tooltipOpen={tooltipOpen}
                />
              </CustomDialog>

              {user && (
                <DialogCvReview
                  open={modalReview}
                  handleClose={() => setModalReview(false)}
                  data={dataDetail}
                />
              )}
            </div>
          )}
        </>
      )}

      {isDesktop && <Desktop />}

      <Backdrop
        sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state) => ({
  cvStep: state.cv.cvStep,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(DashboardCvMaker);
