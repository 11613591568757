import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';

import mentoringServices from 'src/templates/mentoringServices';
import careerMentoring from 'src/templates/careerMentoring';
import Button from 'src/components/button';
import Details from 'src/components/landing/Details';
import Footer from 'src/components/landing/Footer';
import CardServices from 'src/components/landing/CardServices';
import CardPersuasive from 'src/components/landing/CardPersuasive';
import ChecklistItem from 'src/components/landing/ChecklistItem';
import FlowSection from 'src/components/landing/FlowSection';
import Assessment from './Assessment';
import { useStyles } from './landingStyles';
import filterMentorEmpty from 'src/assets/img/filterMentorEmpty.png';
import { setNavbarTitle } from 'src/redux/actions';
import reactGA from 'react-ga';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});
// reactGA.pageview('/mentoring');

const Mentoring = () => {
  const classes = useStyles();
  const isTablet = useMediaQuery('(min-width:767px)');
  const [tabletView, setTabletView] = useState(isTablet);

  useEffect(() => {
    setNavbarTitle('Career Mentoring');
    setTabletView(isTablet);
  }, [isTablet]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', overflowX: 'hidden' }}
    >
      <Helmet>
        <title>Aksel - Buat CV ATS Friendly Gratis dan Mudah</title>
      </Helmet>
      <Grid container style={{ padding: 0, maxWidth: '768px' }}>
        <div className={classes.header}>
          <div className={classes.layer}>
            <Container>
              <Typography
                variant='mainHeader'
                style={{ paddingTop: '64px', color: '#fff', maxWidth: '402px' }}
              >
                Bimbingan Persiapan Karier dengan Mentor
              </Typography>
              <Typography
                variant='p'
                style={{ paddingTop: '9px', color: '#fff', maxWidth: '433px' }}
              >
                Masih bingung mengenai perjalanan kariermu?
                <br /> Konsultasikan dengan mentor-mentor terpilih dari berbagai
                industri dan profesi yang sesuai dengan karier impianmu!
              </Typography>
              <Button
                sx={{
                  marginBottom: '65px',
                  marginTop: '16px',
                }}
                variant='contained'
                color='secondary'
                to={'/app/mentoring'}
                component={RouterLink}
              >
                Pilih Mentor
              </Button>
            </Container>
          </div>
        </div>
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
            backgroundColor: '#F3F5FB',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Assessment />
        </Grid>
        {/* ---------- services ------------ */}
        <Grid item xs={12}>
          <div style={{ paddingLeft: 25, paddingTop: 32 }}>
            <Typography variant='header'>Layanan Career Mentoring</Typography>
            <Typography
              variant='p'
              style={{
                marginTop: 6,
                marginBottom: 16,
                color: 'rgba(0, 0, 0, 0.64)',
              }}
            >
              Kami menyediakan layanan mentoring untuk membantumu sukses dalam
              berkarier!
            </Typography>
          </div>
          <Container
            style={{
              display: 'flex',
              overflowX: 'scroll',
              padding: 0,
              paddingLeft: 25,
              marginBottom: 58,
            }}
          >
            {mentoringServices.map((idx) => (
              <CardServices key={idx.id} data={idx} isLong />
            ))}
          </Container>
        </Grid>
        {/* ------------- Career Plan ---------------- */}
        <Grid item xs={12}>
          <Container
            style={{
              marginTop: '11px',
              marginBottom: '50px',
              padding: '0 24px',
            }}
          >
            <Grid
              container
              xs={12}
              sx={{
                marginBottom: '24px',
                padding: '0 72px',
                backgroundColor: '#1D2D5E',
                textAlign: 'center',
                borderRadius: '16px',
              }}
            >
              <Grid item xs={12} sm={4}>
                <img
                  src={filterMentorEmpty}
                  alt='#'
                  style={{ width: '182.68px', margin: '24px 0 16px 0' }}
                />
              </Grid>
              <Grid item xs={12} sm={8} margin='auto'>
                <Typography variant='sectionHeader' gutterBottom color='#fff'>
                  Cari mentor untuk eventmu
                </Typography>
                <Typography
                  variant='p'
                  color='#fff'
                  style={{ fontSize: '16px' }}
                >
                  Kalian membutuhkan seseorang untuk menjadi speaker di webinar
                  atau kegiatan kalian?
                </Typography>
                <Button
                  sx={{ margin: `16px 0 ${tabletView ? '0' : '24px'} 0` }}
                  variant='contained'
                  color='secondary'
                  onClick={() =>
                    window.open(
                      'https://api.whatsapp.com/send/?phone=6282122391291&text=Halo%21+Saya+ingin+mengundang+mentor+Aksel+untuk+menjadi+pembicara+di+acara+kami.%0A%0ABerikut+mentor+yang+kami+perlukan%3A%0ANama+Mentor%3A%0ABudget+kami%3A%0AWaktu+%26+Tanggal+Acara%3A&app_absent=0',
                      '_blank',
                    )
                  }
                >
                  Hubungi kami!
                </Button>
              </Grid>
            </Grid>
            <Typography variant='header' sx={{ marginBottom: '22px' }}>
              Career Mentoring
            </Typography>
            <Grid container spacing={1}>
              {careerMentoring.map((idx, index) => (
                <ChecklistItem key={index} data={idx} />
              ))}
            </Grid>
          </Container>
        </Grid>
        {/* ------------- alur mentoring ------------ */}
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
            position: 'relative',
            color: '#fff',
          }}
        >
          <FlowSection />
        </Grid>
        {/* ----------- are u ready card ------------- */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: '#1D2D5E',
            height: '192px',
            position: 'relative',
            marginTop: '150px',
          }}
        >
          <CardPersuasive
            title='Siap mengakselerasi kariermu?'
            buttonText='Pilih Mentor'
            buttonColor='secondary'
            linkTo='/app/mentoring'
            isTablet={tabletView}
          >
            Konsultasikan perjalanan kariermu dan
            <br /> mulai suksesmu!
          </CardPersuasive>
        </Grid>
        <Grid item xs={12}>
          <Details />
        </Grid>
        <Footer />
      </Grid>
    </div>
  );
};

export default Mentoring;
