import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Collapse,
  IconButton,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from 'src/components/button';

const DialogFilter = ({
  handleModalOpen,
  modalOpen,
  countServiceLine,
  countIndustry,
  countSkills,
  handleReset,
  expanded2,
  reset,
  filterServiceLine,
  handleChange,
  expanded,
  filterIndustry,
  handleExpandClick,
  expanded1,
  filterSkills,
  handleSubmit,
}) => {
  return (
    <Dialog
      onClose={handleModalOpen}
      aria-labelledby='customized-dialog-title'
      open={modalOpen}
      scroll={'paper'}
      maxWidth={'sm'}
      fullWidth
      sx={{ zIndex: '10000000000 !important' }}
    >
      <DialogTitle id='scroll-dialog-title'>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleModalOpen} style={{ padding: '0' }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography gutterBottom variant='subHeader16' sx={{ m: 'auto 0' }}>
            Filters
          </Typography>
          <Button variant='text' onClick={handleReset}>
            <Typography
              gutterBottom
              variant='smBold'
              sx={{ cursor: 'pointer' }}
            >
              Hapus Filter
            </Typography>
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='subHeader14' sx={{ m: 'auto 0' }}>
            {`Layanan Mentoring (${countServiceLine})`}
          </Typography>
          <IconButton
            onClick={() => handleExpandClick(3)}
            aria-expanded={expanded2}
            aria-label='show more'
          >
            {expanded2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Collapse in={expanded2}>
          <FormControl component='fieldset'>
            <FormGroup>
              {!reset &&
                filterServiceLine.map((dataFilter, seq) => (
                  <FormControlLabel
                    key={seq}
                    id={`${dataFilter.name}-${seq}`}
                    control={
                      <Checkbox
                        id={`${dataFilter.name}-${seq}`}
                        defaultChecked={dataFilter.checked}
                        onChange={(e) => handleChange(e, 'serviceLine')}
                        value={dataFilter.value}
                        color='primary'
                      />
                    }
                    label={
                      <Typography variant='p'>{dataFilter.name}</Typography>
                    }
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Collapse>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='subHeader14' sx={{ m: 'auto 0' }}>
            {`Industri (${countIndustry})`}
          </Typography>
          <IconButton
            onClick={() => handleExpandClick(1)}
            aria-expanded={expanded}
            aria-label='show more'
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Collapse in={expanded}>
          <FormControl component='fieldset'>
            <FormGroup>
              {!reset &&
                filterIndustry.map((dataFilter, seq) => (
                  <FormControlLabel
                    key={seq}
                    id={`${dataFilter.name}-${seq}`}
                    control={
                      <Checkbox
                        id={`${dataFilter.name}-${seq}`}
                        defaultChecked={dataFilter.checked}
                        onChange={(e) => handleChange(e, 'industry')}
                        value={dataFilter.value}
                        color='primary'
                      />
                    }
                    label={
                      <Typography variant='p'>{dataFilter.name}</Typography>
                    }
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Collapse>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='subHeader14' sx={{ m: 'auto 0' }}>
            {`Keahlian (${countSkills})`}
          </Typography>
          <IconButton
            onClick={() => handleExpandClick(2)}
            aria-expanded={expanded1}
            aria-label='show more'
          >
            {expanded1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Collapse in={expanded1}>
          <FormControl component='fieldset'>
            <FormGroup>
              {!reset &&
                filterSkills.map((dataFilter, seq) => (
                  <FormControlLabel
                    key={seq}
                    id={`${dataFilter.name}-${seq}`}
                    control={
                      <Checkbox
                        id={`${dataFilter.name}-${seq}`}
                        defaultChecked={dataFilter.checked}
                        onChange={(e) => handleChange(e, 'skills')}
                        value={dataFilter.value}
                        color='primary'
                      />
                    }
                    label={
                      <Typography variant='p'>{dataFilter.name}</Typography>
                    }
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <div style={{ margin: 'auto', marginTop: '1rem' }}>
          <CustomButton onClick={handleSubmit}>Cari Mentor</CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFilter;
