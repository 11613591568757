import { Grid, Typography } from '@material-ui/core';

const ChecklistItem = ({ data }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Grid
        container
        style={{
          marginBottom: '10px',
          backgroundColor: '#F3F5FB',
          borderRadius: '8px',
          padding: '8px 16px',
          minHeight: '56px',
        }}
      >
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <svg
            width='20'
            height='16'
            viewBox='0 0 20 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.80889 15.5024C8.80889 15.5024 10.1389 15.3868 10.5133 14.5068C10.9006 13.5963 10.1678 13.2241 10.1678 13.2241C10.1678 13.2241 11.2311 13.2568 11.6244 12.1457C11.9956 11.0985 11.0922 10.618 11.0922 10.618C11.0922 10.618 12.1317 10.4474 12.435 9.34463C12.69 8.41629 11.7867 7.72463 11.7867 7.72463H18.5689C19.1017 7.72463 19.9994 7.42352 20 6.31185C20.0011 5.20074 19.0783 4.94685 18.5689 4.94685H7.18C6.62444 4.94685 6.62444 4.39129 7.18 4.39129H8.66111C10.7678 4.39129 12.0739 3.25907 12.4344 2.86296C12.7956 2.46685 12.9783 1.93685 12.8422 1.2963C12.6117 0.209629 11.7756 0.446851 11.0661 0.754629C9.35611 1.49741 6.91944 2.00685 5.76722 2.16185C2.51278 2.6013 0.00333459 5.38907 1.25656e-06 8.76407C-0.00222097 12.4452 2.94333 15.4996 6.62444 15.5024H8.80889Z'
              fill='#FFDC5D'
            />
          </svg>
        </Grid>
        <Grid item xs={11} style={{ margin: 'auto' }}>
          <Typography
            variant='p'
            sx={{ paddingTop: '3px', paddingLeft: '16px', color: '#252525' }}
          >
            {data}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChecklistItem;
