import { v4 as uuid } from 'uuid';
import { getUnsplashPhoto } from 'src/utils/utilities';
import financeCover from 'src/assets/img/templateCvMaker/finance.jpg';
import hrCover from 'src/assets/img/templateCvMaker/hr.jpg';
import marketingCover from 'src/assets/img/templateCvMaker/marketing.jpg';
import productCover from 'src/assets/img/templateCvMaker/product.jpg';
import blankCover from 'src/assets/img/templateCvMaker/blank.jpg';

export const cvMakerTemplates = [
  {
    id: 'finance',
    name: 'Finance',
    cover: financeCover,
  },
  {
    id: 'hr',
    name: 'HR',
    cover: hrCover,
  },
  {
    id: 'marketing',
    name: 'Marketing',
    cover: marketingCover,
  },
  {
    id: 'product',
    name: 'Product',
    cover: productCover,
  },
  {
    id: 'blank',
    name: 'Create Blank Resume',
    cover: blankCover,
  },
];

export const finance = async () => {
  return {
    awards: {
      heading: 'Awards',
      visible: true,
    },
    certifications: {
      heading: 'Certifications',
      visible: true,
    },
    education: {
      heading: 'Education',
      items: [
        {
          degree: 'Bachelor',
          endDate: '2022-06-01',
          field: 'of Science in Business Administration',
          gpa: '3.65/4.00',
          id: uuid(),
          institution: 'University of Aksel',
          startDate: '2018-06-01',
          summary:
            '- Majoring in Financial Management\n- Risk management certification level 1 by Badan ABC (issued on Aug 2020)\n- As a student exchange to University DFG South Korea for 11 months (2019)',
        },
      ],
      visible: true,
    },
    hobbies: {
      heading: 'Hobbies',
      visible: true,
    },
    informalEducation: {
      heading: 'Informal Education',
      visible: true,
    },
    languages: {
      heading: 'Languages',
      visible: true,
    },
    metadata: {
      colors: {
        background: '#FFFFFF',
        primary: '#212121',
        text: '#212121',
      },
      font: 'Nunito',
      fontSize: 12,
      language: 'en',
      layout: {
        castform: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        celebi: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        gengar: [
          ['objective', 'skills'],
          ['awards', 'certifications', 'languages', 'references', 'hobbies'],
          ['work', 'education', 'informalEducation', 'projects'],
        ],
        glalie: [
          ['awards', 'certifications', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'languages',
            'references',
          ],
        ],
        onyx: [['objective', 'work', 'education', 'projects', 'skills']],
        pikachu: [
          ['skills', 'languages', 'hobbies', 'awards', 'certifications'],
          ['work', 'education', 'informalEducation', 'projects', 'references'],
        ],
      },
      template: 'onyx',
    },
    objective: {
      body: `Performance-driven and highly accurate Finance Graduate with extensive knowledge and experience in nance, treasury, and accounting. Passionate in numbers. strong analytical skills, and ability to perform well in multitasking environments.`,
      heading: 'ABOUT ME',
      visible: true,
    },
    preview: await getUnsplashPhoto(),
    profile: {
      address: {
        city: 'Bandung, Indonesia',
        line1: '',
        line2: '',
        pincode: '123456',
      },
      birthDate: '',
      email: 'michael.faraday@gmail.com',
      firstName: 'Michael',
      heading: 'Profile',
      lastName: 'Faraday',
      linkedin: 'linkedin.com/in/michael-faraday',
      phone: '0856431723',
      photograph: '',
      subtitle: '',
      website: '',
    },
    projects: {
      heading: 'Projects',
      visible: true,
    },
    public: true,
    references: {
      heading: 'References',
      visible: true,
    },
    skills: {
      heading: 'Skills',
      items: [
        {
          id: uuid(),
          level: '',
          name: 'Microsoft Office',
        },
        {
          id: uuid(),
          level: '',
          name: 'Google Spreadsheets',
        },
        {
          id: uuid(),
          level: '',
          name: 'Quickbooks',
        },
        {
          id: uuid(),
          level: '',
          name: 'Trello',
        },
        {
          id: uuid(),
          level: '',
          name: 'Analytical Skills',
        },
        {
          id: uuid(),
          level: '',
          name: 'Multitasking',
        },
        {
          id: uuid(),
          level: '',
          name: 'Problem Solving',
        },
        {
          id: uuid(),
          level: '',
          name: 'Time Management',
        },
        {
          id: uuid(),
          level: '',
          name: 'Accounting',
        },
        {
          id: uuid(),
          level: '',
          name: 'Indonesian Taxation',
        },
        {
          id: uuid(),
          level: '',
          name: 'English Speaking and Writing',
        },
        {
          id: uuid(),
          level: '',
          name: 'Native Indonesia',
        },
      ],
      visible: true,
    },
    social: {
      heading: 'Social Network',
      visible: true,
    },
    work: {
      heading: 'Experiences',
      items: [
        {
          company: 'ABC Company',
          endDate: '2021-08-01',
          id: uuid(),
          isVisible: true,
          position: 'Finance Intern',
          startDate: '2021-01-01',
          summary:
            '- Collaborated with Finance Manager to manage account payable and account receivable on a weekly basis\n- Maintained 6 Company’s bank accounts and regularly reporting weekly transactions\n- Monitored and analyzed foreign exchange results and currency risk management',
          website: '',
        },
        {
          company: 'ABC Students Organization',
          endDate: '2021-08-01',
          id: uuid(),
          isVisible: true,
          position: 'Head of Treasury and Finance',
          startDate: '2020-10-01',
          summary: `- Assigned asHead of Department managing 10 people for a 1-year membership\n- Monitored, managed, and calculated monthly revenue and spending with IDR 85,000,000 in annual volume\n- Pro-actively contributed to business process and standard operations procedures from Finance perspective`,
          website: '',
        },
      ],
      visible: true,
    },
  };
};

export const hr = async () => {
  return {
    awards: {
      heading: 'Awards',
      visible: true,
    },
    certifications: {
      heading: 'Certifications',
      visible: true,
    },
    education: {
      heading: 'Education',
      items: [
        {
          degree: 'Bachelor',
          endDate: '2020-08-01',
          field: 'of Arts',
          gpa: '3.85/4.00',
          id: uuid(),
          institution: 'University of Aksel',
          startDate: '2016-08-01',
          summary: `- Majoring inPsychologywith cum laude\n- Awardee of ABC 4-years full scholarship\n- Actively leading Student Executive Board of University of Aksel from 2017-2019`,
        },
      ],
      visible: true,
    },
    hobbies: {
      heading: 'Hobbies',
      visible: true,
    },
    informalEducation: {
      heading: 'Informal Education',
      visible: true,
    },
    languages: {
      heading: 'Languages',
      visible: true,
    },
    metadata: {
      colors: {
        background: '#FFFFFF',
        primary: '#212121',
        text: '#212121',
      },
      font: 'Nunito',
      fontSize: 12,
      language: 'en',
      layout: {
        castform: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        celebi: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        gengar: [
          ['objective', 'skills'],
          ['awards', 'certifications', 'languages', 'references', 'hobbies'],
          ['work', 'education', 'informalEducation', 'projects'],
        ],
        glalie: [
          ['awards', 'certifications', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'languages',
            'references',
          ],
        ],
        onyx: [['objective', 'work', 'education', 'projects', 'skills']],
        pikachu: [
          ['skills', 'languages', 'hobbies', 'awards', 'certifications'],
          ['work', 'education', 'informalEducation', 'projects', 'references'],
        ],
      },
      template: 'onyx',
    },
    objective: {
      body: `Results-oriented and self-driven Psychology graduate with a comprehensive background in participating in the whole hiring process, learning & development, and selection tools creation. Individual with well-developed leadership and communication skills, highly professional attitude, excellent time management abilities, and strong determination to perform great work.`,
      heading: 'ABOUT ME',
      visible: true,
    },
    preview: await getUnsplashPhoto(),
    profile: {
      address: {
        city: 'Surabaya, Indonesia',
        line1: '',
        line2: '',
        pincode: '123456',
      },
      birthDate: '',
      email: 'melissa.franklin@gmail.com',
      firstName: 'Melissa',
      heading: 'Profile',
      lastName: 'Franklin',
      linkedin: 'linkedin.com/in/melissa-franklin',
      phone: '+6289876543',
      photograph: '',
      subtitle: '',
      website: '',
    },
    projects: {
      heading: 'Projects',
      visible: true,
    },
    public: true,
    references: {
      heading: 'References',
      visible: true,
    },
    skills: {
      heading: 'Skills',
      items: [
        {
          id: uuid(),
          level: '',
          name: 'Microsoft Office',
        },
        {
          id: uuid(),
          level: '',
          name: 'HR Management Software',
        },
        {
          id: uuid(),
          level: '',
          name: 'Recruitment',
        },
        {
          id: uuid(),
          level: '',
          name: 'Tools Creation',
        },
        {
          id: uuid(),
          level: '',
          name: 'People Management Strategy',
        },
        {
          id: uuid(),
          level: '',
          name: 'Training Needs Analysis',
        },
        {
          id: uuid(),
          level: '',
          name: 'English Speaking and Writing',
        },
        {
          id: uuid(),
          level: '',
          name: 'Native Bahasa Indonesia',
        },
      ],
      visible: true,
    },
    social: {
      heading: 'Social Network',
      visible: true,
    },
    work: {
      heading: 'Experiences',
      items: [
        {
          company: 'ABC Company',
          endDate: '2021-08-01',
          id: uuid(),
          isVisible: true,
          position: 'Recruitment Ofcer',
          startDate: '2020-10-01',
          summary: `- Developed and implemented new HR policies and procedures to ensure that the company's staffing needs and goals were always met.\n- Working on hiring for 15 office branches all over Indonesia, resulting on average 3 hires/month\n- Maintained files and records for hiring purposes`,
          website: '',
        },
        {
          company: 'ABC Agency',
          endDate: '2020-10-01',
          id: uuid(),
          isVisible: true,
          position: 'Human Resources Intern',
          startDate: '2020-07-01',
          summary: `- Collaborated with Recruitment Team to recruit 20 Management Trainees\n- Conducted selection tools creation projectto support hiring process in the company`,
          website: '',
        },
      ],
      visible: true,
    },
  };
};

export const marketing = async () => {
  return {
    awards: {
      heading: 'Awards',
      visible: true,
    },
    certifications: {
      heading: 'Certifications',
      visible: true,
    },
    education: {
      heading: 'Education',
      items: [
        {
          degree: 'Bachelor',
          endDate: '2022-06-01',
          field: 'of Management',
          gpa: '3.65/4.00',
          id: uuid(),
          institution: 'University of Aksel',
          startDate: '2018-06-01',
          summary: `- Majoring in Marketing\n- Winner of several business plan competitions, such as ABC Competitions\n- Actively involved in Model United Nations University of Aksel since 2018`,
        },
      ],
      visible: true,
    },
    hobbies: {
      heading: 'Hobbies',
      visible: true,
    },
    informalEducation: {
      heading: 'Informal Education',
      visible: true,
    },
    languages: {
      heading: 'Languages',
      visible: true,
    },
    metadata: {
      colors: {
        background: '#FFFFFF',
        primary: '#212121',
        text: '#212121',
      },
      font: 'Nunito',
      fontSize: 12,
      language: 'en',
      layout: {
        castform: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        celebi: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        gengar: [
          ['objective', 'skills'],
          ['awards', 'certifications', 'languages', 'references', 'hobbies'],
          ['work', 'education', 'informalEducation', 'projects'],
        ],
        glalie: [
          ['awards', 'certifications', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'languages',
            'references',
          ],
        ],
        onyx: [['objective', 'work', 'education', 'projects', 'skills']],
        pikachu: [
          ['skills', 'languages', 'hobbies', 'awards', 'certifications'],
          ['work', 'education', 'informalEducation', 'projects', 'references'],
        ],
      },
      template: 'onyx',
    },
    objective: {
      body: `A fresh graduate with a range of experiences in the Marketing field. Creative and results-driven individual, capable of developing new digital marketing strategies and plans, monitoring and analyzing the performance of the campaigns, and producing weekly sales reports.`,
      heading: 'ABOUT ME',
      visible: true,
    },
    preview: await getUnsplashPhoto(),
    profile: {
      address: {
        city: 'Jakarta, Indonesia',
        line1: '',
        line2: '',
        pincode: '123455',
      },
      birthDate: '',
      email: 'wade.warren@gmail.com',
      firstName: 'Wade',
      heading: 'Profile',
      lastName: 'Warren',
      linkedin: 'linkedin.com/in/wade-warren',
      phone: '087654321',
      photograph: '',
      subtitle: '',
      website: '',
    },
    projects: {
      heading: 'Projects',
      visible: true,
    },
    public: true,
    references: {
      heading: 'References',
      visible: true,
    },
    skills: {
      heading: 'Skills',
      items: [
        {
          id: uuid(),
          level: '',
          name: 'Organizing and Planning',
        },
        {
          id: uuid(),
          level: '',
          name: 'Google SEO',
        },
        {
          id: uuid(),
          level: '',
          name: 'Adobe Illustrator',
        },
        {
          id: uuid(),
          level: '',
          name: 'Adobe Photoshop',
        },
        {
          id: uuid(),
          level: '',
          name: 'HubSpot Marketing',
        },
        {
          id: uuid(),
          level: '',
          name: 'Campaign Management',
        },
        {
          id: uuid(),
          level: '',
          name: 'English Speaking and Writing',
        },
        {
          id: uuid(),
          level: '',
          name: 'Native Indonesia',
        },
      ],
      visible: true,
    },
    social: {
      heading: 'Social Network',
      visible: true,
    },
    work: {
      heading: 'Experiences',
      items: [
        {
          company: 'ABC University’s Art Society',
          endDate: '2021-08-01',
          id: uuid(),
          isVisible: true,
          position: 'Instagram Account Manager',
          startDate: '2021-08-01',
          summary: `- Collaborated with 2 other team members to run the community’s Instagram account.\n- Assisted Social Media Team Leader to create a content plan and strategy resulted in growing 300% new followers in 2 months`,
          website: '',
        },
        {
          company: 'ABC Company',
          endDate: '2021-01-01',
          id: uuid(),
          isVisible: true,
          position: 'Marketing Intern',
          startDate: '2020-06-01',
          summary: `- Developed and implemented email marketing campaign which increased customer base by 40% within internship period\n- Collaborated with colleagues on the creation of annual/monthly digital marketing strategies and plans, content writing, ensuring the quality and relevancy of content\n- Managed and grew engagement on multiple social media accounts, including Youtube, Instagram, and Twitter with an average increase of 20% increased weekly`,
          website: '',
        },
      ],
      visible: true,
    },
  };
};

export const product = async () => {
  return {
    awards: {
      heading: 'Awards',
      visible: true,
    },
    certifications: {
      heading: 'Certifications',
      visible: true,
    },
    education: {
      heading: 'Education',
      items: [
        {
          degree: 'Bachelor',
          endDate: '2022-08-01',
          field: 'of Management',
          gpa: '3.65/4.00',
          id: uuid(),
          institution: 'University of Aksel',
          startDate: '2018-08-01',
          summary: `- Majoring in Business Management with cum laude\n- Awardee of ABC full Scholarship 2018/2019\n- Delegation of XXX International Conference 2017, Frankfurt, Germany`,
        },
      ],
      visible: true,
    },
    hobbies: {
      heading: 'Hobbies',
      visible: true,
    },
    informalEducation: {
      heading: 'Informal Education',
      visible: true,
    },
    languages: {
      heading: 'Languages',
      visible: true,
    },
    metadata: {
      colors: {
        background: '#FFFFFF',
        primary: '#212121',
        text: '#212121',
      },
      font: 'Nunito',
      fontSize: 12,
      language: 'en',
      layout: {
        castform: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        celebi: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        gengar: [
          ['objective', 'skills'],
          ['awards', 'certifications', 'languages', 'references', 'hobbies'],
          ['work', 'education', 'informalEducation', 'projects'],
        ],
        glalie: [
          ['awards', 'certifications', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'languages',
            'references',
          ],
        ],
        onyx: [['objective', 'work', 'education', 'projects', 'skills']],
        pikachu: [
          ['skills', 'languages', 'hobbies', 'awards', 'certifications'],
          ['work', 'education', 'informalEducation', 'projects', 'references'],
        ],
      },
      template: 'onyx',
    },
    objective: {
      body: `A fresh graduate with product and project management experience as an intern in Start-Up Companies. A self-driven and purposeful individual with critical thinking and passion in product development. Eagerness on learning new things and positively contributing to projects given.`,
      heading: 'ABOUT ME',
      visible: true,
    },
    preview: await getUnsplashPhoto(),
    profile: {
      address: {
        city: 'Jakarta, Indonesia',
        line1: '',
        line2: '',
        pincode: '123451',
      },
      birthDate: '',
      email: 'peter.maxwell@gmail.com',
      firstName: 'Peter',
      heading: 'Profile',
      lastName: 'Maxwell',
      linkedin: 'linkedin.com/in/peter-maxwell',
      phone: '+6281234567',
      photograph: '',
      subtitle: '',
      website: '',
    },
    projects: {
      heading: 'Projects',
      visible: true,
    },
    public: true,
    references: {
      heading: 'References',
      visible: true,
    },
    skills: {
      heading: 'Skills',
      items: [
        {
          id: uuid(),
          level: '',
          name: 'Product Development Cycle',
        },
        {
          id: uuid(),
          level: '',
          name: 'Interpersonal',
        },
        {
          id: uuid(),
          level: '',
          name: 'Research and Development',
        },
        {
          id: uuid(),
          level: '',
          name: 'Project Management',
        },
        {
          id: uuid(),
          level: '',
          name: 'Content Writing',
        },
        {
          id: uuid(),
          level: '',
          name: 'Leadership',
        },
        {
          id: uuid(),
          level: '',
          name: 'English Speaking and Writing',
        },
        {
          id: uuid(),
          level: '',
          name: 'Native Bahasa Indonesia',
        },
      ],
      visible: true,
    },
    social: {
      heading: 'Social Network',
      visible: true,
    },
    work: {
      heading: 'Experiences',
      items: [
        {
          company: 'AKSEL',
          endDate: '2021-08-01',
          id: uuid(),
          isVisible: true,
          position: 'Product Intern',
          startDate: '2021-01-01',
          summary: `- Developing strategy on 2 new product swithin 6 months with Operations and Tech Team\n- Creating product research, competitive analysis, positioning and differentiation in current market to expand visibility\n- Researched competitive solutions and maintained competitive market comparisons and evaluations`,
          website: '',
        },
        {
          company: 'ABC Organization',
          endDate: '2021-01-01',
          id: uuid(),
          isVisible: true,
          position: 'Project Associate',
          startDate: '2020-10-01',
          summary: `- Working with 5 other team members to run a project on Financial Inclusion for under privileged citizen in Greater Jakarta Area\n- Successfully raise IDR 56,000,000 funding for project development sponsored by private sectors’ through Corporate Social Responsibility (CSR) program\n- Engaged more than 100+ families to join the program`,
          website: '',
        },
      ],
      visible: true,
    },
  };
};

export const blank = async () => {
  return {
    awards: {
      heading: 'Awards',
      visible: true,
    },
    certifications: {
      heading: 'Certifications',
      visible: true,
    },
    education: {
      heading: 'Education',
      visible: true,
    },
    hobbies: {
      heading: 'Hobbies',
      visible: true,
    },
    informalEducation: {
      heading: 'Informal Education',
      visible: true,
    },
    languages: {
      heading: 'Languages',
      visible: true,
    },
    metadata: {
      colors: {
        background: '#FFFFFF',
        primary: '#212121',
        text: '#212121',
      },
      font: 'Nunito',
      fontSize: 12,
      language: 'en',
      layout: {
        castform: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        celebi: [
          ['awards', 'certifications', 'languages', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'references',
          ],
        ],
        gengar: [
          ['objective', 'skills'],
          ['awards', 'certifications', 'languages', 'references', 'hobbies'],
          ['work', 'education', 'informalEducation', 'projects'],
        ],
        glalie: [
          ['awards', 'certifications', 'hobbies'],
          [
            'objective',
            'work',
            'education',
            'informalEducation',
            'skills',
            'projects',
            'languages',
            'references',
          ],
        ],
        onyx: [['objective', 'work', 'education', 'projects', 'skills']],
        pikachu: [
          ['skills', 'languages', 'hobbies', 'awards', 'certifications'],
          ['work', 'education', 'informalEducation', 'projects', 'references'],
        ],
      },
      template: 'onyx',
    },
    objective: {
      body: '',
      heading: 'ABOUT ME',
      visible: true,
    },
    preview: await getUnsplashPhoto(),
    profile: {
      address: {
        city: '',
        line1: '',
        line2: '',
        pincode: '',
      },
      birthDate: '',
      email: '',
      firstName: '',
      heading: 'Profile',
      lastName: '',
      linkedin: '',
      phone: '',
      photograph: '',
      subtitle: '',
      website: '',
    },
    projects: {
      heading: 'Projects',
      visible: true,
    },
    public: true,
    references: {
      heading: 'References',
      visible: true,
    },
    skills: {
      heading: 'Skills',
      visible: true,
    },
    social: {
      heading: 'Social Network',
      visible: true,
    },
    work: {
      heading: 'Experiences',
      visible: true,
    },
  };
};
