import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: '3px !important',
    left: 'calc(-50% + 0px) !important',
    right: 'calc(50% + 0px) !important',
  },
  active: {
    '& $line': {
      backgroundColor: '#1D2D5E',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#1D2D5E',
    },
  },
  line: {
    height: '8px',
    border: '0',
    backgroundColor: '#BDBDBD',
    borderRadius: '1px',
  },
})(StepConnector);

const ColorlibConnectorTablet = withStyles({
  alternativeLabel: {
    top: '3px !important',
    left: 'calc(-50% + 0px) !important',
    right: 'calc(50% + 0px) !important',
  },
  active: {
    '& $line': {
      backgroundColor: '#1D2D5E',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#1D2D5E',
    },
  },
  line: {
    height: '8px',
    border: '0',
    backgroundColor: '#BDBDBD',
    borderRadius: '1px',
    marginTop: '8px',
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#fff',
    width: 13,
    height: 13,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
  },
  active: { backgroundColor: '#1D2D5E' },
  completed: { backgroundColor: '#1D2D5E' },
  tablet: { width: '33px !important', height: '33px !important' },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const isTablet = useMediaQuery('(min-width:768px)');
  const [tabletView, setTabletView] = useState(isTablet);

  useEffect(() => {
    setTabletView(isTablet);
  }, [isTablet]);

  const styleColor =
    active || completed ? { color: '#fff' } : { color: '#BDBDBD' };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.tablet]: isTablet,
      })}
      style={styleColor}
    >
      {tabletView && <Typography>{props.icon}</Typography>}
    </div>
  );
}

const CustomStepper = ({ steps, activeStep, children }) => {
  const isTablet = useMediaQuery('(min-width:768px)');
  const [tabletView, setTabletView] = useState(isTablet);

  useEffect(() => {
    setTabletView(isTablet);
  }, [isTablet]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflowX: 'hidden',
      }}
    >
      <Grid container style={{ padding: 0, maxWidth: '1280px' }}>
        <Grid item xs={12}>
          <div
            style={{
              padding: '18px 24px 8px 24px',
              color: '#fff',
            }}
          >
            {!tabletView && (
              <Typography variant='p'>{steps[activeStep]}</Typography>
            )}
            <Stepper
              style={{ marginTop: '58px' }}
              alternativeLabel
              activeStep={activeStep}
              connector={
                tabletView ? <ColorlibConnectorTablet /> : <ColorlibConnector />
              }
            >
              {steps.map((label, i) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {tabletView ? (
                      <Typography
                        variant='p'
                        sx={{
                          fontFamily: 'Roboto',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          lineHeight: '22px',
                          textAlign: 'center',
                          letterSpacing: '0.1px',
                          color: i <= activeStep ? '#1D2D5E' : '#BDBDBD',
                        }}
                      >
                        {label.slice(3)}
                      </Typography>
                    ) : (
                      ' '
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div style={{ padding: '0 24px' }}>{children}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomStepper;
