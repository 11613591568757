import { useState } from 'react';
import {
  Grid,
  IconButton,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Divider,
  Stack,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { makeStyles } from '@material-ui/styles';
import CustomButton from 'src/components/button';
import voucherCode from 'src/templates/vouchers';
import axios from 'axios';
import reactGA from 'react-ga';
import atsPackages from 'src/templates/atsPackages';
import CustomDialog from 'src/components/cvMaker/CustomDialog';
// import CustomInput from 'src/components/forms/CustomInput';
import firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});

const useStyles = makeStyles(() => ({
  packetCard: {
    width: '320px',
    height: '329px',
    border: '0.5px solid #1D2D5E',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px rgba(37, 37, 37, 0.2)',
    borderRadius: '12px',
    padding: '32px 27px',
    marginBottom: '32px !important',
    marginRight: '32px !important',
  },
}));

const numberWithCommas = (x) => {
  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};

const PilihPaket = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [loadingPayment, setLoadingPayment] = useState('');
  const [atsPackage, setAtsPackage] = useState({
    pkgId: '',
    duration: 0,
    price: 0,
    pkgName: '',
  });
  const [isVoucher, setIsVoucher] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [voucherValue, setVoucherValue] = useState('');
  const [open, setOpen] = useState(false);
  const [redeemOpen, setRedeemOpen] = useState(false);
  const [redeemCode, setRedeemCode] = useState('');
  const [redeemDetail, setRedeemDetail] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);

  const handlePayment = (pkgId, duration, price, pkgName) => {
    setLoadingPayment(pkgId);

    let firstName;
    let lastName;

    if (user != null) {
      [firstName, lastName] = user.displayName.split(' ');
      const voucherFinal = isVoucher ? voucherValue : '';

      const content = {
        customerFirstName: firstName,
        customerLastName: lastName,
        customerEmail: user.email,
        customerId: user.uid,
        productId: pkgId,
        productName: pkgName,
        productPrice: price,
        bookTime: duration, // how many times can check
        transactionType: 'ats', // flagging ats checker
        voucherCode: voucherFinal || redeemCode,
      };

      setButtonLoading(true);
      if (voucherFinal) {
        const voucherRef = firebase.database().ref(`vouchers/${voucherFinal}`);
        // check voucher belum sampai max
        voucherRef.transaction((postVoucher) => {
          if (!postVoucher) {
            return {};
          }

          // nambahin ke voucer counter & daftarin usernya
          postVoucher.counter++;
          const currUsers = JSON.parse(postVoucher.users);
          postVoucher.users = JSON.stringify([...currUsers, user.uid]);
          return postVoucher;
        });
      }

      const paymentServer =
        process.env.REACT_APP_DEPLOYED === 'dev'
          ? 'https://aksel-payment-staging.herokuapp.com/create/invoice'
          : 'https://aksel-payment.herokuapp.com/create/invoice';
      // const paymentServer =
      //   process.env.REACT_APP_DEPLOYED === 'dev'
      //     ? 'https://aksel-payment-staging.herokuapp.com/token'
      //     : 'https://aksel-payment.herokuapp.com/token';

      axios
        .post(paymentServer, content, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          setButtonLoading(false);
          const resp = response.data;
          setLoadingPayment('');
          handleClose()
          setPaymentUrl(resp.resp.invoice_url)
          // window.snap.pay(resp.transactionToken);

          reactGA.event({
            category: 'ATS Checker',
            action: 'User Buy ATS Checker',
            label: user.uid,
          });
        })
        .catch((e) => {
          setButtonLoading(false);
          alert(
            'Cannot connecting to payment. Please contact customer support or wait a minutes.',
          );
          setLoadingPayment('');
        });
    } else {
      alert('User does not exist. Please contact customer support');
      setButtonLoading(false);
      setLoadingPayment('');
    }
  };

  const handleDialogOpen = (pkgId, duration, price, pkgName) => {
    setAtsPackage({
      pkgId,
      duration,
      price,
      pkgName,
    });
    setTotalPrice(price);
    setOpen(true);
  };

  const handleClose = () => {
    setVoucherValue('');
    setOpen(false);
  };

  const handleSubmitVoucher = async () => {
    setTotalPrice(atsPackage.price);
    const ref = firebase.database().ref();
    const resVoucher = await ref.child('vouchers').child(voucherValue).get();

    if (!resVoucher.val()) return alert('Voucher tidak ditemukan');

    // check voucher code in date range
    const epochNow = Math.round(new Date().getTime() / 1000);
    const {
      startAt,
      closeAt,
      id,
      users,
      types,
      appliesTo,
      discount,
      counter,
      max,
    } = resVoucher.val();

    if (epochNow < startAt || epochNow > closeAt) {
      alert('Voucher expired');
      return;
    }

    if (JSON.parse(users).includes(user.uid)) {
      alert('Voucher sudah Anda digunakan');
      return;
    }

    if (counter >= max) {
      alert('Voucher sudah mencapai batas penggunaan');
      return;
    }

    if (id) {
      // cek jika voucher kodenya bener
      const userExist = JSON.parse(users).findIndex(
        (user) => user === user.uid,
      );
      if (userExist === -1) {
        if (types.indexOf('atsc') !== -1) {
          if (appliesTo.indexOf(atsPackage.pkgId) !== -1) {
            if (discount > 1) {
              // kalau diatas 1.0 itu dianggap potongan langsung
              setTotalPrice(atsPackage.price - discount);
            } else {
              setTotalPrice(atsPackage.price * (1 - discount));
            }
            setIsVoucher(true);
          } else {
            alert('Voucher tidak berlaku untuk paket ini');
            return;
          }
        } else {
          alert('Voucher tidak ditemukan');
          return;
        }
      } else {
        alert('Anda sudah menggunakan kode ini');
      }
    } else {
      alert('voucher code gagal diterapkan');
    }

    reactGA.event({
      category: 'ATS Checker',
      action: 'User Set Voucher',
      label: user.uid,
    });

    // let code = voucherCode.filter((c) => {
    //   if (!c.used) {
    //     if (c.code === voucherValue && c.type === 'atsc') {
    //       if (new Date() < new Date(c.until)) {
    //         if (c.appliesTo.indexOf(atsPackage.pkgId) !== -1) {
    //           c.used = true;
    //           return true;
    //         } else {
    //           alert('Voucher ini tidak berlaku untuk paket ini');
    //         }
    //       } else {
    //         alert('Voucher ini sudah kadaluarsa');
    //       }
    //     }
    //   }

    //   return false;
    // })[0];

    // if (code) {
    //   if (code.discount > 1) {
    //     // kalau diatas 1.0 itu dianggap potongan langsung
    //     setTotalPrice(atsPackage.price - code.discount);
    //   } else {
    //     setTotalPrice(atsPackage.price * (1 - code.discount));
    //   }
    //   setIsVoucher(true);

    //   reactGA.event({
    //     category: 'ATS Checker',
    //     action: 'User Set Voucher',
    //     label: user.uid,
    //   });
    // } else {
    //   setVoucherValue('');
    //   alert(`Voucher ${voucherValue} gagal diterapkan`);
    // }
  };

  const cancelVoucher = () => {
    setTotalPrice(atsPackage.price);

    let code = voucherCode.filter((c) => {
      if (c.used) {
        if (c.code === voucherValue && c.type === 'atsc') {
          c.used = false;
          return true;
        }
      }

      return false;
    })[0];

    if (code) {
      setVoucherValue('');
      setIsVoucher(false);
    }
    setVoucherValue('');
    setIsVoucher(false);
  };

  const handleSubmitRedeemCode = async () => {
    const ref = firebase.database().ref();
    const resRedeem = await ref.child('vouchers').child(redeemCode).get();

    if (!resRedeem.val()) return alert('Voucher tidak ditemukan');
    if (resRedeem.val()) {
      const index = resRedeem.val().types.indexOf('redeem-atsc');
      if (index === -1) {
        alert('Voucher tidak ditemukan');
        return;
      }
    }

    // check redeem code in date range
    const epochNow = Math.round(new Date().getTime() / 1000);
    const { startAt, closeAt, id, users } = resRedeem.val();

    if (epochNow < startAt || epochNow > closeAt) {
      alert('Voucher expired');
      return;
    }

    if (JSON.parse(users).includes(user.uid)) {
      alert('Voucher sudah Anda digunakan');
      return;
    }

    if (id) {
      // cek jika redeem kodenya bener
      const userExist = JSON.parse(users).findIndex(
        (user) => user === user.uid,
      );
      if (userExist === -1) {
        setRedeemDetail(resRedeem.val());
      } else {
        alert('Anda sudah menggunakan kode ini');
      }
    } else {
      alert('reedem code gagal diterapkan');
    }
  };

  const handleProcessRedeem = async () => {
    const bookingsRef = firebase.database().ref('bookings');
    const paymentRef = firebase.database().ref('payments');
    const voucherRef = firebase.database().ref(`vouchers/${redeemCode}`);
    if (redeemDetail.id) {
      const orderId = uuid();
      const payload = {
        bookId: orderId,
        customerId: user.uid,
        customerEmail: user.email || '',
        productId: `redeem-ats|${redeemCode}`,
        productPrice: `${redeemDetail.discount}`,
        productName: `redeem-ats|${redeemCode}`,
        productPhoneNumber: '',
        productEmail: '',
        bookStatus: 'settlement',
        bookTime: redeemDetail.bookTime || 1, //ats checker: parse times
        bookNotes: '',
        transactionType: 'ats',
        packageId: redeemCode,
        voucherCode: redeemCode,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
      };
      const paymentData = {
        createdAt: firebase.database.ServerValue.TIMESTAMP,
        fraudStatus: 'accept',
        orderId: orderId,
        paymentType: 'redeem',
        transactionId: orderId,
        transactionStatus: 'settlement',
        transactionTime: new Date().toISOString().replace('T', ' '),
      };
      setButtonLoading(true);

      // check voucher belum sampai max
      voucherRef.transaction((postVoucher) => {
        if (!postVoucher) {
          return {};
        }

        if (postVoucher.counter >= postVoucher.max) {
          alert('Voucher sudah mencapai batas penggunaan');
          setButtonLoading(false);
          return;
        }

        // nambahin ke voucer counter & daftarin usernya
        postVoucher.counter++;
        const currUsers = JSON.parse(postVoucher.users);
        postVoucher.users = JSON.stringify([...currUsers, user.uid]);

        paymentRef
          .child(orderId)
          .set(paymentData)
          .then(() => {
            bookingsRef.child(orderId).set(payload, (error) => {
              if (error) {
                console.error('Booking data could not be saved.' + error);
                alert('Booking data could not be saved.');
                setRedeemOpen(false);
                setButtonLoading(false);
              } else {
                console.log('Booking data saved successfully.');
                setRedeemOpen(false);
                setButtonLoading(false);
                window.location.reload();
              }
            });
          });
        return postVoucher;
      });
    }
  };

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{ minHeight: '400px' }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        sx={{ maxWidth: '450px', margin: '0 auto' }}
      >
        <div style={{ padding: '0 24px' }}>
          <Typography
            variant='sectionHeader'
            sx={{ margin: '24px 0', textAlign: 'left', color: '#252525' }}
          >
            Detail Pesanan
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{ position: 'absolute', top: '16px', right: '24px' }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <Typography
            gutterBottom
            variant='h6'
            component='h6'
            sx={{ color: '#252525' }}
          >
            Gunakan Kode Promo
          </Typography>
          <div>
            <TextField
              size='small'
              fullWidth
              placeholder='Masukan kode promo'
              margin='normal'
              name='confirm'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              sx={{
                display: 'inline-block',
                width: '68%',
                marginRight: '2%',
                marginTop: 0,
              }}
              value={voucherValue}
              onChange={(e) => setVoucherValue(e.target.value)}
              disabled={isVoucher}
            />
            <Button
              variant='outlined'
              color='primary'
              disableElevation
              onClick={isVoucher ? cancelVoucher : handleSubmitVoucher}
              sx={{
                float: 'right',
                display: 'inline-block',
                textTransform: 'capitalize',
                verticalAlign: 'middle',
                borderRadius: '8px',
                width: '30%',
                height: 40,
                color: '#1D2D5E',
              }}
            >
              {isVoucher && 'Batalkan'}
              {!isVoucher && 'Terapkan'}
            </Button>
          </div>
          <Divider style={{ margin: '20px 0' }} />
          <Typography
            gutterBottom
            variant='p'
            component='p'
            sx={{ color: '#252525' }}
          >
            {atsPackage.pkgName}{' '}
            <span style={{ float: 'right' }}>
              Rp{numberWithCommas(atsPackage.price)}
            </span>
          </Typography>
          {totalPrice !== atsPackage.price ? (
            <Typography
              gutterBottom
              variant='p'
              component='p'
              sx={{ color: '#252525' }}
            >
              Diskon{' '}
              <span style={{ float: 'right' }}>
                -Rp{numberWithCommas(atsPackage.price - totalPrice)}
              </span>
            </Typography>
          ) : null}
          <Divider style={{ margin: '20px 0' }} />
          <Typography
            gutterBottom
            variant='h6'
            component='h6'
            sx={{ color: '#252525' }}
          >
            Total Biaya{' '}
            <span style={{ float: 'right' }}>
              Rp{numberWithCommas(totalPrice)}
            </span>
          </Typography>
          <DialogActions
            style={{
              justifyContent: 'center',
              marginBottom: '16px',
              paddingLeft: '0',
              paddingRight: '0',
              flexDirection: 'column',
            }}
          >
            <CustomButton
              fullWidth
              sx={{
                padding: '6px 0',
                marginTop: '16px',
              }}
              disabled={buttonLoading}
              onClick={() =>
                handlePayment(
                  atsPackage.pkgId,
                  atsPackage.duration,
                  totalPrice,
                  atsPackage.pkgName,
                )
              }
            >
              {buttonLoading ? (
                <CircularProgress sx={{ color: '#FFF' }} />
              ) : (
                'Bayar'
              )}
            </CustomButton>
          </DialogActions>
        </div>
      </Dialog>
      <Grid
        container
        justifyContent='space-evenly'
        style={{ marginTop: '52px' }}
      >
        {atsPackages.map((pkg) => (
          <Grid
            item
            className={classes.packetCard}
            style={{ backgroundColor: pkg.backgroundColor }}
            key={pkg.id}
          >
            <Grid>
              <Grid
                container
                direction='row'
                alignItems='center'
                style={{ marginBottom: '24px' }}
              >
                <img
                  src={pkg.icon}
                  alt={pkg.id}
                  style={{
                    marginRight: '8px',
                    width: '24px',
                    height: '24px',
                  }}
                />
                <Typography
                  style={{
                    width: '186px',
                    fontFamily: 'Montserrat',
                    fontWeight: '600',
                    fontSize: '22px',
                    lineHeight: '27px',
                    letterSpacing: '0.1px',
                    color: pkg.fontColor,
                  }}
                >
                  {pkg.name}
                </Typography>
              </Grid>

              <Grid style={{ marginBottom: '24px' }}>
                <Typography
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: '26px',
                    lineHeight: '36px',
                    letterSpacing: '0.1px',
                    color: pkg.fontColor,
                  }}
                >
                  Rp{numberWithCommas(pkg.price)}
                </Typography>
              </Grid>

              <Grid style={{ marginBottom: '24px' }}>
                {pkg.listDesc.map((desc) => (
                  <Grid container direction='row' alignItems='baseline'>
                    <img
                      src={pkg.iconPointer}
                      alt={'paket-pointer'}
                      style={{
                        marginRight: '8px',
                        width: '20px',
                        height: '15px',
                      }}
                    />
                    <Typography
                      style={{
                        width: '235px',
                        marginBottom: '8px',
                        fontFamily: 'Roboto',
                        fontWeight: 'normal',
                        fontSize: '15px',
                        lineHeight: '24px',
                        letterSpacing: '0.1px',
                        color: pkg.fontColor,
                      }}
                    >
                      {desc}
                    </Typography>
                  </Grid>
                ))}
              </Grid>

              <Grid>
                <Grid container justifyContent='center' alignItems='center'>
                  {loadingPayment === pkg.id ? (
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        cursor: 'pointer',
                        width: '221px',
                        height: '40px',
                        backgroundColor: pkg.backgroundButton,
                        borderRadius: '8px',
                        color: pkg.fontColorButton,
                      }}
                    >
                      <CircularProgress
                        size={20}
                        sx={{ color: pkg.fontColorButton }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        cursor: 'pointer',
                        width: '221px',
                        height: '40px',
                        backgroundColor: pkg.backgroundButton,
                        borderRadius: '8px',
                        color: pkg.fontColorButton,
                      }}
                      onClick={() => {
                        if (!user) {
                          localStorage.setItem('to', 'atsc');
                          navigate('/login');
                          return;
                        }
                        handleDialogOpen(
                          pkg.id,
                          pkg.duration,
                          pkg.price,
                          pkg.name,
                        );
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          lineHeight: '20px',
                          letterSpacing: '0.1px',
                        }}
                      >
                        Pilih Paket
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Stack>
        <CustomButton
          variant='outlined'
          startIcon={<ConfirmationNumberIcon />}
          onClick={() => {
            if (!user) {
              localStorage.setItem('to', 'atsc');
              navigate('/login');
              return;
            }
            setRedeemOpen(true);
          }}
        >
          Redeem Code
        </CustomButton>
      </Stack>

      <CustomDialog open={redeemOpen} isCustomComponent maxWidth='xs'>
        <Stack>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography
              variant='sectionHeader'
              sx={{ mt: '24px', mb: '16px', textAlign: 'center' }}
            >
              Input Redeem Code
            </Typography>
            <IconButton
              aria-label='close'
              onClick={() => {
                setRedeemOpen(false);
                setRedeemCode('');
                setRedeemDetail({});
                setButtonLoading(false);
              }}
              sx={{ position: 'absolute', top: '16px', right: '24px' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
            {/* <CustomInput
              label='Redeem Code'
              name='redeemCode'
              onChange={(e) => setRedeemCode(e.target.value)}
              fullWidth
            /> */}
            <TextField
              size='small'
              fullWidth
              placeholder='Redeem Code'
              margin='normal'
              name='confirm'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              sx={{
                display: 'inline-block',
                width: '68%',
                marginRight: '2%',
                marginTop: 0,
              }}
              onChange={(e) => setRedeemCode(e.target.value)}
            />
            <div
              style={{
                width: '41%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                marginBottom: '8px',
              }}
            >
              <CustomButton
                variant='contained'
                fullWidth
                onClick={handleSubmitRedeemCode}
              >
                Terapkan
              </CustomButton>
            </div>
          </Stack>
          <Divider style={{ margin: '20px 0' }} />
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography>Total ATS Checker</Typography>
            <Typography>{redeemDetail.bookTime || 0}</Typography>
          </Stack>
          <CustomButton
            variant='contained'
            sx={{ m: '24px 0 16px 0' }}
            disabled={!redeemDetail.id}
            onClick={handleProcessRedeem}
          >
            {buttonLoading ? (
              <CircularProgress sx={{ color: '#FFF' }} />
            ) : (
              'Proses'
            )}
          </CustomButton>
        </Stack>
      </CustomDialog>

      <Dialog
        open={paymentUrl}
        keepMounted
        fullWidth
        maxWidth='xs'
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>
          <Typography variant='h4'>Pembayaran</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setPaymentUrl(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: '500px',
            paddingTop: '56.25%',
          }}
        >
          <iframe
            src={paymentUrl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
            }}
            title='payment'
          ></iframe>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default PilihPaket;
