import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  IconButton,
  Dialog,
  Typography,
  Button,
  CardMedia,
  Card,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import firebase from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { usePopper } from 'react-popper';
// import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import CloseIcon from '@material-ui/icons/Close';
import SuperIcon from '../../assets/img/superMentorLabel.svg';

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 344,
  },
  bottomCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px 16px !important',
  },
  buttonPlay: {
    position: 'absolute',
    bottom: 24,
    left: 24,
  },
  chip: {
    margin: '5px 5px 5px 0',
    color: 'black !important',
  },
  chipMini: {
    margin: '5px 5px 5px 0',
    padding: '2px',
    height: '24px !important',
    border: 'none !important',
    backgroundColor: 'rgba(29, 45, 94, 0.8) !important',
    color: 'white !important',
  },
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: 'none !important',
    borderRadius: '8px !important',
  },
  cardClick: {
    position: 'absolute',
    height: 260,
    width: '100%',
    cursor: 'pointer',
    zIndex: 1,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 344,
    background:
      'linear-gradient(180deg, rgba(51, 51, 51, 0) 60%, #333333 100%)',
  },
  overlayText: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    color: 'white',
    textShadow: '0 0 5px black',
  },
  overlayTextLong: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    color: 'white',
    textShadow: '0 0 5px black',
  },
  heading: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    marginBottom: 5,
  },
  experiences: {
    paddingLeft: '2em',
  },
  tooltip: {
    backgroundColor: '#1D2D5E',
    borderRadius: '8px',
    padding: '8px 12px',
    width: '250px',
    color: '#FFFFFF',
  },
});

const CardImage = ({ product, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [video, setVideo] = useState({
    modal: false,
    url: '',
    title: '',
  });
  const [rating, setRating] = useState('');
  const [isHoverTooltip, setIsHoverTooltip] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const ref = firebase.database().ref();

  const superRef = useRef();
  const superTooltipRef = useRef();
  const superPopper = usePopper(superRef.current, superTooltipRef.current, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [100, 10],
        },
      },
    ],
  });

  useEffect(() => {
    const getRating = async () => {
      let rates = [];

      await ref
        .child('bookings')
        .orderByChild('productId')
        .equalTo(product.id)
        .once('value', (snapshot) => {
          snapshot.forEach((s) => {
            let b = s.val();

            if (b.rate) {
              rates.push(b.rate);
            }
          });
        });

      let sumRtg = rates.reduce((a, b) => a + b, 0);
      let avgRtg = sumRtg / rates.length || 0;

      setRating(avgRtg);
    };

    if (user) {
      getRating();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOpenVideo = (d) => {
  //   setVideo({
  //     modal: true,
  //     url: product.videoUrl,
  //     title: product.name,
  //   });
  // };

  const handleCard = (d) => {
    navigate(`/app/mentor/${d.id}`, { replace: false });
  };

  return (
    <Card className={classes.card} {...rest}>
      <CardMedia
        className={classes.media}
        image={product.photoUrl}
        title={product.name}
      >
        <div className={classes.overlay}>
          {parseInt(rating) > 0 ? (
            <Button
              size='small'
              variant='outlined'
              disabled
              sx={{
                float: 'right',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                position: 'absolute',
                top: 24,
                right: 24,
                border: 'None',
                backgroundColor: 'white',
                color: 'black !important',
              }}
            >
              <StarRoundedIcon sx={{ mr: 1, color: '#FACD11' }} />
              {rating.toFixed(1)}
            </Button>
          ) : null}
          <div
            className={classes.cardClick}
            onClick={() => handleCard(product)}
          />
          <div
            className={
              product.title.length > 45
                ? classes.overlayTextLong
                : classes.overlayText
            }
          >
            <Typography variant='p' component='p' className={classes.heading}>
              {product.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              {product.super && (
                <img
                  ref={superRef}
                  onMouseEnter={() => setIsHoverTooltip(true)}
                  onMouseLeave={() => setIsHoverTooltip(false)}
                  src={SuperIcon}
                  alt={'tooltip'}
                  style={{
                    marginRight: '4px',
                    width: '20px',
                    height: '20px',
                  }}
                />
              )}
              <Typography
                variant='h2'
                component='h2'
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                {product.name}
              </Typography>
            </Box>
            <Box
              className={classes.tooltip}
              ref={superTooltipRef}
              style={{
                ...superPopper.styles.popper,
                visibility: isHoverTooltip === true ? 'visible' : 'hidden',
                pointerEvents: 'none',
              }}
              {...superPopper.attributes.popper}
            >
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'normal',
                  fontSize: '12px',
                }}
              >
                Mentor dengan label ini memberikan diskon khusus untukmu!
              </Typography>
            </Box>
            {/* TODO: takeout dulu button videonya, kalau sudah ada video mentor di yt, open lagi */}
            {/* <Button
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                color: "#252525",
                borderRadius: 24,
                borderColor: "#fff",
                padding: "8px 0",
                width: 296,
                "&:hover": {
                  background: "#efefef",
                  borderColor: "#fff",
                },
              }}
              onClick={() => handleOpenVideo(product)}
            >
              <PlayCircleFilled sx={{ mr: 2 }} />
              <Typography variant="sm" style={{ textTransform: "none" }}>
                Putar Video Perkenalan
              </Typography>
            </Button> */}
          </div>
        </div>
      </CardMedia>

      <Dialog
        onClose={() => setVideo({ modal: false })}
        aria-labelledby='customized-dialog-title'
        open={video.modal}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle
          onClose={() => setVideo({ modal: false })}
          sx={{ padding: '16px' }}
        >
          {video.title}
          <IconButton
            aria-label='close'
            sx={{ padding: 0, position: 'absolute', right: '16px' }}
            onClick={() => setVideo({ modal: false })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* <DialogContent dividers sx={{ padding: "16px" }}>
          <video autoPlay controls width="100%">
            <source src={video.url} type="video/mp4" />
          </video>
        </DialogContent> */}
      </Dialog>
    </Card>
  );
};

CardImage.propTypes = {
  product: PropTypes.object.isRequired,
};

export default CardImage;
