const refServiceLine = [
  {
    name: 'College Life Sharing',
    value: 'College Life Sharing',
    checked: false,
  },
  {
    name: 'Professional Life Sharing',
    value: 'Professional Life Sharing',
    checked: false,
  },
  {
    name: 'Internship Preparation',
    value: 'Internship Preparation',
    checked: false,
  },
  {
    name: "Mentor's Career Path Exploration",
    value: "Mentor's Career Path Exploration",
    checked: false,
  },
  {
    name: 'Knowledge and Skill Preparation',
    value: 'Knowledge and Skill Preparation',
    checked: false,
  },
  {
    name: 'CV Preparation & Feedback',
    value: 'CV Preparation & Feedback',
    checked: false,
  },
  {
    name: 'Interview Preparation & Mock-up',
    value: 'Interview Preparation & Mock-up',
    checked: false,
  },
  {
    name: 'Opportunity and Network Sourcing',
    value: 'Opportunity and Network Sourcing',
    checked: false,
  },
];

const refIndustry = [
  {
    name: 'Teknologi/Startup',
    value: 'Teknologi/Startup',
    checked: false,
  },
  {
    name: 'FMCG',
    value: 'FMCG',
    checked: false,
  },
  {
    name: 'Financial Services',
    value: 'Financial Services',
    checked: false,
  },
  {
    name: 'Consulting/Advisory Services',
    value: 'Consulting/Advisory Services',
    checked: false,
  },
  {
    name: 'NGO',
    value: 'NGO',
    checked: false,
  },
];

const refSkills = [
  {
    name: 'Tech Engineering',
    value: 'Tech Engineering',
    checked: false,
  },
  {
    name: 'Product',
    value: 'Product',
    checked: false,
  },
  {
    name: 'Data Science',
    value: 'Data Science',
    checked: false,
  },
  {
    name: 'Marketing & PR',
    value: 'Marketing & PR',
    checked: false,
  },
  {
    name: 'Human Resource',
    value: 'Human Resource',
    checked: false,
  },
  {
    name: 'Consulting/Advisory Services',
    value: 'Consulting/Advisory Services',
    checked: false,
  },
  {
    name: 'Business Development',
    value: 'Business Development',
    checked: false,
  },
  {
    name: 'Finance',
    value: 'Finance',
    checked: false,
  },
  {
    name: 'UI/UX',
    value: 'UI/UX',
    checked: false,
  },
];

module.exports = {
  refServiceLine,
  refIndustry,
  refSkills,
};
