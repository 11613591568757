import { Typography, Fab, Grid, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    height: '121px',
    margin: '1.5rem 0',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 8px 8px 0px',
    filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12))',
    display: 'flex',
  },
  image: {
    width: '100px',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px 0px 0px 8px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  '@global': {
    '.css-1dchlth': {
      display: 'none !important',
    },
    '.react-pdf__Page': {
      zIndex: -1,
    },
    '.react-pdf__Page__canvas': {
      height: '121px !important',
    },
    '.react-pdf__Document': {
      paddingLeft: '5px !important',
    },
  },
}));

const ListGrid = ({
  id,
  cover,
  title,
  subTitle,
  data,
  handleClick,
  rightButton,
  handleButton,
  handleReview,
  tooltipComponent,
  tooltipComponent0,
  tooltipOpen,
  tooltipOpen0,
  handleChangeTabAts,
}) => {
  const classes = useStyles();

  return (
    <Grid id={id} xs={12} style={{ padding: '0 5px' }}>
      <div className={classes.card}>
        {/* checking cv is upload */}
        {data.cv ? (
          <Document file={{ url: data.cv }} className={classes.image}>
            <Page
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              scale={0.15}
            />
          </Document>
        ) : (
          <img
            alt='id'
            src={cover}
            className={classes.image}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png';
            }}
            onClick={() =>
              handleClick && handleClick({ id, title, subTitle, cover })
            }
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{ float: 'left', margin: 'auto 0' }}
            onClick={() =>
              handleClick && handleClick({ id, title, subTitle, cover })
            }
          >
            <div style={{ marginLeft: '32px' }}>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineheight: '140%',
                  letterSpacing: '0.1px',
                  color: '#252525',
                }}
              >
                {title}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  lineheight: '150%',
                  letterSpacing: '0.1px',
                  color: '#BDBDBD',
                  marginTop: '4px',
                }}
                sx={{ color: '#BDBDBD' }}
              >
                {subTitle}
              </Typography>
            </div>
          </div>
          <div style={{ float: 'right', margin: 'auto 0' }}>
            {tooltipOpen0 && (
              <Tooltip open={tooltipOpen0} title={tooltipComponent0} arrow>
                <Button
                  sx={{
                    borderRadius: '32px !important',
                    marginRight: '12px',
                    padding: '3px !important',
                    backgroundColor: '#FACD11 !important',
                  }}
                  onClick={handleChangeTabAts}
                >
                  <Typography
                    variant={'smallButton'}
                    sx={{ textTransform: 'none', padding: '5px 16px' }}
                  >
                    Ask for ATS Check
                  </Typography>
                </Button>
              </Tooltip>
            )}
            {!tooltipOpen0 && (
              <Tooltip
                title={tooltipComponent0}
                arrow
                style={{ maxWidth: '168px !important' }}
              >
                <Button
                  sx={{
                    borderRadius: '32px !important',
                    marginRight: '12px',
                    padding: '3px !important',
                    backgroundColor: '#FACD11 !important',
                  }}
                  onClick={handleChangeTabAts}
                >
                  <Typography
                    variant={'smallButton'}
                    sx={{ textTransform: 'none', padding: '5px 16px' }}
                  >
                    Ask for ATS Check
                  </Typography>
                </Button>
              </Tooltip>
            )}

            {tooltipOpen && (
              <Tooltip open={tooltipOpen} title={tooltipComponent} arrow>
                <Button
                  sx={{
                    borderRadius: '32px !important',
                    marginRight: '24px',
                    padding: '3px !important',
                    backgroundColor: '#FACD11 !important',
                  }}
                  onClick={handleReview}
                >
                  <Typography
                    variant={'smallButton'}
                    sx={{ textTransform: 'none', padding: '5px 16px' }}
                  >
                    Ask for CV Review
                  </Typography>
                </Button>
              </Tooltip>
            )}
            {!tooltipOpen && (
              <Tooltip
                title={tooltipComponent}
                arrow
                style={{ maxWidth: '168px !important' }}
              >
                <Button
                  sx={{
                    borderRadius: '32px !important',
                    marginRight: '24px',
                    padding: '3px !important',
                    backgroundColor: '#FACD11 !important',
                  }}
                  onClick={handleReview}
                >
                  <Typography
                    variant={'smallButton'}
                    sx={{ textTransform: 'none', padding: '5px 16px' }}
                  >
                    Ask for CV Review
                  </Typography>
                </Button>
              </Tooltip>
            )}
            <Fab
              sx={{
                backgroundColor: '#fff !important',
                boxShadow: 'none !important',
                marginRight: '1rem',
              }}
              size='small'
              onClick={(e) => handleButton(e, { data })}
              aria-owns={'popover'}
            >
              {rightButton}
            </Fab>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default ListGrid;
