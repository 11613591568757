import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ATSCheckerModal = ({ handleModalOpen, modalOpen, datas }) => {
  return (
    <Dialog
      onClose={handleModalOpen}
      aria-labelledby='customized-dialog-title'
      open={modalOpen}
      scroll={'paper'}
      maxWidth={'sm'}
      fullWidth
      sx={{ zIndex: '10000000000 !important' }}
    >
      <DialogTitle id='scroll-dialog-title'>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleModalOpen} style={{ padding: '0' }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography gutterBottom variant='subHeader16' sx={{ m: 'auto 0' }}>
            ATS Checker Result
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='subHeader14' sx={{ m: 'auto 0' }}>
            {`Resume Data`}
          </Typography>
        </div>
        <div style={{ paddingTop: '10px' }}>
          <Typography gutterBottom sx={{ m: 'auto 0' }}>
            {datas}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ATSCheckerModal;
