import { Container, Grid, Typography } from '@material-ui/core';
import first from 'src/assets/img/landing/flowMentoring/1.png';
import second from 'src/assets/img/landing/flowMentoring/2.png';
import third from 'src/assets/img/landing/flowMentoring/3.png';
import fourth from 'src/assets/img/landing/flowMentoring/4.png';
import fifth from 'src/assets/img/landing/flowMentoring/5.png';
import sixth from 'src/assets/img/landing/flowMentoring/6.png';

const FlowSection = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: '#1D2D5E',
          position: 'absolute',
          width: '100%',
          height: '232px',
        }}
      >
        {' '}
      </div>
      <Container style={{ position: 'inherit' }}>
        <Typography variant='header' style={{ paddingTop: '27px' }}>
          Alur Mentoring
        </Typography>
        <Typography
          variant='p'
          style={{
            marginTop: '10px',
            marginBottom: '30px',
            padding: '0px 60px',
          }}
        >
          Ikuti langkah-langkah berikut untuk mentoring bersama Aksel!
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent='center'
          style={{
            width: '100%',
            marginBottom: '56px',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '505px',
          }}
        >
          {[first, second, third, fourth, fifth, sixth].map((d, idx) => (
            <Grid
              item
              xs={6}
              sm={4}
              key={idx}
              sx={{ paddingLeft: '0px !important' }}
            >
              <img src={d} alt='mentoring-flow' />
            </Grid>
          ))}
          {/* {[
            { no: 1, desc: "Pilih Layanan", background: stepLayanan },
            { no: 2, desc: "Pilih Mentor", background: stepMentor },
            {
              no: 3,
              desc: `Tulis Catatan untuk Mentor`,
              background: stepCatatan,
            },
            {
              no: 4,
              desc: "Mulai Sesi Mentoring",
              background: stepMentoring,
            },
          ].map((idx) => (
            <Grid item xs={6}>
              <div
                style={{
                  background: `url(${idx.background})`,
                  backgroundSize: "cover",
                  borderRadius: "8px",
                }}
              >
                <Card
                  style={{
                    height: "145px",
                    background: `rgba(91, 192, 235, 0.7)`,
                    borderRadius: "8px",
                  }}
                >
                  <Card
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "20px",
                      marginTop: "40px",
                      marginLeft: "16px",
                    }}
                  >
                    <Typography
                      variant="subHeader14"
                      style={{ paddingTop: "6px" }}
                    >
                      {idx.no}
                    </Typography>
                  </Card>
                  <Typography
                    variant="subHeader14"
                    style={{
                      marginTop: "24px",
                      marginLeft: "16px",
                      textAlign: "left",
                      maxWidth: "121px",
                    }}
                  >
                    {idx.desc}
                  </Typography>
                </Card>
              </div>
            </Grid>
          ))} */}
        </Grid>
      </Container>
    </>
  );
};

export default FlowSection;
